import { ErrorCode } from "@/api/sdk";
import { useTranslation } from "react-i18next";
import { type I18NErrorCodesKey, translate } from "../i18n";
import { enumValuesToObject } from "./enumValuesToObject";

/**
 * Enums maps to translation keys.
 * The keys are not parsed by i18next-parser.
 * The name of the locale file is "error-code".
 */

type EnumMap<T extends string> = Record<T, I18NErrorCodesKey>;

const NAMESPACE = "error-code";
const GENERAL_ERROR_KEY: I18NErrorCodesKey = "GENERAL";

const errorCodes = {
  ...enumValuesToObject(ErrorCode),
  GENERAL: GENERAL_ERROR_KEY,
} satisfies EnumMap<ErrorCode | "GENERAL">;

const useTranslationErrorCode = () => {
  const { t } = useTranslation("error-code");

  function tErrorCodes<T extends keyof typeof errorCodes>(code: T) {
    const i18nKey = code === GENERAL_ERROR_KEY ? GENERAL_ERROR_KEY : errorCodes[code] || GENERAL_ERROR_KEY;
    t(i18nKey);
  }

  return { tErrorCodes };
};

function translateErrorCode<T extends keyof typeof errorCodes>(code: T) {
  // The code type is based on the error code enum from the api sdk. But the code could be a out of ErrorCode enum.
  // In this case, the default error is still handled.
  const i18nKey = code === GENERAL_ERROR_KEY ? GENERAL_ERROR_KEY : errorCodes[code] || GENERAL_ERROR_KEY;
  return translate(i18nKey, { ns: NAMESPACE });
}

export { useTranslationErrorCode, translateErrorCode };
