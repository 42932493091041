import { CountrySelect } from "@/client-account/components/CountrySelect";
import { Button, Input } from "@roboton/ui";
import clsx from "clsx";
import { type SubmitErrorHandler, type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormInputs = {
  address: string;
  businessName: string;
  city: string;
  country: string;
  postalCode: string;
};

type ClientAccountFormProps = Omit<JSX.IntrinsicElements["form"], "onSubmit"> & {
  submitButtonText: string;
  defaultValues?: FormInputs;
  onSubmitValid: SubmitHandler<FormInputs>;
  onSubmitInvalid?: SubmitErrorHandler<FormInputs>;
};

const textPattern = /^[a-zA-ZÀ-ž-0-9-\/\s]+$/;
const numberPattern = /^[!-z\s, 0-9]*$/;

export const ClientAccountForm = ({
  submitButtonText,
  defaultValues,
  onSubmitValid,
  onSubmitInvalid,
  className,
  ...rest
}: ClientAccountFormProps) => {
  const { t } = useTranslation();
  const { formState, handleSubmit, register } = useForm<FormInputs>({ defaultValues });
  const { errors } = formState;

  return (
    <form {...rest} onSubmit={handleSubmit(onSubmitValid, onSubmitInvalid)} className={clsx(className, "space-y-6")}>
      <Input
        {...register("businessName", { minLength: 1, maxLength: 50, required: true, pattern: textPattern })}
        id={"client-account-businessName"}
        label={t("Business Name")}
        state={errors.businessName && "negative"}
        required
        autoComplete={"organization"}
        aria-invalid={!!errors.businessName}
      />
      <Input
        {...register("address", { minLength: 1, maxLength: 100, required: true, pattern: textPattern })}
        id={"client-account-firstname"}
        label={t("Street name and number")}
        state={errors.address && "negative"}
        required
        autoComplete={"street-address"}
        aria-invalid={!!errors.address}
      />
      <Input
        {...register("postalCode", { minLength: 1, maxLength: 30, required: true, pattern: numberPattern })}
        id={"client-account-postalCode"}
        label={t("Postal Code")}
        state={errors.postalCode && "negative"}
        required
        autoComplete={"postal-code"}
        aria-invalid={!!errors.postalCode}
      />
      <Input
        {...register("city", { minLength: 1, maxLength: 30, required: true, pattern: textPattern })}
        id={"client-account-city"}
        label={t("City")}
        state={errors.city && "negative"}
        required
        autoComplete={"address-level1"}
        aria-invalid={!!errors.city}
      />
      <CountrySelect
        {...register("country", { required: true })}
        id={"client-account-country"}
        label={t("Country")}
        state={errors.country && "negative"}
        required
        autoComplete={"country-code"}
        aria-invalid={!!errors.country}
      />

      <div className={"text-center"}>
        <Button type={"submit"} size={"large"} icon={"check"}>
          {submitButtonText}
        </Button>
      </div>
    </form>
  );
};
