import { ProtectedLayer } from "@/router/components/ProtectedLayer";
import { routes, useCurrentRoute } from "@/routes";
import type { ReactNode } from "react";
import { useAccessPermissionsStore } from "./store";

type AccessPermissionsProtectedRouteProps = {
  children?: ReactNode;
};

export const AccessPermissionsProtectedLayer = ({ children }: AccessPermissionsProtectedRouteProps): JSX.Element => {
  const { allowedRoutes, isLoading } = useAccessPermissionsStore();
  const currentRoute = useCurrentRoute();
  const isAllowed = currentRoute ? allowedRoutes.includes(currentRoute) : false;

  if (isLoading) {
    return (
      <div className={"bg-light-0 typo-md--bold absolute inset-0 flex items-center justify-center"}>loading...</div>
    );
  }

  return (
    <ProtectedLayer isAllowed={isAllowed} redirectTo={routes.Home}>
      {children}
    </ProtectedLayer>
  );
};
