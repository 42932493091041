import type { Merge } from "@roboton/tools";
import clsx from "clsx";
import { type ReactNode, forwardRef } from "react";

type RadioProps = Merge<
  Omit<JSX.IntrinsicElements["input"], "type" | "placeholder" | "children">,
  {
    label?: ReactNode;
    id: string;
    state?: "base" | "negative";
    size?: "large" | "medium" | "small";
  }
>;

const stateToClassNameMap: Record<Required<RadioProps>["state"], string> = {
  base: "ui-radio--base",
  negative: "ui-radio--negative",
};

const sizeToClassNameMap: Record<Required<RadioProps>["size"], string> = {
  large: "ui-radio--large",
  medium: "ui-radio--medium",
  small: "ui-radio--small",
};

/*
 * Children can contain links so must not be cover by any layer.
 * */
export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ state = "base", size = "medium", label, id, disabled, readOnly, className, ...props }, ref): JSX.Element => {
    const hasLabel = typeof label === "string" ? !!label.length : label !== undefined;
    const Element = hasLabel ? "label" : "div";
    const isTouchable: boolean = !disabled && !readOnly;

    return (
      <Element
        className={clsx(
          "ui-radio",
          isTouchable ? stateToClassNameMap[state] : "ui-radio--disabled",
          hasLabel && "ui-radio--has-label",
          sizeToClassNameMap[size],
          className,
        )}
        {...(hasLabel && { htmlFor: id })}
      >
        <input
          {...props}
          type="radio"
          className={"ui-radio__element"}
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          ref={ref}
        />
        <span className={"ui-radio__checked-icon"} aria-hidden />

        {label ? <span className={"ui-radio__label"}>{label}</span> : null}
      </Element>
    );
  },
);

Radio.displayName = "Radio";
