import { zodResolver } from "@hookform/resolvers/zod";
import { FormField, Select } from "@roboton/ui";
import type { TFunction } from "i18next";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import z from "zod";

/*
---------------------------------------------------------------------
Form Validation Schema utlity
---------------------------------------------------------------------
 */
function getValidationSchema(t: TFunction) {
  return z.object({
    cropId: z.string().min(1, { message: t("Required") }),
    fieldId: z.string().min(1, { message: t("Required") }),
    blockId: z.string().min(1, { message: t("Required") }),
  });
}

type FormData = z.infer<ReturnType<typeof getValidationSchema>>;

/*
---------------------------------------------------------------------
Form Hook
Wrapper around the useForm hook with localized validation schema
---------------------------------------------------------------------
 */
const useCustomForm = (options?: Omit<Parameters<typeof useForm<FormData>>[0], "resolver">) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getValidationSchema(t), [t]);
  return useForm<FormData>({
    ...options,
    resolver: zodResolver(schema),
    defaultValues: {
      fieldId: "",
      cropId: "",
      blockId: "",
    },
  });
};

/*
---------------------------------------------------------------------
Form Component
---------------------------------------------------------------------
 */
type SelectOption = { value: string; label: string };

type FormProps = {
  form: UseFormReturn<FormData>;
  formId: string;
  options: {
    crops: SelectOption[];
    fields: (SelectOption & { isReady: boolean })[];
    blocks: (SelectOption & { isReady: boolean })[];
  };
  isBlockOptionsListFetching: boolean;
  onSuccessSubmit: (data: FormData) => void;
};

const Form = ({ form, formId, options, isBlockOptionsListFetching, onSuccessSubmit }: FormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, register, setValue } = form;

  const hasBlocks = options.blocks.length > 0;
  const isAnyBlockReady = options.blocks.some((block) => block.isReady);
  const isBlockSelectDisabled = isBlockOptionsListFetching || !hasBlocks || !isAnyBlockReady;

  // Reset blockId when fieldId was changed.
  const handleFieldIdChange = () => setValue("blockId", "");

  const messageNoBlocks: Required<ComponentProps<typeof FormField>>["messages"][number] = {
    key: "no-blocks",
    variant: "warning",
    children: t("The Selected Field has no Blocks"),
    icon: "warning",
  };

  const messageNoBlockIsReady: Required<ComponentProps<typeof FormField>>["messages"][number] = {
    key: "no-block-is-ready",
    variant: "warning",
    children: t("The Selected Field has no Block ready to be used"),
    icon: "warning",
  };
  const fieldMessages: ComponentProps<typeof FormField>["messages"] = !hasBlocks
    ? [messageNoBlocks]
    : !isAnyBlockReady
      ? [messageNoBlockIsReady]
      : undefined;

  return (
    <form id={formId} className={"space-y-10"} onSubmit={handleSubmit(onSuccessSubmit)}>
      <fieldset className={"space-y-4"}>
        <legend className={"typo-h2"}>{t("What I want to grow")}</legend>
        <FormField>
          <Select {...register("cropId")} id={`${formId}-crop`} label={t("Select the Crop")}>
            {options.crops.map((crop) => (
              <option key={crop.value} value={crop.value}>
                {crop.label}
              </option>
            ))}
          </Select>
        </FormField>
      </fieldset>

      <fieldset className={"space-y-4"}>
        <legend className={"typo-h2"}>{t("Where I want to grow it")}</legend>
        <FormField messages={fieldMessages}>
          <Select
            {...register("fieldId", { onChange: handleFieldIdChange })}
            id={`${formId}-field`}
            label={t("Select the Field")}
          >
            {options.fields.map((field) => (
              <option key={field.value} value={field.value} disabled={!field.isReady}>
                {field.label}
                {field.isReady ? null : ` [${t("Data is being downloaded, this Field can be selected later")}]`}
              </option>
            ))}
          </Select>
        </FormField>

        <FormField>
          <Select
            {...register("blockId", { disabled: isBlockSelectDisabled })}
            id={`${formId}-block`}
            label={t("Select the Block of the Field")}
          >
            {options.blocks.map((block) => (
              <option key={block.value} value={block.value} disabled={!block.isReady}>
                {block.label}
                {block.isReady ? null : ` [${t("Block is not ready yet, please select another one.")}]`}
              </option>
            ))}
          </Select>
        </FormField>
      </fieldset>
    </form>
  );
};

export {
  Form as CreateGrowingPlanBaseInfoForm,
  useCustomForm as useCreateGrowingPlanBaseInfoForm,
  type FormData as CreateGrowingPlanBaseInfoFormData,
};
