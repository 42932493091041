import pino from "pino";

const isTest = process.env.NODE_ENV === "test";

export const logger = pino({
  enabled: !isTest,
  browser: {
    asObject: true,
  },
});
