import { logger } from "@/logger";
import { forwardRef } from "react";

const PATH_ID = "ld";
const CLIP_PATH_ID = "clip";

type ProgressCirclePathProps = Omit<JSX.IntrinsicElements["path"], "d" | "fill" | "strokeDasharray"> & {
  percentage: number;
};
const ProgressCirclePath = ({ percentage, ...rest }: ProgressCirclePathProps) => {
  if (percentage < 0) logger.warn("Percentage cannot be less than 0", percentage);
  if (percentage > 100) logger.warn("Percentage cannot be greater than 100", percentage);
  const sanitizedPercentage = Math.min(Math.max(percentage, 0), 100);

  return (
    <path
      {...rest}
      d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
      fill="none"
      clipPath={`url(#${CLIP_PATH_ID})`}
      id={PATH_ID}
      strokeDasharray={`${sanitizedPercentage}, 100`}
    />
  );
};

type Item = {
  key: string;
  percentage: number;
  pathProps?: Omit<ProgressCirclePathProps, "percentage">;
};

type ProgressCircleProps = Omit<JSX.IntrinsicElements["svg"], "viewBox"> & {
  items: Item[];
  pathStroke?: string;
};

/**
 * Show a progress circle with multiple items. Each item starts where the previous item ended.
 * The total percentage of all items cannot be greater than 100.
 * The color of each item can be customized by the `pathProps` prop.
 */
const PercentageProgressCircle = forwardRef<SVGSVGElement, ProgressCircleProps>(({ items, children, ...rest }, ref) => {
  // loop over items, add previous item percentage to the current item percentage
  const paths = items
    .map((item, index, array) => {
      const previousItemPercentage = array[index - 1]?.percentage || 0;
      const percentage = item.percentage + previousItemPercentage;

      if (percentage > 100) logger.warn("Total percentage cannot be greater than 100", percentage);

      return <ProgressCirclePath {...item.pathProps} key={item.key} percentage={percentage} />;
    })
    .reverse();

  return (
    <svg {...rest} ref={ref} viewBox="0 0 36 36">
      {children}
      <ProgressCirclePath percentage={100} className={"stroke-light-50/50"} role={"presentation"} />
      {paths}

      {/*
        clip path makes stroke inside the path
        source: https://stackoverflow.com/a/32162431
         */}
      <clipPath id={CLIP_PATH_ID}>
        <use xlinkHref={`#${PATH_ID}`} />
      </clipPath>
    </svg>
  );
});

PercentageProgressCircle.displayName = "PercentageProgressCircle";

export { PercentageProgressCircle };
