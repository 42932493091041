import { FieldTaskStartOption } from "@/api/sdk";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { FormField, Input, Radio } from "@roboton/ui";
import { type Control, Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

const TASK_START_OPTION_ITEMS =
  // convert enum to an array
  Object.values(FieldTaskStartOption)
    // sort by custom order
    .sort((a, b) => {
      if (a === FieldTaskStartOption.AsSoonAsPossible) return -1;
      if (b === FieldTaskStartOption.AfterLastTask) return 1;
      return 0;
    });

type StartFieldsetData = {
  startAtDate?: string;
  startAtTime?: string;
  startOption: FieldTaskStartOption;
};

const StartFieldset = <T extends StartFieldsetData>({ control: controlOrigin }: { control: Control<T> }) => {
  const control = controlOrigin as unknown as Control<StartFieldsetData>;
  const { t } = useTranslation();
  const { tEnums } = useTranslationEnums();

  const startOption = useWatch<StartFieldsetData>({ name: "startOption", control });
  const isStartScheduled = startOption === FieldTaskStartOption.ScheduleStart;

  return (
    <fieldset className={"flex flex-col"}>
      <div className={"flex flex-wrap gap-x-6 gap-y-2"}>
        {TASK_START_OPTION_ITEMS.map((type) => (
          <Controller
            key={type}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Radio
                {...field}
                value={type}
                id={type}
                label={tEnums("fieldTaskStartOption", type)}
                state={error ? "negative" : undefined}
                defaultChecked={control._defaultValues.startOption === type}
              />
            )}
            name={"startOption"}
          />
        ))}
      </div>

      <p id={"start-at-label"} className={"font-bold mt-4"}>
        For when I want to create a new task
      </p>
      <FormField aria-labelledby={"start-at-label"} className={"sm:grid sm:grid-cols-2 sm:gap-x-4"}>
        <Controller
          control={control}
          rules={{ required: isStartScheduled }}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              id={"start-at-date"}
              label={t("Set the date")}
              type={"date"}
              state={error ? "negative" : undefined}
              readOnly={!isStartScheduled}
              className={"grow"}
            />
          )}
          defaultValue={control._defaultValues.startAtDate || ""}
          name={"startAtDate"}
        />

        <Controller
          control={control}
          rules={{ required: isStartScheduled }}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              id={"start-at-time"}
              label={t("Set the time")}
              type={"time"}
              state={error ? "negative" : undefined}
              readOnly={!isStartScheduled}
              className={"grow"}
            />
          )}
          defaultValue={control._defaultValues.startAtTime || ""}
          name={"startAtTime"}
        />
      </FormField>
    </fieldset>
  );
};

export { StartFieldset, type StartFieldsetData };
