import { useMemo } from "react";
import { generatePath, matchPath, useLocation } from "react-router-dom";

/**
 * App routes.
 * If you want to add a new route, you need to add it here.
 * Don't forget to set the access permissions in the `AccessPermissions` provider (@/access-permissions/provider.tsx).
 */
export const routes = {
  Home: "/",
  Register: "/register",
  // Dashboard route has no own page, it's a redirect to the first page in the dashboard. Check the Router component.
  Dashboard: "/dashboard",
  ClientAccounts: "/dashboard/client-accounts",
  CreateClientAccount: "/dashboard/client-accounts/create",
  EditClientAccount: "/dashboard/client-accounts/:id/edit",

  Fields: "/dashboard/fields",
  CreateField: "/dashboard/fields/create",
  EditField: "/dashboard/fields/:id/edit",
  FieldDetail: "/dashboard/fields/:id",
  ManageSegments: "/dashboard/fields/:id/segments",
  ManageBoundaries: "/dashboard/fields/:id/boundaries",
  ManageBlocks: "/dashboard/fields/:id/blocks",
  ManageWorkingLines: "/dashboard/fields/:id/working-lines",
  ManageHomeStation: "/dashboard/fields/:id/home-station",
  BlockDetail: "/dashboard/fields/:id/blocks/:blockId",
  BlockDetailGeneral: "/dashboard/fields/:id/blocks/:blockId/general",
  BlockDetailTasks: "/dashboard/fields/:id/blocks/:blockId/tasks",
  BlockDetailTask: "/dashboard/fields/:id/blocks/:blockId/tasks/:taskId",

  Robots: "/dashboard/robots",
  RegisterRobot: "/dashboard/robots/add",
  RobotDetail: "/dashboard/robots/:id",
  RobotDetailGeneral: "/dashboard/robots/:id/general",
  RobotDetailTasks: "/dashboard/robots/:id/tasks",
  RobotDetailTask: "/dashboard/robots/:id/tasks/:taskId",

  CropSheet: "/dashboard/crop-sheet",
  CropsOverview: "/dashboard/crop-sheet/crops",
  ThreatsOverview: "/dashboard/crop-sheet/diseases",

  CropDetail: "/dashboard/crop-sheet/crops/:id",
  AboutCrop: "/dashboard/crop-sheet/crops/:id/about",
  CropStages: "/dashboard/crop-sheet/crops/:id/stages",
  // Crop Threats
  CropThreatsDiseases: "/dashboard/crop-sheet/crops/:id/diseases",
  CropThreatsPests: "/dashboard/crop-sheet/crops/:id/pests",
  CropThreatsWeeds: "/dashboard/crop-sheet/crops/:id/weeds",

  ThreatDetail: "/dashboard/crop-sheet/diseases/:threatId",

  UserProfile: "/dashboard/user",

  GrowingPlans: "/dashboard/growing-plans",
  CreateGrowingPlan: "/dashboard/growing-plans/create",
  GrowingPlanDetail: "/dashboard/growing-plans/:id",
  GrowingPlanDetailGeneral: "/dashboard/growing-plans/:id/general",
  GrowingPlanDetailTimeline: "/dashboard/growing-plans/:id/timeline",
  GrowingPlanDetailRobotTasks: "/dashboard/growing-plans/:id/robot-tasks",
  GrowingPlanDetailRobotTask: "/dashboard/growing-plans/:id/robot-tasks/:taskId",
  GrowingPlanDetailPlants: "/dashboard/growing-plans/:id/plant-spacing",
} as const;

export { generatePath };

const routesArray = Object.values(routes);
/**
 * Get unresolved current route.
 * Example: /dashboard/fields/1 -> /dashboard/fields/:id
 */
export const useCurrentRoute = () => {
  const { pathname } = useLocation();
  return useMemo(() => routesArray.find((route) => matchPath(route, pathname)), [pathname]);
};
