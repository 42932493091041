function calculateRowWidth({
  robotWidth,
  trackWidth,
  swathSideMargin,
  numberOfRows,
}: {
  robotWidth: number;
  trackWidth: number;
  swathSideMargin: number;
  numberOfRows: number;
}) {
  if (numberOfRows === 0) return 0;
  return (robotWidth - 2 * trackWidth - 2 * swathSideMargin) / numberOfRows;
}

function calculateNumberOfRows({
  robotWidth,
  trackWidth,
  rowWidth,
}: {
  robotWidth: number;
  trackWidth: number;
  rowWidth: number;
}) {
  if (rowWidth === 0) return 0;
  const widthOfSwath = robotWidth - 2 * trackWidth;
  return Math.floor(widthOfSwath / rowWidth);
}

function calculateSwathSideMargin({
  robotWidth,
  trackWidth,
  rowWidth,
}: {
  robotWidth: number;
  trackWidth: number;
  rowWidth: number;
}) {
  if (rowWidth === 0) return 0;
  const widthOfSwath = robotWidth - 2 * trackWidth;
  const numberOfRows = Math.floor(widthOfSwath / rowWidth);
  return (widthOfSwath - numberOfRows * rowWidth) / 2;
}

export const plantSpacingUtils = {
  calculateRowWidth,
  calculateNumberOfRows,
  calculateSwathSideMargin,
};

export const segmentIndexToNameMap = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];
