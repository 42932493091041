import { Message } from "@roboton/ui";
import type { MapLayerMouseEvent } from "maplibre-gl";
import { nanoid } from "nanoid";
import type { ComponentProps } from "react";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import type { CoordinatesType, SegmentPreviewType, SwathSegmentInput } from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { LoadingContent } from "@/components/LoadingContent";
import { Layer, MapComponent, MapUtils, Source } from "@/components/Map";
import { BLOCK_THEME_CONFIG } from "@/components/Map/drawTheme";
import type { FieldModel } from "@/field/field.model";
import { SegmentsSplittingForm } from "@/field/segments/SegmentsSplittingForm";
import { usePreviewSegments } from "@/field/segments/hooks/useSpacingPreview";
import { groupBy } from "@/utils/groupBy";
import type { MapRef } from "react-map-gl/maplibre";

const SELECTED_COLOR = "#FFC8ED";

type SegmentsSplittingFormProps = ComponentProps<typeof SegmentsSplittingForm>;

type Segment = Pick<SegmentPreviewType, "swathId" | "name" | "boundaries" | "startPoint" | "endPoint"> & {
  id: string;
};

type ManageSegmentsPageContentProps = {
  field: FieldModel;
  formId: string;
  onSubmit: (segmentsInput: SwathSegmentInput[]) => void;
};

const getPropsForMap = (sourceId: string, color: string, label: string, boundaries: CoordinatesType[]) => {
  return {
    polygonProps: {
      sourceProps: MapUtils.getPolygonSourceProps(sourceId, MapUtils.getCoords(boundaries)),
      layerProps: {
        ...MapUtils.getPolygonLayerProps(sourceId, {
          color,
          opacity: BLOCK_THEME_CONFIG.polygon.opacity,
        }),
      },
    },
    lineProps: {
      layerProps: {
        ...MapUtils.getLineLayerProps(sourceId, {
          color,
          opacity: BLOCK_THEME_CONFIG.line.opacity,
          width: BLOCK_THEME_CONFIG.line.width,
        }),
      },
    },
    textProps: {
      layerProps: MapUtils.getTextLayerProps(sourceId, label),
    },
  };
};

export const ManageSegmentsPageContent = ({ field, formId, onSubmit }: ManageSegmentsPageContentProps) => {
  const { t } = useTranslation();

  const { propsForMap: fieldPropsForMap, bounds } = field;

  const [swaths, setSwaths] = useState<Record<string, Segment[]>>(() => {
    const initialSegments = field.unassignedSegments.flatMap((s) =>
      // SegmentType has optional startPoint and endPoint. This is a workaround to satisfy the type.
      // TODO: consult with the backend team about the optional type. It should be required as it is in the SegmentPreviewType.
      s.startPoint && s.endPoint && s.swath
        ? [{ ...s, swathId: s.swath.id, startPoint: s.startPoint, endPoint: s.endPoint }]
        : [],
    ) satisfies Segment[];
    return groupBy(initialSegments, (s) => s.swathId);
  });

  const formDefaultValues = useMemo(
    () => ({
      gap: 0,
      maximumLength: 0,
    }),
    [],
  ) satisfies SegmentsSplittingFormProps["defaultValues"];

  const [selectedSwaths, setSelectedSwaths] = useState<string[]>([]);

  const { mutate: previewSegments, isPending: isPreviewLoading } = usePreviewSegments({
    onSuccess: (data) => {
      setSwaths((prevSwaths) => {
        const updatedSwaths = { ...prevSwaths };
        for (const swath of data.previewSegments) {
          updatedSwaths[swath.swathId] = swath.segments.map((s) => ({ ...s, id: nanoid(), swathId: swath.swathId }));
        }
        return updatedSwaths;
      });
    },
  });

  const applySplitting: SegmentsSplittingFormProps["onSubmitValid"] = (values) => {
    previewSegments({
      swathsIds: selectedSwaths,
      length: values.maximumLength,
      gap: values.gap,
    });
  };

  const segments = Object.values(swaths)
    .flat()
    .map((segment) => {
      const isSelected = selectedSwaths.includes(segment.swathId);
      const color = isSelected ? SELECTED_COLOR : "gray";
      const segmentId = segment.id;
      return {
        ...segment,
        propsForMap: getPropsForMap(segmentId, color, segment.name, segment.boundaries),
      };
    });

  const handleSaveSubmit: JSX.IntrinsicElements["form"]["onSubmit"] = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const input: SwathSegmentInput[] = Object.entries(swaths).map(([swathId, segments]) => ({
      swathId,
      segments: segments.map(({ startPoint, endPoint }) => ({ startPoint, endPoint })),
    }));
    onSubmit(input);
  };

  const handleResetSubmit: JSX.IntrinsicElements["form"]["onReset"] = () => {
    previewSegments({
      swathsIds: selectedSwaths,
      ...formDefaultValues,
    });
  };

  const onMapClick = (e: MapLayerMouseEvent) => {
    const clickedSwathIds = e.features?.map((f) => f.layer.id);

    if (!clickedSwathIds?.length) return;

    // Symmetrical Difference of two arrays (-)
    const difference = clickedSwathIds
      .filter((x) => !selectedSwaths.includes(x))
      .concat(selectedSwaths.filter((x) => !clickedSwathIds.includes(x)));

    setSelectedSwaths(difference);
  };

  const onMapMouseEnter = (e: MapLayerMouseEvent) => {
    if (!mapRef?.current) {
      return;
    }
    mapRef.current.getCanvas().style.cursor = e.features?.length ? "pointer" : "grab";
  };
  const onMapMouseLeave = (e: MapLayerMouseEvent) => {
    if (!mapRef?.current) {
      return;
    }
    mapRef.current.getCanvas().style.cursor = e.features?.length ? "grab" : "pointer";
  };

  const mapRef = useRef<MapRef>(null);

  return (
    <Bricks.Layout
      mapArea={
        <Bricks.MapArea className={"relative"}>
          <MapComponent
            mapRef={mapRef}
            initialViewState={{ bounds, fitBoundsOptions: { padding: 20 } }}
            enableControls
            onClick={onMapClick}
            onMouseEnter={onMapMouseEnter}
            onMouseLeave={onMapMouseLeave}
            interactiveLayerIds={segments.map((s) => s.swathId)} // allow segments polygon to be clickable
          >
            <Source {...fieldPropsForMap.polygon.sourceProps}>
              <Layer {...fieldPropsForMap.polygon.layerProps} />
            </Source>

            {segments.map((segment) => (
              <Source key={segment.id} {...segment.propsForMap.polygonProps.sourceProps}>
                <Layer id={segment.swathId} {...segment.propsForMap.polygonProps.layerProps} />
                <Layer {...segment.propsForMap.lineProps.layerProps} />
                <Layer {...segment.propsForMap.textProps.layerProps} />
              </Source>
            ))}

            {isPreviewLoading ? <LoadingContent className={"bg-light-25 absolute inset-0 bg-opacity-60"} /> : null}
          </MapComponent>
        </Bricks.MapArea>
      }
    >
      <form id={formId} onSubmit={handleSaveSubmit} aria-hidden />
      <Bricks.Card headline={t("Split Segments of the Field into Blocks")}>
        <p>
          {t(
            "Split the segments into smaller blocks. The more different crops you want at different times of the year, the more blocks you need.",
          )}
        </p>
        <p>
          {t(
            "Select segments on the map and split them into blocks using the form below. Confirm segment management when you're done splitting them.",
          )}
        </p>

        <Message icon={"warning"} color={"yellow"}>
          {t("This setting can never be adjusted in the future!")}
        </Message>
      </Bricks.Card>

      <Bricks.Card headline={t("Segment Selection")}>
        <p>{t("Click on the segments on the map to select them.")}</p>
        <div className={"flex flex-wrap gap-2 pb-4"}>
          <span className={"font-bold"}>{selectedSwaths.length ? t("Selected Segments:") : null}</span>
          {selectedSwaths.map((swathId) => (
            <div key={swathId} className={"px-2"} style={{ backgroundColor: SELECTED_COLOR }}>
              {swaths?.[swathId]?.[0]?.name}
            </div>
          ))}
        </div>

        <SegmentsSplittingForm
          className={"contents"}
          defaultValues={formDefaultValues}
          onSubmitValid={applySplitting}
          onReset={handleResetSubmit}
          disabled={!selectedSwaths.length}
        />
      </Bricks.Card>
    </Bricks.Layout>
  );
};
