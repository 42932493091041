import type { BlockModelFragment } from "@/api/sdk";
import { MapUtils } from "@/components/Map";
import { BLOCK_THEME_CONFIG } from "@/components/Map/drawTheme";
import type { Coords } from "@/components/Map/types";
import { logger } from "@/logger";
import { formatColor, getColorFromString } from "@/utils/color";

type RemoteBlock = BlockModelFragment;
type RemoteSegment = Exclude<RemoteBlock["segments"], null>[number];

const UNCATEGORIZED_COLOR = "808080";

export class BlockModel {
  constructor(private readonly remoteBlock: RemoteBlock) {}

  get isUncategorized() {
    return false;
  }

  get isNew() {
    return false;
  }

  get isRenameable() {
    return !this.isUncategorized;
  }

  get isColorable() {
    return !this.isUncategorized;
  }

  get isDeletable() {
    return !this.isUncategorized;
  }

  updateBoundaries(boundaries: { lat: number; lon: number }[]) {
    const currentSegment = this.remoteBlock.segments?.[0];
    if (!currentSegment) throw new Error("Block has no segments");

    this.remoteBlock.segments = [
      {
        id: currentSegment.id,
        boundaries,
        name: "New segment",
        order: 1,
        area: 0,
        startPoint: null,
        endPoint: null,
        swath: currentSegment.swath || null,
      },
    ];
  }

  updateName(name: string) {
    if (!this.isRenameable) {
      logger.warn(`Cannot update name of the Block ${this.remoteBlock.id}`);
      return false;
    }
    this.remoteBlock.name = name;
    return true;
  }

  updateColor(color: string) {
    if (!this.isColorable) {
      logger.warn(`Cannot update color of the Block ${this.remoteBlock.id}`);
      return false;
    }
    this.remoteBlock.color = color;
    return true;
  }

  addSegment(segment: RemoteSegment) {
    this.remoteBlock.segments = [...this.segments, segment];
  }

  addSegments(segments: RemoteSegment[]) {
    this.remoteBlock.segments = [...this.segments, ...segments];
  }

  removeSegment(segmentId: string) {
    this.remoteBlock.segments = [...this.segments].filter((segment) => segment.id !== segmentId);
  }

  updateSegment(segmentId: string, boundaries: RemoteSegment["boundaries"]) {
    this.remoteBlock.segments = [...this.segments].map((segment) => {
      return segment.id !== segmentId ? segment : { ...segment, boundaries };
    });
  }

  removeAllSegments() {
    this.remoteBlock.segments = [];
  }

  // get deep copy of the Block
  clone() {
    const deepCopy = {
      ...this.remoteBlock,
      segments: [...(this.remoteBlock.segments || [])],
    };

    return Object.assign(Object.create(Object.getPrototypeOf(this)), { ...this, remoteBlock: deepCopy });
  }

  get id() {
    return this.remoteBlock.id;
  }

  get remoteId() {
    if (this.isUncategorized || this.isNew) {
      return undefined;
    }

    return this.remoteBlock.id;
  }

  /* HEX code */
  get color() {
    return this.remoteBlock.color;
  }

  get formattedColor() {
    return formatColor(this.color);
  }

  get name() {
    return this.remoteBlock.name;
  }

  get area() {
    return this.remoteBlock.area || 0;
  }

  get createdAt() {
    return this.remoteBlock.createdAt;
  }

  get segmentsArea() {
    return this.segments.reduce((acc, segment) => acc + segment.area, 0) || 0;
  }

  // experimental
  get boundaries() {
    return this.remoteBlock.segments.flatMap((segment) => segment.boundaries);
  }

  get hasBoundaries() {
    return this.boundaries.length > 0;
  }

  get coords() {
    return MapUtils.getCoords(this.boundaries);
  }

  get bounds() {
    return MapUtils.getLngLatBounds(this.coords);
  }

  get remoteGrowingPlans() {
    return this.remoteBlock.growingPlans || [];
  }

  private polygonProps() {
    const properties = {
      blockId: this.id,
      blockColor: this.color,
      blockFormattedColor: this.formattedColor,
      blockName: this.name,
      isBlockNew: this.isNew,
    };

    return {
      sourceProps: MapUtils.getPolygonSourceProps(this.id, this.coords, properties),
      layerProps: MapUtils.getPolygonLayerProps(this.id, {
        color: this.formattedColor,
        opacity: BLOCK_THEME_CONFIG.polygon.opacity,
      }),
    };
  }

  // Prepare data for the visualisation of the robot path.
  // The solution is not perfect, there is a space for UI improvement in the future.
  get pathPropsForMap() {
    const path = this.remoteBlock.path;
    if (!path) return undefined;

    // convert API path coordinates to Mapbox coordinates
    const pathCoords = MapUtils.getCoords(path.pathCoordinates);

    // Group orders by coord
    const coordsMap = new Map<string, { coord: Coords; orders: number[] }>();
    pathCoords.forEach((coord, index) => {
      // Round coords to merge close points
      const lat = Number(coord[0].toFixed(10));
      const lon = Number(coord[1].toFixed(10));

      const key = `${lat}${lon}`;
      const current = coordsMap.get(key);
      const currentOrders = current ? current.orders : [];
      const order = index + 1;
      coordsMap.set(key, { coord: [lat, lon], orders: [...currentOrders, order] });
    });

    const mapProps = {
      lineProps: {
        sourceProps: MapUtils.getLineSourceProps(path.id, pathCoords),
        layerProps: MapUtils.getLineLayerProps(path.id, {
          color: this.formattedColor,
          opacity: BLOCK_THEME_CONFIG.line.opacity,
          width: BLOCK_THEME_CONFIG.line.width,
        }),
      },
      pointsProps: [...coordsMap.values()].map((point, index) => ({
        sourceProps: MapUtils.getPointSourceProps(`${path.id}-point-${index}`, point.coord),
        layerProps: MapUtils.getPointLayerProps(`${path.id}-point-${index}`),
        textProps: MapUtils.getTextLayerProps(`${path.id}-point-${index}`, point.orders.join(", ")),
      })),
    };

    return mapProps;
  }

  get mapFeature() {
    return this.polygonProps().sourceProps.data;
  }

  get segments() {
    return this.remoteBlock.segments || [];
  }

  get segmentsForMap() {
    const color = this.formattedColor;
    return this.segments.map((segment) => ({
      id: segment.id,
      propsForMap: {
        polygonProps: {
          sourceProps: MapUtils.getPolygonSourceProps(segment.id, MapUtils.getCoords(segment.boundaries)),
          layerProps: {
            ...MapUtils.getPolygonLayerProps(segment.id, {
              color,
              opacity: BLOCK_THEME_CONFIG.polygon.opacity,
            }),
          },
        },
        lineProps: {
          layerProps: {
            ...MapUtils.getLineLayerProps(segment.id, {
              color,
              opacity: BLOCK_THEME_CONFIG.line.opacity,
              width: BLOCK_THEME_CONFIG.line.width,
            }),
          },
        },
        textProps: {
          layerProps: MapUtils.getTextLayerProps(segment.id, segment.name),
        },
      },
    }));
  }
}

export class UncategorizedBlock extends BlockModel {
  constructor(segments: RemoteSegment[]) {
    super({
      id: "uncategorized",
      name: "Uncategorized",
      color: UNCATEGORIZED_COLOR,
      createdAt: new Date().toISOString(),
      area: 0,
      growingPlans: null,
      segments,
      path: null,
    });
  }

  get isUncategorized() {
    return true;
  }
}

export class NewBlock extends BlockModel {
  constructor(block?: Partial<Pick<RemoteBlock, "id" | "segments">>) {
    const createdAt = new Date().toISOString();
    const id = block?.id || createdAt;
    super({
      id,
      name: "New Block",
      color: getColorFromString(id),
      createdAt,
      area: 0,
      growingPlans: null,
      segments: block?.segments || [],
      path: null,
    });
  }

  get isNew() {
    return true;
  }
}
