import { useCreateSegmentsMutation } from "@/api/sdk";
import { DashboardContent } from "@/components/DashboardContent";
import { useFieldTransformedQuery } from "@/field/useFieldTransformedQuery";
import { generatePath, routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { useAppTitle } from "@/utils/useAppTitle";
import { Button } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ManageSegmentsPageContent } from "./ManageSegmentsPageContent";

const FORM_ID = "create-segments-form";
type ContentProps = ComponentProps<typeof ManageSegmentsPageContent>;

export const ManageSegmentsPage = ({ fieldId }: { fieldId: string }) => {
  const { t } = useTranslation();
  const headline = t("Manage Segments");
  useAppTitle(headline);

  const {
    data: { field } = {},
    isLoading,
  } = useFieldTransformedQuery({ fieldId }, { refetchOnMount: false, refetchOnWindowFocus: false });

  const { isCultivatedByRobot, hasBoundaries } = field || {};
  const isAccessAllowed = isCultivatedByRobot || hasBoundaries;

  const navigate = useNavigate();
  const fieldPath = generatePath(routes.FieldDetail, { id: fieldId });

  const { mutate, isPending: isMutating } = useCreateSegmentsMutation({
    onSuccess: () => {
      notify.positive(t("All changes have been successfully saved."));
      navigate(fieldPath);
    },
  });

  const handleSubmit: ContentProps["onSubmit"] = async (segmentsInput) => mutate({ fieldId, segments: segmentsInput });

  const isSaveEnabled = !isMutating && !isLoading;

  return (
    <DashboardContent
      headline={headline}
      navigateBackTo={fieldPath}
      isLoading={isLoading || isMutating}
      headerArea={
        <Button form={FORM_ID} disabled={!isSaveEnabled} icon={"check"}>
          {t("Confirm")}
        </Button>
      }
    >
      {isAccessAllowed && field ? (
        <ManageSegmentsPageContent field={field} formId={FORM_ID} onSubmit={handleSubmit} />
      ) : (
        <>No data</>
      )}
    </DashboardContent>
  );
};
