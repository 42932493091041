import { type UseMutationOptions, type UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { fetcher } from "api/fetcher";
import { type GraphQLResponseResolver, type RequestHandlerOptions, graphql } from "msw";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  GlobalID: { input: string; output: string };
  Time: { input: string; output: string };
  UUID: { input: string; output: string };
};

export type BlockMutationPayload = {
  __typename?: "BlockMutationPayload";
  field: Maybe<FieldType>;
  success: Scalars["Boolean"]["output"];
};

export enum BlockState {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
}

export type BlockType = {
  __typename?: "BlockType";
  area: Scalars["Float"]["output"];
  /** @deprecated The bearer of the coordinate information is the field segment */
  boundaries: Maybe<Array<CoordinatesType>>;
  /** DEV Color of the block in hex format */
  color: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  field: FieldType;
  growingPlans: Maybe<Array<GrowingPlanType>>;
  id: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  /** Path generated for block (in order) */
  path: Maybe<PathType>;
  routePlannerState: RoutePlannerBlockState;
  /** Blocks segments */
  segments: Array<SegmentType>;
  state: BlockState;
  /** @deprecated Swath are no longer directly connected to blocks, functionally replaced by `segments` */
  swaths: Maybe<Array<SwathType>>;
};

export type BoolValue = {
  __typename?: "BoolValue";
  boolValue: Scalars["Boolean"]["output"];
};

export type ClientAccountInput = {
  address: Scalars["String"]["input"];
  businessName: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  /** ISO 3166-1 alpha-2 */
  country: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
};

export type ClientAccountMutationPayload = {
  __typename?: "ClientAccountMutationPayload";
  clientAccount: Maybe<ClientAccountType>;
  success: Scalars["Boolean"]["output"];
};

export enum ClientAccountState {
  Approved = "APPROVED",
  Archived = "ARCHIVED",
  Created = "CREATED",
}

export type ClientAccountType = {
  __typename?: "ClientAccountType";
  address: Scalars["String"]["output"];
  approvedAt: Maybe<Scalars["DateTime"]["output"]>;
  approvedBy: Scalars["String"]["output"];
  businessName: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  /** ISO 3166-1 alpha-2 */
  country: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["UUID"]["output"];
  postalCode: Scalars["String"]["output"];
  /** Robot assignments of client account */
  robotAssignments: Array<RobotAssignmentType>;
  state: ClientAccountState;
};

export type ClientAccountTypeListType = {
  __typename?: "ClientAccountTypeListType";
  nodes: Array<ClientAccountType>;
  pageInfo: PageInfo;
  success: Scalars["Boolean"]["output"];
  totalCount: Scalars["Int"]["output"];
};

export enum ConditionStatus {
  Critical = "CRITICAL",
  Normal = "NORMAL",
  Warning = "WARNING",
}

export type ConditionType = {
  __typename?: "ConditionType";
  conditionEvalTimeFrame: Scalars["String"]["output"];
  /** Higher health threshold of optimal value for condition */
  healthThresholdHigh: Maybe<FloatValueStringValue>;
  /** Higher health threshold of optimal value for condition */
  healthThresholdLow: Maybe<FloatValueStringValue>;
  id: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  /** Critical values of nominal condition */
  nominalCritical: Maybe<Array<Scalars["String"]["output"]>>;
  /** Limit values of nominal condition */
  nominalLimit: Maybe<Array<Scalars["String"]["output"]>>;
  /** Optimal values of nominal condition */
  nominalOptimal: Maybe<Array<Scalars["String"]["output"]>>;
  order: Scalars["Int"]["output"];
  relevantForCalculation: Scalars["Boolean"]["output"];
  /** The highest value of the condition scale */
  scaleExtremeHigh: Maybe<Scalars["Float"]["output"]>;
  /** Tge lowest value of the condition scale */
  scaleExtremeLow: Maybe<Scalars["Float"]["output"]>;
  /** Step of the condition scale */
  scaleStep: Maybe<Scalars["Float"]["output"]>;
  /** Higher threshold of optimal value for condition */
  thresholdHigh: Maybe<FloatValueStringValue>;
  /** Lower threshold of optimal value for condition */
  thresholdLow: Maybe<FloatValueStringValue>;
  type: Scalars["String"]["output"];
  /** Unit of condition */
  unit: Maybe<Scalars["String"]["output"]>;
};

export type CoordinatesInput = {
  lat: Scalars["Float"]["input"];
  lon: Scalars["Float"]["input"];
};

export type CoordinatesType = {
  __typename?: "CoordinatesType";
  lat: Scalars["Float"]["output"];
  lon: Scalars["Float"]["output"];
};

export type CoreMutation = {
  __typename?: "CoreMutation";
  /**
   * Create client account.
   * Possible errors:
   *  `STAFF_NOT_ALLOWED` - Staff is not allowed do this operation
   */
  createClientAccount: ClientAccountMutationPayload;
  /**
   *
   *         Generate & save plant spacing for GrowingPlan
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `MICROSERVICE_ERROR` - Error caused by incorrect behavior of microservice
   *  `ROUTE_PLANNER_ERROR` - Error when using route planner microservice
   *  `PLANT_SPACING_ALREADY_EXISTS` - Growing plan has already plant spacing.
   */
  createCustomPlantSpacing: PlantSpacing;
  /**
   * Create field with basic information.
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INVALID_SOIL_TYPE` - Invalid soil type
   */
  createField: FieldMutationPayload;
  /**
   *
   *                     Create field task for single or multiple growing plans or blocks of one field.
   *                     (All field task procedures are included in single task)
   *
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `FIELD_TASK_SCHEDULED_INTO_PAST` - Field task cannot be scheduled into the past
   *  `FIELD_TASK_RESCHEDULING_EXCEEDS_DAY_LIMIT` - Field task rescheduling exceeds day limit
   *  `JOB_CANNOT_BE_GENERATED_MISSING_PATH` - Field task cannot be generated because of missing path
   *  `DATA_UNAVAILABLE_ERROR` - Data is unavailable
   *  `FIELD_TASKS_CANNOT_BE_CREATED_FOR_MULTIPLE_ROBOTS` - Field tasks cannot be created for multiple robots at once
   *  `REQUIRE_FIELD_TASK_CONTEXT_ENTITY` - Require field task context entity
   *  `FIELD_TASK_CANNOT_BE_CREATED_FOR_MULTIPLE_CONTEXT_ENTITIES_AT_ONCE` - Single field task cannot be created for multiple context entities at once
   */
  createFieldTask: FieldTaskMutationPayload;
  /**
   *
   *                     Create batch of field tasks for multiple blocks or growing plans.
   *                     (Create standalone field task for every field task procedure given in input)
   *
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `FIELD_TASK_SCHEDULED_INTO_PAST` - Field task cannot be scheduled into the past
   *  `FIELD_TASK_RESCHEDULING_EXCEEDS_DAY_LIMIT` - Field task rescheduling exceeds day limit
   *  `JOB_CANNOT_BE_GENERATED_MISSING_PATH` - Field task cannot be generated because of missing path
   *  `DATA_UNAVAILABLE_ERROR` - Data is unavailable
   *  `FIELD_TASKS_CANNOT_BE_CREATED_FOR_MULTIPLE_ROBOTS` - Field tasks cannot be created for multiple robots at once
   *  `REQUIRE_FIELD_TASK_CONTEXT_ENTITY` - Require field task context entity
   *  `FIELD_TASK_CANNOT_BE_CREATED_FOR_MULTIPLE_CONTEXT_ENTITIES_AT_ONCE` - Single field task cannot be created for multiple context entities at once
   */
  createFieldTaskBatch: MultipleFieldTaskMutationPayload;
  /**
   * Create and schedule growing plan.
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `GROWING_PLANNER_FIELD_IS_NOT_READY` - Field inside growing planner is not ready to render plan
   *  `ROUTE_NOT_AVAILABLE` - Routes for selected block are not available
   */
  createGrowingPlan: GrowingPlanMutationPayload;
  /**
   *
   *         Create & save plant spacing ROW based for Growing Plan
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `MICROSERVICE_ERROR` - Error caused by incorrect behavior of microservice
   *  `ROUTE_PLANNER_ERROR` - Error when using route planner microservice
   *  `PLANT_SPACING_ALREADY_EXISTS` - Growing plan has already plant spacing.
   */
  createRowBasedPlantSpacing: PlantSpacing;
  /**
   *
   *         Create segments on the field with assigned robot.
   *         Provide object for every swath with list of coordinates of segment's boundaries (as provided by
   *         `previewSegments` query).
   *
   * Possible errors:
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `DUPLICATE_SWATH_IN_REQUEST` - Duplicate swath in request
   *  `SEGMENT_HAS_DIFFERENT_COORDINATES` - Provided coordinates of segment do not correspond to existing segment entity
   *  `SEGMENT_CANNOT_BE_ASSIGNED_TO_DIFFERENT_SWATH` - Existing segment cannot be assigned to different swath
   *  `SEGMENT_NOT_ON_SWATH` - Segment is not located on swath
   *  `SEGMENT_COLLIDES_WITH_ANOTHER_SEGMENT` - Segment collides with another segment
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  createSegments: FieldMutationPayload;
  /**
   * Create swaths of the field with assigned robot. Provide `ref_path_segment_id` to generate block parallel to specific path segment of boundary.Provide `angle` to generate swaths at specific angle. One and only one (`ref_path_segment_id` and `angle`) from the options can be passed!
   * Possible errors:
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `INVALID_FIELD_STATE` - Invalid field state
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `MICROSERVICE_ERROR` - Error caused by incorrect behavior of microservice
   */
  createSwaths: FieldMutationPayload;
  /**
   * Decommission robot (remove robot from ClientAccount).
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   */
  decommissionRobot: RobotAssignmentMutationPayload;
  /**
   * Archive field.
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  deleteField: GenericPayload;
  /**
   * Delete scheduled field task
   *
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   */
  deleteFieldTask: FieldTaskMutationPayload;
  /**
   * Delete growing plan.
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   */
  deleteGrowingPlan: GenericPayload;
  /**
   * Delete existing plant spacing for GrowingPlan
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   */
  deletePlantSpacing: GrowingPlanType;
  /**
   * Update field's imported boundaries. THIS IS DEV ONLY. Will be removed in the future.
   *
   * Possible errors:
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INVALID_NUMBER_OF_POINTS_IN_POLYGON` - Invalid number of points in polygon
   *  `BOUNDARIES_OVERLAPPING_ANOTHER_POLYGON` - Boundaries overlaps another polygon
   *  `POLYGON_SELF_CROSSING` - Polygon is self crossing
   *  `AREA_TOO_LARGE` - Field's Area is too large
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  devUpdateFieldImportedBoundaries: FieldMutationPayload;
  /**
   *
   *         Finish growing plan.
   *         Allowed only for Growing Plan in state `IN_PROGRESS`
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   */
  finishGrowingPlan: GenericPayload;
  /**
   * Move task down in robot assignment plan and reschedule collision with other tasks
   *
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `FIELD_TASK_RESCHEDULING_EXCEEDS_DAY_LIMIT` - Field task rescheduling exceeds day limit
   *  `DATA_UNAVAILABLE_ERROR` - Data is unavailable
   *  `FIELD_TASK_CANNOT_BE_MOVED_DOWN` - Field task cannot be moved down
   */
  moveFieldTaskDown: FieldTaskMutationPayload;
  /**
   * Move task up in robot assignment plan and reschedule collision with other tasks
   *
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `FIELD_TASK_RESCHEDULING_EXCEEDS_DAY_LIMIT` - Field task rescheduling exceeds day limit
   *  `DATA_UNAVAILABLE_ERROR` - Data is unavailable
   *  `FIELD_TASK_CANNOT_BE_MOVED_UP` - Field task cannot be moved up
   */
  moveFieldTaskUp: FieldTaskMutationPayload;
  /**
   * Register new user. User's tmp password is send to their email user must login with this password before expiration.
   * Possible errors:
   *  `USER_EXISTS` - User exists
   */
  register: Scalars["Boolean"]["output"];
  /**
   * Register robot to active ClientAccount.
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `ALREADY_ASSIGNED_ROBOT` - Assigned robot has already been registered
   */
  registerRobot: RobotAssignmentMutationPayload;
  /**
   * Register new user via invite. User's tmp password is send to email specified in the invitation. User is also assigned as a member of client account.
   * Possible errors:
   *  `USER_EXISTS` - User exists
   */
  registerWithInvite: Scalars["Boolean"]["output"];
  /**
   * Start now field task and reschedule collision with other tasks
   *
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INVALID_FIELD_TASK_STATE` - Invalid field task state
   *  `FIELD_TASK_CANNOT_BE_UPDATED` - Field task cannot be updated
   */
  startNowFieldTask: FieldTaskMutationPayload;
  /**
   * Switch active client account.
   * Possible errors:
   *  `CLIENT_ACCOUNT_NOT_FOUND` - Client account not found
   *  `PERM_NOT_MEMBER` - User must be member of client account
   */
  switchClientAccount: SwitchClientAccountMutationPayload;
  /**
   * Update client account.
   * Possible errors:
   *  `CLIENT_ACCOUNT_NOT_FOUND` - Client account not found
   */
  updateClientAccount: ClientAccountMutationPayload;
  /**
   *  Update field's basic information.
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `INVALID_SOIL_TYPE` - Invalid soil type
   */
  updateField: FieldMutationPayload;
  /**
   *
   *         Update field's entry point. Point must be selected from the field's imported boundaries. TBI:
   *         archive old blocks.
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `POINT_DOES_NOT_BELONG_TO_POLYGON` - Point doesn't belong to polygon
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  updateFieldEntryPoint: FieldMutationPayload;
  /**
   *
   *         Update field's home station and home station path using GPS Trace.
   *         If gps_trace_id is null then home_station/home_station_path is unassociated from field and archived.
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `PATH_NOT_IN_PROXIMITY` - Path is not in proximity to field's entry point
   *  `NO_ENTRY_POINT` - Field has no entry point
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  updateFieldHomeStation: FieldMutationPayload;
  /**
   *
   *         Update field's imported boundaries from GPS Trace.
   *         WARN: All related objects to the field boundaries will be archived!
   *
   * Possible errors:
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INVALID_NUMBER_OF_POINTS_IN_POLYGON` - Invalid number of points in polygon
   *  `BOUNDARIES_OVERLAPPING_ANOTHER_POLYGON` - Boundaries overlaps another polygon
   *  `POLYGON_SELF_CROSSING` - Polygon is self crossing
   *  `AREA_TOO_LARGE` - Field's Area is too large
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  updateFieldImportedBoundaries: FieldMutationPayload;
  /**
   * Update field's manual boundaries on field without an assigned robot.
   *
   * Possible errors:
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `INVALID_NUMBER_OF_POINTS_IN_POLYGON` - Invalid number of points in polygon
   *  `POLYGON_SELF_CROSSING` - Polygon is self crossing
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  updateFieldManualBoundaries: FieldMutationPayload;
  /**
   *
   *         Update field's assigned robot. If robot is removed or changed all block data are removed.
   *
   * Possible errors:
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   * @deprecated Robot is assigned at creating the field and cannot be changed
   */
  updateFieldRobot: FieldMutationPayload;
  /**
   *
   *         Update `start_at` growing plan.
   *         Allowed only for GrowingPlan in state `SCHEDULED`.
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `GROWING_PLAN_CONFLICT_DATES_ON_SAME_BLOCK` - Growing plan has conflict date with another plan on same block
   */
  updateGrowingPlanStartAt: GenericPayload;
  /**
   * Create, update or delete segments blocks.Blocks not provided are removed. Block can have only one manual segment.Block's name and color can be changed without impact on growing plans. Changes in blocks segments will trigger cleanup on growing plans.
   * Possible errors:
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `MICROSERVICE_ERROR` - Error caused by incorrect behavior of microservice
   *  `BOUNDARIES_OVERLAPPING_ANOTHER_POLYGON` - Boundaries overlaps another polygon
   *  `POLYGON_SELF_CROSSING` - Polygon is self crossing
   *  `BLOCK_IS_NOT_IN_FIELD` - Block is not field boundaries
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  updateManualBlocks: BlockMutationPayload;
  /** Update user's information. TODO: fix return value. */
  updateProfile: Scalars["Boolean"]["output"];
  /**
   * Update robot's information in ClientAccount.
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   */
  updateRobot: RobotAssignmentMutationPayload;
  /**
   * Update generated blocks and swath of the field. Existing blocks not in input will be deleted.
   *
   * Possible errors:
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `CAN_NOT_CREATE_EMPTY_BLOCK` - Can not create empty block
   *  `DUPLICATE_BLOCK_IN_REQUEST` - Duplicate block in request
   *  `DUPLICATE_SEGMENT_IN_REQUEST` - Duplicate segment in request
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  updateSegmentBlocks: BlockMutationPayload;
};

export type CoreMutationCreateClientAccountArgs = {
  clientAccountInput: ClientAccountInput;
};

export type CoreMutationCreateCustomPlantSpacingArgs = {
  growingPlanId: Scalars["UUID"]["input"];
  input: CustomPlantSpacingInput;
};

export type CoreMutationCreateFieldArgs = {
  fieldInput: FieldCreateInput;
};

export type CoreMutationCreateFieldTaskArgs = {
  fieldTaskInput: FieldTaskInput;
};

export type CoreMutationCreateFieldTaskBatchArgs = {
  fieldTaskInput: FieldTaskBatchInput;
};

export type CoreMutationCreateGrowingPlanArgs = {
  blockId: Scalars["UUID"]["input"];
  cropSheetId: Scalars["UUID"]["input"];
  startAt: Scalars["Date"]["input"];
};

export type CoreMutationCreateRowBasedPlantSpacingArgs = {
  growingPlanId: Scalars["UUID"]["input"];
  input: RowBasedPlantSpacingInput;
};

export type CoreMutationCreateSegmentsArgs = {
  fieldId: Scalars["UUID"]["input"];
  swathSegments: Array<SwathSegmentInput>;
};

export type CoreMutationCreateSwathsArgs = {
  angle?: InputMaybe<Scalars["Float"]["input"]>;
  fieldId: Scalars["UUID"]["input"];
  refPathSegmentId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CoreMutationDecommissionRobotArgs = {
  robotAssignmentId: Scalars["UUID"]["input"];
};

export type CoreMutationDeleteFieldArgs = {
  fieldId: Scalars["UUID"]["input"];
};

export type CoreMutationDeleteFieldTaskArgs = {
  fieldTaskId: Scalars["UUID"]["input"];
};

export type CoreMutationDeleteGrowingPlanArgs = {
  growingPlanId: Scalars["UUID"]["input"];
};

export type CoreMutationDeletePlantSpacingArgs = {
  plantSpacingId: Scalars["UUID"]["input"];
};

export type CoreMutationDevUpdateFieldImportedBoundariesArgs = {
  fieldId: Scalars["UUID"]["input"];
  input: ManualFieldBoundariesInput;
};

export type CoreMutationFinishGrowingPlanArgs = {
  growingPlanId: Scalars["UUID"]["input"];
};

export type CoreMutationMoveFieldTaskDownArgs = {
  fieldTaskId: Scalars["UUID"]["input"];
};

export type CoreMutationMoveFieldTaskUpArgs = {
  fieldTaskId: Scalars["UUID"]["input"];
};

export type CoreMutationRegisterArgs = {
  userRegistrationInput: UserRegisterInput;
};

export type CoreMutationRegisterRobotArgs = {
  robotAssignmentInput: RobotAssignmentInput;
};

export type CoreMutationRegisterWithInviteArgs = {
  inviteRegistration: InviteRegisterInput;
};

export type CoreMutationStartNowFieldTaskArgs = {
  fieldTaskId: Scalars["UUID"]["input"];
};

export type CoreMutationSwitchClientAccountArgs = {
  clientAccountId: Scalars["UUID"]["input"];
};

export type CoreMutationUpdateClientAccountArgs = {
  clientAccountId: Scalars["UUID"]["input"];
  clientAccountInput: ClientAccountInput;
};

export type CoreMutationUpdateFieldArgs = {
  fieldId: Scalars["UUID"]["input"];
  fieldInput: FieldInput;
};

export type CoreMutationUpdateFieldEntryPointArgs = {
  entryPoint: CoordinatesInput;
  fieldId: Scalars["UUID"]["input"];
  reverseDirection?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CoreMutationUpdateFieldHomeStationArgs = {
  fieldId: Scalars["UUID"]["input"];
  gpsTraceId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type CoreMutationUpdateFieldImportedBoundariesArgs = {
  fieldId: Scalars["UUID"]["input"];
  gpsTraceId: Scalars["UUID"]["input"];
};

export type CoreMutationUpdateFieldManualBoundariesArgs = {
  fieldId: Scalars["UUID"]["input"];
  input: ManualFieldBoundariesInput;
};

export type CoreMutationUpdateFieldRobotArgs = {
  fieldId: Scalars["UUID"]["input"];
  robotAssignmentId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type CoreMutationUpdateGrowingPlanStartAtArgs = {
  growingPlanId: Scalars["UUID"]["input"];
  startAt: Scalars["Date"]["input"];
};

export type CoreMutationUpdateManualBlocksArgs = {
  blocksInput: ManualBlocksInput;
  fieldId: Scalars["UUID"]["input"];
};

export type CoreMutationUpdateProfileArgs = {
  input: UserEditProfileInput;
};

export type CoreMutationUpdateRobotArgs = {
  robotAssignmentId: Scalars["UUID"]["input"];
  robotAssignmentInput: RobotAssignmentUpdateInput;
};

export type CoreMutationUpdateSegmentBlocksArgs = {
  fieldId: Scalars["UUID"]["input"];
  input: SegmentBlocksInput;
};

export type CropDiseaseType = {
  __typename?: "CropDiseaseType";
  disease: DiseaseType;
  images: Array<ImageType>;
  mainImage: Maybe<ImageType>;
  treatment: Scalars["String"]["output"];
};

export type CropMetadataType = {
  __typename?: "CropMetadataType";
  /** Combined name - used for filtering  */
  combinedName: Scalars["String"]["output"];
  /** Crop common name */
  commonName: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  /** Crop variant */
  variant: Scalars["String"]["output"];
};

export type CropSheetBaseType = {
  __typename?: "CropSheetBaseType";
  alternativeNames: Maybe<Array<Scalars["String"]["output"]>>;
  commonName: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  mainImage: Maybe<ImageType>;
  variant: Maybe<Scalars["String"]["output"]>;
};

export type CropSheetListType = {
  __typename?: "CropSheetListType";
  crops: Array<CropSheetBaseType>;
};

export type CropSheetType = {
  __typename?: "CropSheetType";
  alternativeNames: Maybe<Array<Scalars["String"]["output"]>>;
  code: Scalars["String"]["output"];
  commonName: Scalars["String"]["output"];
  conditions: Array<ConditionType>;
  cultivation: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  images: Array<ImageType>;
  mainImage: Maybe<ImageType>;
  nominalYieldKgPerSqMeter: Scalars["Float"]["output"];
  scientificName: Scalars["String"]["output"];
  variant: Maybe<Scalars["String"]["output"]>;
};

export type CropStageType = {
  __typename?: "CropStageType";
  bbchCode: Maybe<Scalars["String"]["output"]>;
  conditions: Array<ConditionType>;
  continuous: Scalars["Boolean"]["output"];
  cultivationStage: CultivationStageType;
  description: Scalars["String"]["output"];
  dryMatterPercentage: Maybe<Scalars["Int"]["output"]>;
  durationDays: Maybe<Scalars["Int"]["output"]>;
  fieldProcedures: Array<FieldProcedureType>;
  id: Scalars["UUID"]["output"];
  images: Array<ImageType>;
  impactOnYield: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  plantDiameter: Maybe<Scalars["Int"]["output"]>;
  plantHeight: Maybe<Scalars["Int"]["output"]>;
  plantHourlyWaterConsumption: Maybe<Scalars["Float"]["output"]>;
  plantRootLength: Maybe<Scalars["Int"]["output"]>;
  plantWeight: Maybe<Scalars["Float"]["output"]>;
  startAtDay: Maybe<Scalars["Int"]["output"]>;
};

export type CropStagesType = {
  __typename?: "CropStagesType";
  id: Scalars["UUID"]["output"];
  stages: Array<CropStageType>;
};

export enum CultivationMethod {
  Autonomus = "AUTONOMUS",
  Manual = "MANUAL",
}

export type CultivationStageType = {
  __typename?: "CultivationStageType";
  code: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  perpetual: Scalars["Boolean"]["output"];
  principalGrowthStage: PrincipalGrowthStageType;
};

/** Plant spacing configuration input */
export type CustomPlantSpacingInput = {
  /** Swath end margin in cm, range 0, 100. */
  endMargin: Scalars["Float"]["input"];
  /** Sowing pattern. */
  pattern: PlantSpacingPattern;
  /** Number of rows to plant, 1-10 */
  rows: Scalars["Int"]["input"];
  /** Swath side margin in cm, range -10, 100. */
  sideMargin: Scalars["Float"]["input"];
  /** Spacing between plants in cm, range 1, 200. */
  spacing: Scalars["Float"]["input"];
};

export type DefaultParametrizationValue =
  | BoolValue
  | FloatValue
  | IntListValue
  | IntValue
  | PlantSpacingPatternValue
  | StringValue;

export enum DiseaseCategory {
  Disease = "DISEASE",
  Pest = "PEST",
  Weed = "WEED",
}

export type DiseaseType = {
  __typename?: "DiseaseType";
  alternativeNames: Maybe<Array<Scalars["String"]["output"]>>;
  commonName: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  images: Array<ImageType>;
  mainImage: Maybe<ImageType>;
  order: Maybe<Scalars["Int"]["output"]>;
  scientificName: Scalars["String"]["output"];
  threatenedCrops: Array<CropSheetBaseType>;
  type: DiseaseCategory;
};

export enum ErrorCode {
  AlreadyAssignedRobot = "ALREADY_ASSIGNED_ROBOT",
  AlreadyRegisteredRobot = "ALREADY_REGISTERED_ROBOT",
  AngleWithRefPathSegment = "ANGLE_WITH_REF_PATH_SEGMENT",
  AreaTooLarge = "AREA_TOO_LARGE",
  AuthorizationEntityNotFound = "AUTHORIZATION_ENTITY_NOT_FOUND",
  BlockIsNotInField = "BLOCK_IS_NOT_IN_FIELD",
  BoundariesOverlappingAnotherPolygon = "BOUNDARIES_OVERLAPPING_ANOTHER_POLYGON",
  BoundariesOverlappingHomeStation = "BOUNDARIES_OVERLAPPING_HOME_STATION",
  CanNotCreateEmptyBlock = "CAN_NOT_CREATE_EMPTY_BLOCK",
  ClientAccountNotApproved = "CLIENT_ACCOUNT_NOT_APPROVED",
  ClientAccountNotFound = "CLIENT_ACCOUNT_NOT_FOUND",
  CollisionWithField = "COLLISION_WITH_FIELD",
  CultivationMethodCannotBeUpdated = "CULTIVATION_METHOD_CANNOT_BE_UPDATED",
  DataUnavailableError = "DATA_UNAVAILABLE_ERROR",
  DuplicateBlockInRequest = "DUPLICATE_BLOCK_IN_REQUEST",
  DuplicateSegmentInRequest = "DUPLICATE_SEGMENT_IN_REQUEST",
  DuplicateSwathInRequest = "DUPLICATE_SWATH_IN_REQUEST",
  FieldTasksCannotBeCreatedForMultipleRobots = "FIELD_TASKS_CANNOT_BE_CREATED_FOR_MULTIPLE_ROBOTS",
  FieldTaskCannotBeCreatedForMultipleContextEntitiesAtOnce = "FIELD_TASK_CANNOT_BE_CREATED_FOR_MULTIPLE_CONTEXT_ENTITIES_AT_ONCE",
  FieldTaskCannotBeCreateUsingContextEntitiesPathCombination = "FIELD_TASK_CANNOT_BE_CREATE_USING_CONTEXT_ENTITIES_PATH_COMBINATION",
  FieldTaskCannotBeDeleted = "FIELD_TASK_CANNOT_BE_DELETED",
  FieldTaskCannotBeMovedDown = "FIELD_TASK_CANNOT_BE_MOVED_DOWN",
  FieldTaskCannotBeMovedUp = "FIELD_TASK_CANNOT_BE_MOVED_UP",
  FieldTaskCannotBeStartedNow = "FIELD_TASK_CANNOT_BE_STARTED_NOW",
  FieldTaskCannotBeUpdated = "FIELD_TASK_CANNOT_BE_UPDATED",
  FieldTaskReschedulingExceedsDayLimit = "FIELD_TASK_RESCHEDULING_EXCEEDS_DAY_LIMIT",
  FieldTaskScheduledIntoPast = "FIELD_TASK_SCHEDULED_INTO_PAST",
  FieldTaskTypeBlockAvailability = "FIELD_TASK_TYPE_BLOCK_AVAILABILITY",
  GpsTraceInvalidDatetimeSequence = "GPS_TRACE_INVALID_DATETIME_SEQUENCE",
  GpsTraceMissingField = "GPS_TRACE_MISSING_FIELD",
  GpsTraceMissingHomeStation = "GPS_TRACE_MISSING_HOME_STATION",
  GpsTrackingStoppedWithInvalidRobot = "GPS_TRACKING_STOPPED_WITH_INVALID_ROBOT",
  GpsTrackingStoppedWithInvalidUser = "GPS_TRACKING_STOPPED_WITH_INVALID_USER",
  GrowingPlannerError = "GROWING_PLANNER_ERROR",
  GrowingPlannerFieldIsNotReady = "GROWING_PLANNER_FIELD_IS_NOT_READY",
  GrowingPlanConflictDatesOnSameBlock = "GROWING_PLAN_CONFLICT_DATES_ON_SAME_BLOCK",
  HomeStationPathAlreadyExist = "HOME_STATION_PATH_ALREADY_EXIST",
  InactiveClientAccount = "INACTIVE_CLIENT_ACCOUNT",
  IncompleteGpsTracking = "INCOMPLETE_GPS_TRACKING",
  InvalidCultivationMethod = "INVALID_CULTIVATION_METHOD",
  InvalidFieldState = "INVALID_FIELD_STATE",
  InvalidFieldTaskState = "INVALID_FIELD_TASK_STATE",
  InvalidFieldTaskType = "INVALID_FIELD_TASK_TYPE",
  InvalidImportedBoundaries = "INVALID_IMPORTED_BOUNDARIES",
  InvalidLineFormat = "INVALID_LINE_FORMAT",
  InvalidNumberOfPointsInPolygon = "INVALID_NUMBER_OF_POINTS_IN_POLYGON",
  InvalidPathSegmentType = "INVALID_PATH_SEGMENT_TYPE",
  InvalidPendingBlockSwathUpdate = "INVALID_PENDING_BLOCK_SWATH_UPDATE",
  InvalidPointFormat = "INVALID_POINT_FORMAT",
  InvalidRoutePlannerRequest = "INVALID_ROUTE_PLANNER_REQUEST",
  InvalidSoilType = "INVALID_SOIL_TYPE",
  InvitationAccepted = "INVITATION_ACCEPTED",
  InvitationEmailMissmatch = "INVITATION_EMAIL_MISSMATCH",
  InvitationExpired = "INVITATION_EXPIRED",
  JobCannotBeGeneratedBlockInProcessing = "JOB_CANNOT_BE_GENERATED_BLOCK_IN_PROCESSING",
  JobCannotBeGeneratedMissingPath = "JOB_CANNOT_BE_GENERATED_MISSING_PATH",
  LoginRequired = "LOGIN_REQUIRED",
  ManualFieldBlockMultipleSegments = "MANUAL_FIELD_BLOCK_MULTIPLE_SEGMENTS",
  MicroserviceError = "MICROSERVICE_ERROR",
  NotActiveClientAccount = "NOT_ACTIVE_CLIENT_ACCOUNT",
  NotAuthenticated = "NOT_AUTHENTICATED",
  NotAuthorized = "NOT_AUTHORIZED",
  NoEntryPoint = "NO_ENTRY_POINT",
  PathNotInProximity = "PATH_NOT_IN_PROXIMITY",
  PermNotMember = "PERM_NOT_MEMBER",
  PermNotOwner = "PERM_NOT_OWNER",
  PlantSpacingAlreadyExists = "PLANT_SPACING_ALREADY_EXISTS",
  PointDoesNotBelongToPolygon = "POINT_DOES_NOT_BELONG_TO_POLYGON",
  PolygonSelfCrossing = "POLYGON_SELF_CROSSING",
  RequiredUserContext = "REQUIRED_USER_CONTEXT",
  RequireFieldTaskContextEntity = "REQUIRE_FIELD_TASK_CONTEXT_ENTITY",
  RiBadRequest = "RI_BAD_REQUEST",
  RiRecordNotFound = "RI_RECORD_NOT_FOUND",
  RiRequestFailed = "RI_REQUEST_FAILED",
  RiRequestForbidden = "RI_REQUEST_FORBIDDEN",
  RiServiceDown = "RI_SERVICE_DOWN",
  RiValidationError = "RI_VALIDATION_ERROR",
  RouteNotAvailable = "ROUTE_NOT_AVAILABLE",
  RoutePlannerError = "ROUTE_PLANNER_ERROR",
  SegmentCannotBeAssignedToDifferentSwath = "SEGMENT_CANNOT_BE_ASSIGNED_TO_DIFFERENT_SWATH",
  SegmentCollidesWithAnotherSegment = "SEGMENT_COLLIDES_WITH_ANOTHER_SEGMENT",
  SegmentHasDifferentCoordinates = "SEGMENT_HAS_DIFFERENT_COORDINATES",
  SegmentNotOnSwath = "SEGMENT_NOT_ON_SWATH",
  StaffNotAllowed = "STAFF_NOT_ALLOWED",
  TryingToAppendInvalidSwathToBlock = "TRYING_TO_APPEND_INVALID_SWATH_TO_BLOCK",
  UserExists = "USER_EXISTS",
  UseExistingManualSegment = "USE_EXISTING_MANUAL_SEGMENT",
}

export type ErrorCodeType = {
  __typename?: "ErrorCodeType";
  code: ErrorCode;
  description: Scalars["String"]["output"];
};

export type ErrorFieldTask = ErrorFieldTaskInvalid;

export type ErrorFieldTaskInvalid = {
  __typename?: "ErrorFieldTaskInvalid";
  message: Scalars["String"]["output"];
};

export type FieldCreateInput = {
  irrigation: FieldIrrigation;
  lighting: FieldLighting;
  name: Scalars["String"]["input"];
  nitrogen?: InputMaybe<Scalars["Float"]["input"]>;
  ph: Scalars["Float"]["input"];
  phosphorus?: InputMaybe<Scalars["Float"]["input"]>;
  potassium?: InputMaybe<Scalars["Float"]["input"]>;
  robotAssignmentId?: InputMaybe<Scalars["UUID"]["input"]>;
  soilStatus: SoilStatus;
  soilType: Scalars["String"]["input"];
};

export type FieldInput = {
  irrigation: FieldIrrigation;
  lighting: FieldLighting;
  name: Scalars["String"]["input"];
  nitrogen?: InputMaybe<Scalars["Float"]["input"]>;
  ph: Scalars["Float"]["input"];
  phosphorus?: InputMaybe<Scalars["Float"]["input"]>;
  potassium?: InputMaybe<Scalars["Float"]["input"]>;
  soilStatus: SoilStatus;
  soilType: Scalars["String"]["input"];
};

export enum FieldIrrigation {
  Drip = "DRIP",
  No = "NO",
  Sprinkler = "SPRINKLER",
}

export enum FieldLighting {
  FShade = "F_SHADE",
  FSun = "F_SUN",
  PShade = "P_SHADE",
  PSun = "P_SUN",
}

export type FieldMutationPayload = {
  __typename?: "FieldMutationPayload";
  field: Maybe<FieldType>;
  success: Scalars["Boolean"]["output"];
};

export type FieldProcedureSpecificationType = {
  __typename?: "FieldProcedureSpecificationType";
  code: Scalars["String"]["output"];
  commonName: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  fieldProcedureType: FieldProcedureTypeType;
  id: Scalars["UUID"]["output"];
  images: Maybe<Array<ImageType>>;
  mainImage: Maybe<ImageType>;
};

export type FieldProcedureType = {
  __typename?: "FieldProcedureType";
  conditions: Array<ConditionType>;
  fieldProcedureSpecification: FieldProcedureSpecificationType;
  id: Scalars["UUID"]["output"];
  order: Maybe<Scalars["Int"]["output"]>;
  repetitionFrequency: RepetitionFrequency;
};

export type FieldProcedureTypeType = {
  __typename?: "FieldProcedureTypeType";
  code: Scalars["String"]["output"];
  commonName: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
};

export enum FieldState {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Created = "CREATED",
  Surveyed = "SURVEYED",
  Swathed = "SWATHED",
}

export type FieldTask = {
  __typename?: "FieldTask";
  /** Contains information about which actions is allowed for field task */
  actionsPermissions: FieldTaskActionsPermissions;
  /** Real completion of field task */
  completedAt: Maybe<Scalars["DateTime"]["output"]>;
  /** Field task ends */
  completionAt: Scalars["DateTime"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  /** Return delay of task in hours */
  delay: Scalars["Float"]["output"];
  error: Maybe<Scalars["String"]["output"]>;
  estimatedCompletionAt: Scalars["DateTime"]["output"];
  /** Estimated percentage power consumption */
  estimatedPercentagePowerConsumption: Scalars["Int"]["output"];
  estimatedStartAt: Scalars["DateTime"]["output"];
  /** Estimated time consumption in hours */
  estimatedTimeConsumption: Scalars["Float"]["output"];
  /** Estimated water consumption in liters */
  estimatedWaterConsumption: Scalars["Float"]["output"];
  id: Scalars["UUID"]["output"];
  /** The id under which the field task is sent to the robot */
  jobId: Maybe<Scalars["UUID"]["output"]>;
  /** Message which describes why is field task in status `INVALID` */
  message: Maybe<Scalars["String"]["output"]>;
  /** Number of completed plants/actions in field task */
  numberOfCompletedWorkTasks: Maybe<Scalars["Int"]["output"]>;
  /** Number of errors plants/actions during the field task */
  numberOfErrorWorkTasks: Maybe<Scalars["Int"]["output"]>;
  /** Number of plants/actions in field task */
  numberOfWorkTasks: Maybe<Scalars["Int"]["output"]>;
  /** Procedures of the field task */
  procedures: Array<FieldTaskProcedure>;
  robotAssignment: RobotAssignmentType;
  /** Planned start of field task */
  startAt: Scalars["DateTime"]["output"];
  /** Real start of field task */
  startedAt: Maybe<Scalars["DateTime"]["output"]>;
  state: FieldTaskState;
  /** Work coordinates of field task */
  workTaskReports: Maybe<Array<FieldTaskWorkReport>>;
};

export type FieldTaskActionsPermissions = {
  __typename?: "FieldTaskActionsPermissions";
  /** Permission to delete field task */
  delete: Scalars["Boolean"]["output"];
  /** Permission to move down field task */
  moveDown: Scalars["Boolean"]["output"];
  /** Permission to move up field task */
  moveUp: Scalars["Boolean"]["output"];
  /** Permission to start now field task */
  startNow: Scalars["Boolean"]["output"];
};

export type FieldTaskBatchInput = {
  /** Every procedure defined standalone field task */
  procedures: Array<FieldTaskProcedureInput>;
  /** Start of the first field task (others will be scheduled consecutively) */
  start: FieldTaskStartAtInput;
};

export type FieldTaskGqlTypeListType = {
  __typename?: "FieldTaskGQLTypeListType";
  nodes: Array<FieldTask>;
  pageInfo: PageInfo;
  success: Scalars["Boolean"]["output"];
  totalCount: Scalars["Int"]["output"];
};

export type FieldTaskInput = {
  /** Procedures of the field task */
  procedures: Array<FieldTaskProcedureInput>;
  /** Start of the field task */
  start: FieldTaskStartAtInput;
};

export enum FieldTaskJobWarningMessage {
  ExceedingMaxPowerConsumption = "EXCEEDING_MAX_POWER_CONSUMPTION",
  ExceedingMaxWaterConsumption = "EXCEEDING_MAX_WATER_CONSUMPTION",
  FieldTaskTimeOverflow = "FIELD_TASK_TIME_OVERFLOW",
}

export type FieldTaskListType = {
  __typename?: "FieldTaskListType";
  nodes: Array<FieldTask>;
  pageInfo: PageInfo;
  success: Scalars["Boolean"]["output"];
  totalCount: Scalars["Int"]["output"];
};

export type FieldTaskMutationPayload = {
  __typename?: "FieldTaskMutationPayload";
  error: Maybe<ErrorFieldTask>;
  fieldTask: Maybe<FieldTask>;
  /** List rescheduled tasks due to change */
  rescheduledTasks: Array<FieldTask>;
  success: Scalars["Boolean"]["output"];
};

export type FieldTaskParametrization = {
  __typename?: "FieldTaskParametrization";
  /** Corresponds with enum `FieldTaskParametrizationCode` */
  code: Scalars["String"]["output"];
  /** Default value for parametrization */
  default: Maybe<DefaultParametrizationValue>;
  max: Maybe<Scalars["Float"]["output"]>;
  min: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  step: Maybe<Scalars["Float"]["output"]>;
  type: FieldTaskParametrizationType;
  unit: Maybe<Scalars["String"]["output"]>;
};

export enum FieldTaskParametrizationCode {
  AmountOfWater = "AMOUNT_OF_WATER",
  AmountOfWaterMm = "AMOUNT_OF_WATER_MM",
  BreakupLiftOffset = "BREAKUP_LIFT_OFFSET",
  BreakupRotorSpeed = "BREAKUP_ROTOR_SPEED",
  DynamicWeedAll = "DYNAMIC_WEED_ALL",
  LoosenLiftOffset = "LOOSEN_LIFT_OFFSET",
  SurfaceIrrigationLiftOffset = "SURFACE_IRRIGATION_LIFT_OFFSET",
  SurfaceIrrigationRows = "SURFACE_IRRIGATION_ROWS",
  SurfaceSowLiftOffset = "SURFACE_SOW_LIFT_OFFSET",
  SurfaceSowPlantSpacingMargin = "SURFACE_SOW_PLANT_SPACING_MARGIN",
  SurfaceSowPlantSpacingOffset = "SURFACE_SOW_PLANT_SPACING_OFFSET",
  SurfaceSowPlantSpacingPattern = "SURFACE_SOW_PLANT_SPACING_PATTERN",
  SurfaceSowRows = "SURFACE_SOW_ROWS",
  TillLiftOffset = "TILL_LIFT_OFFSET",
}

export type FieldTaskParametrizationField = {
  boolValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Corresponds with enum `FieldTaskParametrizationCode` */
  code: Scalars["String"]["input"];
  floatValue?: InputMaybe<Scalars["Float"]["input"]>;
  intListValue?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  intValue?: InputMaybe<Scalars["Int"]["input"]>;
  patternValue?: InputMaybe<PlantSpacingPattern>;
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
};

export enum FieldTaskParametrizationType {
  Bool = "BOOL",
  Float = "FLOAT",
  Int = "INT",
  IntList = "INT_LIST",
  PlantSpacingPattern = "PLANT_SPACING_PATTERN",
  String = "STRING",
}

export type FieldTaskPossibility = {
  __typename?: "FieldTaskPossibility";
  possible: Scalars["Boolean"]["output"];
};

export type FieldTaskProcedure = {
  __typename?: "FieldTaskProcedure";
  block: Maybe<BlockType>;
  growingPlan: Maybe<GrowingPlanType>;
  id: Scalars["UUID"]["output"];
  order: Scalars["Int"]["output"];
  /** Dynamic enum FieldTaskType provided by query fieldTaskTypes */
  type: Scalars["String"]["output"];
};

/** Each procedure must be assign to single context entity (Block or Growing plan) */
export type FieldTaskProcedureInput = {
  /** ID of Block for which will be procedure executed */
  blockId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** ID of Growing plan for which will be procedure executed */
  growingPlanId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** List of custom fields for field task parameterization */
  parametrization?: InputMaybe<Array<FieldTaskParametrizationField>>;
  /** Type of field task provided by query fieldTaskTypes */
  type: Scalars["String"]["input"];
};

export type FieldTaskStartAtInput = {
  /** Field task must be plan into the future */
  startAtDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** Field task must be plan into the future */
  startAtTime?: InputMaybe<Scalars["Time"]["input"]>;
  /** Option of start time of task (if SCHEDULE_START field 'start_at' is required) */
  startOption: FieldTaskStartOption;
};

export enum FieldTaskStartOption {
  AfterLastTask = "AFTER_LAST_TASK",
  AsSoonAsPossible = "AS_SOON_AS_POSSIBLE",
  ScheduleStart = "SCHEDULE_START",
}

export enum FieldTaskState {
  Aborted = "ABORTED",
  Completed = "COMPLETED",
  Created = "CREATED",
  Expired = "EXPIRED",
  Failed = "FAILED",
  Invalid = "INVALID",
  InProgress = "IN_PROGRESS",
  PartiallyCompleted = "PARTIALLY_COMPLETED",
  Scheduled = "SCHEDULED",
  WaitingForRouteProcessing = "WAITING_FOR_ROUTE_PROCESSING",
}

export type FieldTaskType = {
  __typename?: "FieldTaskType";
  availableForBlock: Scalars["Boolean"]["output"];
  code: Scalars["String"]["output"];
  commonName: Scalars["String"]["output"];
  group: FieldTaskTypeGroup;
  parametrization: Array<FieldTaskParametrization>;
  toolType: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
};

export type FieldTaskTypeGroup = {
  __typename?: "FieldTaskTypeGroup";
  code: Scalars["String"]["output"];
  commonName: Scalars["String"]["output"];
};

export type FieldTaskWarningList = {
  __typename?: "FieldTaskWarningList";
  warnings: Array<FieldTaskJobWarningMessage>;
};

export type FieldTaskWorkReport = FieldTaskWorkReportBase | FieldTaskWorkReportPoint;

export type FieldTaskWorkReportBase = {
  __typename?: "FieldTaskWorkReportBase";
  id: Scalars["UUID"]["output"];
  state: WorkReportState;
};

export type FieldTaskWorkReportPoint = {
  __typename?: "FieldTaskWorkReportPoint";
  id: Scalars["UUID"]["output"];
  point: CoordinatesType;
  state: WorkReportState;
};

export type FieldType = {
  __typename?: "FieldType";
  area: Maybe<Scalars["Float"]["output"]>;
  blocks: Maybe<Array<BlockType>>;
  boundaries: Maybe<Array<CoordinatesType>>;
  clientAccount: ClientAccountType;
  createdAt: Scalars["DateTime"]["output"];
  cultivation: CultivationMethod;
  entryPoint: Maybe<CoordinatesType>;
  /** Field tasks of the field */
  fieldTasks: FieldTaskGqlTypeListType;
  /** Geofencing of the field */
  geofencing: Maybe<Array<CoordinatesType>>;
  growingPlannerState: GrowingPlannerFieldState;
  headland: Maybe<Array<CoordinatesType>>;
  homeStation: Maybe<HomeStationType>;
  id: Scalars["UUID"]["output"];
  importedBoundaries: Maybe<Array<CoordinatesType>>;
  irrigation: FieldIrrigation;
  lighting: FieldLighting;
  name: Scalars["String"]["output"];
  nitrogen: Maybe<Scalars["Float"]["output"]>;
  ph: Scalars["Float"]["output"];
  phosphorus: Maybe<Scalars["Float"]["output"]>;
  potassium: Maybe<Scalars["Float"]["output"]>;
  robotAssignment: Maybe<RobotAssignmentType>;
  soilStatus: SoilStatus;
  soilType: Scalars["String"]["output"];
  state: FieldState;
  swathAngle: Maybe<Scalars["Float"]["output"]>;
  /** Path segment used for generating swaths */
  swathPathSegment: Maybe<OptimalPathSegmentType>;
  /** Id of path segment used for generating swaths */
  swathPathSegmentId: Maybe<Scalars["Int"]["output"]>;
  /** Active swaths */
  swaths: Array<SwathType>;
  /** Swath's segments unassigned to Blocks. This is a shortcut connection to get all segments without blocks */
  unassignedSegments: Array<SegmentType>;
  /** @deprecated DEV Swaths are no longer assigned to blocks, replaced by `unassignedSwaths` */
  uncategorizedSwaths: Maybe<Array<SwathType>>;
};

export type FieldTypeFieldTasksArgs = {
  order?: InputMaybe<Order>;
  paginationInput?: InputMaybe<PaginationInput>;
};

export type FieldTypeListType = {
  __typename?: "FieldTypeListType";
  nodes: Array<FieldType>;
  pageInfo: PageInfo;
  success: Scalars["Boolean"]["output"];
  totalCount: Scalars["Int"]["output"];
};

export type FloatValue = {
  __typename?: "FloatValue";
  floatValue: Scalars["Float"]["output"];
};

export type FloatValueStringValue = FloatValue | StringValue;

export enum GpsTraceState {
  Created = "CREATED",
  Failed = "FAILED",
  Ready = "READY",
  Used = "USED",
}

export enum GpsTraceType {
  Boundaries = "BOUNDARIES",
  HomeStation = "HOME_STATION",
}

export type GeneratedSwathsType = {
  __typename?: "GeneratedSwathsType";
  /** The most optimal path segments of the field boundaries path to generate narrow headland and blocks which are parallel to it. */
  autoOptimalPaths: Array<OptimalPathSegmentType>;
  /** Inner boundaries of the field. */
  headland: Array<CoordinatesType>;
  /** Swaths of the field */
  swaths: Array<PreviewSwathType>;
};

export type GenericPayload = {
  __typename?: "GenericPayload";
  success: Scalars["Boolean"]["output"];
};

/** Summary of condition through all the stages */
export type GrowingPlanConditionSummaryType = {
  __typename?: "GrowingPlanConditionSummaryType";
  type: Scalars["String"]["output"];
  /** Yield decrement cause by current condition in KG */
  yieldDecrement: Scalars["Float"]["output"];
  /** Yield decrement cause by current condition from potential yield in percent */
  yieldDecrementPercentage: Scalars["Int"]["output"];
};

/** Evaluated condition for stage`metric` is not-null only if condition is evaluated/summarized by stage (for example Irradiance).`metric_low`/`metric_high` is not-null only if condition is evaluated daily.`metric_low`/`metric_high` represents min and max of measured metrics by stage.  */
export type GrowingPlanConditionType = {
  __typename?: "GrowingPlanConditionType";
  id: Scalars["UUID"]["output"];
  /** Value of condition in current stage (only for condition which are evaluated by stage, not day) */
  metric: Maybe<Scalars["Float"]["output"]>;
  /** Highest value of condition in current stage */
  metricHigh: Maybe<Scalars["Float"]["output"]>;
  /** Lowest value of condition in current stage */
  metricLow: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  /** Critical values of nominal condition */
  nominalCritical: Maybe<Array<Scalars["String"]["output"]>>;
  /** Limit values of nominal condition */
  nominalLimit: Maybe<Array<Scalars["String"]["output"]>>;
  /** Optimal values of nominal condition */
  nominalOptimal: Maybe<Array<Scalars["String"]["output"]>>;
  /** Nominal value of condition */
  nominalValue: Maybe<Scalars["String"]["output"]>;
  /** Days when is condition violated and condition is reducing yield */
  reduceDays: Array<GrowingPlanReduceDayType>;
  /** The highest value of the condition scale */
  scaleExtremeHigh: Maybe<Scalars["Float"]["output"]>;
  /** The lowest value of the condition scale */
  scaleExtremeLow: Maybe<Scalars["Float"]["output"]>;
  /** Step of the condition scale */
  scaleStep: Maybe<Scalars["Float"]["output"]>;
  status: ConditionStatus;
  /** Optimal high threshold for condition */
  thresholdHigh: Maybe<FloatValueStringValue>;
  /** Optimal low threshold for condition */
  thresholdLow: Maybe<FloatValueStringValue>;
  type: Scalars["String"]["output"];
  /** Units in which is metric and thresholds of conditions */
  unit: Maybe<Scalars["String"]["output"]>;
};

export type GrowingPlanFilter = {
  AND?: InputMaybe<GrowingPlanFilter>;
  DISTINCT?: InputMaybe<Scalars["Boolean"]["input"]>;
  NOT?: InputMaybe<GrowingPlanFilter>;
  OR?: InputMaybe<GrowingPlanFilter>;
  block?: InputMaybe<StrFilterLookup>;
  blockId?: InputMaybe<Scalars["UUID"]["input"]>;
  crop?: InputMaybe<StrFilterLookup>;
  cultivation?: InputMaybe<CultivationMethod>;
  endAt?: InputMaybe<Scalars["Date"]["input"]>;
  field?: InputMaybe<StrFilterLookup>;
  fieldId?: InputMaybe<Scalars["UUID"]["input"]>;
  robotId?: InputMaybe<Scalars["UUID"]["input"]>;
  startAt?: InputMaybe<Scalars["Date"]["input"]>;
  state?: InputMaybe<GrowingPlanState>;
};

export type GrowingPlanLitePreviewType = {
  __typename?: "GrowingPlanLitePreviewType";
  /** First germination day */
  date: Scalars["Date"]["output"];
  /** Expected yield percentage from potential */
  yieldPercentage: Scalars["Float"]["output"];
};

export type GrowingPlanMutationPayload = {
  __typename?: "GrowingPlanMutationPayload";
  growingPlan: Maybe<GrowingPlanType>;
  success: Scalars["Boolean"]["output"];
};

/** Serves for ordering growing plans. Please don't use multiple ordering in a single GrowingPlanOrder object (the order ofindividual attributes cannot be determined in the single GrowingPlanOrder object) instead use the attribute `nextOrder`. A sequence of ordering works how it is passed as a nested objects, so ordering starts from the shallowest to the most nested object. */
export type GrowingPlanOrder = {
  block?: InputMaybe<Ordering>;
  crop?: InputMaybe<Ordering>;
  cultivation?: InputMaybe<Ordering>;
  endAt?: InputMaybe<Ordering>;
  field?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  /** A sequence of ordering works how it is passed as a nested objects, so ordering starts from the shallowest to the most nested object */
  nextOrder?: InputMaybe<GrowingPlanOrder>;
  robot?: InputMaybe<Ordering>;
  startAt?: InputMaybe<Ordering>;
  state?: InputMaybe<Ordering>;
};

export type GrowingPlanOrderBlocks = {
  name?: InputMaybe<Ordering>;
};

export type GrowingPlanOrderCrops = {
  name?: InputMaybe<Ordering>;
};

export type GrowingPlanOrderFields = {
  name?: InputMaybe<Ordering>;
};

/** Detail of growing plan preview */
export type GrowingPlanPreviewType = {
  __typename?: "GrowingPlanPreviewType";
  block: BlockType;
  /** Summary of evaluated condition through all stages */
  conditionsSummary: Maybe<Array<GrowingPlanConditionSummaryType>>;
  cropSheet: CropSheetType;
  endAt: Scalars["Date"]["output"];
  /** Expected yield in KG */
  expectedYield: Scalars["Float"]["output"];
  /** Expected yield in percent from potential */
  expectedYieldPercentage: Scalars["Int"]["output"];
  field: FieldType;
  /** Duration of growing plan in days */
  planDuration: Scalars["Int"]["output"];
  /** Potential yield of growing plan ik KG */
  potentialYield: Scalars["Float"]["output"];
  robot: Maybe<RobotAssignmentType>;
  startAt: Scalars["Date"]["output"];
};

export type GrowingPlanReduceDayType = {
  __typename?: "GrowingPlanReduceDayType";
  /** Day when condition was violated */
  date: Scalars["Date"]["output"];
  /** Value of the condition measured in this day */
  metric: Scalars["Float"]["output"];
  /** How much the condition was violated */
  status: ConditionStatus;
  /** Decrement in percent of the yield caused by breach of condition on this day */
  yieldDecrement: Scalars["Float"]["output"];
};

export type GrowingPlanStageType = {
  __typename?: "GrowingPlanStageType";
  /** Evaluated conditions in current stage */
  conditions: Array<GrowingPlanConditionType>;
  /** If TRUE, the Crop stage represents a part of the continuous growing process between germination and harvest. */
  continuous: Scalars["Boolean"]["output"];
  /** If True, growing plan is in this stage */
  currentStage: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  /** Duration of stage in days */
  duration: Maybe<Scalars["Int"]["output"]>;
  /** Duration of stage of plan in percent */
  durationPercentage: Maybe<Scalars["Int"]["output"]>;
  /** Ending day of this stage */
  endAt: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["UUID"]["output"];
  /** Image of condition */
  image: ImageType;
  name: Scalars["String"]["output"];
  /** Order of the stage in growing plan */
  order: Scalars["Int"]["output"];
  /** Starting day of this stage */
  startAt: Maybe<Scalars["Date"]["output"]>;
};

export enum GrowingPlanState {
  Archived = "ARCHIVED",
  Finished = "FINISHED",
  InProgress = "IN_PROGRESS",
  Scheduled = "SCHEDULED",
}

export type GrowingPlanType = Node & {
  __typename?: "GrowingPlanType";
  block: BlockType;
  /** Evaluated conditions for which are NOT dependent on the stage. */
  conditions: Array<GrowingPlanConditionType>;
  /** Summary of evaluated condition through all stages */
  conditionsSummary: Array<GrowingPlanConditionSummaryType>;
  createdAt: Scalars["DateTime"]["output"];
  /** Crop of growing plan */
  cropSheet: CropSheetType;
  /** End of growing plan */
  endAt: Scalars["Date"]["output"];
  /** Expected yield in kg */
  expectedYield: Scalars["Float"]["output"];
  /** Percent of expected yield from potential yield */
  expectedYieldPercentage: Scalars["Int"]["output"];
  field: FieldType;
  id: Scalars["GlobalID"]["output"];
  name: Scalars["String"]["output"];
  /** Duration of growing plan in days */
  planDuration: Scalars["Int"]["output"];
  /** Percentage progress of growing plan */
  planProgress: Scalars["Int"]["output"];
  /** Progress of plan in days */
  planProgressInDays: Scalars["Int"]["output"];
  /** Plan will start in days */
  planScheduleInDays: Maybe<Scalars["Int"]["output"]>;
  /** Currently active plant spacing if configured */
  plantSpacing: Maybe<PlantSpacing>;
  robot: Maybe<RobotAssignmentType>;
  /** Stages of current growing plan and crop */
  stages: Array<GrowingPlanStageType>;
  /** Start of growing plan */
  startAt: Scalars["Date"]["output"];
  state: GrowingPlanState;
};

export type GrowingPlanTypeListType = {
  __typename?: "GrowingPlanTypeListType";
  nodes: Array<GrowingPlanType>;
  pageInfo: PageInfo;
  success: Scalars["Boolean"]["output"];
  totalCount: Scalars["Int"]["output"];
};

export enum GrowingPlannerFieldState {
  Init = "INIT",
  NoInit = "NO_INIT",
  Ready = "READY",
}

export type HomeStationType = {
  __typename?: "HomeStationType";
  /** Coordinates of home station */
  coordinates: CoordinatesType;
  createdAt: Scalars["DateTime"]["output"];
  field: FieldType;
  /** Geofencing of the home station */
  geofencing: Maybe<Array<CoordinatesType>>;
  /** GPS Trace data used to create the home station path */
  gpsTrace: TraceType;
  /** Return active path unassigned to field */
  homeStationPath: PathType;
  id: Scalars["UUID"]["output"];
};

export type ImageType = {
  __typename?: "ImageType";
  alt: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type IntListValue = {
  __typename?: "IntListValue";
  intListValue: Array<Scalars["Int"]["output"]>;
};

export type IntValue = {
  __typename?: "IntValue";
  intValue: Scalars["Int"]["output"];
};

export type InviteRegisterInput = {
  firstName: Scalars["String"]["input"];
  /** Invitation URL slug, idenfies invite. */
  invitationUrlSlug: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

/** Input type of Block entity for manual field */
export type ManualBlockInput = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  segments: Array<ManualSegmentInput>;
};

/** Input for multiple Blocks entity of manual field */
export type ManualBlocksInput = {
  blocks: Array<ManualBlockInput>;
};

export type ManualFieldBoundariesInput = {
  boundaries: Array<CoordinatesInput>;
};

/** Segment input for segments defined by user. To create new Segment dont set `Id` */
export type ManualSegmentInput = {
  boundaries: Array<CoordinatesInput>;
  /** Optional ID for already existing segments */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MultipleFieldTaskMutationPayload = {
  __typename?: "MultipleFieldTaskMutationPayload";
  errors: Maybe<Array<ErrorFieldTask>>;
  fieldTasks: Maybe<Array<FieldTask>>;
  /** List rescheduled tasks due to change */
  rescheduledTasks: Array<FieldTask>;
  success: Scalars["Boolean"]["output"];
};

/** An object with a Globally Unique ID */
export type Node = {
  /** The Globally Unique ID of this object */
  id: Scalars["GlobalID"]["output"];
};

export type OptimalPathSegmentType = {
  __typename?: "OptimalPathSegmentType";
  endPoint: CoordinatesType;
  /** Fake ID for backward compatibility */
  id: Scalars["Int"]["output"];
  length: Maybe<Scalars["Float"]["output"]>;
  startPoint: CoordinatesType;
  startPointIndex: Scalars["Int"]["output"];
  type: PathSegmentType;
};

export enum Order {
  Asc = "ASC",
  Desc = "DESC",
}

export enum Ordering {
  Asc = "ASC",
  AscNullsFirst = "ASC_NULLS_FIRST",
  AscNullsLast = "ASC_NULLS_LAST",
  Desc = "DESC",
  DescNullsFirst = "DESC_NULLS_FIRST",
  DescNullsLast = "DESC_NULLS_LAST",
}

export type PageInfo = {
  __typename?: "PageInfo";
  lastPage: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  perPage: Scalars["Int"]["output"];
};

export type PaginationInput = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum PathSegmentType {
  GreenhouseSwathTransport = "GREENHOUSE_SWATH_TRANSPORT",
  TransportBoundaries = "TRANSPORT_BOUNDARIES",
  TransportCurve = "TRANSPORT_CURVE",
  TransportCurveRevers = "TRANSPORT_CURVE_REVERS",
  TransportCurveReverse = "TRANSPORT_CURVE_REVERSE",
  TransportSwath = "TRANSPORT_SWATH",
  TransportSwathRevers = "TRANSPORT_SWATH_REVERS",
  TransportSwathReverse = "TRANSPORT_SWATH_REVERSE",
  WorkSwath = "WORK_SWATH",
}

export type PathType = {
  __typename?: "PathType";
  id: Scalars["UUID"]["output"];
  pathCoordinates: Array<CoordinatesType>;
  pathLength: Scalars["Float"]["output"];
};

/** Plant representation */
export type Plant = {
  __typename?: "Plant";
  /** Plant coordinates */
  coordinates: CoordinatesType;
  id: Maybe<Scalars["UUID"]["output"]>;
};

/** Plant rows configuration input */
export type PlantRowsInput = {
  /** Swath end margin in cm, range 0, 100. */
  endMargin: Scalars["Float"]["input"];
};

/** Preview of the plant rows */
export type PlantRowsPreview = {
  __typename?: "PlantRowsPreview";
  /** Swath end margin in cm, range 0, 100. */
  endMargin: Scalars["Int"]["output"];
  /** Number of rows to plant, 1-10 */
  rows: Scalars["Int"]["output"];
  /** Additional planting data for segments (inner boundaries planting rows) */
  segments: Array<SegmentPlantingData>;
  /** Swath side margin in cm, range -10, 100. */
  sideMargin: Scalars["Int"]["output"];
  /** Type of plant spacing */
  type: Scalars["String"]["output"];
};

/** Plant spacing configuration */
export type PlantSpacing = {
  __typename?: "PlantSpacing";
  /** Swath end margin in cm, range 0, 100. */
  endMargin: Scalars["Int"]["output"];
  id: Scalars["UUID"]["output"];
  /** Sowing pattern. */
  pattern: PlantSpacingPattern;
  /** Plants coordinates */
  plants: Array<Plant>;
  /** Number of plants for growing plan/block */
  plantsCount: Scalars["Int"]["output"];
  /** Number of plants for growing plan/block per square meter */
  plantsCountPerSquareMeter: Scalars["Float"]["output"];
  /** Number of rows to plant, 1-10 */
  rows: Scalars["Int"]["output"];
  /** Additional planting data for segments (inner boundaries planting rows) */
  segments: Array<SegmentPlantingData>;
  /** Selected rows for plant spacing (in Custom plant spacing is selected every row) */
  selectedRows: Array<Scalars["Int"]["output"]>;
  /** Swath side margin in cm, range -10, 100. */
  sideMargin: Scalars["Int"]["output"];
  /** Spacing between plants in cm, range 1, 200. */
  spacing: Scalars["Int"]["output"];
  /** Type of plant spacing */
  type: Scalars["String"]["output"];
};

export enum PlantSpacingPattern {
  Rectangle = "RECTANGLE",
  Triangle = "TRIANGLE",
}

export type PlantSpacingPatternValue = {
  __typename?: "PlantSpacingPatternValue";
  patternValue: PlantSpacingPattern;
};

/** Plant spacing preview */
export type PlantSpacingPreview = {
  __typename?: "PlantSpacingPreview";
  /** Swath end margin in cm, range 0, 100. */
  endMargin: Scalars["Int"]["output"];
  /** Sowing pattern. */
  pattern: PlantSpacingPattern;
  /** Plants coordinates */
  plants: Array<Plant>;
  /** Number of plants for growing plan/block */
  plantsCount: Scalars["Int"]["output"];
  /** Number of plants for growing plan/block per square meter */
  plantsCountPerSquareMeter: Scalars["Float"]["output"];
  /** Number of rows to plant, 1-10 */
  rows: Scalars["Int"]["output"];
  /** Additional planting data for segments (inner boundaries planting rows) */
  segments: Array<SegmentPlantingData>;
  /** Selected rows for plant spacing (in Custom plant spacing is selected every row) */
  selectedRows: Array<Scalars["Int"]["output"]>;
  /** Swath side margin in cm, range -10, 100. */
  sideMargin: Scalars["Int"]["output"];
  /** Spacing between plants in cm, range 1, 200. */
  spacing: Scalars["Int"]["output"];
  /** Type of plant spacing */
  type: Scalars["String"]["output"];
};

/** Planting row */
export type PlantingRow = {
  __typename?: "PlantingRow";
  /** List of points bordering the row */
  boundaries: Array<CoordinatesType>;
  /** Planting row end point */
  endPoint: CoordinatesType;
  /** Planting row start point */
  startPoint: CoordinatesType;
};

export type PreviewSwathType = {
  __typename?: "PreviewSwathType";
  area: Maybe<Scalars["Float"]["output"]>;
  endPoint: CoordinatesType;
  length: Maybe<Scalars["Float"]["output"]>;
  order: Maybe<Scalars["Int"]["output"]>;
  startPoint: CoordinatesType;
};

export type PrincipalGrowthStageType = {
  __typename?: "PrincipalGrowthStageType";
  caption: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  step: Scalars["Int"]["output"];
};

export type Query = {
  __typename?: "Query";
  /**
   * Block query
   * Possible errors:
   *  `PERM_NOT_MEMBER` - User must be member of client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   */
  block: BlockType;
  /**
   *
   *         Return gps traces for boundaries
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *
   */
  boundariesGpsTraces: TraceTypeListType;
  /**
   * Get ClientAccount by ID
   * Possible errors:
   *  `CLIENT_ACCOUNT_NOT_FOUND` - Client account not found
   */
  clientAccount: ClientAccountType;
  /** List user's ClientAccounts(without archived accounts) */
  clientAccounts: ClientAccountTypeListType;
  conditions: Array<ConditionType>;
  /** Optional filter by disease type */
  cropDiseases: Array<CropDiseaseType>;
  cropSheet: CropSheetType;
  cropSheetList: CropSheetListType;
  cropStages: CropStagesType;
  disease: DiseaseType;
  diseases: Array<DiseaseType>;
  /** Return list of error codes and default message */
  errorCodes: Array<ErrorCodeType>;
  /**
   * Field query
   * Possible errors:
   *  `PERM_NOT_MEMBER` - User must be member of client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   */
  field: FieldType;
  /**
   * Return field task
   *
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `DATA_UNAVAILABLE_ERROR` - Data is unavailable
   */
  fieldTask: FieldTask;
  fieldTaskParametrizationCode: FieldTaskParametrizationCode;
  /**
   *
   *                     If param `field_id` is None return all field task types.
   *                     Otherwise return field task types filtered for provided field.
   *
   * Possible errors:
   *  `DATA_UNAVAILABLE_ERROR` - Data is unavailable
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   */
  fieldTaskTypes: Array<FieldTaskType>;
  /**
   *
   *         Return the list of field tasks (Default order Order.ASC).
   *         At least one context entity id must be provided, otherwise raise the error.
   *
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `REQUIRE_FIELD_TASK_CONTEXT_ENTITY` - Require field task context entity
   */
  fieldTasks: FieldTaskListType;
  /**
   * Return warning messages for single day for robot assignment's field tasks.
   *
   * Possible errors:
   *  `DATA_UNAVAILABLE_ERROR` - Data is unavailable
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   */
  fieldTasksWarnings: FieldTaskWarningList;
  /**
   * Return list of fields in user's active client account
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   */
  fields: FieldTypeListType;
  /**
   * Single growing plan by id
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   */
  growingPlan: GrowingPlanType;
  /** Filter growing plans */
  growingPlanFilter: GrowingPlanTypeListType;
  /** Filter the available blocks corresponding to the growing plan filter */
  growingPlanFilterBlocks: Array<BlockType>;
  /** Filter the available crops corresponding to the growing plan filter */
  growingPlanFilterCrops: Array<CropMetadataType>;
  /** Filter the available fields corresponding to the growing plan filter */
  growingPlanFilterFields: Array<FieldType>;
  /**
   * Get growing plan preview for specific starting day
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `GROWING_PLANNER_FIELD_IS_NOT_READY` - Field inside growing planner is not ready to render plan
   *  `ROUTE_NOT_AVAILABLE` - Routes for selected block are not available
   */
  growingPlanPreview: GrowingPlanPreviewType;
  /**
   * Get growing plan preview for year
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `GROWING_PLANNER_FIELD_IS_NOT_READY` - Field inside growing planner is not ready to render plan
   *  `ROUTE_NOT_AVAILABLE` - Routes for selected block are not available
   */
  growingPlanYearPreview: Array<GrowingPlanLitePreviewType>;
  /**
   * Return home station of field if exist
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INVALID_FIELD_STATE` - Invalid field state
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   */
  homeStation: Maybe<HomeStationType>;
  /**
   *
   *         Return GPS traces for the home station.
   *         GPS traces are filtered to return only traces close to the field entry point
   *         (close distance is a dynamic value defined in administration).
   *
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `INVALID_FIELD_STATE` - Invalid field state
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *
   */
  homeStationGpsTraces: TraceTypeListType;
  /**
   * Return the possibility of creation field task on multiple blocks.
   *
   * Possible errors:
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   */
  isFieldTaskPossible: FieldTaskPossibility;
  /** Return user information, including active profile. */
  myProfile: UserType;
  /**
   *
   *         Preview plant spacing for GrowingPlan
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `MICROSERVICE_ERROR` - Error caused by incorrect behavior of microservice
   *  `ROUTE_PLANNER_ERROR` - Error when using route planner microservice
   *  `PLANT_SPACING_ALREADY_EXISTS` - Growing plan has already plant spacing.
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   */
  previewCustomPlantSpacing: PlantSpacingPreview;
  /**
   *
   *             Get preview of plant rows
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `MICROSERVICE_ERROR` - Error caused by incorrect behavior of microservice
   *  `ROUTE_PLANNER_ERROR` - Error when using route planner microservice
   *  `PLANT_SPACING_ALREADY_EXISTS` - Growing plan has already plant spacing.
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   */
  previewPlantRows: PlantRowsPreview;
  /**
   *
   *         Preview row based plant spacing for GrowingPlan
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `MICROSERVICE_ERROR` - Error caused by incorrect behavior of microservice
   *  `ROUTE_PLANNER_ERROR` - Error when using route planner microservice
   *  `PLANT_SPACING_ALREADY_EXISTS` - Growing plan has already plant spacing.
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   */
  previewRowBasedPlantSpacing: PlantSpacingPreview;
  /**
   *
   *         Get preview of swath segmentation for field. Call without length to return single segment for each swath.
   *         - `length` max length in meters
   *         - `gap` max gap in meters
   *
   * Possible errors:
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `ROUTE_PLANNER_ERROR` - Error when using route planner microservice
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  previewSegments: Array<SwathSegmentPreviewType>;
  /**
   * Generate preview of swaths for field (with imported boundaries). Swaths are generated in parallel to the longest boundary's path segment by default.Provide `ref_path_segment_id` to generate swath parallel to specific path segment.List of optimal path segment choices is returned by this API.Provide `angle` to generate swath at specific angle. `ref_path_segment_id` and `angle` cannot be passed together.
   * Possible errors:
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   *  `ROUTE_PLANNER_ERROR` - Error when using route planner microservice
   *  `INVALID_CULTIVATION_METHOD` - Invalid cultivation method of the field
   *  `INVALID_FIELD_STATE` - Invalid field state
   */
  previewSwaths: GeneratedSwathsType;
  /**
   * Return assigned robot.
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   */
  robotAssignment: RobotAssignmentType;
  /**
   * Return the list of field tasks assigned to the robot assignment.
   *
   * Possible errors:
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `INACTIVE_CLIENT_ACCOUNT` - User can not access data from inactive client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   * @deprecated This query should be replaced by query `fieldTasks`
   */
  robotAssignmentFieldTasks: FieldTaskListType;
  /**
   * Return list of registered assigned robots. Robots available for Field assignment.
   * Possible errors:
   *  `NOT_ACTIVE_CLIENT_ACCOUNT` - User does not have active client account
   *  `PERM_NOT_OWNER` - User must be owner of client account
   *  `CLIENT_ACCOUNT_NOT_APPROVED` - Client account must be approved
   */
  robotAssignments: Array<RobotAssignmentType>;
  soilTypes: Array<SoilTypeType>;
};

export type QueryBlockArgs = {
  blockId: Scalars["UUID"]["input"];
};

export type QueryBoundariesGpsTracesArgs = {
  paginationInput?: InputMaybe<PaginationInput>;
};

export type QueryClientAccountArgs = {
  clientAccountId: Scalars["UUID"]["input"];
};

export type QueryClientAccountsArgs = {
  paginationInput?: InputMaybe<PaginationInput>;
};

export type QueryCropDiseasesArgs = {
  cropSheetId: Scalars["UUID"]["input"];
  type?: InputMaybe<DiseaseCategory>;
};

export type QueryCropSheetArgs = {
  cropSheetId: Scalars["UUID"]["input"];
};

export type QueryCropStagesArgs = {
  cropSheetId: Scalars["UUID"]["input"];
};

export type QueryDiseaseArgs = {
  diseaseId: Scalars["UUID"]["input"];
};

export type QueryDiseasesArgs = {
  type?: InputMaybe<DiseaseCategory>;
};

export type QueryFieldArgs = {
  fieldId: Scalars["UUID"]["input"];
};

export type QueryFieldTaskArgs = {
  fieldTaskId: Scalars["UUID"]["input"];
};

export type QueryFieldTaskTypesArgs = {
  fieldId?: InputMaybe<Scalars["UUID"]["input"]>;
  growingPlanId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type QueryFieldTasksArgs = {
  blockId?: InputMaybe<Scalars["UUID"]["input"]>;
  growingPlanId?: InputMaybe<Scalars["UUID"]["input"]>;
  order?: InputMaybe<Order>;
  paginationInput?: InputMaybe<PaginationInput>;
  robotAssignmentId?: InputMaybe<Scalars["UUID"]["input"]>;
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type QueryFieldTasksWarningsArgs = {
  date: Scalars["Date"]["input"];
  robotAssignmentId: Scalars["UUID"]["input"];
};

export type QueryFieldsArgs = {
  paginationInput?: InputMaybe<PaginationInput>;
};

export type QueryGrowingPlanArgs = {
  growingPlanId: Scalars["UUID"]["input"];
};

export type QueryGrowingPlanFilterArgs = {
  filters?: InputMaybe<GrowingPlanFilter>;
  order?: InputMaybe<GrowingPlanOrder>;
  paginationInput?: InputMaybe<PaginationInput>;
};

export type QueryGrowingPlanFilterBlocksArgs = {
  filters?: InputMaybe<GrowingPlanFilter>;
  order?: InputMaybe<GrowingPlanOrderBlocks>;
};

export type QueryGrowingPlanFilterCropsArgs = {
  filters?: InputMaybe<GrowingPlanFilter>;
  order?: InputMaybe<GrowingPlanOrderCrops>;
};

export type QueryGrowingPlanFilterFieldsArgs = {
  filters?: InputMaybe<GrowingPlanFilter>;
  order?: InputMaybe<GrowingPlanOrderFields>;
};

export type QueryGrowingPlanPreviewArgs = {
  blockId: Scalars["UUID"]["input"];
  cropSheetId: Scalars["UUID"]["input"];
  startAt: Scalars["Date"]["input"];
};

export type QueryGrowingPlanYearPreviewArgs = {
  blockId: Scalars["UUID"]["input"];
  cropSheetId: Scalars["UUID"]["input"];
};

export type QueryHomeStationArgs = {
  fieldId: Scalars["UUID"]["input"];
};

export type QueryHomeStationGpsTracesArgs = {
  fieldId: Scalars["UUID"]["input"];
  paginationInput?: InputMaybe<PaginationInput>;
};

export type QueryIsFieldTaskPossibleArgs = {
  blockIds: Array<Scalars["UUID"]["input"]>;
};

export type QueryPreviewCustomPlantSpacingArgs = {
  growingPlanId: Scalars["UUID"]["input"];
  input: CustomPlantSpacingInput;
};

export type QueryPreviewPlantRowsArgs = {
  growingPlanId: Scalars["UUID"]["input"];
  input: PlantRowsInput;
};

export type QueryPreviewRowBasedPlantSpacingArgs = {
  growingPlanId: Scalars["UUID"]["input"];
  input: RowBasedPlantSpacingInput;
};

export type QueryPreviewSegmentsArgs = {
  gap?: InputMaybe<Scalars["Float"]["input"]>;
  length?: InputMaybe<Scalars["Float"]["input"]>;
  swaths: Array<Scalars["UUID"]["input"]>;
};

export type QueryPreviewSwathsArgs = {
  angle?: InputMaybe<Scalars["Float"]["input"]>;
  fieldId: Scalars["UUID"]["input"];
  refPathSegmentId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryRobotAssignmentArgs = {
  robotAssignmentId: Scalars["UUID"]["input"];
};

export type QueryRobotAssignmentFieldTasksArgs = {
  growingPlanId?: InputMaybe<Scalars["UUID"]["input"]>;
  paginationInput?: InputMaybe<PaginationInput>;
  robotAssignmentId: Scalars["UUID"]["input"];
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RiRobotBattery = {
  __typename?: "RIRobotBattery";
  chargingState: Maybe<RiRobotBatteryChargingState>;
  estimEnergy: Maybe<Scalars["Float"]["output"]>;
  powerUsage: Maybe<Scalars["Float"]["output"]>;
  soc: Maybe<Scalars["Int"]["output"]>;
  voltage: Maybe<Scalars["Float"]["output"]>;
};

export enum RiRobotBatteryChargingState {
  Balancing = "BALANCING",
  ChargingError = "CHARGING_ERROR",
  ChargingFinished = "CHARGING_FINISHED",
  Disconnect = "DISCONNECT",
  MainCharging = "MAIN_CHARGING",
  Precharging = "PRECHARGING",
  Preheating = "PREHEATING",
}

export type RiRobotDevice = {
  __typename?: "RIRobotDevice";
  active: Scalars["Boolean"]["output"];
  error: Scalars["Boolean"]["output"];
  type: RiRobotDeviceType;
};

export enum RiRobotDeviceType {
  ActiveTool = "ACTIVE_TOOL",
  LeftMotor = "LEFT_MOTOR",
  Lidar = "LIDAR",
  RightMotor = "RIGHT_MOTOR",
  RoboticArm = "ROBOTIC_ARM",
  Tracker = "TRACKER",
  TransverseAxis = "TRANSVERSE_AXIS",
  WaterPump = "WATER_PUMP",
}

export type RiRobotGeneral = {
  __typename?: "RIRobotGeneral";
  deviceBits: Maybe<Scalars["Int"]["output"]>;
  devices: Array<RiRobotDevice>;
  errorBits: Maybe<Scalars["Int"]["output"]>;
  mode: RiRobotMode;
  status: Scalars["Int"]["output"];
  statusTxt: Maybe<Scalars["String"]["output"]>;
  timestamp: Scalars["Int"]["output"];
  waterInTank: Maybe<Scalars["Int"]["output"]>;
};

export enum RiRobotMode {
  Auto = "AUTO",
  Demo = "DEMO",
  Home = "HOME",
  ManualMp = "MANUAL_MP",
  ManualPc = "MANUAL_PC",
  ManualSc = "MANUAL_SC",
  SemiAuto = "SEMI_AUTO",
  Stop = "STOP",
}

export type RiRobotPosition = {
  __typename?: "RIRobotPosition";
  alt: Maybe<Scalars["Float"]["output"]>;
  azi: Maybe<Scalars["Float"]["output"]>;
  dist: Maybe<Scalars["Float"]["output"]>;
  lat: Maybe<Scalars["Float"]["output"]>;
  lon: Maybe<Scalars["Float"]["output"]>;
  speed: Maybe<Scalars["Float"]["output"]>;
};

export type RiRobotState = {
  __typename?: "RIRobotState";
  battery: Maybe<RiRobotBattery>;
  general: RiRobotGeneral;
  position: Maybe<RiRobotPosition>;
  target: Maybe<RiRobotTarget>;
  tool: Maybe<RiRobotTool>;
  tracker: Maybe<RiRobotTarget>;
  transAxis: Maybe<RiRobotTransAxis>;
};

export type RiRobotTarget = {
  __typename?: "RIRobotTarget";
  azi: Maybe<Scalars["Float"]["output"]>;
  lat: Maybe<Scalars["Float"]["output"]>;
  lon: Maybe<Scalars["Float"]["output"]>;
  speed: Maybe<Scalars["Float"]["output"]>;
};

export type RiRobotTool = {
  __typename?: "RIRobotTool";
  mode: Maybe<RiRobotToolMode>;
  type: Maybe<RiRobotToolType>;
};

export enum RiRobotToolMode {
  Off = "OFF",
  RunningProgramWithTool = "RUNNING_PROGRAM_WITH_TOOL",
}

export enum RiRobotToolType {
  EmptySlot_1 = "EMPTY_SLOT_1",
  EmptySlot_2 = "EMPTY_SLOT_2",
  GardenBoy = "GARDEN_BOY",
  JetNozzle = "JET_NOZZLE",
  None = "NONE",
  Plough = "PLOUGH",
  Rake = "RAKE",
  ShowerHead = "SHOWER_HEAD",
  Stake = "STAKE",
}

export type RiRobotTransAxis = {
  __typename?: "RIRobotTransAxis";
  mode: Maybe<Scalars["Int"]["output"]>;
  pos: Maybe<Scalars["Float"]["output"]>;
  targetPos: Maybe<Scalars["Float"]["output"]>;
  targetSpeed: Maybe<Scalars["Float"]["output"]>;
};

export enum RepetitionFrequency {
  Daily = "DAILY",
  EverySecondDay = "EVERY_SECOND_DAY",
  EveryThirdDay = "EVERY_THIRD_DAY",
  Once = "ONCE",
  Other = "OTHER",
  Weekly = "WEEKLY",
}

export type Robot = {
  __typename?: "Robot";
  /** The maximum reach of the robotic arm, the length between the end of the track and the point of the maximum reach */
  armRangeLength: Scalars["Float"]["output"];
  /** The largest measurable width of space that can be served by a robotic arm */
  armRangeWidth: Scalars["Float"]["output"];
  bleAndroidIdentifier: Scalars["String"]["output"];
  bleIosIdentifier: Scalars["UUID"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["UUID"]["output"];
  /** The maximum change of curvature in a turn */
  maxCurvatureChange: Scalars["Float"]["output"];
  /** The largest measurable height of the robot */
  maxHeight: Scalars["Float"]["output"];
  /** The maximum speed of the vehicle when turning */
  maxSpeed: Scalars["Float"]["output"];
  /** The minimum turning circle radius */
  minRadius: Scalars["Float"]["output"];
  /** The speed of the vehicle when traveling through the field */
  operationalSpeed: Scalars["Float"]["output"];
  /** Datetime of last robot state report */
  reportedAt: Maybe<Scalars["DateTime"]["output"]>;
  /** The largest measurable length of the robot at the 15 cm above the the ground */
  robotLengthAboveGround: Scalars["Float"]["output"];
  /** The largest measurable length of the robot at the point of contact with the ground */
  robotLengthOnGround: Scalars["Float"]["output"];
  /** The largest measurable width of the robot at the point of contact with the ground */
  robotWidth: Scalars["Float"]["output"];
  serialNumber: Scalars["String"]["output"];
  /** The height between the ground and the lowest part of solar panels */
  solarPanelsMinHeight: Scalars["Float"]["output"];
  /** The largest measurable width of the robot at the point between the lowest and highest part of solar panel */
  solarPanelsWidth: Scalars["Float"]["output"];
  state: Scalars["String"]["output"];
  /** Current state reported by robot */
  stateReport: Maybe<RiRobotState>;
  /** The largest measurable width of track */
  trackWidth: Scalars["Float"]["output"];
};

export type RobotAssignmentInput = {
  name: Scalars["String"]["input"];
  secretKey: Scalars["String"]["input"];
  serialNumber: Scalars["String"]["input"];
};

export type RobotAssignmentMutationPayload = {
  __typename?: "RobotAssignmentMutationPayload";
  robotAssignment: Maybe<RobotAssignmentType>;
  success: Scalars["Boolean"]["output"];
};

export type RobotAssignmentType = {
  __typename?: "RobotAssignmentType";
  createdAt: Scalars["DateTime"]["output"];
  /** Fields related to robot assignment */
  fields: Array<FieldType>;
  /** Growing plans related to robot assignment */
  growingPlans: Array<GrowingPlanType>;
  id: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  registeredAt: Maybe<Scalars["DateTime"]["output"]>;
  /** Robot of the robot assignment */
  robot: Robot;
};

export type RobotAssignmentUpdateInput = {
  name: Scalars["String"]["input"];
};

export enum RoutePlannerBlockState {
  Done = "DONE",
  Error = "ERROR",
  NoPath = "NO_PATH",
  Processing = "PROCESSING",
}

/** Row based plant spacing configuration input */
export type RowBasedPlantSpacingInput = {
  /** Swath end margin in cm, range 0, 100. */
  endMargin: Scalars["Float"]["input"];
  /** Sowing pattern. */
  pattern: PlantSpacingPattern;
  /** Indexes of selected rows (index from 0) */
  selectedRows: Array<Scalars["Int"]["input"]>;
  /** Spacing between plants in cm, range 1, 200. */
  spacing: Scalars["Float"]["input"];
};

/** Block input for Blocks with segments. To create new Block dont set `Id` */
export type SegmentBlockInput = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional Id, provide if changing existing block */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  name: Scalars["String"]["input"];
  /** List of segments ids to be associated with block */
  segmentsId: Array<Scalars["UUID"]["input"]>;
};

export type SegmentBlocksInput = {
  blocks: Array<SegmentBlockInput>;
};

export type SegmentInput = {
  endPoint: CoordinatesInput;
  /** Optional ID for already existing segments */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  startPoint: CoordinatesInput;
};

/** Additional segment rendering data for planting */
export type SegmentPlantingData = {
  __typename?: "SegmentPlantingData";
  /** Inner boundaries of segment determined by PlantSpacing setup */
  innerBoundaries: Array<CoordinatesType>;
  /** Planting rows determined by PlantSpacing setup */
  rows: Array<PlantingRow>;
  /** Id of parent segment */
  segmentId: Scalars["UUID"]["output"];
};

/** Segment preview, used to preview swath segmentation */
export type SegmentPreviewType = {
  __typename?: "SegmentPreviewType";
  /** Area of the Segment in m^2 */
  area: Scalars["Float"]["output"];
  /** The boundaries of the Segment */
  boundaries: Array<CoordinatesType>;
  /** The ending GPS coordinates of the Segment (pass back to BE in update mutation, use polygon for displaying) */
  endPoint: CoordinatesType;
  /** Length of the Segment in meters */
  length: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  /** Order in the swath */
  order: Scalars["Int"]["output"];
  /** The starting GPS coordinates of the Segment (pass back to BE in update mutation, use polygon for displaying) */
  startPoint: CoordinatesType;
  swathId: Scalars["ID"]["output"];
};

export type SegmentType = {
  __typename?: "SegmentType";
  area: Scalars["Float"]["output"];
  block: Maybe<BlockType>;
  boundaries: Array<CoordinatesType>;
  /** End point of the segment, used for autonomous field */
  endPoint: Maybe<CoordinatesType>;
  id: Scalars["UUID"]["output"];
  length: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  order: Maybe<Scalars["Int"]["output"]>;
  /** Start point of the segment, used for autonomous field */
  startPoint: Maybe<CoordinatesType>;
  state: Scalars["String"]["output"];
  swath: Maybe<SwathType>;
};

export enum SoilStatus {
  AddedSoilAmendments = "ADDED_SOIL_AMENDMENTS",
  BrokenUp = "BROKEN_UP",
  EarthedUp = "EARTHED_UP",
  Leveled = "LEVELED",
  Loosened = "LOOSENED",
  Overgrown = "OVERGROWN",
  Spreaded = "SPREADED",
}

export type SoilTypeType = {
  __typename?: "SoilTypeType";
  code: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  optimalMoisture: Scalars["Float"]["output"];
};

export type StrFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<Scalars["String"]["input"]>;
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars["String"]["input"]>;
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<Scalars["String"]["input"]>;
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<Scalars["String"]["input"]>;
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<Scalars["String"]["input"]>;
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<Scalars["String"]["input"]>;
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringValue = {
  __typename?: "StringValue";
  stringValue: Scalars["String"]["output"];
};

export type SwathSegmentInput = {
  segments: Array<SegmentInput>;
  swathId: Scalars["UUID"]["input"];
};

/** Segment wrapper, umbrella object for segments under same swath */
export type SwathSegmentPreviewType = {
  __typename?: "SwathSegmentPreviewType";
  segments: Array<SegmentPreviewType>;
  swathId: Scalars["UUID"]["output"];
};

export type SwathType = {
  __typename?: "SwathType";
  area: Scalars["Float"]["output"];
  endPoint: CoordinatesType;
  id: Scalars["UUID"]["output"];
  length: Scalars["Float"]["output"];
  order: Scalars["Int"]["output"];
  /** DEV segments of the swath */
  segments: Array<SegmentType>;
  startPoint: CoordinatesType;
};

export type SwitchClientAccountMutationPayload = {
  __typename?: "SwitchClientAccountMutationPayload";
  success: Scalars["Boolean"]["output"];
  user: Maybe<UserType>;
};

export type TraceType = {
  __typename?: "TraceType";
  completedAt: Scalars["DateTime"]["output"];
  coordinates: Maybe<Array<CoordinatesType>>;
  createdAt: Scalars["DateTime"]["output"];
  field: Maybe<FieldType>;
  homeStations: Array<HomeStationType>;
  id: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  robotAssignment: Maybe<RobotAssignmentType>;
  startedAt: Scalars["DateTime"]["output"];
  state: GpsTraceState;
  type: GpsTraceType;
};

export type TraceTypeListType = {
  __typename?: "TraceTypeListType";
  nodes: Array<TraceType>;
  pageInfo: PageInfo;
  success: Scalars["Boolean"]["output"];
  totalCount: Scalars["Int"]["output"];
};

export type UserEditProfileInput = {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  locale: Scalars["String"]["input"];
};

export type UserRegisterInput = {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserType = {
  __typename?: "UserType";
  activeClientAccount: Maybe<ClientAccountType>;
  clientAccounts: Maybe<Array<ClientAccountType>>;
  createdAt: Scalars["DateTime"]["output"];
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  lastName: Scalars["String"]["output"];
  phone: Maybe<Scalars["String"]["output"]>;
};

export enum WorkReportState {
  Completed = "COMPLETED",
  Failed = "FAILED",
  Pending = "PENDING",
}

export type CoordinateFragment = { __typename?: "CoordinatesType"; lat: number; lon: number };

type DefaultParametrizationValue_BoolValue_Fragment = { __typename: "BoolValue"; boolValue: boolean };

type DefaultParametrizationValue_FloatValue_Fragment = { __typename: "FloatValue"; floatValue: number };

type DefaultParametrizationValue_IntListValue_Fragment = { __typename: "IntListValue"; intListValue: Array<number> };

type DefaultParametrizationValue_IntValue_Fragment = { __typename: "IntValue"; intValue: number };

type DefaultParametrizationValue_PlantSpacingPatternValue_Fragment = {
  __typename: "PlantSpacingPatternValue";
  patternValue: PlantSpacingPattern;
};

type DefaultParametrizationValue_StringValue_Fragment = { __typename: "StringValue"; stringValue: string };

export type DefaultParametrizationValueFragment =
  | DefaultParametrizationValue_BoolValue_Fragment
  | DefaultParametrizationValue_FloatValue_Fragment
  | DefaultParametrizationValue_IntListValue_Fragment
  | DefaultParametrizationValue_IntValue_Fragment
  | DefaultParametrizationValue_PlantSpacingPatternValue_Fragment
  | DefaultParametrizationValue_StringValue_Fragment;

export type ErrorFieldTaskFragment = { __typename: "ErrorFieldTaskInvalid"; message: string };

type FloatValueStringValue_FloatValue_Fragment = { __typename: "FloatValue"; floatValue: number };

type FloatValueStringValue_StringValue_Fragment = { __typename: "StringValue"; stringValue: string };

export type FloatValueStringValueFragment =
  | FloatValueStringValue_FloatValue_Fragment
  | FloatValueStringValue_StringValue_Fragment;

export type ImageFragment = { __typename?: "ImageType"; id: string; alt: string; url: string };

export type PageInfoFragment = { __typename?: "PageInfo"; perPage: number; page: number; lastPage: number };

export type GrowingPlanInfoRowFragment = {
  __typename?: "GrowingPlanType";
  id: string;
  name: string;
  state: GrowingPlanState;
  planScheduleInDays: number | null;
};

export type AccessPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type AccessPermissionsQuery = {
  __typename?: "Query";
  myProfile: {
    __typename?: "UserType";
    id: string;
    activeClientAccount: { __typename?: "ClientAccountType"; state: ClientAccountState } | null;
  };
};

export type AuthorizationCheckQueryVariables = Exact<{ [key: string]: never }>;

export type AuthorizationCheckQuery = { __typename?: "Query"; myProfile: { __typename?: "UserType"; id: string } };

export type UserInfoForAuthPageQueryVariables = Exact<{ [key: string]: never }>;

export type UserInfoForAuthPageQuery = {
  __typename?: "Query";
  myProfile: {
    __typename?: "UserType";
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    clientAccounts: Array<{ __typename?: "ClientAccountType"; id: string }> | null;
  };
};

export type ClientAccountCardFragment = {
  __typename?: "ClientAccountType";
  businessName: string;
  createdAt: string;
  approvedAt: string | null;
  city: string;
  country: string;
  address: string;
  postalCode: string;
};

export type ClientAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type ClientAccountsQuery = {
  __typename?: "Query";
  myProfile: {
    __typename?: "UserType";
    activeClientAccount: { __typename?: "ClientAccountType"; id: string } | null;
    clientAccounts: Array<{
      __typename?: "ClientAccountType";
      id: string;
      state: ClientAccountState;
      businessName: string;
      createdAt: string;
      approvedAt: string | null;
      city: string;
      country: string;
      address: string;
      postalCode: string;
    }> | null;
  };
};

export type CreateClientAccountMutationVariables = Exact<{
  input: ClientAccountInput;
}>;

export type CreateClientAccountMutation = {
  __typename?: "CoreMutation";
  createClientAccount: { __typename?: "ClientAccountMutationPayload"; success: boolean };
};

export type UpdateClientAccountMutationVariables = Exact<{
  clientAccountId: Scalars["UUID"]["input"];
  clientAccountInput: ClientAccountInput;
}>;

export type UpdateClientAccountMutation = {
  __typename?: "CoreMutation";
  updateClientAccount: { __typename?: "ClientAccountMutationPayload"; success: boolean };
};

export type SwitchClientAccountMutationVariables = Exact<{
  clientAccountId: Scalars["UUID"]["input"];
}>;

export type SwitchClientAccountMutation = {
  __typename?: "CoreMutation";
  switchClientAccount: { __typename?: "SwitchClientAccountMutationPayload"; success: boolean };
};

export type ClientAccountForEditPageQueryVariables = Exact<{
  clientAccountId: Scalars["UUID"]["input"];
}>;

export type ClientAccountForEditPageQuery = {
  __typename?: "Query";
  clientAccount: {
    __typename?: "ClientAccountType";
    id: string;
    address: string;
    businessName: string;
    city: string;
    state: ClientAccountState;
    country: string;
    postalCode: string;
  };
};

export type CropSheetListQueryVariables = Exact<{ [key: string]: never }>;

export type CropSheetListQuery = {
  __typename?: "Query";
  cropSheetList: {
    __typename?: "CropSheetListType";
    crops: Array<{
      __typename?: "CropSheetBaseType";
      id: string;
      commonName: string;
      alternativeNames: Array<string> | null;
      variant: string | null;
      mainImage: { __typename?: "ImageType"; id: string; alt: string; url: string } | null;
    }>;
  };
};

export type ThreatsQueryVariables = Exact<{
  type?: InputMaybe<DiseaseCategory>;
}>;

export type ThreatsQuery = {
  __typename?: "Query";
  threats: Array<{
    __typename?: "DiseaseType";
    id: string;
    commonName: string;
    alternativeNames: Array<string> | null;
    mainImage: { __typename?: "ImageType"; id: string; alt: string; url: string } | null;
  }>;
};

export type CropSheetQueryVariables = Exact<{
  cropSheetId: Scalars["UUID"]["input"];
}>;

export type CropSheetQuery = {
  __typename?: "Query";
  cropSheet: {
    __typename?: "CropSheetType";
    id: string;
    commonName: string;
    alternativeNames: Array<string> | null;
    scientificName: string;
    variant: string | null;
    description: string;
    cultivation: string;
    mainImage: { __typename?: "ImageType"; id: string; alt: string; url: string } | null;
    images: Array<{ __typename?: "ImageType"; id: string; alt: string; url: string }>;
    conditions: Array<{
      __typename?: "ConditionType";
      id: string;
      name: string;
      unit: string | null;
      nominalOptimal: Array<string> | null;
      nominalLimit: Array<string> | null;
      nominalCritical: Array<string> | null;
      scaleExtremeLow: number | null;
      scaleExtremeHigh: number | null;
      scaleStep: number | null;
      healthThresholdLow:
        | { __typename: "FloatValue"; floatValue: number }
        | { __typename: "StringValue"; stringValue: string }
        | null;
      healthThresholdHigh:
        | { __typename: "FloatValue"; floatValue: number }
        | { __typename: "StringValue"; stringValue: string }
        | null;
      thresholdLow:
        | { __typename: "FloatValue"; floatValue: number }
        | { __typename: "StringValue"; stringValue: string }
        | null;
      thresholdHigh:
        | { __typename: "FloatValue"; floatValue: number }
        | { __typename: "StringValue"; stringValue: string }
        | null;
    }>;
  };
  cropThreats: Array<{
    __typename?: "CropDiseaseType";
    mainImage: { __typename?: "ImageType"; id: string; alt: string; url: string } | null;
    threat: { __typename?: "DiseaseType"; id: string; commonName: string; type: DiseaseCategory };
  }>;
  cropStages: {
    __typename?: "CropStagesType";
    id: string;
    stages: Array<{
      __typename?: "CropStageType";
      id: string;
      name: string;
      order: number;
      description: string;
      durationDays: number | null;
      continuous: boolean;
      startAtDay: number | null;
      conditions: Array<{ __typename?: "ConditionType"; id: string }>;
      images: Array<{ __typename?: "ImageType"; id: string; alt: string; url: string }>;
    }>;
  };
};

export type CropThreatsQueryVariables = Exact<{
  cropSheetId: Scalars["UUID"]["input"];
  threatType: DiseaseCategory;
}>;

export type CropThreatsQuery = {
  __typename?: "Query";
  cropSheet: { __typename?: "CropSheetType"; id: string; commonName: string };
  cropThreats: Array<{
    __typename?: "CropDiseaseType";
    treatment: string;
    mainImage: { __typename?: "ImageType"; id: string; alt: string; url: string } | null;
    images: Array<{ __typename?: "ImageType"; id: string; alt: string; url: string }>;
    threat: {
      __typename?: "DiseaseType";
      id: string;
      commonName: string;
      scientificName: string;
      alternativeNames: Array<string> | null;
      description: string;
    };
  }>;
};

export type ThreatQueryVariables = Exact<{
  threatId: Scalars["UUID"]["input"];
}>;

export type ThreatQuery = {
  __typename?: "Query";
  threat: {
    __typename?: "DiseaseType";
    id: string;
    commonName: string;
    alternativeNames: Array<string> | null;
    scientificName: string;
    description: string;
    mainImage: { __typename?: "ImageType"; id: string; alt: string; url: string } | null;
    images: Array<{ __typename?: "ImageType"; id: string; alt: string; url: string }>;
    threatenedCrops: Array<{
      __typename?: "CropSheetBaseType";
      id: string;
      commonName: string;
      alternativeNames: Array<string> | null;
      mainImage: { __typename?: "ImageType"; id: string; alt: string; url: string } | null;
    }>;
  };
};

export type FieldTaskWorkReportBaseFragment = {
  __typename?: "FieldTaskWorkReportBase";
  id: string;
  state: WorkReportState;
};

export type FieldTaskWorkReportPointFragment = {
  __typename?: "FieldTaskWorkReportPoint";
  id: string;
  state: WorkReportState;
  point: { __typename?: "CoordinatesType"; lat: number; lon: number };
};

export type FieldTaskQueryVariables = Exact<{
  fieldTaskId: Scalars["UUID"]["input"];
}>;

export type FieldTaskQuery = {
  __typename?: "Query";
  task: {
    __typename?: "FieldTask";
    id: string;
    jobId: string | null;
    createdAt: string;
    startAt: string;
    startedAt: string | null;
    completedAt: string | null;
    state: FieldTaskState;
    completionAt: string;
    delay: number;
    estimatedCompletionAt: string;
    estimatedPercentagePowerConsumption: number;
    estimatedStartAt: string;
    estimatedTimeConsumption: number;
    estimatedWaterConsumption: number;
    numberOfErrorWorkTasks: number | null;
    numberOfCompletedWorkTasks: number | null;
    numberOfWorkTasks: number | null;
    procedures: Array<{
      __typename?: "FieldTaskProcedure";
      type: string;
      growingPlan: {
        __typename?: "GrowingPlanType";
        field: {
          __typename?: "FieldType";
          id: string;
          name: string;
          area: number | null;
          irrigation: FieldIrrigation;
          lighting: FieldLighting;
          nitrogen: number | null;
          ph: number;
          phosphorus: number | null;
          potassium: number | null;
          soilStatus: SoilStatus;
          soilType: string;
          state: FieldState;
          cultivation: CultivationMethod;
          swathAngle: number | null;
          swathPathSegment: {
            __typename?: "OptimalPathSegmentType";
            id: number;
            startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
            endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
          } | null;
          geofencing: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
          boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
          importedBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
          homeStation: {
            __typename?: "HomeStationType";
            id: string;
            gpsTrace: { __typename?: "TraceType"; name: string };
            coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
            homeStationPath: {
              __typename?: "PathType";
              id: string;
              pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
            };
          } | null;
        };
      } | null;
      block: {
        __typename?: "BlockType";
        field: {
          __typename?: "FieldType";
          id: string;
          name: string;
          area: number | null;
          irrigation: FieldIrrigation;
          lighting: FieldLighting;
          nitrogen: number | null;
          ph: number;
          phosphorus: number | null;
          potassium: number | null;
          soilStatus: SoilStatus;
          soilType: string;
          state: FieldState;
          cultivation: CultivationMethod;
          swathAngle: number | null;
          swathPathSegment: {
            __typename?: "OptimalPathSegmentType";
            id: number;
            startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
            endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
          } | null;
          geofencing: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
          boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
          importedBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
          homeStation: {
            __typename?: "HomeStationType";
            id: string;
            gpsTrace: { __typename?: "TraceType"; name: string };
            coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
            homeStationPath: {
              __typename?: "PathType";
              id: string;
              pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
            };
          } | null;
        };
      } | null;
    }>;
    robotAssignment: { __typename?: "RobotAssignmentType"; id: string; name: string };
    workTaskReports: Array<
      | { __typename: "FieldTaskWorkReportBase"; id: string; state: WorkReportState }
      | {
          __typename: "FieldTaskWorkReportPoint";
          id: string;
          state: WorkReportState;
          point: { __typename?: "CoordinatesType"; lat: number; lon: number };
        }
    > | null;
  };
};

export type ParametrizationFragment = {
  __typename?: "FieldTaskParametrization";
  code: string;
  max: number | null;
  min: number | null;
  name: string;
  step: number | null;
  type: FieldTaskParametrizationType;
  unit: string | null;
  default:
    | { __typename: "BoolValue"; boolValue: boolean }
    | { __typename: "FloatValue"; floatValue: number }
    | { __typename: "IntListValue"; intListValue: Array<number> }
    | { __typename: "IntValue"; intValue: number }
    | { __typename: "PlantSpacingPatternValue"; patternValue: PlantSpacingPattern }
    | { __typename: "StringValue"; stringValue: string }
    | null;
};

export type FieldTasksWarningsQueryVariables = Exact<{
  date: Scalars["Date"]["input"];
  robotAssignmentId: Scalars["UUID"]["input"];
}>;

export type FieldTasksWarningsQuery = {
  __typename?: "Query";
  fieldTasksWarnings: { __typename?: "FieldTaskWarningList"; warnings: Array<FieldTaskJobWarningMessage> };
};

export type FieldTaskTypesQueryVariables = Exact<{ [key: string]: never }>;

export type FieldTaskTypesQuery = {
  __typename?: "Query";
  fieldTaskTypes: Array<{
    __typename?: "FieldTaskType";
    commonName: string;
    type: string;
    availableForBlock: boolean;
    group: { __typename?: "FieldTaskTypeGroup"; code: string; commonName: string };
    parametrization: Array<{
      __typename?: "FieldTaskParametrization";
      code: string;
      max: number | null;
      min: number | null;
      name: string;
      step: number | null;
      type: FieldTaskParametrizationType;
      unit: string | null;
      default:
        | { __typename: "BoolValue"; boolValue: boolean }
        | { __typename: "FloatValue"; floatValue: number }
        | { __typename: "IntListValue"; intListValue: Array<number> }
        | { __typename: "IntValue"; intValue: number }
        | { __typename: "PlantSpacingPatternValue"; patternValue: PlantSpacingPattern }
        | { __typename: "StringValue"; stringValue: string }
        | null;
    }>;
  }>;
};

export type FieldTaskTypesWithDefaultValuesQueryVariables = Exact<{
  growingPlanId?: InputMaybe<Scalars["UUID"]["input"]>;
}>;

export type FieldTaskTypesWithDefaultValuesQuery = {
  __typename?: "Query";
  fieldTaskTypes: Array<{
    __typename?: "FieldTaskType";
    commonName: string;
    type: string;
    availableForBlock: boolean;
    group: { __typename?: "FieldTaskTypeGroup"; code: string; commonName: string };
    parametrization: Array<{
      __typename?: "FieldTaskParametrization";
      code: string;
      max: number | null;
      min: number | null;
      name: string;
      step: number | null;
      type: FieldTaskParametrizationType;
      unit: string | null;
      default:
        | { __typename: "BoolValue"; boolValue: boolean }
        | { __typename: "FloatValue"; floatValue: number }
        | { __typename: "IntListValue"; intListValue: Array<number> }
        | { __typename: "IntValue"; intValue: number }
        | { __typename: "PlantSpacingPatternValue"; patternValue: PlantSpacingPattern }
        | { __typename: "StringValue"; stringValue: string }
        | null;
    }>;
  }>;
};

export type IsFieldTaskPossibleQueryVariables = Exact<{
  blockIds: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type IsFieldTaskPossibleQuery = {
  __typename?: "Query";
  isFieldTaskPossible: { __typename?: "FieldTaskPossibility"; possible: boolean };
};

export type CreateFieldTasksMutationVariables = Exact<{
  fieldTaskInput: FieldTaskBatchInput;
}>;

export type CreateFieldTasksMutation = {
  __typename?: "CoreMutation";
  createFieldTaskBatch: {
    __typename?: "MultipleFieldTaskMutationPayload";
    success: boolean;
    errors: Array<{ __typename: "ErrorFieldTaskInvalid"; message: string }> | null;
  };
};

export type CreateFieldTaskMutationVariables = Exact<{
  fieldTaskInput: FieldTaskInput;
}>;

export type CreateFieldTaskMutation = {
  __typename?: "CoreMutation";
  createFieldTask: {
    __typename?: "FieldTaskMutationPayload";
    success: boolean;
    error: { __typename: "ErrorFieldTaskInvalid"; message: string } | null;
  };
};

export type DeleteFieldTaskMutationVariables = Exact<{
  fieldTaskId: Scalars["UUID"]["input"];
}>;

export type DeleteFieldTaskMutation = {
  __typename?: "CoreMutation";
  result: { __typename?: "FieldTaskMutationPayload"; success: boolean };
};

export type MoveUpFieldTaskMutationVariables = Exact<{
  fieldTaskId: Scalars["UUID"]["input"];
}>;

export type MoveUpFieldTaskMutation = {
  __typename?: "CoreMutation";
  result: { __typename?: "FieldTaskMutationPayload"; success: boolean };
};

export type MoveDownFieldTaskMutationVariables = Exact<{
  fieldTaskId: Scalars["UUID"]["input"];
}>;

export type MoveDownFieldTaskMutation = {
  __typename?: "CoreMutation";
  result: { __typename?: "FieldTaskMutationPayload"; success: boolean };
};

export type StartNowTaskMutationVariables = Exact<{
  fieldTaskId: Scalars["UUID"]["input"];
}>;

export type StartNowTaskMutation = {
  __typename?: "CoreMutation";
  result: { __typename?: "FieldTaskMutationPayload"; success: boolean };
};

export type FieldTaskCardFragment = {
  __typename?: "FieldTask";
  id: string;
  jobId: string | null;
  createdAt: string;
  startAt: string;
  startedAt: string | null;
  completedAt: string | null;
  state: FieldTaskState;
  completionAt: string;
  delay: number;
  estimatedCompletionAt: string;
  estimatedPercentagePowerConsumption: number;
  estimatedStartAt: string;
  estimatedTimeConsumption: number;
  estimatedWaterConsumption: number;
  numberOfErrorWorkTasks: number | null;
  robotAssignment: { __typename?: "RobotAssignmentType"; id: string; name: string };
  procedures: Array<{
    __typename?: "FieldTaskProcedure";
    type: string;
    growingPlan: { __typename?: "GrowingPlanType"; id: string; name: string } | null;
    block: {
      __typename?: "BlockType";
      id: string;
      name: string;
      field: { __typename?: "FieldType"; id: string };
    } | null;
  }>;
  actionsPermissions: {
    __typename?: "FieldTaskActionsPermissions";
    delete: boolean;
    moveDown: boolean;
    moveUp: boolean;
    startNow: boolean;
  };
};

export type FieldTasksListFragment = {
  __typename?: "FieldTaskListType";
  nodes: Array<{
    __typename?: "FieldTask";
    id: string;
    jobId: string | null;
    createdAt: string;
    startAt: string;
    startedAt: string | null;
    completedAt: string | null;
    state: FieldTaskState;
    completionAt: string;
    delay: number;
    estimatedCompletionAt: string;
    estimatedPercentagePowerConsumption: number;
    estimatedStartAt: string;
    estimatedTimeConsumption: number;
    estimatedWaterConsumption: number;
    numberOfErrorWorkTasks: number | null;
    robotAssignment: { __typename?: "RobotAssignmentType"; id: string; name: string };
    procedures: Array<{
      __typename?: "FieldTaskProcedure";
      type: string;
      growingPlan: { __typename?: "GrowingPlanType"; id: string; name: string } | null;
      block: {
        __typename?: "BlockType";
        id: string;
        name: string;
        field: { __typename?: "FieldType"; id: string };
      } | null;
    }>;
    actionsPermissions: {
      __typename?: "FieldTaskActionsPermissions";
      delete: boolean;
      moveDown: boolean;
      moveUp: boolean;
      startNow: boolean;
    };
  }>;
  pageInfo: { __typename?: "PageInfo"; perPage: number; page: number; lastPage: number };
};

export type FieldBasicQueryVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
}>;

export type FieldBasicQuery = {
  __typename?: "Query";
  field: {
    __typename?: "FieldType";
    id: string;
    name: string;
    area: number | null;
    irrigation: FieldIrrigation;
    lighting: FieldLighting;
    nitrogen: number | null;
    ph: number;
    phosphorus: number | null;
    potassium: number | null;
    soilStatus: SoilStatus;
    soilType: string;
    state: FieldState;
    cultivation: CultivationMethod;
    swathAngle: number | null;
    swathPathSegment: {
      __typename?: "OptimalPathSegmentType";
      id: number;
      startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
    } | null;
    geofencing: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
    boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
    importedBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
    homeStation: {
      __typename?: "HomeStationType";
      id: string;
      gpsTrace: { __typename?: "TraceType"; name: string };
      coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
      homeStationPath: {
        __typename?: "PathType";
        id: string;
        pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      };
    } | null;
  };
};

export type FieldQueryVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
}>;

export type FieldQuery = {
  __typename?: "Query";
  field: {
    __typename?: "FieldType";
    id: string;
    name: string;
    area: number | null;
    irrigation: FieldIrrigation;
    lighting: FieldLighting;
    nitrogen: number | null;
    ph: number;
    phosphorus: number | null;
    potassium: number | null;
    soilStatus: SoilStatus;
    soilType: string;
    state: FieldState;
    cultivation: CultivationMethod;
    swathAngle: number | null;
    blocks: Array<{
      __typename?: "BlockType";
      id: string;
      name: string;
      area: number;
      createdAt: string;
      color: string;
      growingPlans: Array<{
        __typename?: "GrowingPlanType";
        id: string;
        name: string;
        state: GrowingPlanState;
        planScheduleInDays: number | null;
      }> | null;
      segments: Array<{
        __typename?: "SegmentType";
        id: string;
        name: string;
        area: number;
        order: number | null;
        boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
        swath: { __typename?: "SwathType"; id: string } | null;
        startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
        endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
      }>;
      path: {
        __typename?: "PathType";
        id: string;
        pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      } | null;
    }> | null;
    robotAssignment: { __typename?: "RobotAssignmentType"; id: string; name: string } | null;
    unassignedSegments: Array<{
      __typename?: "SegmentType";
      id: string;
      name: string;
      area: number;
      order: number | null;
      boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      swath: { __typename?: "SwathType"; id: string } | null;
      startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
      endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
    }>;
    swathPathSegment: {
      __typename?: "OptimalPathSegmentType";
      id: number;
      startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
    } | null;
    geofencing: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
    boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
    importedBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
    homeStation: {
      __typename?: "HomeStationType";
      id: string;
      gpsTrace: { __typename?: "TraceType"; name: string };
      coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
      homeStationPath: {
        __typename?: "PathType";
        id: string;
        pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      };
    } | null;
  };
};

export type BlockForDetailPageQueryVariables = Exact<{
  blockId: Scalars["UUID"]["input"];
}>;

export type BlockForDetailPageQuery = {
  __typename?: "Query";
  block: {
    __typename?: "BlockType";
    id: string;
    name: string;
    area: number;
    createdAt: string;
    color: string;
    field: {
      __typename?: "FieldType";
      id: string;
      name: string;
      area: number | null;
      irrigation: FieldIrrigation;
      lighting: FieldLighting;
      nitrogen: number | null;
      ph: number;
      phosphorus: number | null;
      potassium: number | null;
      soilStatus: SoilStatus;
      soilType: string;
      state: FieldState;
      cultivation: CultivationMethod;
      swathAngle: number | null;
      swathPathSegment: {
        __typename?: "OptimalPathSegmentType";
        id: number;
        startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
        endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      } | null;
      geofencing: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
      boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
      importedBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
      homeStation: {
        __typename?: "HomeStationType";
        id: string;
        gpsTrace: { __typename?: "TraceType"; name: string };
        coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
        homeStationPath: {
          __typename?: "PathType";
          id: string;
          pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
        };
      } | null;
    };
    growingPlans: Array<{
      __typename?: "GrowingPlanType";
      id: string;
      name: string;
      state: GrowingPlanState;
      planScheduleInDays: number | null;
    }> | null;
    segments: Array<{
      __typename?: "SegmentType";
      id: string;
      name: string;
      area: number;
      order: number | null;
      boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      swath: { __typename?: "SwathType"; id: string } | null;
      startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
      endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
    }>;
    path: {
      __typename?: "PathType";
      id: string;
      pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    } | null;
  };
  blockTasks: {
    __typename?: "FieldTaskListType";
    totalCount: number;
    nodes: Array<{
      __typename?: "FieldTask";
      id: string;
      jobId: string | null;
      createdAt: string;
      startAt: string;
      startedAt: string | null;
      completedAt: string | null;
      state: FieldTaskState;
      completionAt: string;
      delay: number;
      estimatedCompletionAt: string;
      estimatedPercentagePowerConsumption: number;
      estimatedStartAt: string;
      estimatedTimeConsumption: number;
      estimatedWaterConsumption: number;
      numberOfErrorWorkTasks: number | null;
      robotAssignment: { __typename?: "RobotAssignmentType"; id: string; name: string };
      procedures: Array<{
        __typename?: "FieldTaskProcedure";
        type: string;
        growingPlan: { __typename?: "GrowingPlanType"; id: string; name: string } | null;
        block: {
          __typename?: "BlockType";
          id: string;
          name: string;
          field: { __typename?: "FieldType"; id: string };
        } | null;
      }>;
      actionsPermissions: {
        __typename?: "FieldTaskActionsPermissions";
        delete: boolean;
        moveDown: boolean;
        moveUp: boolean;
        startNow: boolean;
      };
    }>;
    pageInfo: { __typename?: "PageInfo"; perPage: number; page: number; lastPage: number };
  };
};

export type UpdateFieldManualBoundariesMutationVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
  input: ManualFieldBoundariesInput;
}>;

export type UpdateFieldManualBoundariesMutation = {
  __typename?: "CoreMutation";
  updateFieldManualBoundaries: { __typename?: "FieldMutationPayload"; success: boolean };
};

export type UpdateManualBlocksMutationVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
  blocksInput: ManualBlocksInput;
}>;

export type UpdateManualBlocksMutation = {
  __typename?: "CoreMutation";
  updateManualBlocks: { __typename?: "BlockMutationPayload"; success: boolean };
};

export type UpdateSegmentBlocksMutationVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
  input: SegmentBlocksInput;
}>;

export type UpdateSegmentBlocksMutation = {
  __typename?: "CoreMutation";
  updateSegmentBlocks: { __typename?: "BlockMutationPayload"; success: boolean };
};

export type UpdateFieldEntryPointMutationVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
  entryPoint: CoordinatesInput;
  reverseDirection?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateFieldEntryPointMutation = {
  __typename?: "CoreMutation";
  updateFieldEntryPoint: { __typename?: "FieldMutationPayload"; success: boolean };
};

export type BlocksSegmentFragment = {
  __typename?: "SegmentType";
  id: string;
  name: string;
  area: number;
  order: number | null;
  boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
  swath: { __typename?: "SwathType"; id: string } | null;
  startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
  endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
};

export type BlockModelFragment = {
  __typename?: "BlockType";
  id: string;
  name: string;
  area: number;
  createdAt: string;
  color: string;
  growingPlans: Array<{
    __typename?: "GrowingPlanType";
    id: string;
    name: string;
    state: GrowingPlanState;
    planScheduleInDays: number | null;
  }> | null;
  segments: Array<{
    __typename?: "SegmentType";
    id: string;
    name: string;
    area: number;
    order: number | null;
    boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    swath: { __typename?: "SwathType"; id: string } | null;
    startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
    endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
  }>;
  path: {
    __typename?: "PathType";
    id: string;
    pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
  } | null;
};

export type UpdateFieldImportedBoundariesMutationVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
  gpsTraceId: Scalars["UUID"]["input"];
}>;

export type UpdateFieldImportedBoundariesMutation = {
  __typename?: "CoreMutation";
  updateFieldImportedBoundaries: { __typename?: "FieldMutationPayload"; success: boolean };
};

export type TraceFragmentFragment = {
  __typename?: "TraceType";
  id: string;
  name: string;
  state: GpsTraceState;
  type: GpsTraceType;
  createdAt: string;
  completedAt: string;
  startedAt: string;
  coordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
};

export type BoundariesGpsTracesQueryVariables = Exact<{ [key: string]: never }>;

export type BoundariesGpsTracesQuery = {
  __typename?: "Query";
  boundariesGpsTraces: {
    __typename?: "TraceTypeListType";
    nodes: Array<{
      __typename?: "TraceType";
      id: string;
      name: string;
      state: GpsTraceState;
      type: GpsTraceType;
      createdAt: string;
      completedAt: string;
      startedAt: string;
      coordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
    }>;
  };
};

export type FieldBasicModelFragment = {
  __typename?: "FieldType";
  id: string;
  name: string;
  area: number | null;
  irrigation: FieldIrrigation;
  lighting: FieldLighting;
  nitrogen: number | null;
  ph: number;
  phosphorus: number | null;
  potassium: number | null;
  soilStatus: SoilStatus;
  soilType: string;
  state: FieldState;
  cultivation: CultivationMethod;
  swathAngle: number | null;
  swathPathSegment: {
    __typename?: "OptimalPathSegmentType";
    id: number;
    startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
    endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
  } | null;
  geofencing: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
  boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
  importedBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
  homeStation: {
    __typename?: "HomeStationType";
    id: string;
    gpsTrace: { __typename?: "TraceType"; name: string };
    coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
    homeStationPath: {
      __typename?: "PathType";
      id: string;
      pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    };
  } | null;
};

export type FieldModelFragment = {
  __typename?: "FieldType";
  id: string;
  name: string;
  area: number | null;
  irrigation: FieldIrrigation;
  lighting: FieldLighting;
  nitrogen: number | null;
  ph: number;
  phosphorus: number | null;
  potassium: number | null;
  soilStatus: SoilStatus;
  soilType: string;
  state: FieldState;
  cultivation: CultivationMethod;
  swathAngle: number | null;
  blocks: Array<{
    __typename?: "BlockType";
    id: string;
    name: string;
    area: number;
    createdAt: string;
    color: string;
    growingPlans: Array<{
      __typename?: "GrowingPlanType";
      id: string;
      name: string;
      state: GrowingPlanState;
      planScheduleInDays: number | null;
    }> | null;
    segments: Array<{
      __typename?: "SegmentType";
      id: string;
      name: string;
      area: number;
      order: number | null;
      boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      swath: { __typename?: "SwathType"; id: string } | null;
      startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
      endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
    }>;
    path: {
      __typename?: "PathType";
      id: string;
      pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    } | null;
  }> | null;
  robotAssignment: { __typename?: "RobotAssignmentType"; id: string; name: string } | null;
  unassignedSegments: Array<{
    __typename?: "SegmentType";
    id: string;
    name: string;
    area: number;
    order: number | null;
    boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    swath: { __typename?: "SwathType"; id: string } | null;
    startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
    endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number } | null;
  }>;
  swathPathSegment: {
    __typename?: "OptimalPathSegmentType";
    id: number;
    startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
    endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
  } | null;
  geofencing: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
  boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
  importedBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
  homeStation: {
    __typename?: "HomeStationType";
    id: string;
    gpsTrace: { __typename?: "TraceType"; name: string };
    coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
    homeStationPath: {
      __typename?: "PathType";
      id: string;
      pathCoordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    };
  } | null;
};

export type FieldsQueryVariables = Exact<{
  paginationInput?: InputMaybe<PaginationInput>;
}>;

export type FieldsQuery = {
  __typename?: "Query";
  fields: {
    __typename?: "FieldTypeListType";
    nodes: Array<{
      __typename?: "FieldType";
      id: string;
      name: string;
      state: FieldState;
      cultivation: CultivationMethod;
      blocks: Array<{
        __typename?: "BlockType";
        id: string;
        area: number;
        name: string;
        growingPlans: Array<{
          __typename?: "GrowingPlanType";
          id: string;
          name: string;
          state: GrowingPlanState;
          planScheduleInDays: number | null;
        }> | null;
      }> | null;
    }>;
    pageInfo: { __typename?: "PageInfo"; perPage: number; page: number; lastPage: number };
  };
};

export type DeleteFieldMutationVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
}>;

export type DeleteFieldMutation = {
  __typename?: "CoreMutation";
  deleteField: { __typename?: "GenericPayload"; success: boolean };
};

export type FieldCardFragment = {
  __typename?: "FieldType";
  id: string;
  name: string;
  state: FieldState;
  cultivation: CultivationMethod;
  blocks: Array<{
    __typename?: "BlockType";
    id: string;
    area: number;
    name: string;
    growingPlans: Array<{
      __typename?: "GrowingPlanType";
      id: string;
      name: string;
      state: GrowingPlanState;
      planScheduleInDays: number | null;
    }> | null;
  }> | null;
};

export type HomeStationsQueryVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
}>;

export type HomeStationsQuery = {
  __typename?: "Query";
  homeStationGpsTraces: {
    __typename?: "TraceTypeListType";
    nodes: Array<{
      __typename?: "TraceType";
      id: string;
      createdAt: string;
      name: string;
      type: GpsTraceType;
      state: GpsTraceState;
      coordinates: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }> | null;
    }>;
  };
};

export type UpdateFieldHomeStationPathMutationVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
  gpsTraceId?: InputMaybe<Scalars["UUID"]["input"]>;
}>;

export type UpdateFieldHomeStationPathMutation = {
  __typename?: "CoreMutation";
  updateFieldHomeStation: { __typename?: "FieldMutationPayload"; success: boolean };
};

export type SoilTypesQueryVariables = Exact<{ [key: string]: never }>;

export type SoilTypesQuery = {
  __typename?: "Query";
  soilTypes: Array<{ __typename?: "SoilTypeType"; name: string; code: string }>;
};

export type RobotAssignmentsQueryVariables = Exact<{ [key: string]: never }>;

export type RobotAssignmentsQuery = {
  __typename?: "Query";
  robotAssignments: Array<{ __typename?: "RobotAssignmentType"; id: string; name: string }>;
};

export type FieldForEditPageQueryVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
}>;

export type FieldForEditPageQuery = {
  __typename?: "Query";
  field: {
    __typename?: "FieldType";
    irrigation: FieldIrrigation;
    lighting: FieldLighting;
    name: string;
    nitrogen: number | null;
    ph: number;
    phosphorus: number | null;
    potassium: number | null;
    soilStatus: SoilStatus;
    soilType: string;
    robotAssignment: { __typename?: "RobotAssignmentType"; id: string; name: string } | null;
  };
};

export type CreateFieldMutationVariables = Exact<{
  fieldInput: FieldCreateInput;
}>;

export type CreateFieldMutation = {
  __typename?: "CoreMutation";
  createField: {
    __typename?: "FieldMutationPayload";
    success: boolean;
    field: { __typename?: "FieldType"; id: string } | null;
  };
};

export type UpdateFieldMutationVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
  fieldInput: FieldInput;
}>;

export type UpdateFieldMutation = {
  __typename?: "CoreMutation";
  updateField: { __typename?: "FieldMutationPayload"; success: boolean };
};

export type PreviewSegmentsQueryVariables = Exact<{
  swathsIds: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
  gap?: InputMaybe<Scalars["Float"]["input"]>;
  length?: InputMaybe<Scalars["Float"]["input"]>;
}>;

export type PreviewSegmentsQuery = {
  __typename?: "Query";
  previewSegments: Array<{
    __typename?: "SwathSegmentPreviewType";
    swathId: string;
    segments: Array<{
      __typename?: "SegmentPreviewType";
      name: string;
      boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
    }>;
  }>;
};

export type CreateSegmentsMutationVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
  segments: Array<SwathSegmentInput> | SwathSegmentInput;
}>;

export type CreateSegmentsMutation = {
  __typename?: "CoreMutation";
  createSegments: { __typename?: "FieldMutationPayload"; success: boolean };
};

export type PreviewSwathsQueryVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
  angle?: InputMaybe<Scalars["Float"]["input"]>;
  refPathSegmentId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type PreviewSwathsQuery = {
  __typename?: "Query";
  previewSwaths: {
    __typename?: "GeneratedSwathsType";
    swaths: Array<{
      __typename?: "PreviewSwathType";
      area: number | null;
      order: number | null;
      startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
    }>;
    headland: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    autoOptimalPaths: Array<{
      __typename?: "OptimalPathSegmentType";
      id: number;
      length: number | null;
      startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
    }>;
  };
};

export type CreateSwathsMutationVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
  refPathSegmentId?: InputMaybe<Scalars["Int"]["input"]>;
  angle?: InputMaybe<Scalars["Float"]["input"]>;
}>;

export type CreateSwathsMutation = {
  __typename?: "CoreMutation";
  createSwaths: { __typename?: "FieldMutationPayload"; success: boolean };
};

export type DataForCreateGrowingPlanBaseInfoContentQueryVariables = Exact<{ [key: string]: never }>;

export type DataForCreateGrowingPlanBaseInfoContentQuery = {
  __typename?: "Query";
  cropSheetList: {
    __typename?: "CropSheetListType";
    crops: Array<{ __typename?: "CropSheetBaseType"; id: string; commonName: string; variant: string | null }>;
  };
  fields: {
    __typename?: "FieldTypeListType";
    nodes: Array<{ __typename?: "FieldType"; id: string; name: string; growingPlannerState: GrowingPlannerFieldState }>;
  };
};

export type FieldBlocksForCreateGrowingPlanBaseInfoContentQueryVariables = Exact<{
  fieldId: Scalars["UUID"]["input"];
}>;

export type FieldBlocksForCreateGrowingPlanBaseInfoContentQuery = {
  __typename?: "Query";
  field: {
    __typename?: "FieldType";
    id: string;
    blocks: Array<{
      __typename?: "BlockType";
      id: string;
      name: string;
      routePlannerState: RoutePlannerBlockState;
    }> | null;
  };
};

export type GrowingPlanPreviewQueryVariables = Exact<{
  cropSheetId: Scalars["UUID"]["input"];
  blockId: Scalars["UUID"]["input"];
  startAt: Scalars["Date"]["input"];
}>;

export type GrowingPlanPreviewQuery = {
  __typename?: "Query";
  growingPlanPreview: {
    __typename?: "GrowingPlanPreviewType";
    endAt: string;
    planDuration: number;
    potentialYield: number;
    expectedYield: number;
    expectedYieldPercentage: number;
  };
};

export type CreateGrowingPlanMutationVariables = Exact<{
  cropSheetId: Scalars["UUID"]["input"];
  blockId: Scalars["UUID"]["input"];
  startAt: Scalars["Date"]["input"];
}>;

export type CreateGrowingPlanMutation = {
  __typename?: "CoreMutation";
  createGrowingPlan: { __typename?: "GrowingPlanMutationPayload"; success: boolean };
};

export type GrowingPlanYearPreviewQueryVariables = Exact<{
  cropSheetId: Scalars["UUID"]["input"];
  blockId: Scalars["UUID"]["input"];
}>;

export type GrowingPlanYearPreviewQuery = {
  __typename?: "Query";
  growingPlanYearPreview: Array<{ __typename?: "GrowingPlanLitePreviewType"; date: string; yieldPercentage: number }>;
};

export type GrowingPlanConditionFragment = {
  __typename?: "GrowingPlanConditionType";
  id: string;
  name: string;
  type: string;
  unit: string | null;
  status: ConditionStatus;
  metric: number | null;
  metricLow: number | null;
  metricHigh: number | null;
  nominalValue: string | null;
  nominalOptimal: Array<string> | null;
  thresholdLow:
    | { __typename: "FloatValue"; floatValue: number }
    | { __typename: "StringValue"; stringValue: string }
    | null;
  thresholdHigh:
    | { __typename: "FloatValue"; floatValue: number }
    | { __typename: "StringValue"; stringValue: string }
    | null;
  reduceDays: Array<{
    __typename?: "GrowingPlanReduceDayType";
    date: string;
    status: ConditionStatus;
    yieldDecrement: number;
    metric: number;
  }>;
};

export type GrowingPlanQueryVariables = Exact<{
  growingPlanId: Scalars["UUID"]["input"];
}>;

export type GrowingPlanQuery = {
  __typename?: "Query";
  growingPlan: {
    __typename?: "GrowingPlanType";
    id: string;
    name: string;
    state: GrowingPlanState;
    createdAt: string;
    startAt: string;
    endAt: string;
    planDuration: number;
    planProgress: number;
    planScheduleInDays: number | null;
    planProgressInDays: number;
    expectedYield: number;
    expectedYieldPercentage: number;
    cropSheet: { __typename?: "CropSheetType"; id: string; commonName: string; variant: string | null };
    block: {
      __typename?: "BlockType";
      id: string;
      name: string;
      field: { __typename?: "FieldType"; id: string; name: string };
    };
    robot: { __typename?: "RobotAssignmentType"; id: string; name: string } | null;
    conditionsSummary: Array<{
      __typename?: "GrowingPlanConditionSummaryType";
      type: string;
      yieldDecrement: number;
      yieldDecrementPercentage: number;
    }>;
    conditions: Array<{
      __typename?: "GrowingPlanConditionType";
      id: string;
      name: string;
      type: string;
      unit: string | null;
      status: ConditionStatus;
      metric: number | null;
      metricLow: number | null;
      metricHigh: number | null;
      nominalValue: string | null;
      nominalOptimal: Array<string> | null;
      thresholdLow:
        | { __typename: "FloatValue"; floatValue: number }
        | { __typename: "StringValue"; stringValue: string }
        | null;
      thresholdHigh:
        | { __typename: "FloatValue"; floatValue: number }
        | { __typename: "StringValue"; stringValue: string }
        | null;
      reduceDays: Array<{
        __typename?: "GrowingPlanReduceDayType";
        date: string;
        status: ConditionStatus;
        yieldDecrement: number;
        metric: number;
      }>;
    }>;
    field: { __typename?: "FieldType"; cultivation: CultivationMethod };
    stages: Array<{
      __typename?: "GrowingPlanStageType";
      id: string;
      duration: number | null;
      order: number;
      name: string;
      startAt: string | null;
      endAt: string | null;
      description: string;
      continuous: boolean;
      currentStage: boolean;
      conditions: Array<{
        __typename?: "GrowingPlanConditionType";
        id: string;
        name: string;
        type: string;
        unit: string | null;
        status: ConditionStatus;
        metric: number | null;
        metricLow: number | null;
        metricHigh: number | null;
        nominalValue: string | null;
        nominalOptimal: Array<string> | null;
        thresholdLow:
          | { __typename: "FloatValue"; floatValue: number }
          | { __typename: "StringValue"; stringValue: string }
          | null;
        thresholdHigh:
          | { __typename: "FloatValue"; floatValue: number }
          | { __typename: "StringValue"; stringValue: string }
          | null;
        reduceDays: Array<{
          __typename?: "GrowingPlanReduceDayType";
          date: string;
          status: ConditionStatus;
          yieldDecrement: number;
          metric: number;
        }>;
      }>;
    }>;
  };
};

export type GrowingPlanTasksQueryVariables = Exact<{
  startAt: Scalars["DateTime"]["input"];
  growingPlanId: Scalars["UUID"]["input"];
  paginationInput: PaginationInput;
}>;

export type GrowingPlanTasksQuery = {
  __typename?: "Query";
  tasks: {
    __typename?: "FieldTaskListType";
    nodes: Array<{
      __typename?: "FieldTask";
      id: string;
      jobId: string | null;
      createdAt: string;
      startAt: string;
      startedAt: string | null;
      completedAt: string | null;
      state: FieldTaskState;
      completionAt: string;
      delay: number;
      estimatedCompletionAt: string;
      estimatedPercentagePowerConsumption: number;
      estimatedStartAt: string;
      estimatedTimeConsumption: number;
      estimatedWaterConsumption: number;
      numberOfErrorWorkTasks: number | null;
      robotAssignment: { __typename?: "RobotAssignmentType"; id: string; name: string };
      procedures: Array<{
        __typename?: "FieldTaskProcedure";
        type: string;
        growingPlan: { __typename?: "GrowingPlanType"; id: string; name: string } | null;
        block: {
          __typename?: "BlockType";
          id: string;
          name: string;
          field: { __typename?: "FieldType"; id: string };
        } | null;
      }>;
      actionsPermissions: {
        __typename?: "FieldTaskActionsPermissions";
        delete: boolean;
        moveDown: boolean;
        moveUp: boolean;
        startNow: boolean;
      };
    }>;
    pageInfo: { __typename?: "PageInfo"; perPage: number; page: number; lastPage: number };
  };
};

export type UpdateGrowingPlanStartAtMutationVariables = Exact<{
  growingPlanId: Scalars["UUID"]["input"];
  startAt: Scalars["Date"]["input"];
}>;

export type UpdateGrowingPlanStartAtMutation = {
  __typename?: "CoreMutation";
  updateGrowingPlanStartAt: { __typename?: "GenericPayload"; success: boolean };
};

export type FinishGrowingPlanMutationVariables = Exact<{
  growingPlanId: Scalars["UUID"]["input"];
}>;

export type FinishGrowingPlanMutation = {
  __typename?: "CoreMutation";
  finishGrowingPlan: { __typename?: "GenericPayload"; success: boolean };
};

export type PlantSpacingGrowingPlanFragment = {
  __typename?: "GrowingPlanType";
  id: string;
  state: GrowingPlanState;
  block: {
    __typename?: "BlockType";
    id: string;
    area: number;
    name: string;
    color: string;
    segments: Array<{
      __typename?: "SegmentType";
      id: string;
      name: string;
      area: number;
      boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    }>;
  };
  plantSpacing: {
    __typename?: "PlantSpacing";
    id: string;
    endMargin: number;
    pattern: PlantSpacingPattern;
    sideMargin: number;
    spacing: number;
    plantsCount: number;
    plantsCountPerSquareMeter: number;
    rows: number;
    selectedRows: Array<number>;
    plants: Array<{
      __typename?: "Plant";
      id: string | null;
      coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
    }>;
    segments: Array<{
      __typename?: "SegmentPlantingData";
      segmentId: string;
      rows: Array<{
        __typename?: "PlantingRow";
        endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
        startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      }>;
      innerBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    }>;
  } | null;
};

export type PlantSpacingFragment = {
  __typename?: "PlantSpacing";
  id: string;
  endMargin: number;
  pattern: PlantSpacingPattern;
  sideMargin: number;
  spacing: number;
  plantsCount: number;
  plantsCountPerSquareMeter: number;
  rows: number;
  selectedRows: Array<number>;
  plants: Array<{
    __typename?: "Plant";
    id: string | null;
    coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
  }>;
  segments: Array<{
    __typename?: "SegmentPlantingData";
    segmentId: string;
    rows: Array<{
      __typename?: "PlantingRow";
      endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
    }>;
    innerBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
  }>;
};

export type PlantSpacingPreviewFragment = {
  __typename?: "PlantSpacingPreview";
  endMargin: number;
  pattern: PlantSpacingPattern;
  sideMargin: number;
  spacing: number;
  plantsCount: number;
  plantsCountPerSquareMeter: number;
  rows: number;
  selectedRows: Array<number>;
  plants: Array<{
    __typename?: "Plant";
    id: string | null;
    coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
  }>;
  segments: Array<{
    __typename?: "SegmentPlantingData";
    segmentId: string;
    rows: Array<{
      __typename?: "PlantingRow";
      endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
    }>;
    innerBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
  }>;
};

export type PreviewRowBasedPlantSpacingQueryVariables = Exact<{
  growingPlanId: Scalars["UUID"]["input"];
  input: RowBasedPlantSpacingInput;
}>;

export type PreviewRowBasedPlantSpacingQuery = {
  __typename?: "Query";
  previewRowBasedPlantSpacing: {
    __typename?: "PlantSpacingPreview";
    endMargin: number;
    pattern: PlantSpacingPattern;
    sideMargin: number;
    spacing: number;
    plantsCount: number;
    plantsCountPerSquareMeter: number;
    rows: number;
    selectedRows: Array<number>;
    plants: Array<{
      __typename?: "Plant";
      id: string | null;
      coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
    }>;
    segments: Array<{
      __typename?: "SegmentPlantingData";
      segmentId: string;
      rows: Array<{
        __typename?: "PlantingRow";
        endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
        startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      }>;
      innerBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    }>;
  };
};

export type PreviewPlantRowsQueryVariables = Exact<{
  growingPlanId: Scalars["UUID"]["input"];
  input: PlantRowsInput;
}>;

export type PreviewPlantRowsQuery = {
  __typename?: "Query";
  previewPlantRows: { __typename?: "PlantRowsPreview"; rows: number };
};

export type PlantSpacingQueryVariables = Exact<{
  growingPlanId: Scalars["UUID"]["input"];
}>;

export type PlantSpacingQuery = {
  __typename?: "Query";
  growingPlan: {
    __typename?: "GrowingPlanType";
    id: string;
    state: GrowingPlanState;
    block: {
      __typename?: "BlockType";
      id: string;
      area: number;
      name: string;
      color: string;
      segments: Array<{
        __typename?: "SegmentType";
        id: string;
        name: string;
        area: number;
        boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      }>;
    };
    plantSpacing: {
      __typename?: "PlantSpacing";
      id: string;
      endMargin: number;
      pattern: PlantSpacingPattern;
      sideMargin: number;
      spacing: number;
      plantsCount: number;
      plantsCountPerSquareMeter: number;
      rows: number;
      selectedRows: Array<number>;
      plants: Array<{
        __typename?: "Plant";
        id: string | null;
        coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
      }>;
      segments: Array<{
        __typename?: "SegmentPlantingData";
        segmentId: string;
        rows: Array<{
          __typename?: "PlantingRow";
          endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
          startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
        }>;
        innerBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      }>;
    } | null;
  };
};

export type PreviewPlantSpacingQueryVariables = Exact<{
  growingPlanId: Scalars["UUID"]["input"];
  plantSpacingInput: CustomPlantSpacingInput;
}>;

export type PreviewPlantSpacingQuery = {
  __typename?: "Query";
  previewCustomPlantSpacing: {
    __typename?: "PlantSpacingPreview";
    endMargin: number;
    pattern: PlantSpacingPattern;
    sideMargin: number;
    spacing: number;
    plantsCount: number;
    plantsCountPerSquareMeter: number;
    rows: number;
    selectedRows: Array<number>;
    plants: Array<{
      __typename?: "Plant";
      id: string | null;
      coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
    }>;
    segments: Array<{
      __typename?: "SegmentPlantingData";
      segmentId: string;
      rows: Array<{
        __typename?: "PlantingRow";
        endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
        startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      }>;
      innerBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    }>;
  };
};

export type CreatePlantSpacingMutationVariables = Exact<{
  growingPlanId: Scalars["UUID"]["input"];
  plantSpacingInput: RowBasedPlantSpacingInput;
}>;

export type CreatePlantSpacingMutation = {
  __typename?: "CoreMutation";
  createRowBasedPlantSpacing: {
    __typename?: "PlantSpacing";
    id: string;
    endMargin: number;
    pattern: PlantSpacingPattern;
    sideMargin: number;
    spacing: number;
    plantsCount: number;
    plantsCountPerSquareMeter: number;
    rows: number;
    selectedRows: Array<number>;
    plants: Array<{
      __typename?: "Plant";
      id: string | null;
      coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
    }>;
    segments: Array<{
      __typename?: "SegmentPlantingData";
      segmentId: string;
      rows: Array<{
        __typename?: "PlantingRow";
        endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
        startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
      }>;
      innerBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
    }>;
  };
};

export type DeletePlantSpacingMutationVariables = Exact<{
  plantSpacingId: Scalars["UUID"]["input"];
}>;

export type DeletePlantSpacingMutation = {
  __typename?: "CoreMutation";
  deletePlantSpacing: {
    __typename?: "GrowingPlanType";
    id: string;
    state: GrowingPlanState;
    block: {
      __typename?: "BlockType";
      id: string;
      area: number;
      name: string;
      color: string;
      segments: Array<{
        __typename?: "SegmentType";
        id: string;
        name: string;
        area: number;
        boundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      }>;
    };
    plantSpacing: {
      __typename?: "PlantSpacing";
      id: string;
      endMargin: number;
      pattern: PlantSpacingPattern;
      sideMargin: number;
      spacing: number;
      plantsCount: number;
      plantsCountPerSquareMeter: number;
      rows: number;
      selectedRows: Array<number>;
      plants: Array<{
        __typename?: "Plant";
        id: string | null;
        coordinates: { __typename?: "CoordinatesType"; lat: number; lon: number };
      }>;
      segments: Array<{
        __typename?: "SegmentPlantingData";
        segmentId: string;
        rows: Array<{
          __typename?: "PlantingRow";
          endPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
          startPoint: { __typename?: "CoordinatesType"; lat: number; lon: number };
        }>;
        innerBoundaries: Array<{ __typename?: "CoordinatesType"; lat: number; lon: number }>;
      }>;
    } | null;
  };
};

export type GrowingPlansQueryVariables = Exact<{
  paginationInput: PaginationInput;
  filters?: InputMaybe<GrowingPlanFilter>;
  order?: InputMaybe<GrowingPlanOrder>;
}>;

export type GrowingPlansQuery = {
  __typename?: "Query";
  growingPlanFilter: {
    __typename?: "GrowingPlanTypeListType";
    totalCount: number;
    nodes: Array<{
      __typename?: "GrowingPlanType";
      id: string;
      name: string;
      planScheduleInDays: number | null;
      planProgress: number;
      state: GrowingPlanState;
      planProgressInDays: number;
      planDuration: number;
      startAt: string;
      endAt: string;
      plantSpacing: { __typename?: "PlantSpacing"; selectedRows: Array<number> } | null;
      field: { __typename?: "FieldType"; id: string; name: string; cultivation: CultivationMethod };
      block: { __typename?: "BlockType"; id: string; name: string };
      cropSheet: { __typename?: "CropSheetType"; id: string; commonName: string; variant: string | null };
      robot: { __typename?: "RobotAssignmentType"; id: string; name: string } | null;
    }>;
    pageInfo: { __typename?: "PageInfo"; perPage: number; page: number; lastPage: number };
  };
};

export type BlocksSuggestionsQueryVariables = Exact<{
  filters?: InputMaybe<GrowingPlanFilter>;
}>;

export type BlocksSuggestionsQuery = {
  __typename?: "Query";
  suggestion: Array<{ __typename?: "BlockType"; id: string; name: string }>;
};

export type FieldsSuggestionsQueryVariables = Exact<{
  filters?: InputMaybe<GrowingPlanFilter>;
}>;

export type FieldsSuggestionsQuery = {
  __typename?: "Query";
  suggestion: Array<{ __typename?: "FieldType"; id: string; name: string }>;
};

export type CropsSuggestionsQueryVariables = Exact<{
  filters?: InputMaybe<GrowingPlanFilter>;
}>;

export type CropsSuggestionsQuery = {
  __typename?: "Query";
  suggestion: Array<{ __typename?: "CropMetadataType"; id: string; commonName: string; combinedName: string }>;
};

export type CropsForGrowingPlansPageQueryVariables = Exact<{ [key: string]: never }>;

export type CropsForGrowingPlansPageQuery = {
  __typename?: "Query";
  cropSheetList: {
    __typename?: "CropSheetListType";
    crops: Array<{ __typename?: "CropSheetBaseType"; id: string; commonName: string }>;
  };
};

export type DeleteGrowingPlanMutationVariables = Exact<{
  growingPlanId: Scalars["UUID"]["input"];
}>;

export type DeleteGrowingPlanMutation = {
  __typename?: "CoreMutation";
  deleteGrowingPlan: { __typename?: "GenericPayload"; success: boolean };
};

export type GrowingPlanCardFragment = {
  __typename?: "GrowingPlanType";
  id: string;
  name: string;
  planScheduleInDays: number | null;
  planProgress: number;
  state: GrowingPlanState;
  planProgressInDays: number;
  planDuration: number;
  startAt: string;
  endAt: string;
  field: { __typename?: "FieldType"; id: string; name: string; cultivation: CultivationMethod };
  block: { __typename?: "BlockType"; id: string; name: string };
  cropSheet: { __typename?: "CropSheetType"; id: string; commonName: string; variant: string | null };
  robot: { __typename?: "RobotAssignmentType"; id: string; name: string } | null;
};

export type RegisterMutationVariables = Exact<{
  input: UserRegisterInput;
}>;

export type RegisterMutation = { __typename?: "CoreMutation"; register: boolean };

export type RobotsQueryVariables = Exact<{ [key: string]: never }>;

export type RobotsQuery = {
  __typename?: "Query";
  robotAssignments: Array<{
    __typename?: "RobotAssignmentType";
    id: string;
    name: string;
    robot: { __typename?: "Robot"; serialNumber: string };
  }>;
};

export type RobotQueryVariables = Exact<{
  robotAssignmentId: Scalars["UUID"]["input"];
}>;

export type RobotQuery = {
  __typename?: "Query";
  robotAssignment: {
    __typename?: "RobotAssignmentType";
    id: string;
    name: string;
    createdAt: string;
    registeredAt: string | null;
    robot: {
      __typename?: "Robot";
      id: string;
      serialNumber: string;
      robotWidth: number;
      trackWidth: number;
      stateReport: {
        __typename?: "RIRobotState";
        battery: {
          __typename?: "RIRobotBattery";
          estimEnergy: number | null;
          chargingState: RiRobotBatteryChargingState | null;
        } | null;
        general: {
          __typename?: "RIRobotGeneral";
          status: number;
          devices: Array<{ __typename?: "RIRobotDevice"; type: RiRobotDeviceType; active: boolean; error: boolean }>;
        };
        position: {
          __typename?: "RIRobotPosition";
          lat: number | null;
          lon: number | null;
          speed: number | null;
          dist: number | null;
        } | null;
        target: { __typename?: "RIRobotTarget"; lat: number | null; lon: number | null } | null;
        tool: { __typename?: "RIRobotTool"; type: RiRobotToolType | null; mode: RiRobotToolMode | null } | null;
      } | null;
    };
  };
};

export type RobotTasksQueryVariables = Exact<{
  robotAssignmentId: Scalars["UUID"]["input"];
  paginationInput: PaginationInput;
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  growingPlanId?: InputMaybe<Scalars["UUID"]["input"]>;
}>;

export type RobotTasksQuery = {
  __typename?: "Query";
  tasks: {
    __typename?: "FieldTaskListType";
    nodes: Array<{
      __typename?: "FieldTask";
      id: string;
      jobId: string | null;
      createdAt: string;
      startAt: string;
      startedAt: string | null;
      completedAt: string | null;
      state: FieldTaskState;
      completionAt: string;
      delay: number;
      estimatedCompletionAt: string;
      estimatedPercentagePowerConsumption: number;
      estimatedStartAt: string;
      estimatedTimeConsumption: number;
      estimatedWaterConsumption: number;
      numberOfErrorWorkTasks: number | null;
      robotAssignment: { __typename?: "RobotAssignmentType"; id: string; name: string };
      procedures: Array<{
        __typename?: "FieldTaskProcedure";
        type: string;
        growingPlan: { __typename?: "GrowingPlanType"; id: string; name: string } | null;
        block: {
          __typename?: "BlockType";
          id: string;
          name: string;
          field: { __typename?: "FieldType"; id: string };
        } | null;
      }>;
      actionsPermissions: {
        __typename?: "FieldTaskActionsPermissions";
        delete: boolean;
        moveDown: boolean;
        moveUp: boolean;
        startNow: boolean;
      };
    }>;
    pageInfo: { __typename?: "PageInfo"; perPage: number; page: number; lastPage: number };
  };
};

export type RegisterRobotMutationVariables = Exact<{
  robotAssignmentInput: RobotAssignmentInput;
}>;

export type RegisterRobotMutation = {
  __typename?: "CoreMutation";
  registerRobot: { __typename?: "RobotAssignmentMutationPayload"; success: boolean };
};

export type DecommissionRobotMutationVariables = Exact<{
  robotId: Scalars["UUID"]["input"];
}>;

export type DecommissionRobotMutation = {
  __typename?: "CoreMutation";
  decommissionRobot: { __typename?: "RobotAssignmentMutationPayload"; success: boolean };
};

export type AllRobotsGrowingPlansQueryVariables = Exact<{
  robotAssignmentId: Scalars["UUID"]["input"];
}>;

export type AllRobotsGrowingPlansQuery = {
  __typename?: "Query";
  robot: {
    __typename?: "RobotAssignmentType";
    growingPlans: Array<{ __typename?: "GrowingPlanType"; id: string; name: string }>;
  };
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: "Query";
  myProfile: {
    __typename?: "UserType";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string | null;
  };
};

export const ErrorFieldTaskFragmentDoc = `
    fragment ErrorFieldTask on ErrorFieldTask {
  __typename
  ... on ErrorFieldTaskInvalid {
    message
  }
}
    `;
export const ImageFragmentDoc = `
    fragment Image on ImageType {
  id
  alt
  url
}
    `;
export const ClientAccountCardFragmentDoc = `
    fragment ClientAccountCard on ClientAccountType {
  businessName
  createdAt
  approvedAt
  city
  country
  address
  postalCode
}
    `;
export const FieldTaskWorkReportBaseFragmentDoc = `
    fragment FieldTaskWorkReportBase on FieldTaskWorkReportBase {
  id
  state
}
    `;
export const CoordinateFragmentDoc = `
    fragment Coordinate on CoordinatesType {
  lat
  lon
}
    `;
export const FieldTaskWorkReportPointFragmentDoc = `
    fragment FieldTaskWorkReportPoint on FieldTaskWorkReportPoint {
  id
  state
  point {
    ...Coordinate
  }
}
    `;
export const DefaultParametrizationValueFragmentDoc = `
    fragment DefaultParametrizationValue on DefaultParametrizationValue {
  __typename
  ... on BoolValue {
    boolValue
  }
  ... on FloatValue {
    floatValue
  }
  ... on IntListValue {
    intListValue
  }
  ... on IntValue {
    intValue
  }
  ... on StringValue {
    stringValue
  }
  ... on PlantSpacingPatternValue {
    patternValue
  }
}
    `;
export const ParametrizationFragmentDoc = `
    fragment Parametrization on FieldTaskParametrization {
  code
  max
  min
  name
  step
  type
  unit
  default {
    ...DefaultParametrizationValue
  }
}
    `;
export const FieldTaskCardFragmentDoc = `
    fragment FieldTaskCard on FieldTask {
  id
  jobId
  createdAt
  startAt
  startedAt
  completedAt
  state
  completionAt
  delay
  estimatedCompletionAt
  estimatedPercentagePowerConsumption
  estimatedStartAt
  estimatedTimeConsumption
  estimatedWaterConsumption
  numberOfErrorWorkTasks
  robotAssignment {
    id
    name
  }
  procedures {
    growingPlan {
      id
      name
    }
    block {
      id
      name
      field {
        id
      }
    }
    type
  }
  actionsPermissions {
    delete
    moveDown
    moveUp
    startNow
  }
}
    `;
export const PageInfoFragmentDoc = `
    fragment PageInfo on PageInfo {
  perPage
  page
  lastPage
}
    `;
export const FieldTasksListFragmentDoc = `
    fragment FieldTasksList on FieldTaskListType {
  nodes {
    ...FieldTaskCard
  }
  pageInfo {
    ...PageInfo
  }
}
    `;
export const TraceFragmentFragmentDoc = `
    fragment TraceFragment on TraceType {
  id
  name
  state
  coordinates {
    ...Coordinate
  }
  type
  createdAt
  completedAt
  startedAt
}
    `;
export const FieldBasicModelFragmentDoc = `
    fragment FieldBasicModel on FieldType {
  id
  name
  area
  irrigation
  lighting
  nitrogen
  ph
  phosphorus
  potassium
  soilStatus
  soilType
  state
  cultivation
  swathAngle
  swathPathSegment {
    id
    startPoint {
      ...Coordinate
    }
    endPoint {
      ...Coordinate
    }
  }
  geofencing {
    ...Coordinate
  }
  boundaries {
    ...Coordinate
  }
  importedBoundaries {
    ...Coordinate
  }
  homeStation {
    id
    gpsTrace {
      name
    }
    coordinates {
      ...Coordinate
    }
    homeStationPath {
      id
      pathCoordinates {
        ...Coordinate
      }
    }
  }
}
    `;
export const GrowingPlanInfoRowFragmentDoc = `
    fragment GrowingPlanInfoRow on GrowingPlanType {
  id
  name
  state
  planScheduleInDays
}
    `;
export const BlocksSegmentFragmentDoc = `
    fragment BlocksSegment on SegmentType {
  id
  name
  area
  order
  boundaries {
    ...Coordinate
  }
  swath {
    id
  }
  startPoint {
    ...Coordinate
  }
  endPoint {
    ...Coordinate
  }
}
    `;
export const BlockModelFragmentDoc = `
    fragment BlockModel on BlockType {
  id
  name
  area
  createdAt
  color
  growingPlans {
    ...GrowingPlanInfoRow
  }
  segments {
    ...BlocksSegment
  }
  path {
    id
    pathCoordinates {
      ...Coordinate
    }
  }
}
    `;
export const FieldModelFragmentDoc = `
    fragment FieldModel on FieldType {
  ...FieldBasicModel
  blocks {
    ...BlockModel
  }
  robotAssignment {
    id
    name
  }
  unassignedSegments {
    ...BlocksSegment
  }
}
    `;
export const FieldCardFragmentDoc = `
    fragment FieldCard on FieldType {
  id
  name
  state
  cultivation
  blocks {
    id
    area
    name
    growingPlans {
      ...GrowingPlanInfoRow
    }
  }
}
    `;
export const FloatValueStringValueFragmentDoc = `
    fragment FloatValueStringValue on FloatValueStringValue {
  __typename
  ... on FloatValue {
    floatValue
  }
  ... on StringValue {
    stringValue
  }
}
    `;
export const GrowingPlanConditionFragmentDoc = `
    fragment GrowingPlanCondition on GrowingPlanConditionType {
  id
  name
  type
  unit
  thresholdLow {
    ...FloatValueStringValue
  }
  thresholdHigh {
    ...FloatValueStringValue
  }
  status
  metric
  metricLow
  metricHigh
  nominalValue
  nominalOptimal
  reduceDays {
    date
    status
    yieldDecrement
    metric
  }
}
    `;
export const PlantSpacingFragmentDoc = `
    fragment PlantSpacing on PlantSpacing {
  id
  endMargin
  pattern
  sideMargin
  spacing
  plantsCount
  plantsCountPerSquareMeter
  rows
  selectedRows
  plants {
    id
    coordinates {
      ...Coordinate
    }
  }
  segments {
    segmentId
    rows {
      endPoint {
        ...Coordinate
      }
      startPoint {
        ...Coordinate
      }
    }
    innerBoundaries {
      ...Coordinate
    }
  }
}
    `;
export const PlantSpacingGrowingPlanFragmentDoc = `
    fragment PlantSpacingGrowingPlan on GrowingPlanType {
  id
  state
  block {
    id
    area
    name
    color
    segments {
      id
      name
      area
      boundaries {
        ...Coordinate
      }
    }
  }
  plantSpacing {
    ...PlantSpacing
  }
}
    `;
export const PlantSpacingPreviewFragmentDoc = `
    fragment PlantSpacingPreview on PlantSpacingPreview {
  endMargin
  pattern
  sideMargin
  spacing
  plantsCount
  plantsCountPerSquareMeter
  rows
  selectedRows
  plants {
    id
    coordinates {
      ...Coordinate
    }
  }
  segments {
    segmentId
    rows {
      endPoint {
        ...Coordinate
      }
      startPoint {
        ...Coordinate
      }
    }
    innerBoundaries {
      ...Coordinate
    }
  }
}
    `;
export const GrowingPlanCardFragmentDoc = `
    fragment GrowingPlanCard on GrowingPlanType {
  id
  name
  planScheduleInDays
  planProgress
  state
  planProgressInDays
  planDuration
  startAt
  endAt
  field {
    id
    name
    cultivation
  }
  block {
    id
    name
  }
  cropSheet {
    id
    commonName
    variant
  }
  robot {
    id
    name
  }
}
    `;
export const AccessPermissionsDocument = `
    query accessPermissions {
  myProfile {
    id
    activeClientAccount {
      state
    }
  }
}
    `;

export const useAccessPermissionsQuery = <
  TData = AccessPermissionsQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables?: AccessPermissionsQueryVariables,
  options?: Omit<UseQueryOptions<AccessPermissionsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<AccessPermissionsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<AccessPermissionsQuery, TError, TData>({
    queryKey: variables === undefined ? ["accessPermissions"] : ["accessPermissions", variables],
    queryFn: fetcher<AccessPermissionsQuery, AccessPermissionsQueryVariables>(AccessPermissionsDocument, variables),
    ...options,
  });
};

useAccessPermissionsQuery.document = AccessPermissionsDocument;

useAccessPermissionsQuery.getKey = (variables?: AccessPermissionsQueryVariables) =>
  variables === undefined ? ["accessPermissions"] : ["accessPermissions", variables];

export const AuthorizationCheckDocument = `
    query authorizationCheck {
  myProfile {
    id
  }
}
    `;

export const useAuthorizationCheckQuery = <
  TData = AuthorizationCheckQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables?: AuthorizationCheckQueryVariables,
  options?: Omit<UseQueryOptions<AuthorizationCheckQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<AuthorizationCheckQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<AuthorizationCheckQuery, TError, TData>({
    queryKey: variables === undefined ? ["authorizationCheck"] : ["authorizationCheck", variables],
    queryFn: fetcher<AuthorizationCheckQuery, AuthorizationCheckQueryVariables>(AuthorizationCheckDocument, variables),
    ...options,
  });
};

useAuthorizationCheckQuery.document = AuthorizationCheckDocument;

useAuthorizationCheckQuery.getKey = (variables?: AuthorizationCheckQueryVariables) =>
  variables === undefined ? ["authorizationCheck"] : ["authorizationCheck", variables];

export const UserInfoForAuthPageDocument = `
    query userInfoForAuthPage {
  myProfile {
    id
    email
    firstName
    lastName
    clientAccounts {
      id
    }
  }
}
    `;

export const useUserInfoForAuthPageQuery = <
  TData = UserInfoForAuthPageQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables?: UserInfoForAuthPageQueryVariables,
  options?: Omit<UseQueryOptions<UserInfoForAuthPageQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<UserInfoForAuthPageQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<UserInfoForAuthPageQuery, TError, TData>({
    queryKey: variables === undefined ? ["userInfoForAuthPage"] : ["userInfoForAuthPage", variables],
    queryFn: fetcher<UserInfoForAuthPageQuery, UserInfoForAuthPageQueryVariables>(
      UserInfoForAuthPageDocument,
      variables,
    ),
    ...options,
  });
};

useUserInfoForAuthPageQuery.document = UserInfoForAuthPageDocument;

useUserInfoForAuthPageQuery.getKey = (variables?: UserInfoForAuthPageQueryVariables) =>
  variables === undefined ? ["userInfoForAuthPage"] : ["userInfoForAuthPage", variables];

export const ClientAccountsDocument = `
    query ClientAccounts {
  myProfile {
    activeClientAccount {
      id
    }
    clientAccounts {
      id
      state
      ...ClientAccountCard
    }
  }
}
    ${ClientAccountCardFragmentDoc}`;

export const useClientAccountsQuery = <TData = ClientAccountsQuery, TError = { message: string; code: ErrorCode }>(
  variables?: ClientAccountsQueryVariables,
  options?: Omit<UseQueryOptions<ClientAccountsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<ClientAccountsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<ClientAccountsQuery, TError, TData>({
    queryKey: variables === undefined ? ["ClientAccounts"] : ["ClientAccounts", variables],
    queryFn: fetcher<ClientAccountsQuery, ClientAccountsQueryVariables>(ClientAccountsDocument, variables),
    ...options,
  });
};

useClientAccountsQuery.document = ClientAccountsDocument;

useClientAccountsQuery.getKey = (variables?: ClientAccountsQueryVariables) =>
  variables === undefined ? ["ClientAccounts"] : ["ClientAccounts", variables];

export const CreateClientAccountDocument = `
    mutation createClientAccount($input: ClientAccountInput!) {
  createClientAccount(clientAccountInput: $input) {
    success
  }
}
    `;

export const useCreateClientAccountMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<CreateClientAccountMutation, TError, CreateClientAccountMutationVariables, TContext>,
) => {
  return useMutation<CreateClientAccountMutation, TError, CreateClientAccountMutationVariables, TContext>({
    mutationKey: ["createClientAccount"],
    mutationFn: (variables?: CreateClientAccountMutationVariables) =>
      fetcher<CreateClientAccountMutation, CreateClientAccountMutationVariables>(
        CreateClientAccountDocument,
        variables,
      )(),
    ...options,
  });
};

export const UpdateClientAccountDocument = `
    mutation updateClientAccount($clientAccountId: UUID!, $clientAccountInput: ClientAccountInput!) {
  updateClientAccount(
    clientAccountId: $clientAccountId
    clientAccountInput: $clientAccountInput
  ) {
    success
  }
}
    `;

export const useUpdateClientAccountMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<UpdateClientAccountMutation, TError, UpdateClientAccountMutationVariables, TContext>,
) => {
  return useMutation<UpdateClientAccountMutation, TError, UpdateClientAccountMutationVariables, TContext>({
    mutationKey: ["updateClientAccount"],
    mutationFn: (variables?: UpdateClientAccountMutationVariables) =>
      fetcher<UpdateClientAccountMutation, UpdateClientAccountMutationVariables>(
        UpdateClientAccountDocument,
        variables,
      )(),
    ...options,
  });
};

export const SwitchClientAccountDocument = `
    mutation switchClientAccount($clientAccountId: UUID!) {
  switchClientAccount(clientAccountId: $clientAccountId) {
    success
  }
}
    `;

export const useSwitchClientAccountMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<SwitchClientAccountMutation, TError, SwitchClientAccountMutationVariables, TContext>,
) => {
  return useMutation<SwitchClientAccountMutation, TError, SwitchClientAccountMutationVariables, TContext>({
    mutationKey: ["switchClientAccount"],
    mutationFn: (variables?: SwitchClientAccountMutationVariables) =>
      fetcher<SwitchClientAccountMutation, SwitchClientAccountMutationVariables>(
        SwitchClientAccountDocument,
        variables,
      )(),
    ...options,
  });
};

export const ClientAccountForEditPageDocument = `
    query clientAccountForEditPage($clientAccountId: UUID!) {
  clientAccount(clientAccountId: $clientAccountId) {
    id
    address
    businessName
    city
    state
    country
    postalCode
  }
}
    `;

export const useClientAccountForEditPageQuery = <
  TData = ClientAccountForEditPageQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables: ClientAccountForEditPageQueryVariables,
  options?: Omit<UseQueryOptions<ClientAccountForEditPageQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<ClientAccountForEditPageQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<ClientAccountForEditPageQuery, TError, TData>({
    queryKey: ["clientAccountForEditPage", variables],
    queryFn: fetcher<ClientAccountForEditPageQuery, ClientAccountForEditPageQueryVariables>(
      ClientAccountForEditPageDocument,
      variables,
    ),
    ...options,
  });
};

useClientAccountForEditPageQuery.document = ClientAccountForEditPageDocument;

useClientAccountForEditPageQuery.getKey = (variables: ClientAccountForEditPageQueryVariables) => [
  "clientAccountForEditPage",
  variables,
];

export const CropSheetListDocument = `
    query cropSheetList {
  cropSheetList {
    crops {
      id
      commonName
      alternativeNames
      variant
      mainImage {
        ...Image
      }
    }
  }
}
    ${ImageFragmentDoc}`;

export const useCropSheetListQuery = <TData = CropSheetListQuery, TError = { message: string; code: ErrorCode }>(
  variables?: CropSheetListQueryVariables,
  options?: Omit<UseQueryOptions<CropSheetListQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<CropSheetListQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<CropSheetListQuery, TError, TData>({
    queryKey: variables === undefined ? ["cropSheetList"] : ["cropSheetList", variables],
    queryFn: fetcher<CropSheetListQuery, CropSheetListQueryVariables>(CropSheetListDocument, variables),
    ...options,
  });
};

useCropSheetListQuery.document = CropSheetListDocument;

useCropSheetListQuery.getKey = (variables?: CropSheetListQueryVariables) =>
  variables === undefined ? ["cropSheetList"] : ["cropSheetList", variables];

export const ThreatsDocument = `
    query threats($type: DiseaseCategory) {
  threats: diseases(type: $type) {
    id
    commonName
    alternativeNames
    mainImage {
      ...Image
    }
  }
}
    ${ImageFragmentDoc}`;

export const useThreatsQuery = <TData = ThreatsQuery, TError = { message: string; code: ErrorCode }>(
  variables?: ThreatsQueryVariables,
  options?: Omit<UseQueryOptions<ThreatsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<ThreatsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<ThreatsQuery, TError, TData>({
    queryKey: variables === undefined ? ["threats"] : ["threats", variables],
    queryFn: fetcher<ThreatsQuery, ThreatsQueryVariables>(ThreatsDocument, variables),
    ...options,
  });
};

useThreatsQuery.document = ThreatsDocument;

useThreatsQuery.getKey = (variables?: ThreatsQueryVariables) =>
  variables === undefined ? ["threats"] : ["threats", variables];

export const CropSheetDocument = `
    query cropSheet($cropSheetId: UUID!) {
  cropSheet(cropSheetId: $cropSheetId) {
    id
    commonName
    alternativeNames
    scientificName
    mainImage {
      ...Image
    }
    images {
      ...Image
    }
    variant
    description
    cultivation
    conditions {
      id
      name
      unit
      healthThresholdLow {
        ...FloatValueStringValue
      }
      healthThresholdHigh {
        ...FloatValueStringValue
      }
      thresholdLow {
        ...FloatValueStringValue
      }
      thresholdHigh {
        ...FloatValueStringValue
      }
      nominalOptimal
      nominalLimit
      nominalCritical
      scaleExtremeLow
      scaleExtremeHigh
      scaleStep
    }
  }
  cropThreats: cropDiseases(cropSheetId: $cropSheetId) {
    mainImage {
      ...Image
    }
    threat: disease {
      id
      commonName
      type
    }
  }
  cropStages(cropSheetId: $cropSheetId) {
    id
    stages {
      id
      name
      order
      description
      durationDays
      continuous
      startAtDay
      conditions {
        id
      }
      images {
        ...Image
      }
    }
  }
}
    ${ImageFragmentDoc}
${FloatValueStringValueFragmentDoc}`;

export const useCropSheetQuery = <TData = CropSheetQuery, TError = { message: string; code: ErrorCode }>(
  variables: CropSheetQueryVariables,
  options?: Omit<UseQueryOptions<CropSheetQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<CropSheetQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<CropSheetQuery, TError, TData>({
    queryKey: ["cropSheet", variables],
    queryFn: fetcher<CropSheetQuery, CropSheetQueryVariables>(CropSheetDocument, variables),
    ...options,
  });
};

useCropSheetQuery.document = CropSheetDocument;

useCropSheetQuery.getKey = (variables: CropSheetQueryVariables) => ["cropSheet", variables];

export const CropThreatsDocument = `
    query cropThreats($cropSheetId: UUID!, $threatType: DiseaseCategory!) {
  cropSheet(cropSheetId: $cropSheetId) {
    id
    commonName
  }
  cropThreats: cropDiseases(cropSheetId: $cropSheetId, type: $threatType) {
    mainImage {
      ...Image
    }
    images {
      ...Image
    }
    treatment
    threat: disease {
      id
      commonName
      scientificName
      alternativeNames
      description
    }
  }
}
    ${ImageFragmentDoc}`;

export const useCropThreatsQuery = <TData = CropThreatsQuery, TError = { message: string; code: ErrorCode }>(
  variables: CropThreatsQueryVariables,
  options?: Omit<UseQueryOptions<CropThreatsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<CropThreatsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<CropThreatsQuery, TError, TData>({
    queryKey: ["cropThreats", variables],
    queryFn: fetcher<CropThreatsQuery, CropThreatsQueryVariables>(CropThreatsDocument, variables),
    ...options,
  });
};

useCropThreatsQuery.document = CropThreatsDocument;

useCropThreatsQuery.getKey = (variables: CropThreatsQueryVariables) => ["cropThreats", variables];

export const ThreatDocument = `
    query threat($threatId: UUID!) {
  threat: disease(diseaseId: $threatId) {
    id
    commonName
    alternativeNames
    scientificName
    mainImage {
      ...Image
    }
    images {
      ...Image
    }
    description
    threatenedCrops {
      id
      mainImage {
        ...Image
      }
      commonName
      alternativeNames
    }
  }
}
    ${ImageFragmentDoc}`;

export const useThreatQuery = <TData = ThreatQuery, TError = { message: string; code: ErrorCode }>(
  variables: ThreatQueryVariables,
  options?: Omit<UseQueryOptions<ThreatQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<ThreatQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<ThreatQuery, TError, TData>({
    queryKey: ["threat", variables],
    queryFn: fetcher<ThreatQuery, ThreatQueryVariables>(ThreatDocument, variables),
    ...options,
  });
};

useThreatQuery.document = ThreatDocument;

useThreatQuery.getKey = (variables: ThreatQueryVariables) => ["threat", variables];

export const FieldTaskDocument = `
    query fieldTask($fieldTaskId: UUID!) {
  task: fieldTask(fieldTaskId: $fieldTaskId) {
    procedures {
      growingPlan {
        field {
          ...FieldBasicModel
        }
      }
      block {
        field {
          ...FieldBasicModel
        }
      }
      type
    }
    id
    jobId
    createdAt
    startAt
    startedAt
    completedAt
    state
    completionAt
    delay
    estimatedCompletionAt
    estimatedPercentagePowerConsumption
    estimatedStartAt
    estimatedTimeConsumption
    estimatedWaterConsumption
    numberOfErrorWorkTasks
    robotAssignment {
      id
      name
    }
    numberOfCompletedWorkTasks
    numberOfErrorWorkTasks
    numberOfWorkTasks
    workTaskReports {
      __typename
      ... on FieldTaskWorkReportBase {
        ...FieldTaskWorkReportBase
      }
      ... on FieldTaskWorkReportPoint {
        ...FieldTaskWorkReportPoint
      }
    }
  }
}
    ${FieldBasicModelFragmentDoc}
${CoordinateFragmentDoc}
${FieldTaskWorkReportBaseFragmentDoc}
${FieldTaskWorkReportPointFragmentDoc}`;

export const useFieldTaskQuery = <TData = FieldTaskQuery, TError = { message: string; code: ErrorCode }>(
  variables: FieldTaskQueryVariables,
  options?: Omit<UseQueryOptions<FieldTaskQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FieldTaskQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<FieldTaskQuery, TError, TData>({
    queryKey: ["fieldTask", variables],
    queryFn: fetcher<FieldTaskQuery, FieldTaskQueryVariables>(FieldTaskDocument, variables),
    ...options,
  });
};

useFieldTaskQuery.document = FieldTaskDocument;

useFieldTaskQuery.getKey = (variables: FieldTaskQueryVariables) => ["fieldTask", variables];

export const FieldTasksWarningsDocument = `
    query fieldTasksWarnings($date: Date!, $robotAssignmentId: UUID!) {
  fieldTasksWarnings(date: $date, robotAssignmentId: $robotAssignmentId) {
    warnings
  }
}
    `;

export const useFieldTasksWarningsQuery = <
  TData = FieldTasksWarningsQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables: FieldTasksWarningsQueryVariables,
  options?: Omit<UseQueryOptions<FieldTasksWarningsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FieldTasksWarningsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<FieldTasksWarningsQuery, TError, TData>({
    queryKey: ["fieldTasksWarnings", variables],
    queryFn: fetcher<FieldTasksWarningsQuery, FieldTasksWarningsQueryVariables>(FieldTasksWarningsDocument, variables),
    ...options,
  });
};

useFieldTasksWarningsQuery.document = FieldTasksWarningsDocument;

useFieldTasksWarningsQuery.getKey = (variables: FieldTasksWarningsQueryVariables) => ["fieldTasksWarnings", variables];

export const FieldTaskTypesDocument = `
    query fieldTaskTypes {
  fieldTaskTypes {
    commonName
    type
    availableForBlock
    group {
      code
      commonName
    }
    parametrization {
      ...Parametrization
    }
  }
}
    ${ParametrizationFragmentDoc}
${DefaultParametrizationValueFragmentDoc}`;

export const useFieldTaskTypesQuery = <TData = FieldTaskTypesQuery, TError = { message: string; code: ErrorCode }>(
  variables?: FieldTaskTypesQueryVariables,
  options?: Omit<UseQueryOptions<FieldTaskTypesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FieldTaskTypesQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<FieldTaskTypesQuery, TError, TData>({
    queryKey: variables === undefined ? ["fieldTaskTypes"] : ["fieldTaskTypes", variables],
    queryFn: fetcher<FieldTaskTypesQuery, FieldTaskTypesQueryVariables>(FieldTaskTypesDocument, variables),
    ...options,
  });
};

useFieldTaskTypesQuery.document = FieldTaskTypesDocument;

useFieldTaskTypesQuery.getKey = (variables?: FieldTaskTypesQueryVariables) =>
  variables === undefined ? ["fieldTaskTypes"] : ["fieldTaskTypes", variables];

export const FieldTaskTypesWithDefaultValuesDocument = `
    query fieldTaskTypesWithDefaultValues($growingPlanId: UUID) {
  fieldTaskTypes(growingPlanId: $growingPlanId) {
    commonName
    type
    availableForBlock
    group {
      code
      commonName
    }
    parametrization {
      ...Parametrization
    }
  }
}
    ${ParametrizationFragmentDoc}
${DefaultParametrizationValueFragmentDoc}`;

export const useFieldTaskTypesWithDefaultValuesQuery = <
  TData = FieldTaskTypesWithDefaultValuesQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables?: FieldTaskTypesWithDefaultValuesQueryVariables,
  options?: Omit<UseQueryOptions<FieldTaskTypesWithDefaultValuesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FieldTaskTypesWithDefaultValuesQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<FieldTaskTypesWithDefaultValuesQuery, TError, TData>({
    queryKey:
      variables === undefined ? ["fieldTaskTypesWithDefaultValues"] : ["fieldTaskTypesWithDefaultValues", variables],
    queryFn: fetcher<FieldTaskTypesWithDefaultValuesQuery, FieldTaskTypesWithDefaultValuesQueryVariables>(
      FieldTaskTypesWithDefaultValuesDocument,
      variables,
    ),
    ...options,
  });
};

useFieldTaskTypesWithDefaultValuesQuery.document = FieldTaskTypesWithDefaultValuesDocument;

useFieldTaskTypesWithDefaultValuesQuery.getKey = (variables?: FieldTaskTypesWithDefaultValuesQueryVariables) =>
  variables === undefined ? ["fieldTaskTypesWithDefaultValues"] : ["fieldTaskTypesWithDefaultValues", variables];

export const IsFieldTaskPossibleDocument = `
    query isFieldTaskPossible($blockIds: [UUID!]!) {
  isFieldTaskPossible(blockIds: $blockIds) {
    possible
  }
}
    `;

export const useIsFieldTaskPossibleQuery = <
  TData = IsFieldTaskPossibleQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables: IsFieldTaskPossibleQueryVariables,
  options?: Omit<UseQueryOptions<IsFieldTaskPossibleQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<IsFieldTaskPossibleQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<IsFieldTaskPossibleQuery, TError, TData>({
    queryKey: ["isFieldTaskPossible", variables],
    queryFn: fetcher<IsFieldTaskPossibleQuery, IsFieldTaskPossibleQueryVariables>(
      IsFieldTaskPossibleDocument,
      variables,
    ),
    ...options,
  });
};

useIsFieldTaskPossibleQuery.document = IsFieldTaskPossibleDocument;

useIsFieldTaskPossibleQuery.getKey = (variables: IsFieldTaskPossibleQueryVariables) => [
  "isFieldTaskPossible",
  variables,
];

export const CreateFieldTasksDocument = `
    mutation createFieldTasks($fieldTaskInput: FieldTaskBatchInput!) {
  createFieldTaskBatch(fieldTaskInput: $fieldTaskInput) {
    success
    errors {
      ...ErrorFieldTask
    }
  }
}
    ${ErrorFieldTaskFragmentDoc}`;

export const useCreateFieldTasksMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<CreateFieldTasksMutation, TError, CreateFieldTasksMutationVariables, TContext>,
) => {
  return useMutation<CreateFieldTasksMutation, TError, CreateFieldTasksMutationVariables, TContext>({
    mutationKey: ["createFieldTasks"],
    mutationFn: (variables?: CreateFieldTasksMutationVariables) =>
      fetcher<CreateFieldTasksMutation, CreateFieldTasksMutationVariables>(CreateFieldTasksDocument, variables)(),
    ...options,
  });
};

export const CreateFieldTaskDocument = `
    mutation createFieldTask($fieldTaskInput: FieldTaskInput!) {
  createFieldTask(fieldTaskInput: $fieldTaskInput) {
    success
    error {
      ...ErrorFieldTask
    }
  }
}
    ${ErrorFieldTaskFragmentDoc}`;

export const useCreateFieldTaskMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<CreateFieldTaskMutation, TError, CreateFieldTaskMutationVariables, TContext>,
) => {
  return useMutation<CreateFieldTaskMutation, TError, CreateFieldTaskMutationVariables, TContext>({
    mutationKey: ["createFieldTask"],
    mutationFn: (variables?: CreateFieldTaskMutationVariables) =>
      fetcher<CreateFieldTaskMutation, CreateFieldTaskMutationVariables>(CreateFieldTaskDocument, variables)(),
    ...options,
  });
};

export const DeleteFieldTaskDocument = `
    mutation deleteFieldTask($fieldTaskId: UUID!) {
  result: deleteFieldTask(fieldTaskId: $fieldTaskId) {
    success
  }
}
    `;

export const useDeleteFieldTaskMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<DeleteFieldTaskMutation, TError, DeleteFieldTaskMutationVariables, TContext>,
) => {
  return useMutation<DeleteFieldTaskMutation, TError, DeleteFieldTaskMutationVariables, TContext>({
    mutationKey: ["deleteFieldTask"],
    mutationFn: (variables?: DeleteFieldTaskMutationVariables) =>
      fetcher<DeleteFieldTaskMutation, DeleteFieldTaskMutationVariables>(DeleteFieldTaskDocument, variables)(),
    ...options,
  });
};

export const MoveUpFieldTaskDocument = `
    mutation moveUpFieldTask($fieldTaskId: UUID!) {
  result: moveFieldTaskUp(fieldTaskId: $fieldTaskId) {
    success
  }
}
    `;

export const useMoveUpFieldTaskMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<MoveUpFieldTaskMutation, TError, MoveUpFieldTaskMutationVariables, TContext>,
) => {
  return useMutation<MoveUpFieldTaskMutation, TError, MoveUpFieldTaskMutationVariables, TContext>({
    mutationKey: ["moveUpFieldTask"],
    mutationFn: (variables?: MoveUpFieldTaskMutationVariables) =>
      fetcher<MoveUpFieldTaskMutation, MoveUpFieldTaskMutationVariables>(MoveUpFieldTaskDocument, variables)(),
    ...options,
  });
};

export const MoveDownFieldTaskDocument = `
    mutation moveDownFieldTask($fieldTaskId: UUID!) {
  result: moveFieldTaskDown(fieldTaskId: $fieldTaskId) {
    success
  }
}
    `;

export const useMoveDownFieldTaskMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<MoveDownFieldTaskMutation, TError, MoveDownFieldTaskMutationVariables, TContext>,
) => {
  return useMutation<MoveDownFieldTaskMutation, TError, MoveDownFieldTaskMutationVariables, TContext>({
    mutationKey: ["moveDownFieldTask"],
    mutationFn: (variables?: MoveDownFieldTaskMutationVariables) =>
      fetcher<MoveDownFieldTaskMutation, MoveDownFieldTaskMutationVariables>(MoveDownFieldTaskDocument, variables)(),
    ...options,
  });
};

export const StartNowTaskDocument = `
    mutation startNowTask($fieldTaskId: UUID!) {
  result: startNowFieldTask(fieldTaskId: $fieldTaskId) {
    success
  }
}
    `;

export const useStartNowTaskMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<StartNowTaskMutation, TError, StartNowTaskMutationVariables, TContext>,
) => {
  return useMutation<StartNowTaskMutation, TError, StartNowTaskMutationVariables, TContext>({
    mutationKey: ["startNowTask"],
    mutationFn: (variables?: StartNowTaskMutationVariables) =>
      fetcher<StartNowTaskMutation, StartNowTaskMutationVariables>(StartNowTaskDocument, variables)(),
    ...options,
  });
};

export const FieldBasicDocument = `
    query fieldBasic($fieldId: UUID!) {
  field(fieldId: $fieldId) {
    ...FieldBasicModel
  }
}
    ${FieldBasicModelFragmentDoc}
${CoordinateFragmentDoc}`;

export const useFieldBasicQuery = <TData = FieldBasicQuery, TError = { message: string; code: ErrorCode }>(
  variables: FieldBasicQueryVariables,
  options?: Omit<UseQueryOptions<FieldBasicQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FieldBasicQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<FieldBasicQuery, TError, TData>({
    queryKey: ["fieldBasic", variables],
    queryFn: fetcher<FieldBasicQuery, FieldBasicQueryVariables>(FieldBasicDocument, variables),
    ...options,
  });
};

useFieldBasicQuery.document = FieldBasicDocument;

useFieldBasicQuery.getKey = (variables: FieldBasicQueryVariables) => ["fieldBasic", variables];

export const FieldDocument = `
    query field($fieldId: UUID!) {
  field(fieldId: $fieldId) {
    ...FieldModel
  }
}
    ${FieldModelFragmentDoc}
${FieldBasicModelFragmentDoc}
${CoordinateFragmentDoc}
${BlockModelFragmentDoc}
${GrowingPlanInfoRowFragmentDoc}
${BlocksSegmentFragmentDoc}`;

export const useFieldQuery = <TData = FieldQuery, TError = { message: string; code: ErrorCode }>(
  variables: FieldQueryVariables,
  options?: Omit<UseQueryOptions<FieldQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FieldQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<FieldQuery, TError, TData>({
    queryKey: ["field", variables],
    queryFn: fetcher<FieldQuery, FieldQueryVariables>(FieldDocument, variables),
    ...options,
  });
};

useFieldQuery.document = FieldDocument;

useFieldQuery.getKey = (variables: FieldQueryVariables) => ["field", variables];

export const BlockForDetailPageDocument = `
    query blockForDetailPage($blockId: UUID!) {
  block(blockId: $blockId) {
    ...BlockModel
    field {
      ...FieldBasicModel
    }
  }
  blockTasks: fieldTasks(blockId: $blockId) {
    nodes {
      ...FieldTaskCard
    }
    totalCount
    pageInfo {
      ...PageInfo
    }
  }
}
    ${BlockModelFragmentDoc}
${GrowingPlanInfoRowFragmentDoc}
${BlocksSegmentFragmentDoc}
${CoordinateFragmentDoc}
${FieldBasicModelFragmentDoc}
${FieldTaskCardFragmentDoc}
${PageInfoFragmentDoc}`;

export const useBlockForDetailPageQuery = <
  TData = BlockForDetailPageQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables: BlockForDetailPageQueryVariables,
  options?: Omit<UseQueryOptions<BlockForDetailPageQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<BlockForDetailPageQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<BlockForDetailPageQuery, TError, TData>({
    queryKey: ["blockForDetailPage", variables],
    queryFn: fetcher<BlockForDetailPageQuery, BlockForDetailPageQueryVariables>(BlockForDetailPageDocument, variables),
    ...options,
  });
};

useBlockForDetailPageQuery.document = BlockForDetailPageDocument;

useBlockForDetailPageQuery.getKey = (variables: BlockForDetailPageQueryVariables) => ["blockForDetailPage", variables];

export const UpdateFieldManualBoundariesDocument = `
    mutation updateFieldManualBoundaries($fieldId: UUID!, $input: ManualFieldBoundariesInput!) {
  updateFieldManualBoundaries(fieldId: $fieldId, input: $input) {
    success
  }
}
    `;

export const useUpdateFieldManualBoundariesMutation = <
  TError = { message: string; code: ErrorCode },
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateFieldManualBoundariesMutation,
    TError,
    UpdateFieldManualBoundariesMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateFieldManualBoundariesMutation,
    TError,
    UpdateFieldManualBoundariesMutationVariables,
    TContext
  >({
    mutationKey: ["updateFieldManualBoundaries"],
    mutationFn: (variables?: UpdateFieldManualBoundariesMutationVariables) =>
      fetcher<UpdateFieldManualBoundariesMutation, UpdateFieldManualBoundariesMutationVariables>(
        UpdateFieldManualBoundariesDocument,
        variables,
      )(),
    ...options,
  });
};

export const UpdateManualBlocksDocument = `
    mutation updateManualBlocks($fieldId: UUID!, $blocksInput: ManualBlocksInput!) {
  updateManualBlocks(fieldId: $fieldId, blocksInput: $blocksInput) {
    success
  }
}
    `;

export const useUpdateManualBlocksMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<UpdateManualBlocksMutation, TError, UpdateManualBlocksMutationVariables, TContext>,
) => {
  return useMutation<UpdateManualBlocksMutation, TError, UpdateManualBlocksMutationVariables, TContext>({
    mutationKey: ["updateManualBlocks"],
    mutationFn: (variables?: UpdateManualBlocksMutationVariables) =>
      fetcher<UpdateManualBlocksMutation, UpdateManualBlocksMutationVariables>(UpdateManualBlocksDocument, variables)(),
    ...options,
  });
};

export const UpdateSegmentBlocksDocument = `
    mutation updateSegmentBlocks($fieldId: UUID!, $input: SegmentBlocksInput!) {
  updateSegmentBlocks(fieldId: $fieldId, input: $input) {
    success
  }
}
    `;

export const useUpdateSegmentBlocksMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<UpdateSegmentBlocksMutation, TError, UpdateSegmentBlocksMutationVariables, TContext>,
) => {
  return useMutation<UpdateSegmentBlocksMutation, TError, UpdateSegmentBlocksMutationVariables, TContext>({
    mutationKey: ["updateSegmentBlocks"],
    mutationFn: (variables?: UpdateSegmentBlocksMutationVariables) =>
      fetcher<UpdateSegmentBlocksMutation, UpdateSegmentBlocksMutationVariables>(
        UpdateSegmentBlocksDocument,
        variables,
      )(),
    ...options,
  });
};

export const UpdateFieldEntryPointDocument = `
    mutation updateFieldEntryPoint($fieldId: UUID!, $entryPoint: CoordinatesInput!, $reverseDirection: Boolean) {
  updateFieldEntryPoint(
    fieldId: $fieldId
    entryPoint: $entryPoint
    reverseDirection: $reverseDirection
  ) {
    success
  }
}
    `;

export const useUpdateFieldEntryPointMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<UpdateFieldEntryPointMutation, TError, UpdateFieldEntryPointMutationVariables, TContext>,
) => {
  return useMutation<UpdateFieldEntryPointMutation, TError, UpdateFieldEntryPointMutationVariables, TContext>({
    mutationKey: ["updateFieldEntryPoint"],
    mutationFn: (variables?: UpdateFieldEntryPointMutationVariables) =>
      fetcher<UpdateFieldEntryPointMutation, UpdateFieldEntryPointMutationVariables>(
        UpdateFieldEntryPointDocument,
        variables,
      )(),
    ...options,
  });
};

export const UpdateFieldImportedBoundariesDocument = `
    mutation updateFieldImportedBoundaries($fieldId: UUID!, $gpsTraceId: UUID!) {
  updateFieldImportedBoundaries(fieldId: $fieldId, gpsTraceId: $gpsTraceId) {
    success
  }
}
    `;

export const useUpdateFieldImportedBoundariesMutation = <
  TError = { message: string; code: ErrorCode },
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateFieldImportedBoundariesMutation,
    TError,
    UpdateFieldImportedBoundariesMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateFieldImportedBoundariesMutation,
    TError,
    UpdateFieldImportedBoundariesMutationVariables,
    TContext
  >({
    mutationKey: ["updateFieldImportedBoundaries"],
    mutationFn: (variables?: UpdateFieldImportedBoundariesMutationVariables) =>
      fetcher<UpdateFieldImportedBoundariesMutation, UpdateFieldImportedBoundariesMutationVariables>(
        UpdateFieldImportedBoundariesDocument,
        variables,
      )(),
    ...options,
  });
};

export const BoundariesGpsTracesDocument = `
    query boundariesGpsTraces {
  boundariesGpsTraces {
    nodes {
      ...TraceFragment
    }
  }
}
    ${TraceFragmentFragmentDoc}
${CoordinateFragmentDoc}`;

export const useBoundariesGpsTracesQuery = <
  TData = BoundariesGpsTracesQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables?: BoundariesGpsTracesQueryVariables,
  options?: Omit<UseQueryOptions<BoundariesGpsTracesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<BoundariesGpsTracesQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<BoundariesGpsTracesQuery, TError, TData>({
    queryKey: variables === undefined ? ["boundariesGpsTraces"] : ["boundariesGpsTraces", variables],
    queryFn: fetcher<BoundariesGpsTracesQuery, BoundariesGpsTracesQueryVariables>(
      BoundariesGpsTracesDocument,
      variables,
    ),
    ...options,
  });
};

useBoundariesGpsTracesQuery.document = BoundariesGpsTracesDocument;

useBoundariesGpsTracesQuery.getKey = (variables?: BoundariesGpsTracesQueryVariables) =>
  variables === undefined ? ["boundariesGpsTraces"] : ["boundariesGpsTraces", variables];

export const FieldsDocument = `
    query fields($paginationInput: PaginationInput) {
  fields(paginationInput: $paginationInput) {
    nodes {
      ...FieldCard
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${FieldCardFragmentDoc}
${GrowingPlanInfoRowFragmentDoc}
${PageInfoFragmentDoc}`;

export const useFieldsQuery = <TData = FieldsQuery, TError = { message: string; code: ErrorCode }>(
  variables?: FieldsQueryVariables,
  options?: Omit<UseQueryOptions<FieldsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FieldsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<FieldsQuery, TError, TData>({
    queryKey: variables === undefined ? ["fields"] : ["fields", variables],
    queryFn: fetcher<FieldsQuery, FieldsQueryVariables>(FieldsDocument, variables),
    ...options,
  });
};

useFieldsQuery.document = FieldsDocument;

useFieldsQuery.getKey = (variables?: FieldsQueryVariables) =>
  variables === undefined ? ["fields"] : ["fields", variables];

export const DeleteFieldDocument = `
    mutation deleteField($fieldId: UUID!) {
  deleteField(fieldId: $fieldId) {
    success
  }
}
    `;

export const useDeleteFieldMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<DeleteFieldMutation, TError, DeleteFieldMutationVariables, TContext>,
) => {
  return useMutation<DeleteFieldMutation, TError, DeleteFieldMutationVariables, TContext>({
    mutationKey: ["deleteField"],
    mutationFn: (variables?: DeleteFieldMutationVariables) =>
      fetcher<DeleteFieldMutation, DeleteFieldMutationVariables>(DeleteFieldDocument, variables)(),
    ...options,
  });
};

export const HomeStationsDocument = `
    query homeStations($fieldId: UUID!) {
  homeStationGpsTraces(fieldId: $fieldId) {
    nodes {
      id
      createdAt
      name
      type
      state
      coordinates {
        ...Coordinate
      }
    }
  }
}
    ${CoordinateFragmentDoc}`;

export const useHomeStationsQuery = <TData = HomeStationsQuery, TError = { message: string; code: ErrorCode }>(
  variables: HomeStationsQueryVariables,
  options?: Omit<UseQueryOptions<HomeStationsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<HomeStationsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<HomeStationsQuery, TError, TData>({
    queryKey: ["homeStations", variables],
    queryFn: fetcher<HomeStationsQuery, HomeStationsQueryVariables>(HomeStationsDocument, variables),
    ...options,
  });
};

useHomeStationsQuery.document = HomeStationsDocument;

useHomeStationsQuery.getKey = (variables: HomeStationsQueryVariables) => ["homeStations", variables];

export const UpdateFieldHomeStationPathDocument = `
    mutation updateFieldHomeStationPath($fieldId: UUID!, $gpsTraceId: UUID) {
  updateFieldHomeStation(fieldId: $fieldId, gpsTraceId: $gpsTraceId) {
    success
  }
}
    `;

export const useUpdateFieldHomeStationPathMutation = <
  TError = { message: string; code: ErrorCode },
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateFieldHomeStationPathMutation,
    TError,
    UpdateFieldHomeStationPathMutationVariables,
    TContext
  >,
) => {
  return useMutation<UpdateFieldHomeStationPathMutation, TError, UpdateFieldHomeStationPathMutationVariables, TContext>(
    {
      mutationKey: ["updateFieldHomeStationPath"],
      mutationFn: (variables?: UpdateFieldHomeStationPathMutationVariables) =>
        fetcher<UpdateFieldHomeStationPathMutation, UpdateFieldHomeStationPathMutationVariables>(
          UpdateFieldHomeStationPathDocument,
          variables,
        )(),
      ...options,
    },
  );
};

export const SoilTypesDocument = `
    query soilTypes {
  soilTypes {
    name
    code
  }
}
    `;

export const useSoilTypesQuery = <TData = SoilTypesQuery, TError = { message: string; code: ErrorCode }>(
  variables?: SoilTypesQueryVariables,
  options?: Omit<UseQueryOptions<SoilTypesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<SoilTypesQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<SoilTypesQuery, TError, TData>({
    queryKey: variables === undefined ? ["soilTypes"] : ["soilTypes", variables],
    queryFn: fetcher<SoilTypesQuery, SoilTypesQueryVariables>(SoilTypesDocument, variables),
    ...options,
  });
};

useSoilTypesQuery.document = SoilTypesDocument;

useSoilTypesQuery.getKey = (variables?: SoilTypesQueryVariables) =>
  variables === undefined ? ["soilTypes"] : ["soilTypes", variables];

export const RobotAssignmentsDocument = `
    query robotAssignments {
  robotAssignments {
    id
    name
  }
}
    `;

export const useRobotAssignmentsQuery = <TData = RobotAssignmentsQuery, TError = { message: string; code: ErrorCode }>(
  variables?: RobotAssignmentsQueryVariables,
  options?: Omit<UseQueryOptions<RobotAssignmentsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<RobotAssignmentsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<RobotAssignmentsQuery, TError, TData>({
    queryKey: variables === undefined ? ["robotAssignments"] : ["robotAssignments", variables],
    queryFn: fetcher<RobotAssignmentsQuery, RobotAssignmentsQueryVariables>(RobotAssignmentsDocument, variables),
    ...options,
  });
};

useRobotAssignmentsQuery.document = RobotAssignmentsDocument;

useRobotAssignmentsQuery.getKey = (variables?: RobotAssignmentsQueryVariables) =>
  variables === undefined ? ["robotAssignments"] : ["robotAssignments", variables];

export const FieldForEditPageDocument = `
    query fieldForEditPage($fieldId: UUID!) {
  field(fieldId: $fieldId) {
    irrigation
    lighting
    name
    nitrogen
    ph
    phosphorus
    potassium
    soilStatus
    soilType
    robotAssignment {
      id
      name
    }
  }
}
    `;

export const useFieldForEditPageQuery = <TData = FieldForEditPageQuery, TError = { message: string; code: ErrorCode }>(
  variables: FieldForEditPageQueryVariables,
  options?: Omit<UseQueryOptions<FieldForEditPageQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FieldForEditPageQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<FieldForEditPageQuery, TError, TData>({
    queryKey: ["fieldForEditPage", variables],
    queryFn: fetcher<FieldForEditPageQuery, FieldForEditPageQueryVariables>(FieldForEditPageDocument, variables),
    ...options,
  });
};

useFieldForEditPageQuery.document = FieldForEditPageDocument;

useFieldForEditPageQuery.getKey = (variables: FieldForEditPageQueryVariables) => ["fieldForEditPage", variables];

export const CreateFieldDocument = `
    mutation createField($fieldInput: FieldCreateInput!) {
  createField(fieldInput: $fieldInput) {
    success
    field {
      id
    }
  }
}
    `;

export const useCreateFieldMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<CreateFieldMutation, TError, CreateFieldMutationVariables, TContext>,
) => {
  return useMutation<CreateFieldMutation, TError, CreateFieldMutationVariables, TContext>({
    mutationKey: ["createField"],
    mutationFn: (variables?: CreateFieldMutationVariables) =>
      fetcher<CreateFieldMutation, CreateFieldMutationVariables>(CreateFieldDocument, variables)(),
    ...options,
  });
};

export const UpdateFieldDocument = `
    mutation updateField($fieldId: UUID!, $fieldInput: FieldInput!) {
  updateField(fieldId: $fieldId, fieldInput: $fieldInput) {
    success
  }
}
    `;

export const useUpdateFieldMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<UpdateFieldMutation, TError, UpdateFieldMutationVariables, TContext>,
) => {
  return useMutation<UpdateFieldMutation, TError, UpdateFieldMutationVariables, TContext>({
    mutationKey: ["updateField"],
    mutationFn: (variables?: UpdateFieldMutationVariables) =>
      fetcher<UpdateFieldMutation, UpdateFieldMutationVariables>(UpdateFieldDocument, variables)(),
    ...options,
  });
};

export const PreviewSegmentsDocument = `
    query previewSegments($swathsIds: [UUID!]!, $gap: Float, $length: Float) {
  previewSegments(swaths: $swathsIds, gap: $gap, length: $length) {
    swathId
    segments {
      name
      boundaries {
        ...Coordinate
      }
      startPoint {
        ...Coordinate
      }
      endPoint {
        ...Coordinate
      }
    }
  }
}
    ${CoordinateFragmentDoc}`;

export const usePreviewSegmentsQuery = <TData = PreviewSegmentsQuery, TError = { message: string; code: ErrorCode }>(
  variables: PreviewSegmentsQueryVariables,
  options?: Omit<UseQueryOptions<PreviewSegmentsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<PreviewSegmentsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<PreviewSegmentsQuery, TError, TData>({
    queryKey: ["previewSegments", variables],
    queryFn: fetcher<PreviewSegmentsQuery, PreviewSegmentsQueryVariables>(PreviewSegmentsDocument, variables),
    ...options,
  });
};

usePreviewSegmentsQuery.document = PreviewSegmentsDocument;

usePreviewSegmentsQuery.getKey = (variables: PreviewSegmentsQueryVariables) => ["previewSegments", variables];

export const CreateSegmentsDocument = `
    mutation createSegments($fieldId: UUID!, $segments: [SwathSegmentInput!]!) {
  createSegments(fieldId: $fieldId, swathSegments: $segments) {
    success
  }
}
    `;

export const useCreateSegmentsMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<CreateSegmentsMutation, TError, CreateSegmentsMutationVariables, TContext>,
) => {
  return useMutation<CreateSegmentsMutation, TError, CreateSegmentsMutationVariables, TContext>({
    mutationKey: ["createSegments"],
    mutationFn: (variables?: CreateSegmentsMutationVariables) =>
      fetcher<CreateSegmentsMutation, CreateSegmentsMutationVariables>(CreateSegmentsDocument, variables)(),
    ...options,
  });
};

export const PreviewSwathsDocument = `
    query previewSwaths($fieldId: UUID!, $angle: Float, $refPathSegmentId: Int) {
  previewSwaths(
    fieldId: $fieldId
    angle: $angle
    refPathSegmentId: $refPathSegmentId
  ) {
    swaths {
      area
      order
      startPoint {
        ...Coordinate
      }
      endPoint {
        ...Coordinate
      }
    }
    headland {
      ...Coordinate
    }
    autoOptimalPaths {
      id
      length
      startPoint {
        ...Coordinate
      }
      endPoint {
        ...Coordinate
      }
    }
  }
}
    ${CoordinateFragmentDoc}`;

export const usePreviewSwathsQuery = <TData = PreviewSwathsQuery, TError = { message: string; code: ErrorCode }>(
  variables: PreviewSwathsQueryVariables,
  options?: Omit<UseQueryOptions<PreviewSwathsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<PreviewSwathsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<PreviewSwathsQuery, TError, TData>({
    queryKey: ["previewSwaths", variables],
    queryFn: fetcher<PreviewSwathsQuery, PreviewSwathsQueryVariables>(PreviewSwathsDocument, variables),
    ...options,
  });
};

usePreviewSwathsQuery.document = PreviewSwathsDocument;

usePreviewSwathsQuery.getKey = (variables: PreviewSwathsQueryVariables) => ["previewSwaths", variables];

export const CreateSwathsDocument = `
    mutation createSwaths($fieldId: UUID!, $refPathSegmentId: Int, $angle: Float) {
  createSwaths(
    fieldId: $fieldId
    refPathSegmentId: $refPathSegmentId
    angle: $angle
  ) {
    success
  }
}
    `;

export const useCreateSwathsMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<CreateSwathsMutation, TError, CreateSwathsMutationVariables, TContext>,
) => {
  return useMutation<CreateSwathsMutation, TError, CreateSwathsMutationVariables, TContext>({
    mutationKey: ["createSwaths"],
    mutationFn: (variables?: CreateSwathsMutationVariables) =>
      fetcher<CreateSwathsMutation, CreateSwathsMutationVariables>(CreateSwathsDocument, variables)(),
    ...options,
  });
};

export const DataForCreateGrowingPlanBaseInfoContentDocument = `
    query dataForCreateGrowingPlanBaseInfoContent {
  cropSheetList {
    crops {
      id
      commonName
      variant
    }
  }
  fields(paginationInput: {page: 1, perPage: null}) {
    nodes {
      id
      name
      growingPlannerState
    }
  }
}
    `;

export const useDataForCreateGrowingPlanBaseInfoContentQuery = <
  TData = DataForCreateGrowingPlanBaseInfoContentQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables?: DataForCreateGrowingPlanBaseInfoContentQueryVariables,
  options?: Omit<UseQueryOptions<DataForCreateGrowingPlanBaseInfoContentQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<DataForCreateGrowingPlanBaseInfoContentQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<DataForCreateGrowingPlanBaseInfoContentQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ["dataForCreateGrowingPlanBaseInfoContent"]
        : ["dataForCreateGrowingPlanBaseInfoContent", variables],
    queryFn: fetcher<
      DataForCreateGrowingPlanBaseInfoContentQuery,
      DataForCreateGrowingPlanBaseInfoContentQueryVariables
    >(DataForCreateGrowingPlanBaseInfoContentDocument, variables),
    ...options,
  });
};

useDataForCreateGrowingPlanBaseInfoContentQuery.document = DataForCreateGrowingPlanBaseInfoContentDocument;

useDataForCreateGrowingPlanBaseInfoContentQuery.getKey = (
  variables?: DataForCreateGrowingPlanBaseInfoContentQueryVariables,
) =>
  variables === undefined
    ? ["dataForCreateGrowingPlanBaseInfoContent"]
    : ["dataForCreateGrowingPlanBaseInfoContent", variables];

export const FieldBlocksForCreateGrowingPlanBaseInfoContentDocument = `
    query fieldBlocksForCreateGrowingPlanBaseInfoContent($fieldId: UUID!) {
  field(fieldId: $fieldId) {
    id
    blocks {
      id
      name
      routePlannerState
    }
  }
}
    `;

export const useFieldBlocksForCreateGrowingPlanBaseInfoContentQuery = <
  TData = FieldBlocksForCreateGrowingPlanBaseInfoContentQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables: FieldBlocksForCreateGrowingPlanBaseInfoContentQueryVariables,
  options?: Omit<UseQueryOptions<FieldBlocksForCreateGrowingPlanBaseInfoContentQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FieldBlocksForCreateGrowingPlanBaseInfoContentQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<FieldBlocksForCreateGrowingPlanBaseInfoContentQuery, TError, TData>({
    queryKey: ["fieldBlocksForCreateGrowingPlanBaseInfoContent", variables],
    queryFn: fetcher<
      FieldBlocksForCreateGrowingPlanBaseInfoContentQuery,
      FieldBlocksForCreateGrowingPlanBaseInfoContentQueryVariables
    >(FieldBlocksForCreateGrowingPlanBaseInfoContentDocument, variables),
    ...options,
  });
};

useFieldBlocksForCreateGrowingPlanBaseInfoContentQuery.document =
  FieldBlocksForCreateGrowingPlanBaseInfoContentDocument;

useFieldBlocksForCreateGrowingPlanBaseInfoContentQuery.getKey = (
  variables: FieldBlocksForCreateGrowingPlanBaseInfoContentQueryVariables,
) => ["fieldBlocksForCreateGrowingPlanBaseInfoContent", variables];

export const GrowingPlanPreviewDocument = `
    query growingPlanPreview($cropSheetId: UUID!, $blockId: UUID!, $startAt: Date!) {
  growingPlanPreview(
    startAt: $startAt
    blockId: $blockId
    cropSheetId: $cropSheetId
  ) {
    endAt
    planDuration
    potentialYield
    expectedYield
    expectedYieldPercentage
  }
}
    `;

export const useGrowingPlanPreviewQuery = <
  TData = GrowingPlanPreviewQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables: GrowingPlanPreviewQueryVariables,
  options?: Omit<UseQueryOptions<GrowingPlanPreviewQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GrowingPlanPreviewQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<GrowingPlanPreviewQuery, TError, TData>({
    queryKey: ["growingPlanPreview", variables],
    queryFn: fetcher<GrowingPlanPreviewQuery, GrowingPlanPreviewQueryVariables>(GrowingPlanPreviewDocument, variables),
    ...options,
  });
};

useGrowingPlanPreviewQuery.document = GrowingPlanPreviewDocument;

useGrowingPlanPreviewQuery.getKey = (variables: GrowingPlanPreviewQueryVariables) => ["growingPlanPreview", variables];

export const CreateGrowingPlanDocument = `
    mutation createGrowingPlan($cropSheetId: UUID!, $blockId: UUID!, $startAt: Date!) {
  createGrowingPlan(
    cropSheetId: $cropSheetId
    blockId: $blockId
    startAt: $startAt
  ) {
    success
  }
}
    `;

export const useCreateGrowingPlanMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<CreateGrowingPlanMutation, TError, CreateGrowingPlanMutationVariables, TContext>,
) => {
  return useMutation<CreateGrowingPlanMutation, TError, CreateGrowingPlanMutationVariables, TContext>({
    mutationKey: ["createGrowingPlan"],
    mutationFn: (variables?: CreateGrowingPlanMutationVariables) =>
      fetcher<CreateGrowingPlanMutation, CreateGrowingPlanMutationVariables>(CreateGrowingPlanDocument, variables)(),
    ...options,
  });
};

export const GrowingPlanYearPreviewDocument = `
    query growingPlanYearPreview($cropSheetId: UUID!, $blockId: UUID!) {
  growingPlanYearPreview(blockId: $blockId, cropSheetId: $cropSheetId) {
    date
    yieldPercentage
  }
}
    `;

export const useGrowingPlanYearPreviewQuery = <
  TData = GrowingPlanYearPreviewQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables: GrowingPlanYearPreviewQueryVariables,
  options?: Omit<UseQueryOptions<GrowingPlanYearPreviewQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GrowingPlanYearPreviewQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<GrowingPlanYearPreviewQuery, TError, TData>({
    queryKey: ["growingPlanYearPreview", variables],
    queryFn: fetcher<GrowingPlanYearPreviewQuery, GrowingPlanYearPreviewQueryVariables>(
      GrowingPlanYearPreviewDocument,
      variables,
    ),
    ...options,
  });
};

useGrowingPlanYearPreviewQuery.document = GrowingPlanYearPreviewDocument;

useGrowingPlanYearPreviewQuery.getKey = (variables: GrowingPlanYearPreviewQueryVariables) => [
  "growingPlanYearPreview",
  variables,
];

export const GrowingPlanDocument = `
    query growingPlan($growingPlanId: UUID!) {
  growingPlan(growingPlanId: $growingPlanId) {
    id
    name
    state
    createdAt
    startAt
    endAt
    planDuration
    planProgress
    planScheduleInDays
    planProgressInDays
    expectedYield
    expectedYieldPercentage
    cropSheet {
      id
      commonName
      variant
    }
    block {
      id
      name
      field {
        id
        name
      }
    }
    robot {
      id
      name
    }
    conditionsSummary {
      type
      yieldDecrement
      yieldDecrementPercentage
    }
    conditions {
      ...GrowingPlanCondition
    }
    field {
      cultivation
    }
    stages {
      id
      duration
      order
      name
      startAt
      endAt
      description
      continuous
      currentStage
      conditions {
        ...GrowingPlanCondition
      }
    }
  }
}
    ${GrowingPlanConditionFragmentDoc}
${FloatValueStringValueFragmentDoc}`;

export const useGrowingPlanQuery = <TData = GrowingPlanQuery, TError = { message: string; code: ErrorCode }>(
  variables: GrowingPlanQueryVariables,
  options?: Omit<UseQueryOptions<GrowingPlanQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GrowingPlanQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<GrowingPlanQuery, TError, TData>({
    queryKey: ["growingPlan", variables],
    queryFn: fetcher<GrowingPlanQuery, GrowingPlanQueryVariables>(GrowingPlanDocument, variables),
    ...options,
  });
};

useGrowingPlanQuery.document = GrowingPlanDocument;

useGrowingPlanQuery.getKey = (variables: GrowingPlanQueryVariables) => ["growingPlan", variables];

export const GrowingPlanTasksDocument = `
    query growingPlanTasks($startAt: DateTime!, $growingPlanId: UUID!, $paginationInput: PaginationInput!) {
  tasks: fieldTasks(
    startAt: $startAt
    growingPlanId: $growingPlanId
    paginationInput: $paginationInput
  ) {
    ...FieldTasksList
  }
}
    ${FieldTasksListFragmentDoc}
${FieldTaskCardFragmentDoc}
${PageInfoFragmentDoc}`;

export const useGrowingPlanTasksQuery = <TData = GrowingPlanTasksQuery, TError = { message: string; code: ErrorCode }>(
  variables: GrowingPlanTasksQueryVariables,
  options?: Omit<UseQueryOptions<GrowingPlanTasksQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GrowingPlanTasksQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<GrowingPlanTasksQuery, TError, TData>({
    queryKey: ["growingPlanTasks", variables],
    queryFn: fetcher<GrowingPlanTasksQuery, GrowingPlanTasksQueryVariables>(GrowingPlanTasksDocument, variables),
    ...options,
  });
};

useGrowingPlanTasksQuery.document = GrowingPlanTasksDocument;

useGrowingPlanTasksQuery.getKey = (variables: GrowingPlanTasksQueryVariables) => ["growingPlanTasks", variables];

export const UpdateGrowingPlanStartAtDocument = `
    mutation updateGrowingPlanStartAt($growingPlanId: UUID!, $startAt: Date!) {
  updateGrowingPlanStartAt(growingPlanId: $growingPlanId, startAt: $startAt) {
    success
  }
}
    `;

export const useUpdateGrowingPlanStartAtMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateGrowingPlanStartAtMutation,
    TError,
    UpdateGrowingPlanStartAtMutationVariables,
    TContext
  >,
) => {
  return useMutation<UpdateGrowingPlanStartAtMutation, TError, UpdateGrowingPlanStartAtMutationVariables, TContext>({
    mutationKey: ["updateGrowingPlanStartAt"],
    mutationFn: (variables?: UpdateGrowingPlanStartAtMutationVariables) =>
      fetcher<UpdateGrowingPlanStartAtMutation, UpdateGrowingPlanStartAtMutationVariables>(
        UpdateGrowingPlanStartAtDocument,
        variables,
      )(),
    ...options,
  });
};

export const FinishGrowingPlanDocument = `
    mutation finishGrowingPlan($growingPlanId: UUID!) {
  finishGrowingPlan(growingPlanId: $growingPlanId) {
    success
  }
}
    `;

export const useFinishGrowingPlanMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<FinishGrowingPlanMutation, TError, FinishGrowingPlanMutationVariables, TContext>,
) => {
  return useMutation<FinishGrowingPlanMutation, TError, FinishGrowingPlanMutationVariables, TContext>({
    mutationKey: ["finishGrowingPlan"],
    mutationFn: (variables?: FinishGrowingPlanMutationVariables) =>
      fetcher<FinishGrowingPlanMutation, FinishGrowingPlanMutationVariables>(FinishGrowingPlanDocument, variables)(),
    ...options,
  });
};

export const PreviewRowBasedPlantSpacingDocument = `
    query previewRowBasedPlantSpacing($growingPlanId: UUID!, $input: RowBasedPlantSpacingInput!) {
  previewRowBasedPlantSpacing(growingPlanId: $growingPlanId, input: $input) {
    ...PlantSpacingPreview
  }
}
    ${PlantSpacingPreviewFragmentDoc}
${CoordinateFragmentDoc}`;

export const usePreviewRowBasedPlantSpacingQuery = <
  TData = PreviewRowBasedPlantSpacingQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables: PreviewRowBasedPlantSpacingQueryVariables,
  options?: Omit<UseQueryOptions<PreviewRowBasedPlantSpacingQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<PreviewRowBasedPlantSpacingQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<PreviewRowBasedPlantSpacingQuery, TError, TData>({
    queryKey: ["previewRowBasedPlantSpacing", variables],
    queryFn: fetcher<PreviewRowBasedPlantSpacingQuery, PreviewRowBasedPlantSpacingQueryVariables>(
      PreviewRowBasedPlantSpacingDocument,
      variables,
    ),
    ...options,
  });
};

usePreviewRowBasedPlantSpacingQuery.document = PreviewRowBasedPlantSpacingDocument;

usePreviewRowBasedPlantSpacingQuery.getKey = (variables: PreviewRowBasedPlantSpacingQueryVariables) => [
  "previewRowBasedPlantSpacing",
  variables,
];

export const PreviewPlantRowsDocument = `
    query previewPlantRows($growingPlanId: UUID!, $input: PlantRowsInput!) {
  previewPlantRows(growingPlanId: $growingPlanId, input: $input) {
    rows
  }
}
    `;

export const usePreviewPlantRowsQuery = <TData = PreviewPlantRowsQuery, TError = { message: string; code: ErrorCode }>(
  variables: PreviewPlantRowsQueryVariables,
  options?: Omit<UseQueryOptions<PreviewPlantRowsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<PreviewPlantRowsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<PreviewPlantRowsQuery, TError, TData>({
    queryKey: ["previewPlantRows", variables],
    queryFn: fetcher<PreviewPlantRowsQuery, PreviewPlantRowsQueryVariables>(PreviewPlantRowsDocument, variables),
    ...options,
  });
};

usePreviewPlantRowsQuery.document = PreviewPlantRowsDocument;

usePreviewPlantRowsQuery.getKey = (variables: PreviewPlantRowsQueryVariables) => ["previewPlantRows", variables];

export const PlantSpacingDocument = `
    query plantSpacing($growingPlanId: UUID!) {
  growingPlan(growingPlanId: $growingPlanId) {
    ...PlantSpacingGrowingPlan
  }
}
    ${PlantSpacingGrowingPlanFragmentDoc}
${CoordinateFragmentDoc}
${PlantSpacingFragmentDoc}`;

export const usePlantSpacingQuery = <TData = PlantSpacingQuery, TError = { message: string; code: ErrorCode }>(
  variables: PlantSpacingQueryVariables,
  options?: Omit<UseQueryOptions<PlantSpacingQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<PlantSpacingQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<PlantSpacingQuery, TError, TData>({
    queryKey: ["plantSpacing", variables],
    queryFn: fetcher<PlantSpacingQuery, PlantSpacingQueryVariables>(PlantSpacingDocument, variables),
    ...options,
  });
};

usePlantSpacingQuery.document = PlantSpacingDocument;

usePlantSpacingQuery.getKey = (variables: PlantSpacingQueryVariables) => ["plantSpacing", variables];

export const PreviewPlantSpacingDocument = `
    query previewPlantSpacing($growingPlanId: UUID!, $plantSpacingInput: CustomPlantSpacingInput!) {
  previewCustomPlantSpacing(
    growingPlanId: $growingPlanId
    input: $plantSpacingInput
  ) {
    ...PlantSpacingPreview
  }
}
    ${PlantSpacingPreviewFragmentDoc}
${CoordinateFragmentDoc}`;

export const usePreviewPlantSpacingQuery = <
  TData = PreviewPlantSpacingQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables: PreviewPlantSpacingQueryVariables,
  options?: Omit<UseQueryOptions<PreviewPlantSpacingQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<PreviewPlantSpacingQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<PreviewPlantSpacingQuery, TError, TData>({
    queryKey: ["previewPlantSpacing", variables],
    queryFn: fetcher<PreviewPlantSpacingQuery, PreviewPlantSpacingQueryVariables>(
      PreviewPlantSpacingDocument,
      variables,
    ),
    ...options,
  });
};

usePreviewPlantSpacingQuery.document = PreviewPlantSpacingDocument;

usePreviewPlantSpacingQuery.getKey = (variables: PreviewPlantSpacingQueryVariables) => [
  "previewPlantSpacing",
  variables,
];

export const CreatePlantSpacingDocument = `
    mutation createPlantSpacing($growingPlanId: UUID!, $plantSpacingInput: RowBasedPlantSpacingInput!) {
  createRowBasedPlantSpacing(
    growingPlanId: $growingPlanId
    input: $plantSpacingInput
  ) {
    ...PlantSpacing
  }
}
    ${PlantSpacingFragmentDoc}
${CoordinateFragmentDoc}`;

export const useCreatePlantSpacingMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<CreatePlantSpacingMutation, TError, CreatePlantSpacingMutationVariables, TContext>,
) => {
  return useMutation<CreatePlantSpacingMutation, TError, CreatePlantSpacingMutationVariables, TContext>({
    mutationKey: ["createPlantSpacing"],
    mutationFn: (variables?: CreatePlantSpacingMutationVariables) =>
      fetcher<CreatePlantSpacingMutation, CreatePlantSpacingMutationVariables>(CreatePlantSpacingDocument, variables)(),
    ...options,
  });
};

export const DeletePlantSpacingDocument = `
    mutation deletePlantSpacing($plantSpacingId: UUID!) {
  deletePlantSpacing(plantSpacingId: $plantSpacingId) {
    ...PlantSpacingGrowingPlan
  }
}
    ${PlantSpacingGrowingPlanFragmentDoc}
${CoordinateFragmentDoc}
${PlantSpacingFragmentDoc}`;

export const useDeletePlantSpacingMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<DeletePlantSpacingMutation, TError, DeletePlantSpacingMutationVariables, TContext>,
) => {
  return useMutation<DeletePlantSpacingMutation, TError, DeletePlantSpacingMutationVariables, TContext>({
    mutationKey: ["deletePlantSpacing"],
    mutationFn: (variables?: DeletePlantSpacingMutationVariables) =>
      fetcher<DeletePlantSpacingMutation, DeletePlantSpacingMutationVariables>(DeletePlantSpacingDocument, variables)(),
    ...options,
  });
};

export const GrowingPlansDocument = `
    query growingPlans($paginationInput: PaginationInput!, $filters: GrowingPlanFilter, $order: GrowingPlanOrder) {
  growingPlanFilter(
    paginationInput: $paginationInput
    filters: $filters
    order: $order
  ) {
    nodes {
      ...GrowingPlanCard
      plantSpacing {
        selectedRows
      }
    }
    totalCount
    pageInfo {
      ...PageInfo
    }
  }
}
    ${GrowingPlanCardFragmentDoc}
${PageInfoFragmentDoc}`;

export const useGrowingPlansQuery = <TData = GrowingPlansQuery, TError = { message: string; code: ErrorCode }>(
  variables: GrowingPlansQueryVariables,
  options?: Omit<UseQueryOptions<GrowingPlansQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GrowingPlansQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<GrowingPlansQuery, TError, TData>({
    queryKey: ["growingPlans", variables],
    queryFn: fetcher<GrowingPlansQuery, GrowingPlansQueryVariables>(GrowingPlansDocument, variables),
    ...options,
  });
};

useGrowingPlansQuery.document = GrowingPlansDocument;

useGrowingPlansQuery.getKey = (variables: GrowingPlansQueryVariables) => ["growingPlans", variables];

export const BlocksSuggestionsDocument = `
    query blocksSuggestions($filters: GrowingPlanFilter) {
  suggestion: growingPlanFilterBlocks(filters: $filters) {
    id
    name
  }
}
    `;

export const useBlocksSuggestionsQuery = <
  TData = BlocksSuggestionsQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables?: BlocksSuggestionsQueryVariables,
  options?: Omit<UseQueryOptions<BlocksSuggestionsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<BlocksSuggestionsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<BlocksSuggestionsQuery, TError, TData>({
    queryKey: variables === undefined ? ["blocksSuggestions"] : ["blocksSuggestions", variables],
    queryFn: fetcher<BlocksSuggestionsQuery, BlocksSuggestionsQueryVariables>(BlocksSuggestionsDocument, variables),
    ...options,
  });
};

useBlocksSuggestionsQuery.document = BlocksSuggestionsDocument;

useBlocksSuggestionsQuery.getKey = (variables?: BlocksSuggestionsQueryVariables) =>
  variables === undefined ? ["blocksSuggestions"] : ["blocksSuggestions", variables];

export const FieldsSuggestionsDocument = `
    query fieldsSuggestions($filters: GrowingPlanFilter) {
  suggestion: growingPlanFilterFields(filters: $filters) {
    id
    name
  }
}
    `;

export const useFieldsSuggestionsQuery = <
  TData = FieldsSuggestionsQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables?: FieldsSuggestionsQueryVariables,
  options?: Omit<UseQueryOptions<FieldsSuggestionsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FieldsSuggestionsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<FieldsSuggestionsQuery, TError, TData>({
    queryKey: variables === undefined ? ["fieldsSuggestions"] : ["fieldsSuggestions", variables],
    queryFn: fetcher<FieldsSuggestionsQuery, FieldsSuggestionsQueryVariables>(FieldsSuggestionsDocument, variables),
    ...options,
  });
};

useFieldsSuggestionsQuery.document = FieldsSuggestionsDocument;

useFieldsSuggestionsQuery.getKey = (variables?: FieldsSuggestionsQueryVariables) =>
  variables === undefined ? ["fieldsSuggestions"] : ["fieldsSuggestions", variables];

export const CropsSuggestionsDocument = `
    query cropsSuggestions($filters: GrowingPlanFilter) {
  suggestion: growingPlanFilterCrops(filters: $filters) {
    id
    commonName
    combinedName
  }
}
    `;

export const useCropsSuggestionsQuery = <TData = CropsSuggestionsQuery, TError = { message: string; code: ErrorCode }>(
  variables?: CropsSuggestionsQueryVariables,
  options?: Omit<UseQueryOptions<CropsSuggestionsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<CropsSuggestionsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<CropsSuggestionsQuery, TError, TData>({
    queryKey: variables === undefined ? ["cropsSuggestions"] : ["cropsSuggestions", variables],
    queryFn: fetcher<CropsSuggestionsQuery, CropsSuggestionsQueryVariables>(CropsSuggestionsDocument, variables),
    ...options,
  });
};

useCropsSuggestionsQuery.document = CropsSuggestionsDocument;

useCropsSuggestionsQuery.getKey = (variables?: CropsSuggestionsQueryVariables) =>
  variables === undefined ? ["cropsSuggestions"] : ["cropsSuggestions", variables];

export const CropsForGrowingPlansPageDocument = `
    query cropsForGrowingPlansPage {
  cropSheetList {
    crops {
      id
      commonName
    }
  }
}
    `;

export const useCropsForGrowingPlansPageQuery = <
  TData = CropsForGrowingPlansPageQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables?: CropsForGrowingPlansPageQueryVariables,
  options?: Omit<UseQueryOptions<CropsForGrowingPlansPageQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<CropsForGrowingPlansPageQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<CropsForGrowingPlansPageQuery, TError, TData>({
    queryKey: variables === undefined ? ["cropsForGrowingPlansPage"] : ["cropsForGrowingPlansPage", variables],
    queryFn: fetcher<CropsForGrowingPlansPageQuery, CropsForGrowingPlansPageQueryVariables>(
      CropsForGrowingPlansPageDocument,
      variables,
    ),
    ...options,
  });
};

useCropsForGrowingPlansPageQuery.document = CropsForGrowingPlansPageDocument;

useCropsForGrowingPlansPageQuery.getKey = (variables?: CropsForGrowingPlansPageQueryVariables) =>
  variables === undefined ? ["cropsForGrowingPlansPage"] : ["cropsForGrowingPlansPage", variables];

export const DeleteGrowingPlanDocument = `
    mutation deleteGrowingPlan($growingPlanId: UUID!) {
  deleteGrowingPlan(growingPlanId: $growingPlanId) {
    success
  }
}
    `;

export const useDeleteGrowingPlanMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<DeleteGrowingPlanMutation, TError, DeleteGrowingPlanMutationVariables, TContext>,
) => {
  return useMutation<DeleteGrowingPlanMutation, TError, DeleteGrowingPlanMutationVariables, TContext>({
    mutationKey: ["deleteGrowingPlan"],
    mutationFn: (variables?: DeleteGrowingPlanMutationVariables) =>
      fetcher<DeleteGrowingPlanMutation, DeleteGrowingPlanMutationVariables>(DeleteGrowingPlanDocument, variables)(),
    ...options,
  });
};

export const RegisterDocument = `
    mutation register($input: UserRegisterInput!) {
  register(userRegistrationInput: $input)
}
    `;

export const useRegisterMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<RegisterMutation, TError, RegisterMutationVariables, TContext>,
) => {
  return useMutation<RegisterMutation, TError, RegisterMutationVariables, TContext>({
    mutationKey: ["register"],
    mutationFn: (variables?: RegisterMutationVariables) =>
      fetcher<RegisterMutation, RegisterMutationVariables>(RegisterDocument, variables)(),
    ...options,
  });
};

export const RobotsDocument = `
    query robots {
  robotAssignments {
    id
    name
    robot {
      serialNumber
    }
  }
}
    `;

export const useRobotsQuery = <TData = RobotsQuery, TError = { message: string; code: ErrorCode }>(
  variables?: RobotsQueryVariables,
  options?: Omit<UseQueryOptions<RobotsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<RobotsQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<RobotsQuery, TError, TData>({
    queryKey: variables === undefined ? ["robots"] : ["robots", variables],
    queryFn: fetcher<RobotsQuery, RobotsQueryVariables>(RobotsDocument, variables),
    ...options,
  });
};

useRobotsQuery.document = RobotsDocument;

useRobotsQuery.getKey = (variables?: RobotsQueryVariables) =>
  variables === undefined ? ["robots"] : ["robots", variables];

export const RobotDocument = `
    query robot($robotAssignmentId: UUID!) {
  robotAssignment(robotAssignmentId: $robotAssignmentId) {
    id
    name
    robot {
      id
      serialNumber
      robotWidth
      trackWidth
      stateReport {
        battery {
          estimEnergy
          chargingState
        }
        general {
          status
          devices {
            type
            active
            error
          }
        }
        position {
          lat
          lon
          speed
          dist
        }
        target {
          lat
          lon
        }
        tool {
          type
          mode
        }
      }
    }
    createdAt
    registeredAt
  }
}
    `;

export const useRobotQuery = <TData = RobotQuery, TError = { message: string; code: ErrorCode }>(
  variables: RobotQueryVariables,
  options?: Omit<UseQueryOptions<RobotQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<RobotQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<RobotQuery, TError, TData>({
    queryKey: ["robot", variables],
    queryFn: fetcher<RobotQuery, RobotQueryVariables>(RobotDocument, variables),
    ...options,
  });
};

useRobotQuery.document = RobotDocument;

useRobotQuery.getKey = (variables: RobotQueryVariables) => ["robot", variables];

export const RobotTasksDocument = `
    query robotTasks($robotAssignmentId: UUID!, $paginationInput: PaginationInput!, $startAt: DateTime, $growingPlanId: UUID) {
  tasks: fieldTasks(
    robotAssignmentId: $robotAssignmentId
    paginationInput: $paginationInput
    growingPlanId: $growingPlanId
    startAt: $startAt
  ) {
    ...FieldTasksList
  }
}
    ${FieldTasksListFragmentDoc}
${FieldTaskCardFragmentDoc}
${PageInfoFragmentDoc}`;

export const useRobotTasksQuery = <TData = RobotTasksQuery, TError = { message: string; code: ErrorCode }>(
  variables: RobotTasksQueryVariables,
  options?: Omit<UseQueryOptions<RobotTasksQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<RobotTasksQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<RobotTasksQuery, TError, TData>({
    queryKey: ["robotTasks", variables],
    queryFn: fetcher<RobotTasksQuery, RobotTasksQueryVariables>(RobotTasksDocument, variables),
    ...options,
  });
};

useRobotTasksQuery.document = RobotTasksDocument;

useRobotTasksQuery.getKey = (variables: RobotTasksQueryVariables) => ["robotTasks", variables];

export const RegisterRobotDocument = `
    mutation registerRobot($robotAssignmentInput: RobotAssignmentInput!) {
  registerRobot(robotAssignmentInput: $robotAssignmentInput) {
    success
  }
}
    `;

export const useRegisterRobotMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<RegisterRobotMutation, TError, RegisterRobotMutationVariables, TContext>,
) => {
  return useMutation<RegisterRobotMutation, TError, RegisterRobotMutationVariables, TContext>({
    mutationKey: ["registerRobot"],
    mutationFn: (variables?: RegisterRobotMutationVariables) =>
      fetcher<RegisterRobotMutation, RegisterRobotMutationVariables>(RegisterRobotDocument, variables)(),
    ...options,
  });
};

export const DecommissionRobotDocument = `
    mutation decommissionRobot($robotId: UUID!) {
  decommissionRobot(robotAssignmentId: $robotId) {
    success
  }
}
    `;

export const useDecommissionRobotMutation = <TError = { message: string; code: ErrorCode }, TContext = unknown>(
  options?: UseMutationOptions<DecommissionRobotMutation, TError, DecommissionRobotMutationVariables, TContext>,
) => {
  return useMutation<DecommissionRobotMutation, TError, DecommissionRobotMutationVariables, TContext>({
    mutationKey: ["decommissionRobot"],
    mutationFn: (variables?: DecommissionRobotMutationVariables) =>
      fetcher<DecommissionRobotMutation, DecommissionRobotMutationVariables>(DecommissionRobotDocument, variables)(),
    ...options,
  });
};

export const AllRobotsGrowingPlansDocument = `
    query allRobotsGrowingPlans($robotAssignmentId: UUID!) {
  robot: robotAssignment(robotAssignmentId: $robotAssignmentId) {
    growingPlans {
      id
      name
    }
  }
}
    `;

export const useAllRobotsGrowingPlansQuery = <
  TData = AllRobotsGrowingPlansQuery,
  TError = { message: string; code: ErrorCode },
>(
  variables: AllRobotsGrowingPlansQueryVariables,
  options?: Omit<UseQueryOptions<AllRobotsGrowingPlansQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<AllRobotsGrowingPlansQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<AllRobotsGrowingPlansQuery, TError, TData>({
    queryKey: ["allRobotsGrowingPlans", variables],
    queryFn: fetcher<AllRobotsGrowingPlansQuery, AllRobotsGrowingPlansQueryVariables>(
      AllRobotsGrowingPlansDocument,
      variables,
    ),
    ...options,
  });
};

useAllRobotsGrowingPlansQuery.document = AllRobotsGrowingPlansDocument;

useAllRobotsGrowingPlansQuery.getKey = (variables: AllRobotsGrowingPlansQueryVariables) => [
  "allRobotsGrowingPlans",
  variables,
];

export const UserDocument = `
    query user {
  myProfile {
    id
    firstName
    lastName
    email
    phone
  }
}
    `;

export const useUserQuery = <TData = UserQuery, TError = { message: string; code: ErrorCode }>(
  variables?: UserQueryVariables,
  options?: Omit<UseQueryOptions<UserQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<UserQuery, TError, TData>["queryKey"];
  },
) => {
  return useQuery<UserQuery, TError, TData>({
    queryKey: variables === undefined ? ["user"] : ["user", variables],
    queryFn: fetcher<UserQuery, UserQueryVariables>(UserDocument, variables),
    ...options,
  });
};

useUserQuery.document = UserDocument;

useUserQuery.getKey = (variables?: UserQueryVariables) => (variables === undefined ? ["user"] : ["user", variables]);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAccessPermissionsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { myProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAccessPermissionsQuery = (
  resolver: GraphQLResponseResolver<AccessPermissionsQuery, AccessPermissionsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<AccessPermissionsQuery, AccessPermissionsQueryVariables>("accessPermissions", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAuthorizationCheckQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { myProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAuthorizationCheckQuery = (
  resolver: GraphQLResponseResolver<AuthorizationCheckQuery, AuthorizationCheckQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<AuthorizationCheckQuery, AuthorizationCheckQueryVariables>("authorizationCheck", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUserInfoForAuthPageQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { myProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUserInfoForAuthPageQuery = (
  resolver: GraphQLResponseResolver<UserInfoForAuthPageQuery, UserInfoForAuthPageQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<UserInfoForAuthPageQuery, UserInfoForAuthPageQueryVariables>("userInfoForAuthPage", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockClientAccountsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { myProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockClientAccountsQuery = (
  resolver: GraphQLResponseResolver<ClientAccountsQuery, ClientAccountsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<ClientAccountsQuery, ClientAccountsQueryVariables>("ClientAccounts", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateClientAccountMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createClientAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateClientAccountMutation = (
  resolver: GraphQLResponseResolver<CreateClientAccountMutation, CreateClientAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<CreateClientAccountMutation, CreateClientAccountMutationVariables>(
    "createClientAccount",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateClientAccountMutation(
 *   ({ query, variables }) => {
 *     const { clientAccountId, clientAccountInput } = variables;
 *     return HttpResponse.json({
 *       data: { updateClientAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateClientAccountMutation = (
  resolver: GraphQLResponseResolver<UpdateClientAccountMutation, UpdateClientAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<UpdateClientAccountMutation, UpdateClientAccountMutationVariables>(
    "updateClientAccount",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSwitchClientAccountMutation(
 *   ({ query, variables }) => {
 *     const { clientAccountId } = variables;
 *     return HttpResponse.json({
 *       data: { switchClientAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSwitchClientAccountMutation = (
  resolver: GraphQLResponseResolver<SwitchClientAccountMutation, SwitchClientAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<SwitchClientAccountMutation, SwitchClientAccountMutationVariables>(
    "switchClientAccount",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockClientAccountForEditPageQuery(
 *   ({ query, variables }) => {
 *     const { clientAccountId } = variables;
 *     return HttpResponse.json({
 *       data: { clientAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockClientAccountForEditPageQuery = (
  resolver: GraphQLResponseResolver<ClientAccountForEditPageQuery, ClientAccountForEditPageQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<ClientAccountForEditPageQuery, ClientAccountForEditPageQueryVariables>(
    "clientAccountForEditPage",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCropSheetListQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { cropSheetList }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCropSheetListQuery = (
  resolver: GraphQLResponseResolver<CropSheetListQuery, CropSheetListQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<CropSheetListQuery, CropSheetListQueryVariables>("cropSheetList", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockThreatsQuery(
 *   ({ query, variables }) => {
 *     const { type } = variables;
 *     return HttpResponse.json({
 *       data: { diseases }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockThreatsQuery = (
  resolver: GraphQLResponseResolver<ThreatsQuery, ThreatsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<ThreatsQuery, ThreatsQueryVariables>("threats", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCropSheetQuery(
 *   ({ query, variables }) => {
 *     const { cropSheetId } = variables;
 *     return HttpResponse.json({
 *       data: { cropSheet, cropDiseases, cropStages }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCropSheetQuery = (
  resolver: GraphQLResponseResolver<CropSheetQuery, CropSheetQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<CropSheetQuery, CropSheetQueryVariables>("cropSheet", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCropThreatsQuery(
 *   ({ query, variables }) => {
 *     const { cropSheetId, threatType } = variables;
 *     return HttpResponse.json({
 *       data: { cropSheet, cropDiseases }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCropThreatsQuery = (
  resolver: GraphQLResponseResolver<CropThreatsQuery, CropThreatsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<CropThreatsQuery, CropThreatsQueryVariables>("cropThreats", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockThreatQuery(
 *   ({ query, variables }) => {
 *     const { threatId } = variables;
 *     return HttpResponse.json({
 *       data: { disease }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockThreatQuery = (
  resolver: GraphQLResponseResolver<ThreatQuery, ThreatQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<ThreatQuery, ThreatQueryVariables>("threat", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFieldTaskQuery(
 *   ({ query, variables }) => {
 *     const { fieldTaskId } = variables;
 *     return HttpResponse.json({
 *       data: { fieldTask }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFieldTaskQuery = (
  resolver: GraphQLResponseResolver<FieldTaskQuery, FieldTaskQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<FieldTaskQuery, FieldTaskQueryVariables>("fieldTask", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFieldTasksWarningsQuery(
 *   ({ query, variables }) => {
 *     const { date, robotAssignmentId } = variables;
 *     return HttpResponse.json({
 *       data: { fieldTasksWarnings }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFieldTasksWarningsQuery = (
  resolver: GraphQLResponseResolver<FieldTasksWarningsQuery, FieldTasksWarningsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<FieldTasksWarningsQuery, FieldTasksWarningsQueryVariables>("fieldTasksWarnings", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFieldTaskTypesQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { fieldTaskTypes }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFieldTaskTypesQuery = (
  resolver: GraphQLResponseResolver<FieldTaskTypesQuery, FieldTaskTypesQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<FieldTaskTypesQuery, FieldTaskTypesQueryVariables>("fieldTaskTypes", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFieldTaskTypesWithDefaultValuesQuery(
 *   ({ query, variables }) => {
 *     const { growingPlanId } = variables;
 *     return HttpResponse.json({
 *       data: { fieldTaskTypes }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFieldTaskTypesWithDefaultValuesQuery = (
  resolver: GraphQLResponseResolver<
    FieldTaskTypesWithDefaultValuesQuery,
    FieldTaskTypesWithDefaultValuesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<FieldTaskTypesWithDefaultValuesQuery, FieldTaskTypesWithDefaultValuesQueryVariables>(
    "fieldTaskTypesWithDefaultValues",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockIsFieldTaskPossibleQuery(
 *   ({ query, variables }) => {
 *     const { blockIds } = variables;
 *     return HttpResponse.json({
 *       data: { isFieldTaskPossible }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockIsFieldTaskPossibleQuery = (
  resolver: GraphQLResponseResolver<IsFieldTaskPossibleQuery, IsFieldTaskPossibleQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<IsFieldTaskPossibleQuery, IsFieldTaskPossibleQueryVariables>("isFieldTaskPossible", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateFieldTasksMutation(
 *   ({ query, variables }) => {
 *     const { fieldTaskInput } = variables;
 *     return HttpResponse.json({
 *       data: { createFieldTaskBatch }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateFieldTasksMutation = (
  resolver: GraphQLResponseResolver<CreateFieldTasksMutation, CreateFieldTasksMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<CreateFieldTasksMutation, CreateFieldTasksMutationVariables>("createFieldTasks", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateFieldTaskMutation(
 *   ({ query, variables }) => {
 *     const { fieldTaskInput } = variables;
 *     return HttpResponse.json({
 *       data: { createFieldTask }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateFieldTaskMutation = (
  resolver: GraphQLResponseResolver<CreateFieldTaskMutation, CreateFieldTaskMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<CreateFieldTaskMutation, CreateFieldTaskMutationVariables>("createFieldTask", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteFieldTaskMutation(
 *   ({ query, variables }) => {
 *     const { fieldTaskId } = variables;
 *     return HttpResponse.json({
 *       data: { deleteFieldTask }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteFieldTaskMutation = (
  resolver: GraphQLResponseResolver<DeleteFieldTaskMutation, DeleteFieldTaskMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<DeleteFieldTaskMutation, DeleteFieldTaskMutationVariables>("deleteFieldTask", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMoveUpFieldTaskMutation(
 *   ({ query, variables }) => {
 *     const { fieldTaskId } = variables;
 *     return HttpResponse.json({
 *       data: { moveFieldTaskUp }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockMoveUpFieldTaskMutation = (
  resolver: GraphQLResponseResolver<MoveUpFieldTaskMutation, MoveUpFieldTaskMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<MoveUpFieldTaskMutation, MoveUpFieldTaskMutationVariables>("moveUpFieldTask", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMoveDownFieldTaskMutation(
 *   ({ query, variables }) => {
 *     const { fieldTaskId } = variables;
 *     return HttpResponse.json({
 *       data: { moveFieldTaskDown }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockMoveDownFieldTaskMutation = (
  resolver: GraphQLResponseResolver<MoveDownFieldTaskMutation, MoveDownFieldTaskMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<MoveDownFieldTaskMutation, MoveDownFieldTaskMutationVariables>(
    "moveDownFieldTask",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockStartNowTaskMutation(
 *   ({ query, variables }) => {
 *     const { fieldTaskId } = variables;
 *     return HttpResponse.json({
 *       data: { startNowFieldTask }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockStartNowTaskMutation = (
  resolver: GraphQLResponseResolver<StartNowTaskMutation, StartNowTaskMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<StartNowTaskMutation, StartNowTaskMutationVariables>("startNowTask", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFieldBasicQuery(
 *   ({ query, variables }) => {
 *     const { fieldId } = variables;
 *     return HttpResponse.json({
 *       data: { field }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFieldBasicQuery = (
  resolver: GraphQLResponseResolver<FieldBasicQuery, FieldBasicQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<FieldBasicQuery, FieldBasicQueryVariables>("fieldBasic", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFieldQuery(
 *   ({ query, variables }) => {
 *     const { fieldId } = variables;
 *     return HttpResponse.json({
 *       data: { field }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFieldQuery = (
  resolver: GraphQLResponseResolver<FieldQuery, FieldQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<FieldQuery, FieldQueryVariables>("field", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBlockForDetailPageQuery(
 *   ({ query, variables }) => {
 *     const { blockId } = variables;
 *     return HttpResponse.json({
 *       data: { block, fieldTasks }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBlockForDetailPageQuery = (
  resolver: GraphQLResponseResolver<BlockForDetailPageQuery, BlockForDetailPageQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<BlockForDetailPageQuery, BlockForDetailPageQueryVariables>("blockForDetailPage", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateFieldManualBoundariesMutation(
 *   ({ query, variables }) => {
 *     const { fieldId, input } = variables;
 *     return HttpResponse.json({
 *       data: { updateFieldManualBoundaries }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateFieldManualBoundariesMutation = (
  resolver: GraphQLResponseResolver<UpdateFieldManualBoundariesMutation, UpdateFieldManualBoundariesMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<UpdateFieldManualBoundariesMutation, UpdateFieldManualBoundariesMutationVariables>(
    "updateFieldManualBoundaries",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateManualBlocksMutation(
 *   ({ query, variables }) => {
 *     const { fieldId, blocksInput } = variables;
 *     return HttpResponse.json({
 *       data: { updateManualBlocks }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateManualBlocksMutation = (
  resolver: GraphQLResponseResolver<UpdateManualBlocksMutation, UpdateManualBlocksMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<UpdateManualBlocksMutation, UpdateManualBlocksMutationVariables>(
    "updateManualBlocks",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateSegmentBlocksMutation(
 *   ({ query, variables }) => {
 *     const { fieldId, input } = variables;
 *     return HttpResponse.json({
 *       data: { updateSegmentBlocks }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateSegmentBlocksMutation = (
  resolver: GraphQLResponseResolver<UpdateSegmentBlocksMutation, UpdateSegmentBlocksMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<UpdateSegmentBlocksMutation, UpdateSegmentBlocksMutationVariables>(
    "updateSegmentBlocks",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateFieldEntryPointMutation(
 *   ({ query, variables }) => {
 *     const { fieldId, entryPoint, reverseDirection } = variables;
 *     return HttpResponse.json({
 *       data: { updateFieldEntryPoint }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateFieldEntryPointMutation = (
  resolver: GraphQLResponseResolver<UpdateFieldEntryPointMutation, UpdateFieldEntryPointMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<UpdateFieldEntryPointMutation, UpdateFieldEntryPointMutationVariables>(
    "updateFieldEntryPoint",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateFieldImportedBoundariesMutation(
 *   ({ query, variables }) => {
 *     const { fieldId, gpsTraceId } = variables;
 *     return HttpResponse.json({
 *       data: { updateFieldImportedBoundaries }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateFieldImportedBoundariesMutation = (
  resolver: GraphQLResponseResolver<
    UpdateFieldImportedBoundariesMutation,
    UpdateFieldImportedBoundariesMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<UpdateFieldImportedBoundariesMutation, UpdateFieldImportedBoundariesMutationVariables>(
    "updateFieldImportedBoundaries",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBoundariesGpsTracesQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { boundariesGpsTraces }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBoundariesGpsTracesQuery = (
  resolver: GraphQLResponseResolver<BoundariesGpsTracesQuery, BoundariesGpsTracesQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<BoundariesGpsTracesQuery, BoundariesGpsTracesQueryVariables>("boundariesGpsTraces", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFieldsQuery(
 *   ({ query, variables }) => {
 *     const { paginationInput } = variables;
 *     return HttpResponse.json({
 *       data: { fields }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFieldsQuery = (
  resolver: GraphQLResponseResolver<FieldsQuery, FieldsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<FieldsQuery, FieldsQueryVariables>("fields", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteFieldMutation(
 *   ({ query, variables }) => {
 *     const { fieldId } = variables;
 *     return HttpResponse.json({
 *       data: { deleteField }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteFieldMutation = (
  resolver: GraphQLResponseResolver<DeleteFieldMutation, DeleteFieldMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<DeleteFieldMutation, DeleteFieldMutationVariables>("deleteField", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockHomeStationsQuery(
 *   ({ query, variables }) => {
 *     const { fieldId } = variables;
 *     return HttpResponse.json({
 *       data: { homeStationGpsTraces }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockHomeStationsQuery = (
  resolver: GraphQLResponseResolver<HomeStationsQuery, HomeStationsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<HomeStationsQuery, HomeStationsQueryVariables>("homeStations", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateFieldHomeStationPathMutation(
 *   ({ query, variables }) => {
 *     const { fieldId, gpsTraceId } = variables;
 *     return HttpResponse.json({
 *       data: { updateFieldHomeStation }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateFieldHomeStationPathMutation = (
  resolver: GraphQLResponseResolver<UpdateFieldHomeStationPathMutation, UpdateFieldHomeStationPathMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<UpdateFieldHomeStationPathMutation, UpdateFieldHomeStationPathMutationVariables>(
    "updateFieldHomeStationPath",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSoilTypesQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { soilTypes }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSoilTypesQuery = (
  resolver: GraphQLResponseResolver<SoilTypesQuery, SoilTypesQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<SoilTypesQuery, SoilTypesQueryVariables>("soilTypes", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRobotAssignmentsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { robotAssignments }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRobotAssignmentsQuery = (
  resolver: GraphQLResponseResolver<RobotAssignmentsQuery, RobotAssignmentsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<RobotAssignmentsQuery, RobotAssignmentsQueryVariables>("robotAssignments", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFieldForEditPageQuery(
 *   ({ query, variables }) => {
 *     const { fieldId } = variables;
 *     return HttpResponse.json({
 *       data: { field }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFieldForEditPageQuery = (
  resolver: GraphQLResponseResolver<FieldForEditPageQuery, FieldForEditPageQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<FieldForEditPageQuery, FieldForEditPageQueryVariables>("fieldForEditPage", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateFieldMutation(
 *   ({ query, variables }) => {
 *     const { fieldInput } = variables;
 *     return HttpResponse.json({
 *       data: { createField }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateFieldMutation = (
  resolver: GraphQLResponseResolver<CreateFieldMutation, CreateFieldMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<CreateFieldMutation, CreateFieldMutationVariables>("createField", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateFieldMutation(
 *   ({ query, variables }) => {
 *     const { fieldId, fieldInput } = variables;
 *     return HttpResponse.json({
 *       data: { updateField }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateFieldMutation = (
  resolver: GraphQLResponseResolver<UpdateFieldMutation, UpdateFieldMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<UpdateFieldMutation, UpdateFieldMutationVariables>("updateField", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPreviewSegmentsQuery(
 *   ({ query, variables }) => {
 *     const { swathsIds, gap, length } = variables;
 *     return HttpResponse.json({
 *       data: { previewSegments }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPreviewSegmentsQuery = (
  resolver: GraphQLResponseResolver<PreviewSegmentsQuery, PreviewSegmentsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<PreviewSegmentsQuery, PreviewSegmentsQueryVariables>("previewSegments", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateSegmentsMutation(
 *   ({ query, variables }) => {
 *     const { fieldId, segments } = variables;
 *     return HttpResponse.json({
 *       data: { createSegments }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateSegmentsMutation = (
  resolver: GraphQLResponseResolver<CreateSegmentsMutation, CreateSegmentsMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<CreateSegmentsMutation, CreateSegmentsMutationVariables>("createSegments", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPreviewSwathsQuery(
 *   ({ query, variables }) => {
 *     const { fieldId, angle, refPathSegmentId } = variables;
 *     return HttpResponse.json({
 *       data: { previewSwaths }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPreviewSwathsQuery = (
  resolver: GraphQLResponseResolver<PreviewSwathsQuery, PreviewSwathsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<PreviewSwathsQuery, PreviewSwathsQueryVariables>("previewSwaths", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateSwathsMutation(
 *   ({ query, variables }) => {
 *     const { fieldId, refPathSegmentId, angle } = variables;
 *     return HttpResponse.json({
 *       data: { createSwaths }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateSwathsMutation = (
  resolver: GraphQLResponseResolver<CreateSwathsMutation, CreateSwathsMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<CreateSwathsMutation, CreateSwathsMutationVariables>("createSwaths", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDataForCreateGrowingPlanBaseInfoContentQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { cropSheetList, fields }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDataForCreateGrowingPlanBaseInfoContentQuery = (
  resolver: GraphQLResponseResolver<
    DataForCreateGrowingPlanBaseInfoContentQuery,
    DataForCreateGrowingPlanBaseInfoContentQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<DataForCreateGrowingPlanBaseInfoContentQuery, DataForCreateGrowingPlanBaseInfoContentQueryVariables>(
    "dataForCreateGrowingPlanBaseInfoContent",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFieldBlocksForCreateGrowingPlanBaseInfoContentQuery(
 *   ({ query, variables }) => {
 *     const { fieldId } = variables;
 *     return HttpResponse.json({
 *       data: { field }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFieldBlocksForCreateGrowingPlanBaseInfoContentQuery = (
  resolver: GraphQLResponseResolver<
    FieldBlocksForCreateGrowingPlanBaseInfoContentQuery,
    FieldBlocksForCreateGrowingPlanBaseInfoContentQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    FieldBlocksForCreateGrowingPlanBaseInfoContentQuery,
    FieldBlocksForCreateGrowingPlanBaseInfoContentQueryVariables
  >("fieldBlocksForCreateGrowingPlanBaseInfoContent", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGrowingPlanPreviewQuery(
 *   ({ query, variables }) => {
 *     const { cropSheetId, blockId, startAt } = variables;
 *     return HttpResponse.json({
 *       data: { growingPlanPreview }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGrowingPlanPreviewQuery = (
  resolver: GraphQLResponseResolver<GrowingPlanPreviewQuery, GrowingPlanPreviewQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GrowingPlanPreviewQuery, GrowingPlanPreviewQueryVariables>("growingPlanPreview", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateGrowingPlanMutation(
 *   ({ query, variables }) => {
 *     const { cropSheetId, blockId, startAt } = variables;
 *     return HttpResponse.json({
 *       data: { createGrowingPlan }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateGrowingPlanMutation = (
  resolver: GraphQLResponseResolver<CreateGrowingPlanMutation, CreateGrowingPlanMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<CreateGrowingPlanMutation, CreateGrowingPlanMutationVariables>(
    "createGrowingPlan",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGrowingPlanYearPreviewQuery(
 *   ({ query, variables }) => {
 *     const { cropSheetId, blockId } = variables;
 *     return HttpResponse.json({
 *       data: { growingPlanYearPreview }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGrowingPlanYearPreviewQuery = (
  resolver: GraphQLResponseResolver<GrowingPlanYearPreviewQuery, GrowingPlanYearPreviewQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<GrowingPlanYearPreviewQuery, GrowingPlanYearPreviewQueryVariables>(
    "growingPlanYearPreview",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGrowingPlanQuery(
 *   ({ query, variables }) => {
 *     const { growingPlanId } = variables;
 *     return HttpResponse.json({
 *       data: { growingPlan }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGrowingPlanQuery = (
  resolver: GraphQLResponseResolver<GrowingPlanQuery, GrowingPlanQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GrowingPlanQuery, GrowingPlanQueryVariables>("growingPlan", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGrowingPlanTasksQuery(
 *   ({ query, variables }) => {
 *     const { startAt, growingPlanId, paginationInput } = variables;
 *     return HttpResponse.json({
 *       data: { fieldTasks }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGrowingPlanTasksQuery = (
  resolver: GraphQLResponseResolver<GrowingPlanTasksQuery, GrowingPlanTasksQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GrowingPlanTasksQuery, GrowingPlanTasksQueryVariables>("growingPlanTasks", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateGrowingPlanStartAtMutation(
 *   ({ query, variables }) => {
 *     const { growingPlanId, startAt } = variables;
 *     return HttpResponse.json({
 *       data: { updateGrowingPlanStartAt }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateGrowingPlanStartAtMutation = (
  resolver: GraphQLResponseResolver<UpdateGrowingPlanStartAtMutation, UpdateGrowingPlanStartAtMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<UpdateGrowingPlanStartAtMutation, UpdateGrowingPlanStartAtMutationVariables>(
    "updateGrowingPlanStartAt",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFinishGrowingPlanMutation(
 *   ({ query, variables }) => {
 *     const { growingPlanId } = variables;
 *     return HttpResponse.json({
 *       data: { finishGrowingPlan }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFinishGrowingPlanMutation = (
  resolver: GraphQLResponseResolver<FinishGrowingPlanMutation, FinishGrowingPlanMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<FinishGrowingPlanMutation, FinishGrowingPlanMutationVariables>(
    "finishGrowingPlan",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPreviewRowBasedPlantSpacingQuery(
 *   ({ query, variables }) => {
 *     const { growingPlanId, input } = variables;
 *     return HttpResponse.json({
 *       data: { previewRowBasedPlantSpacing }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPreviewRowBasedPlantSpacingQuery = (
  resolver: GraphQLResponseResolver<PreviewRowBasedPlantSpacingQuery, PreviewRowBasedPlantSpacingQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<PreviewRowBasedPlantSpacingQuery, PreviewRowBasedPlantSpacingQueryVariables>(
    "previewRowBasedPlantSpacing",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPreviewPlantRowsQuery(
 *   ({ query, variables }) => {
 *     const { growingPlanId, input } = variables;
 *     return HttpResponse.json({
 *       data: { previewPlantRows }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPreviewPlantRowsQuery = (
  resolver: GraphQLResponseResolver<PreviewPlantRowsQuery, PreviewPlantRowsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<PreviewPlantRowsQuery, PreviewPlantRowsQueryVariables>("previewPlantRows", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPlantSpacingQuery(
 *   ({ query, variables }) => {
 *     const { growingPlanId } = variables;
 *     return HttpResponse.json({
 *       data: { growingPlan }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPlantSpacingQuery = (
  resolver: GraphQLResponseResolver<PlantSpacingQuery, PlantSpacingQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<PlantSpacingQuery, PlantSpacingQueryVariables>("plantSpacing", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPreviewPlantSpacingQuery(
 *   ({ query, variables }) => {
 *     const { growingPlanId, plantSpacingInput } = variables;
 *     return HttpResponse.json({
 *       data: { previewCustomPlantSpacing }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPreviewPlantSpacingQuery = (
  resolver: GraphQLResponseResolver<PreviewPlantSpacingQuery, PreviewPlantSpacingQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<PreviewPlantSpacingQuery, PreviewPlantSpacingQueryVariables>("previewPlantSpacing", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePlantSpacingMutation(
 *   ({ query, variables }) => {
 *     const { growingPlanId, plantSpacingInput } = variables;
 *     return HttpResponse.json({
 *       data: { createRowBasedPlantSpacing }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreatePlantSpacingMutation = (
  resolver: GraphQLResponseResolver<CreatePlantSpacingMutation, CreatePlantSpacingMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<CreatePlantSpacingMutation, CreatePlantSpacingMutationVariables>(
    "createPlantSpacing",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeletePlantSpacingMutation(
 *   ({ query, variables }) => {
 *     const { plantSpacingId } = variables;
 *     return HttpResponse.json({
 *       data: { deletePlantSpacing }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeletePlantSpacingMutation = (
  resolver: GraphQLResponseResolver<DeletePlantSpacingMutation, DeletePlantSpacingMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<DeletePlantSpacingMutation, DeletePlantSpacingMutationVariables>(
    "deletePlantSpacing",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGrowingPlansQuery(
 *   ({ query, variables }) => {
 *     const { paginationInput, filters, order } = variables;
 *     return HttpResponse.json({
 *       data: { growingPlanFilter }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGrowingPlansQuery = (
  resolver: GraphQLResponseResolver<GrowingPlansQuery, GrowingPlansQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GrowingPlansQuery, GrowingPlansQueryVariables>("growingPlans", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBlocksSuggestionsQuery(
 *   ({ query, variables }) => {
 *     const { filters } = variables;
 *     return HttpResponse.json({
 *       data: { growingPlanFilterBlocks }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBlocksSuggestionsQuery = (
  resolver: GraphQLResponseResolver<BlocksSuggestionsQuery, BlocksSuggestionsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<BlocksSuggestionsQuery, BlocksSuggestionsQueryVariables>("blocksSuggestions", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockFieldsSuggestionsQuery(
 *   ({ query, variables }) => {
 *     const { filters } = variables;
 *     return HttpResponse.json({
 *       data: { growingPlanFilterFields }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockFieldsSuggestionsQuery = (
  resolver: GraphQLResponseResolver<FieldsSuggestionsQuery, FieldsSuggestionsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<FieldsSuggestionsQuery, FieldsSuggestionsQueryVariables>("fieldsSuggestions", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCropsSuggestionsQuery(
 *   ({ query, variables }) => {
 *     const { filters } = variables;
 *     return HttpResponse.json({
 *       data: { growingPlanFilterCrops }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCropsSuggestionsQuery = (
  resolver: GraphQLResponseResolver<CropsSuggestionsQuery, CropsSuggestionsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<CropsSuggestionsQuery, CropsSuggestionsQueryVariables>("cropsSuggestions", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCropsForGrowingPlansPageQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { cropSheetList }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCropsForGrowingPlansPageQuery = (
  resolver: GraphQLResponseResolver<CropsForGrowingPlansPageQuery, CropsForGrowingPlansPageQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<CropsForGrowingPlansPageQuery, CropsForGrowingPlansPageQueryVariables>(
    "cropsForGrowingPlansPage",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteGrowingPlanMutation(
 *   ({ query, variables }) => {
 *     const { growingPlanId } = variables;
 *     return HttpResponse.json({
 *       data: { deleteGrowingPlan }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteGrowingPlanMutation = (
  resolver: GraphQLResponseResolver<DeleteGrowingPlanMutation, DeleteGrowingPlanMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<DeleteGrowingPlanMutation, DeleteGrowingPlanMutationVariables>(
    "deleteGrowingPlan",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRegisterMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { register }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRegisterMutation = (
  resolver: GraphQLResponseResolver<RegisterMutation, RegisterMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<RegisterMutation, RegisterMutationVariables>("register", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRobotsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { robotAssignments }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRobotsQuery = (
  resolver: GraphQLResponseResolver<RobotsQuery, RobotsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<RobotsQuery, RobotsQueryVariables>("robots", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRobotQuery(
 *   ({ query, variables }) => {
 *     const { robotAssignmentId } = variables;
 *     return HttpResponse.json({
 *       data: { robotAssignment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRobotQuery = (
  resolver: GraphQLResponseResolver<RobotQuery, RobotQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<RobotQuery, RobotQueryVariables>("robot", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRobotTasksQuery(
 *   ({ query, variables }) => {
 *     const { robotAssignmentId, paginationInput, startAt, growingPlanId } = variables;
 *     return HttpResponse.json({
 *       data: { fieldTasks }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRobotTasksQuery = (
  resolver: GraphQLResponseResolver<RobotTasksQuery, RobotTasksQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<RobotTasksQuery, RobotTasksQueryVariables>("robotTasks", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRegisterRobotMutation(
 *   ({ query, variables }) => {
 *     const { robotAssignmentInput } = variables;
 *     return HttpResponse.json({
 *       data: { registerRobot }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRegisterRobotMutation = (
  resolver: GraphQLResponseResolver<RegisterRobotMutation, RegisterRobotMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<RegisterRobotMutation, RegisterRobotMutationVariables>("registerRobot", resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDecommissionRobotMutation(
 *   ({ query, variables }) => {
 *     const { robotId } = variables;
 *     return HttpResponse.json({
 *       data: { decommissionRobot }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDecommissionRobotMutation = (
  resolver: GraphQLResponseResolver<DecommissionRobotMutation, DecommissionRobotMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<DecommissionRobotMutation, DecommissionRobotMutationVariables>(
    "decommissionRobot",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAllRobotsGrowingPlansQuery(
 *   ({ query, variables }) => {
 *     const { robotAssignmentId } = variables;
 *     return HttpResponse.json({
 *       data: { robotAssignment }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAllRobotsGrowingPlansQuery = (
  resolver: GraphQLResponseResolver<AllRobotsGrowingPlansQuery, AllRobotsGrowingPlansQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<AllRobotsGrowingPlansQuery, AllRobotsGrowingPlansQueryVariables>(
    "allRobotsGrowingPlans",
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUserQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { myProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUserQuery = (
  resolver: GraphQLResponseResolver<UserQuery, UserQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<UserQuery, UserQueryVariables>("user", resolver, options);
