import { Icon } from "@roboton/ui";
import clsx from "clsx";

export const WarningMessage = ({ className, children, ...rest }: JSX.IntrinsicElements["div"]) => {
  return (
    <div
      {...rest}
      className={clsx(
        "text-dark-50 border-gold-25 bg-gold-0 flex items-center gap-1 rounded-sm border px-2 py-1 text-sm",
        className,
      )}
    >
      <Icon type={"warning"} className={"w-3 text-yellow-500"} />
      {children}
    </div>
  );
};
