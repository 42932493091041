import { Tabs, useTabs } from "@roboton/ui";
import type { ComponentProps, ReactNode } from "react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

type Item = {
  id: string;
  label: string;
  path: string;
};

type RouterTabsProps<T extends Item[]> = {
  id: string;
  items: T;
  children: ReactNode;
  size: ComponentProps<typeof Tabs.List>["size"];
  panelProps?: JSX.IntrinsicElements["div"];
};

/**
 * Tabs component that is connected to the router. The selected tab is controlled by the current route.
 */
export const RouterTabs = <T extends Item[]>({ id, items, children, size, panelProps }: RouterTabsProps<T>) => {
  if (items.length < 1) {
    throw Error("At least one item is required.");
  }

  const [tabs] = useState(() => items.map((item) => item.id));
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const initItem = items.find((item) => item.path === pathname) ?? items[0];
  const initItemId = initItem.id;

  const { selectedTab, setSelectedTab, getPanelProps, getTabProps, getListProps } = useTabs({
    id,
    tabs,
    initTab: initItemId,
    options: { disableScrollIntoView: true },
  });

  // control selected tab by current route, not by user interaction only
  useEffect(() => {
    setSelectedTab(initItemId);
  }, [setSelectedTab, initItemId]);

  return (
    <>
      <Tabs.List {...getListProps()} size={size}>
        {items.map((item) => {
          return (
            <Tabs.Tab
              key={item.id}
              {...getTabProps({
                tab: item.id,
                onClick: () => navigate(item.path),
                onKeyUp: () => navigate(item.path),
              })}
              as={Link}
              to={item.path}
              size={size}
              isSelected={item.id === selectedTab}
            >
              {item.label}
            </Tabs.Tab>
          );
        })}
      </Tabs.List>

      <div {...getPanelProps<HTMLDivElement>({ tab: selectedTab, ...panelProps })}>{children}</div>
    </>
  );
};
