import type { GrowingPlanCardFragment } from "@/api/sdk";
import { PercentageProgressCircle } from "@/components/PercentageProgressCircle";
import { growingPlanStateToBadgeColorMap } from "@/growing-plan/enums";
import { generatePath, routes } from "@/routes";
import { backToUtils } from "@/utils/backToUtils";
import { formatDate } from "@/utils/format";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { Badge, Button, Checkbox, Tooltip } from "@roboton/ui";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GrowingPlanCardLayout } from "./GrowingPlanCardLayout";

type GrowingPlanCardProps = {
  growingPlan: GrowingPlanCardFragment;
  isSelected: boolean;
  onCheckboxChange?: (checked: boolean, growingPlan: GrowingPlanCardFragment) => void;
};

export const GrowingPlanCard = ({ growingPlan, isSelected, onCheckboxChange }: GrowingPlanCardProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const detailPath = generatePath(routes.GrowingPlanDetail, { id: growingPlan.id });

  const { tEnums } = useTranslationEnums();

  return (
    <GrowingPlanCardLayout
      key={growingPlan.id}
      checkbox={
        <Checkbox
          id={`growing-plan-card-${growingPlan.id}`}
          checked={isSelected}
          disabled={!onCheckboxChange}
          onChange={
            onCheckboxChange ? (event) => onCheckboxChange(event.currentTarget.checked, growingPlan) : undefined
          }
        />
      }
      progress={
        <Tooltip size={"small"} content={`${growingPlan.planProgress}%`} enableAutoUpdate>
          <PercentageProgressCircle
            strokeWidth={"8"}
            items={[
              {
                key: "plan-progress",
                percentage: growingPlan.planProgress,
                pathProps: { className: "stroke-brand-50" },
              },
            ]}
          />
        </Tooltip>
      }
      name={<h3 className={"typo-h3"}>{growingPlan.name}</h3>}
      crop={
        <>
          {growingPlan.cropSheet.commonName}
          {growingPlan.cropSheet.variant && ` (${growingPlan.cropSheet.variant})`}
        </>
      }
      state={
        <div className={"border-light-50 inline-flex items-center gap-1 rounded-full border-2 px-2"}>
          <Badge color={growingPlanStateToBadgeColorMap[growingPlan.state]} size={"small"} />
          <div className={"whitespace-nowrap"}>{tEnums("growingPlanState", growingPlan.state)}</div>
        </div>
      }
      type={tEnums("cultivationMethod", growingPlan.field.cultivation)}
      robot={growingPlan.robot ? growingPlan.robot.name : null}
      field={growingPlan.field ? growingPlan.field.name : null}
      block={growingPlan.block ? growingPlan.block.name : null}
      start={formatDate(growingPlan.startAt, language)}
      end={formatDate(growingPlan.endAt, language)}
      action={
        <Button
          as={Link}
          to={detailPath}
          onClick={() => backToUtils.set()}
          size={"small"}
          icon={"arrow_right"}
          iconAlign={"right"}
          className={"col-span-full"}
        >
          {t("Detail")}
        </Button>
      }
    />
  );
};
