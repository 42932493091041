import { RouterTabs } from "@/components/RouterTabs";
import { generatePath, routes } from "@/routes";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const TABS = ["about", "stages"] as const;

type CropDetailTabsProps = Omit<ComponentProps<typeof RouterTabs>, "id" | "items" | "size" | "panelClassName"> & {
  cropId: string;
};

export const CropDetailTabs = ({ cropId, children }: CropDetailTabsProps) => {
  const { t } = useTranslation();

  const tabItems = useMemo(() => {
    const mapTabToLabel: Record<(typeof TABS)[number], string> = {
      about: t("About"),
      stages: t("Crop Stages"),
    };

    const mapTabToPath = {
      about: generatePath(routes.AboutCrop, { id: cropId }),
      stages: generatePath(routes.CropStages, { id: cropId }),
    } satisfies Record<(typeof TABS)[number], string>;

    return TABS.map((tab) => ({
      id: tab,
      label: mapTabToLabel[tab],
      path: mapTabToPath[tab],
    }));
  }, [cropId, t]);

  return (
    <RouterTabs id={"crop-detail-tabs"} items={tabItems} size={"large"} panelProps={{ className: "mt-4 grow" }}>
      {children}
    </RouterTabs>
  );
};
