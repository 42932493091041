import { useAuthorizationCheckQuery } from "@/api/sdk";
import { getHashValue } from "@/auth/utils";
import { usePersistentStateQuery } from "@/utils/usePersistentStateQuery";
import { useQueryClient } from "@tanstack/react-query";
import { type ComponentProps, type ReactNode, useCallback, useEffect, useState } from "react";
import { authStorage, authStorageKey } from "./storage";
import { AuthContext } from "./store";

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const queryClient = useQueryClient();

  const tokenFromUrl = getHashValue("id_token");
  const [token, setToken] = usePersistentStateQuery<string>(authStorageKey, {
    storage: authStorage,
  });

  const [invalidToken, setInvalidToken] = useState<typeof token>();

  const isTokenValid = typeof token === "string" && !!token.length && token !== invalidToken;

  useEffect(() => {
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, [setToken, tokenFromUrl]);

  const onLogout = useCallback(async () => {
    await setToken(null); // null value will remove item from storage
    queryClient.clear();
  }, [setToken, queryClient]);

  const onLogin = useCallback(
    async (token: string) => {
      await setToken(token);
    },
    [setToken],
  );

  // fetch user info to check if the token is valid
  const { data, error, isLoading, isFetchedAfterMount } = useAuthorizationCheckQuery(undefined, {
    retry: false,
    enabled: isTokenValid,
  });

  useEffect(() => {
    if (!error) return;

    setInvalidToken(token);
    onLogout();
  }, [error, onLogout, token]);

  const isLogged = !!data?.myProfile;

  // not show any previously cached data if the query is still loading
  const isInitLoading: boolean = isTokenValid && isLoading && !isFetchedAfterMount;

  const value: ComponentProps<typeof AuthContext.Provider>["value"] = {
    isLoading: isInitLoading,
    isLogged,
    onLogout,
    onLogin,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
