import type { routes } from "@/routes";
import type { ValueOf } from "@roboton/tools";
import { createContext, useContext } from "react";

type ContextValue = {
  isLoading: boolean;
  allowedRoutes: ValueOf<typeof routes>[];
};

export const AccessPermissionsContext = createContext<ContextValue | undefined>(undefined);

export function useAccessPermissionsStore(): ContextValue {
  const context = useContext(AccessPermissionsContext);
  if (context === undefined) {
    throw new Error("useAccessPermissionsStore must be used within a AccessPermissionsProvider");
  }

  return context;
}
