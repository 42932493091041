import type { Merge } from "@roboton/tools";
import clsx from "clsx";
import { type ReactNode, forwardRef } from "react";

type ToggleProps = Merge<
  Omit<JSX.IntrinsicElements["input"], "type" | "placeholder" | "children">,
  {
    label?: ReactNode;
    id: string;
    state?: "base" | "negative";
    size?: "large" | "medium" | "small";
  }
>;

const stateToClassNameMap: Record<Required<ToggleProps>["state"], string> = {
  base: "ui-toggle--base",
  negative: "ui-toggle--negative",
};

const sizeToClassNameMap: Record<Required<ToggleProps>["size"], string> = {
  large: "ui-toggle--large",
  medium: "ui-toggle--medium",
  small: "ui-toggle--small",
};

/*
 * Children can contain links so must not be cover by any layer.
 * */
export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ state = "base", size = "medium", label, id, disabled, readOnly, className, ...props }, ref): JSX.Element => {
    const hasLabel = label !== undefined;
    const isTouchable: boolean = !disabled && !readOnly;

    return (
      <label
        className={clsx(
          "ui-toggle",
          isTouchable ? stateToClassNameMap[state] : "ui-toggle--disabled",
          sizeToClassNameMap[size],
          className,
        )}
        {...(hasLabel && { htmlFor: id })}
      >
        <input
          {...props}
          type="checkbox"
          className={"ui-toggle__element"}
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          ref={ref}
        />
        <div className={"ui-toggle__shadow-element"} aria-hidden />

        {label ? <span className={"ui-toggle__label"}>{label}</span> : null}
      </label>
    );
  },
);

Toggle.displayName = "Toggle";
