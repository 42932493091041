import { GrowingPlanDetailLayout } from "@/growing-plan/growing-plan-detail/GrowingPlanDetailLayout";
import type { routes } from "@/routes";
import type { ParamParseKey } from "react-router-dom";
import { useParams } from "react-router-dom";

const GrowingPlanDetailRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.GrowingPlanDetail>>();
  if (!id) throw new Error("Missing Growing Plan ID");

  return <GrowingPlanDetailLayout growingPlanId={id} />;
};

export default GrowingPlanDetailRoute;
