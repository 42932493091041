import { useFieldTasksWarningsQuery } from "@/api/sdk";
import { formatDate } from "@/utils/format";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { Button } from "@roboton/ui";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { WarningMessage } from "./WarningMessage";

export const FieldTaskGroup = ({
  date,
  robotId,
  onClickCreateTask,
  children,
}: {
  date: string;
  robotId: string;
  onClickCreateTask?: (date: string) => void;
  children: ReactNode;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { tEnums } = useTranslationEnums();

  const {
    data: { fieldTasksWarnings } = {},
  } = useFieldTasksWarningsQuery({ date, robotAssignmentId: robotId });

  const handleClickCreateTask: JSX.IntrinsicElements["button"]["onClick"] = (e) => {
    e.preventDefault();
    onClickCreateTask?.(date);
  };

  return (
    <div key={date} className={"grid grid-cols-1 gap-2"}>
      <div className={"flex flex-wrap items-center gap-2 sm:justify-between"}>
        <div className={"leading-none"}>{formatDate(date, language)}</div>
        {onClickCreateTask ? (
          <Button size={"small"} variant={"text-base"} type={"button"} onClick={handleClickCreateTask}>
            + {t("Create a new Task for this day")}
          </Button>
        ) : null}
      </div>

      {fieldTasksWarnings?.warnings?.length ? (
        <div className={"space-y-1"}>
          {fieldTasksWarnings.warnings.map((warning) => (
            <WarningMessage key={warning}>{tEnums("fieldTaskJobWarningMessage", warning)}</WarningMessage>
          ))}
        </div>
      ) : null}

      {children}
    </div>
  );
};
