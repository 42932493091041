import type { ErrorCode } from "./sdk";

export class GraphQLError extends Error {
  public code?: ErrorCode;
  public error?: string;

  constructor(message = "Unexpected GraphQL error", code?: ErrorCode, error?: string) {
    super(message);
    this.code = code;
    this.error = error;
  }
}
