import { Select } from "@roboton/ui";
import { type ChangeEvent, type ComponentProps, useState } from "react";

const VALUES = [10, 20, 50, 100];

export const usePerPage = () => {
  const [perPage, setPerPage] = useState(VALUES[0]);

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const perPage = Number.parseInt(e.target.value);
    setPerPage(perPage);
  };

  const getSelectProps = () => {
    return { value: perPage, onChange };
  };

  return { perPage, getSelectProps };
};

export const PerPageSelect = (props: Omit<ComponentProps<typeof Select>, "options">) => {
  return (
    <Select {...props}>
      {VALUES.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </Select>
  );
};
