import plugin from "tailwindcss/plugin";

/**
 * Responsive layout with grid without media queries
 * source: https://www.sitepoint.com/responsive-css-layout-grids-without-media-queries/
 */
export default plugin(({ matchUtilities, theme }) => {
  matchUtilities(
    {
      "grid-dynamic-cols": (value: string) => {
        return {
          gridTemplateColumns: `repeat(auto-fit, minmax(min(100%, ${value}), 1fr))`,
        };
      },
    },
    {
      values: theme("spacing"),
    },
  );
});
