import { useCropSheetListQuery } from "@/api/sdk";
import { LoadingContent } from "@/components/LoadingContent";
import { generatePath, routes } from "@/routes";
import { groupBy } from "@/utils/groupBy";
import { resolveImage } from "@/utils/image";
import { useAppTitle } from "@/utils/useAppTitle";
import { useTranslation } from "react-i18next";
import { CropSheetCard } from "./components/CropSheetCard";

const useCropSheetListTransformedQuery = (...args: Parameters<typeof useCropSheetListQuery>) =>
  useCropSheetListQuery(args[0], {
    ...args[1],
    select: (data) => {
      const transformedCrops = data.cropSheetList.crops
        .map((cropSheet) => ({
          ...cropSheet,
          mainImage: resolveImage(cropSheet.mainImage),
        }))
        .sort((a, b) => a.commonName.localeCompare(b.commonName));

      return {
        ...data,
        crops: groupBy(transformedCrops, (crop) => crop.commonName),
      };
    },
  });

export const CropsOverviewPage = () => {
  const { t } = useTranslation();
  useAppTitle(t("Crops Overview"));

  const {
    data: { crops } = {},
    isLoading,
  } = useCropSheetListTransformedQuery(undefined);

  if (isLoading) {
    return <LoadingContent className={"h-full"} />;
  }

  return (
    <>
      <div>
        <h2 className={"sr-only"}>{t("List of Crops")}</h2>

        <div className={"flex flex-col gap-4"}>
          {crops
            ? Object.entries(crops).map(([commonName, crops]) => {
                return (
                  <div key={commonName}>
                    <h3 className={"typo-h3"}>{commonName}</h3>
                    <div className={"flex flex-col gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"}>
                      {crops.map((crop) => {
                        return (
                          <CropSheetCard
                            key={crop.id}
                            detailTo={generatePath(routes.CropDetail, { id: crop.id })}
                            image={crop.mainImage}
                            headline={
                              <>
                                {crop.commonName}
                                {crop.variant && ` (${crop.variant})`}
                              </>
                            }
                          >
                            {crop.alternativeNames?.length ? (
                              <p>{t("Also {{ names }}", { names: crop.alternativeNames.join(", ") })}</p>
                            ) : null}
                          </CropSheetCard>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
};
