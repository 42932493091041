import { ConditionStatus, GrowingPlanState, useUpdateGrowingPlanStartAtMutation } from "@/api/sdk";
import { Card } from "@/components/Card";
import { PercentageProgressCircle } from "@/components/PercentageProgressCircle";
import { ProgressBar } from "@/components/ProgressBar";
import { DeleteGrowingPlanConfirmationModal } from "@/growing-plan/growing-plans/components/DeleteGrowingPlanConfirmationModal";
import { generatePath, routes } from "@/routes";
import { getColorFromString } from "@/utils/color";
import { useConfirm } from "@/utils/useConfirm";
import { Button } from "@roboton/ui";
import clsx from "clsx";
import { type ComponentProps, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useGrowingPlanDetailLayoutContext } from "./GrowingPlanDetailLayout";
import { CropStagesTable } from "./components/CropStagesTable/CropStagesTable";
import { StartAtForm } from "./components/StartAtForm";
import { transformRemoteGrowingPlanConditionTypeToTableCell } from "./utils";

const mappedConditionStatusToClassName = {
  [ConditionStatus.Critical]: "bg-red-0 text-red-50",
  [ConditionStatus.Warning]: "bg-gold-0 text-gold-50",
  [ConditionStatus.Normal]: "",
} satisfies Record<ConditionStatus, string>;

export const GrowingPlanDetailGeneralPage = () => {
  const { t } = useTranslation();

  const { remoteGrowingPlan, onChange } = useGrowingPlanDetailLayoutContext();
  const {
    cropSheet,
    block,
    robot,
    state,
    planScheduleInDays,
    planProgress,
    planProgressInDays,
    planDuration,
    conditions,

    stages,
    startAtFormatted,
    createdAtFormatted,
    endAtFormatted,

    expectedYieldPercentage,
    expectedYieldFormatted,
    expectedYieldPercentageFormatted,
    conditionsSummary,
  } = remoteGrowingPlan;

  const cropDetailPath = generatePath(routes.CropDetail, { id: cropSheet.id });
  const blockDetailPath = generatePath(routes.BlockDetail, { id: block.field.id, blockId: block.id });

  const continuousStages = stages.filter((stage) => stage.continuous);

  const mappedConditionsForTable = conditions.map((condition) => {
    const mapped = transformRemoteGrowingPlanConditionTypeToTableCell(condition);
    return { ...mapped, className: mappedConditionStatusToClassName[mapped.status] };
  });

  const percentageProgressCircleItems: ComponentProps<typeof PercentageProgressCircle>["items"] = [
    {
      key: "expectedYieldPercentage",
      percentage: expectedYieldPercentage,
      pathProps: { className: "stroke-brand-50" },
    },
    ...conditionsSummary.map((condition) => ({
      key: condition.type,
      percentage: condition.yieldDecrementPercentage,
      pathProps: { stroke: getColorFromString(condition.type) },
    })),
  ];

  const { isOpen, onDecline, onConfirm, getConfirmation } = useConfirm();
  const navigate = useNavigate();

  const handleSuccessDeleteGrowingPlan = () => {
    onConfirm();
    navigate(routes.GrowingPlans);
  };

  const { mutate: updateStartAt, isPending: isPendingStartAt } = useUpdateGrowingPlanStartAtMutation({
    onSuccess: (data) => {
      if (!data.updateGrowingPlanStartAt.success) return;

      setIsStartDateEditMode(false);
      onChange();
    },
  });

  const handleDeleteClick = () => getConfirmation();

  const isStartDateEditable = state === GrowingPlanState.Scheduled;
  const [isStartDateEditMode, setIsStartDateEditMode] = useState(false);

  return (
    <>
      <div className={"space-y-4"}>
        <h2 className={"typo-h2"}>{t("Overview")}</h2>
        <div className={"flex flex-col gap-4 lg:grid lg:grid-cols-3"}>
          <Card as={"section"}>
            <h3 className={"typo-h3"}>
              {cropSheet.commonName}
              {cropSheet.variant && ` (${cropSheet.variant})`}
            </h3>
            <p>{t("Crop in the Plan")}</p>
            <Button as={Link} to={cropDetailPath} variant={"text-base"} icon={"arrow_right"} iconAlign={"right"}>
              {t("View the Crop Sheet")}
            </Button>
          </Card>
          <Card>
            <h3 className={"typo-h3"}>{block.name}</h3>
            <p>{t("Block of the {{ fieldName }}", { fieldName: block.field.name })}</p>
            <Button as={Link} to={blockDetailPath} variant={"text-base"} icon={"arrow_right"} iconAlign={"right"}>
              {t("View the Block")}
            </Button>
          </Card>

          {robot ? (
            <Card>
              <h3 className={"typo-h3"}>{robot.name}</h3>
              <p>{t("Robot for automated Tasks")}</p>
            </Card>
          ) : null}
        </div>

        {/* Time info */}
        <Card as={"section"}>
          <h3 id={"progress-headline"} className={"typo-h3"}>
            {state === GrowingPlanState.Scheduled ? (
              <>
                {t("Scheduled launch in {{ days }}", {
                  days: t("dayWithCount", { count: planScheduleInDays ?? 0 }),
                })}{" "}
                <span className={"whitespace-nowrap"}>[{startAtFormatted}]</span>
              </>
            ) : state === GrowingPlanState.Finished ? (
              t("{{ progress }}% Done", { progress: planProgress })
            ) : state === GrowingPlanState.InProgress ? (
              <>
                {t("{{ progress }}% Done", { progress: planProgress })}{" "}
                <span className={"whitespace-nowrap"}>
                  [
                  {t("{{ doneDays }} of {{ totalDays }}", {
                    doneDays: planProgressInDays,
                    totalDays: planDuration,
                  })}
                  ]
                </span>
              </>
            ) : null}
          </h3>

          <div>
            <ProgressBar aria-labelledby={"progress-headline"} value={planProgress} max="100">
              {planProgress}
            </ProgressBar>

            <ul className={"list-inside list-decimal space-y-1"}>
              {stages.map((stage) => {
                return (
                  <li key={stage.id} className={clsx(stage.currentStage && "font-bold")}>
                    {stage.name} ({t("dayWithCount", { count: stage.duration ?? 0 })})
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={"mt-4 gap-2 lg:grid lg:grid-cols-4"}>
            <div>
              <strong>{t("Sowing")}</strong>
              <div>
                {isStartDateEditMode ? (
                  <StartAtForm
                    initDate={remoteGrowingPlan.startAt}
                    disabled={isPendingStartAt}
                    onSuccessSubmit={(startAt) => {
                      updateStartAt({ growingPlanId: remoteGrowingPlan.id, startAt });
                    }}
                    onCancel={() => setIsStartDateEditMode(false)}
                  />
                ) : (
                  <>
                    {startAtFormatted}
                    {isStartDateEditable ? (
                      <>
                        [{" "}
                        <Button
                          type={"button"}
                          variant={"text-base"}
                          size={"small"}
                          onClick={() => setIsStartDateEditMode(true)}
                        >
                          {t("Edit")}
                        </Button>
                        ]
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            <div>
              <strong>{t("Harvest")}</strong>
              <div>{endAtFormatted}</div>
            </div>
            <div>
              <strong>{t("Plan Duration")}</strong>
              <div>{t("dayWithCount", { count: planDuration })}</div>
            </div>
            <div>
              <strong>{t("Created")}</strong>
              <div>{createdAtFormatted}</div>
            </div>
          </div>
        </Card>

        <Card as={"section"}>
          <h3 className={"typo-h3"}>{t("Potential Yield")}</h3>

          <div className={"flex flex-col sm:gap-4 lg:flex-row"}>
            <PercentageProgressCircle
              items={percentageProgressCircleItems}
              className={"mx-auto max-w-[20rem]"}
              aria-hidden
            />

            <div className={"flex-grow"}>
              <h4 id={"reduced-crop-yeld-label"} className={"typo-h4"}>
                {t("Reduces crop yield")}
              </h4>
              <ul className={"list-inside"} aria-labelledby={"reduced-crop-yeld-label"}>
                <li>
                  <strong>{expectedYieldPercentageFormatted} = Expected Yield</strong> ({expectedYieldFormatted})
                </li>
                {conditionsSummary.map((condition) => {
                  return (
                    <li key={condition.type}>
                      {t("{{ value }} for {{ name }}", {
                        value: condition.yieldDecrementPercentageFormatted,
                        name: condition.type,
                      })}{" "}
                      {condition.yieldDecrementFormatted}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Card>

        <Card as={"section"}>
          <h3 className={"typo-h3"}>{t("Crop Stages")}</h3>

          <div className={"max-w-full overflow-auto"}>
            <CropStagesTable cropStages={continuousStages} />
          </div>
        </Card>

        <Card as={"section"}>
          <h3 className={"typo-h3"}>{t("Crop Sheet")}</h3>

          <div className={"max-w-full overflow-auto"}>
            <table cellPadding={6}>
              <thead>
                <tr>
                  <th className={"invisible"} colSpan={3} aria-hidden />
                </tr>
              </thead>
              <tbody>
                {mappedConditionsForTable.map((condition) => {
                  return (
                    <Fragment key={condition.id}>
                      <tr className={"odd:bg-light-25 even:bg-light-50"}>
                        <td rowSpan={2} className={"bg-dark-75 text-light-0 whitespace-nowrap uppercase"}>
                          {condition.name}
                        </td>
                        <td className={"bg-dark-75 text-light-0 whitespace-nowrap"}>{t("Optimal")}</td>
                        <td className={clsx("whitespace-nowrap", condition.className)}>{condition.optimal}</td>
                      </tr>
                      <tr className={"odd:bg-light-25 even:bg-light-50"}>
                        <td className={"bg-dark-75 text-light-0 whitespace-nowrap"}>{t("My Simulated Data")}</td>
                        <td className={clsx("whitespace-nowrap", condition.className)}>{condition.simulated}</td>
                      </tr>

                      {/* rows separator */}
                      <tr aria-hidden className={"bg-none last:hidden"}>
                        <td className={"pt-4"} />
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Card>

        <Card as={"section"} className={"flex justify-end"}>
          <Button type={"button"} icon={"trash"} variant={"primary-negative"} onClick={handleDeleteClick}>
            Delete this Growing Plan
          </Button>
          <DeleteGrowingPlanConfirmationModal
            isOpen={isOpen}
            data={{ growingPlanId: remoteGrowingPlan.id }}
            onSuccess={handleSuccessDeleteGrowingPlan}
            onClose={onDecline}
          />
        </Card>
      </div>
    </>
  );
};
