import { useAppTitle } from "@/utils/useAppTitle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RegisterContent } from "./components/RegisterContent";
import { SuccessContent } from "./components/SuccessContent";

export const RegisterPage = () => {
  const [isSuccessRegister, setIsSuccessRegister] = useState(false);
  const { t } = useTranslation("public");
  useAppTitle(t("Register"));

  const handleSuccessRegister = () => setIsSuccessRegister(true);

  return (
    <main className={"mx-auto flex max-w-[20rem] grow flex-col items-center justify-center gap-4"}>
      {isSuccessRegister ? <SuccessContent /> : <RegisterContent onSuccess={handleSuccessRegister} />}
    </main>
  );
};
