import { ManageSegmentsPage } from "@/field/segments/ManageSegmentsPage";
import type { routes } from "@/routes";
import { type ParamParseKey, useParams } from "react-router-dom";

const ManageSegmentsRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.ManageSegments>>();
  if (!id) throw new Error("Missing ID");

  return <ManageSegmentsPage fieldId={id} />;
};

export default ManageSegmentsRoute;
