import type { FloatValueStringValue } from "@/api/sdk";
import { logger } from "@/logger";

export function mapRemoteFloatValueStringValueToNumber(value: FloatValueStringValue | null) {
  if (value?.__typename === "FloatValue") {
    return value.floatValue;
  }

  if (value?.__typename === "StringValue") {
    const parsed = Number.parseFloat(value.stringValue);
    const isValidNumber = !Number.isNaN(parsed);

    if (isValidNumber) return parsed;
    logger.warn(`The value "${value.stringValue}" is not a valid float value`);
  }

  return 0;
}
