import { RouterTabs } from "@/components/RouterTabs";
import { generatePath, routes } from "@/routes";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const TABS = ["general", "tasks"] as const;

type BlockDetailTabsProps = Omit<ComponentProps<typeof RouterTabs>, "id" | "items" | "size" | "panelClassName">;

export const BlockDetailTabs = ({
  id,
  blockId,
  children,
  hideTasks,
}: BlockDetailTabsProps & {
  id: string;
  blockId: string;
  hideTasks: boolean;
}) => {
  const { t } = useTranslation();

  const tabItems = useMemo(() => {
    const mapTabToLabel: Record<(typeof TABS)[number], string> = {
      general: t("General"),
      tasks: t("Tasks"),
    };

    const mapTabToPath = {
      general: generatePath(routes.BlockDetailGeneral, { id, blockId }),
      tasks: generatePath(routes.BlockDetailTasks, { id, blockId }),
    } satisfies Record<(typeof TABS)[number], string>;

    return TABS.flatMap((tab) => {
      if (tab === "tasks" && hideTasks) return [];
      return [
        {
          id: tab,
          label: t(mapTabToLabel[tab]),
          path: mapTabToPath[tab],
        },
      ];
    });
  }, [t, id, blockId, hideTasks]);

  return (
    <RouterTabs
      id={"crop-sheet-tabs"}
      items={tabItems}
      size={"large"}
      panelProps={{ className: "mt-4 grow flex flex-col" }}
    >
      {children}
    </RouterTabs>
  );
};
