import type { PolymorphicProps } from "@roboton/tools";
import clsx from "clsx";
import type { ElementType } from "react";

type CardProps = JSX.IntrinsicElements["div"] & {
  // control the visual appearance of the card
  variant?: "base" | "flat" | "none";
};

const variantToClassMap: Record<Required<CardProps>["variant"], string> = {
  base: "bg-light-0 rounded-lg shadow-sm",
  flat: "bg-light-0 rounded-lg border border-light-50",
  none: "",
};

/**
 * Card with minimal styling
 */
export const Card = <T extends ElementType = "div">({
  as: El,
  variant = "base",
  className,
  children,
  ...rest
}: PolymorphicProps<T, CardProps>): JSX.Element => {
  const As = El || "div";
  return (
    <As {...rest} className={clsx("p-6", variantToClassMap[variant], className)}>
      {children}
    </As>
  );
};
