import { createContext, useContext } from "react";

type ContextValue = {
  isLoading: boolean;
  isLogged: boolean;
  onLogin: (token: string) => Promise<void>;
  onLogout: () => Promise<void>;
};

export const AuthContext = createContext<ContextValue | undefined>(undefined);
// Authorization context.
export function useAuthStore(): ContextValue {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthStore must be used within a AuthProvider");
  }

  return context;
}
