import {
  GpsTraceState,
  GpsTraceType,
  useBoundariesGpsTracesQuery,
  useUpdateFieldImportedBoundariesMutation,
} from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { DashboardContent } from "@/components/DashboardContent";
import { LoadingContent } from "@/components/LoadingContent";
import { Layer, MapComponent, MapUtils, Source } from "@/components/Map";
import { GpsTraceRadio } from "@/field/boundaries/components/GpsTraceSelectBox";
import { SelectBoundariesConfirmationModal } from "@/field/boundaries/components/SelectBoundariesConfirmationModal";
import type { FieldBasicModel } from "@/field/field.model";
import { useFieldBasicTransformedQuery } from "@/field/useFieldBasicTransformedQuery";
import { generatePath, routes } from "@/routes";
import { theme } from "@/theme";
import { notify } from "@/utils/Notifications";
import { useConfirm } from "@/utils/useConfirm";
import { Button } from "@roboton/ui";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { MapRef } from "react-map-gl/maplibre";

type ContentProps = { field: FieldBasicModel; headline: string };

export const ImportedBoundariesPageContent = ({ field, headline }: ContentProps) => {
  const { id: fieldId } = field;

  const { t } = useTranslation();

  const fieldDetailPath = generatePath(routes.FieldDetail, { id: fieldId });

  const [selectedTraceId, setSelectedTraceId] = useState<string | null>(null);

  const queryClient = useQueryClient();
  const { data, isLoading } = useBoundariesGpsTracesQuery({}, { refetchInterval: 5000 });
  const { mutateAsync, isPending: isMutating } = useUpdateFieldImportedBoundariesMutation();

  const gpsTraces = useMemo(() => {
    return data?.boundariesGpsTraces.nodes.filter(
      (trace) => trace.type === GpsTraceType.Boundaries && trace.state === GpsTraceState.Ready,
    );
  }, [data?.boundariesGpsTraces.nodes]);

  useEffect(() => {
    if (gpsTraces?.length && !selectedTraceId) {
      setSelectedTraceId(gpsTraces[0].id);
    }
  }, [gpsTraces, selectedTraceId]);

  const {
    isOpen: isConfirmationModalOpen,
    onConfirm: onConfirmationModalConfirm,
    onDecline: onConfirmationModalDecline,
    getConfirmation: getConfirmationModalConfirmation,
  } = useConfirm();

  const onGpsTraceSelect = (id: string) => {
    setSelectedTraceId(id);

    const coords = gpsTraces?.find((trace) => trace.id === id)?.coordinates;

    if (coords && mapRef.current) {
      mapRef.current.fitBounds(MapUtils.getLngLatBounds(MapUtils.getCoords(coords)), { padding: 40 });
    }
  };

  const handleSaveClick = async () => {
    if (!selectedTraceId) return;

    const isConfirmed = await getConfirmationModalConfirmation();
    if (!isConfirmed) return;

    try {
      const res = await mutateAsync({ fieldId, gpsTraceId: selectedTraceId });
      if (res.updateFieldImportedBoundaries.success) {
        await queryClient.refetchQueries({ queryKey: useFieldBasicTransformedQuery.getKey({ fieldId }) });
        notify.positive(t("Boundaries have been successfully saved."));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const mapRef = useRef<MapRef>(null);

  return (
    <DashboardContent
      headline={headline}
      navigateBackTo={fieldDetailPath}
      headerArea={
        <Button type={"button"} onClick={handleSaveClick} icon={"check"} disabled={!selectedTraceId}>
          {t("Select boundaries")}
        </Button>
      }
    >
      <Bricks.Layout
        mapArea={
          <Bricks.MapArea>
            {isMutating ? <LoadingContent /> : null}
            {gpsTraces?.length ? (
              <MapComponent
                enableControls
                mapRef={mapRef}
                initialViewState={{
                  bounds: MapUtils.getLngLatBounds(MapUtils.getCoords(gpsTraces?.[0]?.coordinates ?? [])),
                  padding: {
                    top: 40,
                    bottom: 40,
                    left: 40,
                    right: 40,
                  },
                }}
              >
                {gpsTraces.map((gpsTrace) => {
                  const isSelected = selectedTraceId === gpsTrace.id;
                  const color = isSelected ? theme.colors.blue["50"] : "grey";
                  const opacity = isSelected ? 0.6 : 0.3;

                  return (
                    <Source
                      key={gpsTrace.id}
                      {...MapUtils.getPolygonSourceProps(gpsTrace.id, MapUtils.getCoords(gpsTrace?.coordinates ?? []))}
                    >
                      <Layer
                        id={gpsTrace.id}
                        {...MapUtils.getPolygonLayerProps(gpsTrace.id, {
                          color,
                          opacity,
                        })}
                      />
                      <Layer {...MapUtils.getTextLayerProps(gpsTrace.id, gpsTrace.name)} />
                    </Source>
                  );
                })}
              </MapComponent>
            ) : (
              <div className={"flex flex-col gap-4"}>
                <LoadingContent />
                <p className={"text-center"}>{t("Waiting for data from the mobile app...")}</p>
              </div>
            )}
          </Bricks.MapArea>
        }
        mapAreaSize={"large"}
      >
        <Bricks.Card headline={t("Choose Boundaries")}>
          <p>
            {t(
              "This is the path imported from the mobile app. The shape of this path will be slightly simplified and used as a field boundary.",
            )}
          </p>

          {gpsTraces?.map((gpsTrace) => (
            <GpsTraceRadio
              key={gpsTrace.id}
              onClick={onGpsTraceSelect}
              gpsTrace={gpsTrace}
              isSelected={selectedTraceId === gpsTrace.id}
            />
          ))}

          {!gpsTraces?.length && !isLoading ? (
            <p className={"text-center"}>{t("Waiting for data from the mobile app...")}</p>
          ) : null}
        </Bricks.Card>
      </Bricks.Layout>

      <SelectBoundariesConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={onConfirmationModalDecline}
        onConfirm={onConfirmationModalConfirm}
      />
    </DashboardContent>
  );
};
