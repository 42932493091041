type Stage = Record<string, unknown> & {
  conditions: (Record<string, unknown> & {
    name: string;
    type: string;
  })[];
};

/**
 * Group stages by conditions.
 * Return array of objects with condition and all stages that have this condition.
 * The stages will have the condition property with the condition object.
 */
export function groupStagesByConditions<T extends Stage>(stages: T[]) {
  type Condition = T["conditions"][number];

  type TransformedData = {
    condition: {
      name: Condition["name"];
      type: Condition["type"];
    };
    stages: (Omit<T, "conditions"> & { condition: Condition | null })[];
  };

  // get all unique conditions from all stages
  const uniqueConditions = [
    ...new Set(
      stages.flatMap((stage) =>
        stage.conditions.flatMap((condition) => ({
          name: condition.name,
          type: condition.type,
        })),
      ),
    ),
  ];

  const conditionsMap: Map<Condition["type"], TransformedData> = new Map();

  for (const condition of uniqueConditions) {
    conditionsMap.set(condition.type, {
      condition,
      stages: stages.map((stage) => {
        return {
          ...stage,
          // add first condition that matches the current condition type
          condition: stage.conditions.find((c) => c.type === condition.type) ?? null,
        };
      }),
    });
  }

  return Array.from(conditionsMap.values());
}
