import { useUpdateFieldManualBoundariesMutation } from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { DashboardContent } from "@/components/DashboardContent";
import type { Coords } from "@/components/Map/types";
import { ManuallyBoundariesMap } from "@/field/boundaries/components/ManuallyBoundariesMap";
import { PruneFieldConfirmationModal } from "@/field/components/PruneFieldConfirmationModal";
import type { FieldBasicModel } from "@/field/field.model";
import { useFieldTransformedQuery } from "@/field/useFieldTransformedQuery";
import { generatePath, routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { formatCoordinates } from "@/utils/format";
import { useConfirm } from "@/utils/useConfirm";
import { padWithZeros } from "@roboton/tools";
import { removeDuplicates } from "@roboton/tools/src/removeDuplicates";
import { Button } from "@roboton/ui";
import { useQueryClient } from "@tanstack/react-query";
import type { ComponentProps } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type ContentProps = { field: FieldBasicModel; isEditMode?: boolean; headline: string };

export const ManualBoundariesPageContent = ({ field, isEditMode, headline }: ContentProps) => {
  const { id: fieldId } = field;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const fieldDetailPath = generatePath(routes.FieldDetail, { id: fieldId });

  const [isTouched, setIsTouched] = useState(false);

  const [manuallyCoords, setManuallyCoords] = useState<Coords[]>(() => field.coords);

  const { mutate: updateManualBoundaries } = useUpdateFieldManualBoundariesMutation({
    onSuccess: ({ updateFieldManualBoundaries: { success } }) => {
      if (!success) return;

      const message = isEditMode
        ? t("All changes have been successfully saved.")
        : t("Field boundaries have been successfully added.");
      notify.positive(message);

      // remove field query to prevent rendering old map on the FieldDetail page.
      queryClient.removeQueries({ queryKey: useFieldTransformedQuery.getKey({ fieldId }), exact: true });
      navigate(fieldDetailPath);
    },
  });

  const saveManualBoundaries = () => {
    const boundaries = manuallyCoords.map(([lon, lat]) => ({ lat, lon }));
    updateManualBoundaries({ fieldId, input: { boundaries } });
  };

  const handleMapChange: ComponentProps<typeof ManuallyBoundariesMap>["onChange"] = (feature) => {
    setManuallyCoords((feature?.geometry.coordinates[0] as Coords[]) || []);
    setIsTouched(true);
  };

  const isSaveDisabled = !isTouched || !manuallyCoords.length;

  const {
    isOpen: isPruneModalOpen,
    onConfirm: onPruneModalConfirm,
    onDecline: onPruneModalDecline,
    getConfirmation: getPruneModalConfirmation,
  } = useConfirm();

  const handleSaveClick = async () => {
    if (isEditMode) {
      const isConfirmed = await getPruneModalConfirmation();
      isConfirmed && saveManualBoundaries();
      return;
    }
    saveManualBoundaries();
  };

  return (
    <DashboardContent
      headline={headline}
      navigateBackTo={fieldDetailPath}
      headerArea={
        <Button type={"button"} disabled={isSaveDisabled} onClick={handleSaveClick}>
          {t("Save")}
        </Button>
      }
    >
      <Bricks.Layout
        mapArea={<ManuallyBoundariesMap fieldId={fieldId} initCoords={manuallyCoords} onChange={handleMapChange} />}
        mapAreaSize={"large"}
      >
        <Bricks.Card headline={t("Boundary Points")}>
          <p>{t("Mark the field boundary points by clicking on the map.")}</p>
          <ul className={"space-y-2 overflow-auto"}>
            {removeDuplicates(manuallyCoords, ([lon, lat]) => `${lon}${lat}`).map(([lon, lat], index) => {
              return (
                <li key={`${lon}${lat}`}>
                  <strong>{padWithZeros(index + 1, 2)})</strong> {formatCoordinates({ lat, lon })}
                </li>
              );
            })}
          </ul>
        </Bricks.Card>
      </Bricks.Layout>

      <PruneFieldConfirmationModal
        isOpen={isPruneModalOpen}
        onClose={onPruneModalDecline}
        onConfirm={onPruneModalConfirm}
      />
    </DashboardContent>
  );
};
