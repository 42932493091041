import type { GrowingPlanTransformedQuery } from "@/growing-plan/growing-plan-detail/useGrowingPlanTransformedQuery";
import { roundToDecimal } from "@/utils/format";

export function resolveConditionValues({
  min,
  max,
  single,
  unit,
}: {
  min?: number;
  max?: number;
  single?: number;
  unit: string;
}) {
  if (single !== undefined) return `${single}${unit}`;

  if (min === undefined || max === undefined) return undefined;

  const minFormatted = min === Number.POSITIVE_INFINITY || min === Number.NEGATIVE_INFINITY ? "∞" : `${min}${unit}`;
  const maxFormatted = max === Number.POSITIVE_INFINITY || max === Number.NEGATIVE_INFINITY ? "∞" : `${max}${unit}`;

  return `${minFormatted} — ${maxFormatted}`;
}

type Condition = Pick<
  GrowingPlanTransformedQuery["growingPlan"]["conditions"][number],
  | "id"
  | "name"
  | "status"
  | "metric"
  | "metricLow"
  | "metricHigh"
  | "unit"
  | "thresholdLow"
  | "thresholdHigh"
  | "nominalValue"
  | "nominalOptimal"
>;

export function transformRemoteGrowingPlanConditionTypeToTableCell<T extends Condition>(condition: T) {
  const { metric, metricLow, metricHigh, unit, thresholdLow, thresholdHigh, nominalValue, nominalOptimal } = condition;

  const simulatedChildren =
    nominalValue ??
    resolveConditionValues({
      min: typeof metricLow === "number" ? roundToDecimal(metricLow, 2) : undefined,
      max: typeof metricHigh === "number" ? roundToDecimal(metricHigh, 2) : undefined,
      single: typeof metric === "number" ? roundToDecimal(metric, 2) : undefined,
      unit: unit ?? "",
    });

  const optimalChildren =
    nominalOptimal?.join(", ") ??
    resolveConditionValues({
      min: thresholdLow,
      max: thresholdHigh,
      unit: unit ?? "",
    });

  return {
    ...condition,
    simulated: simulatedChildren,
    optimal: optimalChildren,
  };
}
