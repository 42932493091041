import { PlaceholderCard } from "@/components/PlaceholderCard";
import { useCreateMultitaskMutation } from "@/field-task/apiHooks";
import type { CreateNewTaskForm } from "@/field-task/components/CreateNewTaskForm";
import { CreateNewTaskModal } from "@/field-task/components/CreateNewTaskModal";
import { FieldTasksList } from "@/field-task/components/FieldTasksList";
import { fieldTaskUtils } from "@/field-task/utils";
import { useAppTitle } from "@/utils/useAppTitle";
import { useConfirm } from "@/utils/useConfirm";
import { Button } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { useBlockDetailLayoutContext } from "./BlockDetailLayout";

export const BlockDetailTasksPage = () => {
  const { t } = useTranslation();
  useAppTitle(t("Block Detail"));

  const { remoteTasks, remoteBlock, fieldTaskTypes, parametrization, onTasksChange } = useBlockDetailLayoutContext();

  const {
    isOpen: isCreateTaskModalOpen,
    onConfirm: onCreateTaskModalConfirm,
    onDecline: onCreateTaskModalDecline,
    getConfirmation: getCreateTaskModalConfirmation,
  } = useConfirm();

  const handleCreateTaskClick = () => getCreateTaskModalConfirmation();
  const { mutate: createTaskMutate } = useCreateMultitaskMutation({
    onAfterSuccess: () => {
      onTasksChange();
      onCreateTaskModalConfirm();
    },
  });

  const handleCreateNewTaskFormSuccessSubmit: ComponentProps<typeof CreateNewTaskForm>["onSuccessSubmit"] = (data) => {
    createTaskMutate({
      fieldTaskInput: fieldTaskUtils.transformNewTaskFormDataToFieldTaskInput(data, parametrization),
    });
  };

  return (
    <>
      <Button type={"button"} onClick={handleCreateTaskClick} className={"mb-4 ml-auto"}>
        Add new task
      </Button>

      <CreateNewTaskModal
        isOpen={isCreateTaskModalOpen}
        entities={[{ entityType: "block", id: remoteBlock.id, name: remoteBlock.name }]}
        onSuccessSubmit={handleCreateNewTaskFormSuccessSubmit}
        onClose={onCreateTaskModalDecline}
      />

      {remoteTasks.totalCount > 0 ? (
        <FieldTasksList
          tasks={remoteTasks}
          fieldTaskTypes={fieldTaskTypes}
          allowExtendedActions={false}
          onDeleteSuccess={onTasksChange}
        />
      ) : (
        <PlaceholderCard icon={"calendar"} description={t("No Tasks")} className={"py-20"} />
      )}
    </>
  );
};
