import {
  type FieldTaskParametrizationType,
  type FieldTaskTypesQuery,
  FieldTaskTypesWithDefaultValuesDocument,
  type FieldTaskTypesWithDefaultValuesQuery,
  type PlantSpacingPattern,
  type Scalars,
  useFieldTaskTypesQuery,
  useFieldTaskTypesWithDefaultValuesQuery,
} from "@/api/sdk";
import { keepPreviousData, useQueries } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns";
import { fetcher } from "../../api/fetcher";

function transform<T extends FieldTaskTypesQuery>({ fieldTaskTypes, ...data }: T, isForBlock: boolean) {
  const filteredTypes = fieldTaskTypes.filter((fieldTaskType) => {
    return isForBlock ? fieldTaskType.availableForBlock : true;
  });

  // get all unique groups
  const uniqueGroups = filteredTypes.reduce((acc: (typeof filteredTypes)[number]["group"][], fieldTaskType) => {
    if (!acc.some((group) => group.code === fieldTaskType.group.code)) {
      acc.push(fieldTaskType.group);
    }
    return acc;
  }, []);

  // get all unique parametrization types
  const uniqueParametrization = [...new Set(filteredTypes.flatMap((fieldTaskType) => fieldTaskType.parametrization))];

  return {
    ...data,
    parametrization: uniqueParametrization,
    fieldTaskGroups: uniqueGroups,
    fieldTaskTypes: filteredTypes,
  };
}

/**
 * Get unique field task groups and add them to the API response
 */
export const useFieldTaskTypesTransformedQuery = (
  variables: {
    availableJustForBlock: boolean;
  },
  options?: Parameters<typeof useFieldTaskTypesQuery>[1] & { select?: never },
) => {
  return useFieldTaskTypesQuery(undefined, {
    ...options,
    select: (data) => transform(data, variables.availableJustForBlock),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(5),
  });
};

// Same as above but with default values for the parametrization, min, max, step etc.
// The default values might depend on the Growing Plan ID.
// In most cases, we don't need it and we can use the above hook which is cached .
export const useFieldTaskTypesWithDefaultsTransformedQuery = (
  variables: {
    availableJustForBlock: boolean;
    growingPlanId?: string;
  },
  options?: Parameters<typeof useFieldTaskTypesWithDefaultValuesQuery>[1] & { select?: never },
) => {
  return useFieldTaskTypesWithDefaultValuesQuery(
    {
      growingPlanId: variables.growingPlanId,
    },
    {
      ...options,
      select: (data) => transform<FieldTaskTypesWithDefaultValuesQuery>(data, variables.availableJustForBlock),
    },
  );
};

type FieldTaskTypesQueriesData = {
  group: { code: string; commonName: string };
  type: {
    commonName: string;
    type: string;
    parametrization: {
      __typename?: "FieldTaskParametrization";
      code: string;
      max: number | null;
      min: number | null;
      name: string;
      step: number | null;
      type: FieldTaskParametrizationType;
      unit: string | null;
      default:
        | { __typename: "BoolValue"; boolValue: boolean }
        | { __typename: "FloatValue"; floatValue: number }
        | { __typename: "IntListValue"; intListValue: Array<number> }
        | { __typename: "IntValue"; intValue: number }
        | { __typename: "PlantSpacingPatternValue"; patternValue: PlantSpacingPattern }
        | { __typename: "StringValue"; stringValue: string }
        | null;
    }[];
  }[];
}[];

type FieldTaskTypesQueriesVariables = { growingPlanId: Scalars["UUID"]["input"] };
export const useFieldTaskTypesQueries = (variables: FieldTaskTypesQueriesVariables[]) => {
  return useQueries({
    combine: (results) => {
      return {
        data: results.flatMap(({ data }) => {
          if (!data) return [];
          const uniqueGroups = data.fieldTaskTypes.reduce(
            (acc: (typeof data.fieldTaskTypes)[number]["group"][], fieldTaskType) => {
              if (!acc.some((group) => group.code === fieldTaskType.group.code)) {
                acc.push(fieldTaskType.group);
              }
              return acc;
            },
            [],
          );

          return uniqueGroups.map((group) => {
            return {
              entity: {
                id: data.growingPlanId,
              },
              group: {
                code: group.code,
                commonName: group.commonName,
              },
              type: data.fieldTaskTypes
                .filter((fieldTaskType) => fieldTaskType.group.code === group.code)
                .map((fieldTaskType) => {
                  return {
                    commonName: fieldTaskType.commonName,
                    type: fieldTaskType.type,
                    parametrization: fieldTaskType.parametrization,
                  };
                }),
            };
          });
        }) satisfies FieldTaskTypesQueriesData,
        isPending: results.some((result) => result.isPending),
        isError: results.some((result) => result.isError),
      };
    },
    queries: variables.map(({ growingPlanId }) => {
      const [keyPrefix, keyVariables] = useFieldTaskTypesWithDefaultValuesQuery.getKey({ growingPlanId });
      return {
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: [`${keyPrefix}-bulk`, keyVariables],
        queryFn: async () => {
          const data = await fetcher<FieldTaskTypesWithDefaultValuesQuery, FieldTaskTypesQueriesVariables>(
            FieldTaskTypesWithDefaultValuesDocument,
            // add growingPlanId to the data
            growingPlanId ? { growingPlanId } : undefined,
          )();

          if (!data) {
            return Promise.reject(new Error("No data"));
          }

          return { growingPlanId, ...data };
        },
      };
    }),
  });
};

export type FieldTaskTypesTransformedQuery = ReturnType<typeof transform>;
