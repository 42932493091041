import type { FieldCardFragment } from "@/api/sdk";
import { Card } from "@/components/Card";
import { GrowingPlanInfoRow } from "@/components/GrowingPlanInfoRow/GrowingPlanInfoRow";
import { Spacer } from "@/components/Spacer";
import { generatePath, routes } from "@/routes";
import { formatNumberToSquareMeters } from "@/utils/format";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { Button } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type FieldCardProps = {
  field: FieldCardFragment;
  onDeleteClick: (id: string) => void;
};

export const FieldCard = ({ field, onDeleteClick }: FieldCardProps) => {
  const { t } = useTranslation();
  const { tEnums } = useTranslationEnums();
  const detailPath = generatePath(routes.FieldDetail, { id: field.id });

  const handleDeleteClick: Required<ComponentProps<typeof Button<"button">>>["onClick"] = (e) => {
    e.preventDefault();
    onDeleteClick(field.id);
  };

  return (
    <Card key={field.id} variant={"flat"} className={"flex flex-col space-y-6"}>
      <div className={"flex grow flex-col"}>
        <div className={"flex items-start justify-between gap-1"}>
          <h3 className={"typo-h3"}>{field.name}</h3>
          <Button
            as={"button"}
            type={"button"}
            icon={"trash"}
            variant={"text-negative"}
            size={"small"}
            aria-label={t("Delete field")}
            onClick={handleDeleteClick}
          />
        </div>

        {field.blocks?.length ? (
          <div className={"space-y-2"}>
            {field.blocks.map((block) => (
              <div key={block.id} className={"bg-light-25 border-light-50 space-y-1 rounded border p-2"}>
                <div className={"typo-sm"}>
                  {block.name}{" "}
                  <span className={"text-light-100"}>
                    [{t("Growing Area")} {formatNumberToSquareMeters(block.area)}]
                  </span>
                </div>
                {/* growing plans */}
                {block.growingPlans?.length ? (
                  <div className={"space-y-2 pl-3"}>
                    {block.growingPlans?.map((plan) => {
                      return <GrowingPlanInfoRow key={plan.id} remoteGrowingPlan={plan} />;
                    })}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          <div className={"text-light-100 flex grow flex-col items-center justify-center py-4 text-center"}>
            {t("This Field has no Blocks yet.")}
          </div>
        )}
      </div>
      <Spacer />
      <div className={"flex flex-wrap justify-between gap-2"}>
        <p className={"typo-sm text-light-100"}>
          <span className={"sr-only"}>Cultivation method: </span>
          {tEnums("cultivationMethod", field.cultivation)}
        </p>
        <Button as={Link} to={detailPath} size={"small"} icon={"arrow_right"} className={"ml-auto"} iconAlign={"right"}>
          {t("Detail")}
        </Button>
      </div>
    </Card>
  );
};
