// transform enum values to object with key and value as enum value
export function enumValuesToObject<T extends Record<string, string | number>>(enumObj: T) {
  const result = {};

  for (const key in enumObj) {
    if (typeof enumObj[key] === "string" || typeof enumObj[key] === "number") {
      const value = enumObj[key];
      // @ts-expect-error
      result[value] = value;
    }
  }

  return result as Record<T[keyof T], T[keyof T]>;
}
