import { Notification } from "@roboton/ui";
import type { ComponentPropsWithoutRef } from "react";
import { type ToastOptions, Toaster, type ToasterProps, toast as toastOrigin } from "react-hot-toast";
import { useTranslation } from "react-i18next";

type NotificationProps = ComponentPropsWithoutRef<typeof Notification>;
type CustomToastOptions = ToastOptions & Partial<Pick<NotificationProps, "a11y">>;

function createHandler(color: NotificationProps["color"]) {
  return (message: string, { a11y, ...originOptions }: CustomToastOptions = {}) => {
    return toastOrigin.custom((t) => {
      const id = t.id || t.createdAt.toString();
      const close = () => toastOrigin.dismiss(id);

      return (
        <Notification
          {...t.ariaProps}
          aria-live="assertive"
          role="status"
          a11y={a11y || { closeLabel: "" }}
          color={color}
          className={t.visible ? "" : "hidden"} // disable animation
          style={t.style}
          onClose={close}
        >
          {message}
        </Notification>
      );
    }, originOptions);
  };
}

/*
 * Re-define origin toast to custom names.
 *
 * Examples of usage:
 * notify.positive("My own message");
 * notify.positive("My own message", { duration: 5000 });
 * notify.positive("My own message", { duration: 5000, key: "my-own-key" });
 * */
const notification = {
  positive: createHandler("green"),
  negative: createHandler("red"),
  warning: createHandler("yellow"),
  neutral: createHandler("gray"),
} satisfies Record<string, ReturnType<typeof createHandler>>;

const actions = {
  remove: (notifyId: string) => toastOrigin.remove(notifyId),
  dismiss: (notifyId: string) => toastOrigin.dismiss(notifyId),
};

const notify = { ...notification, ...actions };

type NotificationsProviderProps = {
  position?: ToasterProps["position"];
  style?: ToasterProps["containerStyle"];
  className?: ToasterProps["containerClassName"];
  toastOptions?: Pick<ToastOptions, "duration" | "ariaProps"> & Pick<NotificationProps, "a11y">;
};
const NotificationsProvider = ({
  position = "top-right",
  style,
  className,
  toastOptions = { a11y: { closeLabel: "" } },
}: NotificationsProviderProps) => {
  const { t } = useTranslation("public");
  return (
    <Toaster
      position={position}
      containerStyle={style}
      containerClassName={className}
      toastOptions={
        {
          duration: toastOptions.duration || 4000,
          ariaProps: toastOptions.ariaProps,
          a11y: { closeLabel: t("Close") },
        } as typeof toastOptions
      }
    />
  );
};

export { NotificationsProvider, notify };
