import {
  GrowingPlannerFieldState,
  RoutePlannerBlockState,
  useDataForCreateGrowingPlanBaseInfoContentQuery,
  useFieldBlocksForCreateGrowingPlanBaseInfoContentQuery,
} from "@/api/sdk";
import { keepPreviousData } from "@tanstack/react-query";

type Props = {
  fieldId: string;
};

const isFieldReadyToGrowing = (state: GrowingPlannerFieldState) => state === GrowingPlannerFieldState.Ready;
const isBlockReadyToGrowing = (state: RoutePlannerBlockState) =>
  state === RoutePlannerBlockState.Done || state === RoutePlannerBlockState.NoPath;

export const useCreateGrowingPlanBaseInfoData = ({ fieldId }: Props) => {
  // Data (Field and CropSheets)
  const {
    data: { fieldsOptions, cropsOptions } = {},
    isLoading: isLoadingData,
  } = useDataForCreateGrowingPlanBaseInfoContentQuery(undefined, {
    refetchOnWindowFocus: false,
    select: (data) => ({
      fieldsOptions: data?.fields?.nodes?.map((field) => ({
        value: field.id,
        label: field.name,
        isReady: isFieldReadyToGrowing(field.growingPlannerState),
      })),
      cropsOptions: data?.cropSheetList?.crops?.map((crop) => ({
        value: crop.id,
        label: `${crop.commonName}${crop.variant && ` (${crop.variant})`}`,
      })),
    }),
  });

  // Blocks
  const {
    data: { blocksOptions } = {},
    isFetching: isBlockOptionsListFetching,
  } = useFieldBlocksForCreateGrowingPlanBaseInfoContentQuery(
    { fieldId },
    {
      enabled: !!fieldId,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      select: (data) => ({
        blocksOptions: data?.field?.blocks?.map((block) => ({
          value: block.id,
          label: block.name,
          isReady: isBlockReadyToGrowing(block.routePlannerState),
        })),
      }),
    },
  );

  return {
    isLoadingData,
    isBlockOptionsListFetching,

    options: {
      fields: fieldsOptions || [],
      crops: cropsOptions || [],
      blocks: blocksOptions || [],
    },
  };
};
