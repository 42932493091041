import {
  type ErrorCode,
  PreviewRowBasedPlantSpacingDocument,
  type PreviewRowBasedPlantSpacingQuery,
  type PreviewRowBasedPlantSpacingQueryVariables,
} from "@/api/sdk";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { fetcher } from "../../../../../api/fetcher";

export const useRowBasedPlantSpacingPreview = <
  TData = PreviewRowBasedPlantSpacingQuery,
  TError = { message: string; code: ErrorCode },
>(
  options?: UseMutationOptions<
    PreviewRowBasedPlantSpacingQuery,
    TError,
    PreviewRowBasedPlantSpacingQueryVariables,
    TData
  >,
) =>
  useMutation<PreviewRowBasedPlantSpacingQuery, TError, PreviewRowBasedPlantSpacingQueryVariables, TData>({
    mutationKey: ["previewPlantSpacing"],
    mutationFn: (variables?: PreviewRowBasedPlantSpacingQueryVariables) =>
      fetcher<PreviewRowBasedPlantSpacingQuery, PreviewRowBasedPlantSpacingQueryVariables>(
        PreviewRowBasedPlantSpacingDocument,
        variables,
      )(),
    ...options,
  });
