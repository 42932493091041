import { usePreviewSwathsQuery } from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { Layer, MapUtils, Source } from "@/components/Map";
import { Spinner } from "@/components/Spinner";
import { formatNumberToSquareMeters } from "@/utils/format";
import { useConfirm } from "@/utils/useConfirm";
import { padWithZeros } from "@roboton/tools";
import { Select } from "@roboton/ui";
import { keepPreviousData } from "@tanstack/react-query";
import { type ComponentProps, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldMap } from "../components/FieldMap";
import { PruneFieldConfirmationModal } from "../components/PruneFieldConfirmationModal";
import type { FieldBasicModel } from "../field.model";
import { ManualAngleForm } from "./components/ManualAngleForm";

const SELECT_MANUAL_ANGLE_VALUE = "manual-angle" as const;
const SELECT_DEFAULT_VALUE = "" as const;
type Options = string | typeof SELECT_MANUAL_ANGLE_VALUE | typeof SELECT_DEFAULT_VALUE;

type ManageWorkingLinesPageContentProps = {
  field: FieldBasicModel;
  formId: string;
  onSubmit: (data: { angle?: number; refPathSegmentId?: number }) => void;
  onChange: ({ isTouched, isValid }: { isTouched: boolean; isValid: boolean }) => void;
};

export const ManageWorkingLinesPageContent = ({
  field,
  formId,
  onSubmit,
  onChange,
}: ManageWorkingLinesPageContentProps) => {
  const { t } = useTranslation();

  const [manualAngle, setManualAngle] = useState<number | undefined>(field.swathAngle ?? undefined);
  const [selectedValue, setSelectedValue] = useState<Options>(() =>
    typeof manualAngle === "number"
      ? SELECT_MANUAL_ANGLE_VALUE
      : field.swathPathSegment?.id.toString() || SELECT_DEFAULT_VALUE,
  );

  const {
    isOpen: isPruneModalOpen,
    onConfirm: onPruneModalConfirm,
    onDecline: onPruneModalDecline,
    getConfirmation: getPruneModalConfirmation,
  } = useConfirm();

  const isManualAngleSelected = selectedValue === SELECT_MANUAL_ANGLE_VALUE;
  const isPreviewQueryEnabled = isManualAngleSelected ? typeof manualAngle === "number" : true;

  const apiVariables = {
    angle: isManualAngleSelected ? manualAngle : undefined,
    refPathSegmentId: !isManualAngleSelected && selectedValue.length ? Number(selectedValue) : undefined,
  };
  const {
    data: { previewSwaths, selectOptions, headlandCoords } = {},
    isFetching,
    isLoading,
  } = usePreviewSwathsQuery(
    { ...apiVariables, fieldId: field.id },
    {
      enabled: isPreviewQueryEnabled,
      retry: false,
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
      select: (data) => ({
        ...data,
        headlandCoords: MapUtils.getCoords(data?.previewSwaths.headland),
        selectOptions: [
          {
            label: t("Select an optimal path"),
            value: SELECT_DEFAULT_VALUE,
            disabled: true,
          },
          ...(data?.previewSwaths.autoOptimalPaths || []).map((path, index) => ({
            label: `${t("Auto-Optimal")} ${padWithZeros(index + 1, 2)}`,
            value: path.id.toString(),
          })),
          {
            label: t("Custom"),
            value: SELECT_MANUAL_ANGLE_VALUE,
          },
        ] satisfies JSX.IntrinsicElements["option"][],
      }),
    },
  );
  const { swaths = [] } = previewSwaths || {};

  const isManualAngleEditDisabled = isFetching || !isManualAngleSelected;
  const isAngleSets = (apiVariables.angle ?? apiVariables.refPathSegmentId) !== undefined;

  const handleManualAngleFormSubmit: ComponentProps<typeof ManualAngleForm>["onSuccessSubmit"] = (data) => {
    setManualAngle(data.angle);
    onChange({
      isTouched: true,
      isValid: typeof data.angle === "number",
    });
  };

  const handleOptimalPathChange: ComponentProps<typeof Select>["onChange"] = (event) => {
    const nextValue = event.target.value;
    setSelectedValue(nextValue);
    onChange({
      isTouched: true,
      isValid: nextValue !== SELECT_DEFAULT_VALUE && nextValue !== SELECT_MANUAL_ANGLE_VALUE,
    });
  };

  const isConfirmationRequired = field.hasSwaths;

  const handleSubmit: JSX.IntrinsicElements["form"]["onSubmit"] = async (e) => {
    e.preventDefault(); // Prevent page reload

    if (isConfirmationRequired) {
      const isConfirmed = await getPruneModalConfirmation();
      if (!isConfirmed) return;
    }

    onSubmit(apiVariables);
  };

  return (
    <>
      <Bricks.Layout
        mapArea={
          <Bricks.MapArea className={"relative"}>
            <FieldMap field={field}>
              {isAngleSets ? (
                <>
                  <Source {...MapUtils.getLineSourceProps("headland", headlandCoords || [])}>
                    <Layer {...MapUtils.getPolygonLayerProps("headland", { color: "#C6DDFF", opacity: 1 })} />
                    <Layer {...MapUtils.getLineLayerProps("headland", { color: "#406FDB", opacity: 1 })} />
                  </Source>

                  {swaths.map((swath) => {
                    const coords = MapUtils.getCoords([swath.startPoint, swath.endPoint]);
                    const id = coords.toString();
                    return (
                      <Source key={id} {...MapUtils.getLineSourceProps(id, coords)}>
                        <Layer {...MapUtils.getLineLayerProps(id, { color: "#203F9D", opacity: 1 })} />
                      </Source>
                    );
                  })}
                </>
              ) : null}
            </FieldMap>
            {isFetching ? (
              <div className={"bg-light-0 absolute inset-0 flex items-center justify-center bg-opacity-70"}>
                <Spinner />
              </div>
            ) : null}
          </Bricks.MapArea>
        }
        mapAreaSize={"large"}
      >
        <Bricks.Card headline={t("Working Lines of the field")}>
          <p className={"text-light-100"}>
            {t(
              "Setting the working area of the field to be used for growing the crop. The space outside this marked area is for field maintenance.",
            )}
          </p>

          <div className={"flex flex-col gap-4 md:grid md:grid-cols-2"}>
            <div>
              <strong>{t("Field Name")}</strong>: {field.details.name}
            </div>
            <div>
              <strong>{t("Field Area")}</strong>: {formatNumberToSquareMeters(field.details.area)}
            </div>
          </div>

          <h3 className={"typo-h3"}>{t("Angle of Working Lines")}</h3>
          <p>{t("Rows in which the crop is grown (Auto aimed by longest side)")}</p>

          <form id={formId} onSubmit={handleSubmit} className={"hidden"} />

          {isLoading ? (
            <Spinner className={"mx-auto"} />
          ) : (
            <>
              <Select
                id={"angle-select"}
                onChange={handleOptimalPathChange}
                value={selectedValue}
                disabled={isFetching}
              >
                {selectOptions?.map((option) => {
                  return <option key={option.value} {...option} />;
                })}
              </Select>
              {isManualAngleSelected ? (
                <ManualAngleForm
                  onSuccessSubmit={handleManualAngleFormSubmit}
                  defaultValues={{ angle: manualAngle }}
                  disabled={isManualAngleEditDisabled}
                />
              ) : null}
            </>
          )}
        </Bricks.Card>
      </Bricks.Layout>

      <PruneFieldConfirmationModal
        isOpen={isPruneModalOpen}
        onClose={onPruneModalDecline}
        onConfirm={onPruneModalConfirm}
      />
    </>
  );
};
