import { useBlocksSuggestionsQuery, useCropsSuggestionsQuery, useFieldsSuggestionsQuery } from "@/api/sdk";
import { keepPreviousData } from "@tanstack/react-query";
import { useState } from "react";
import { useFiltersStore } from "./FiltersStore";
import { AutosuggestionField } from "./ui/AutosuggestionField";

export const CropsAutosuggestion = () => {
  const {
    actions: { setCrop: setFilter },
    values: { crop: selected },
    searched: { crop: initialSearched },
    apiFilter,
  } = useFiltersStore();
  const [searchValue, setSearchValue] = useState<string>(() => initialSearched || "");

  const { data, isLoading } = useCropsSuggestionsQuery(
    {
      filters: {
        ...apiFilter,
        crop: {
          iContains: searchValue,
        },
        DISTINCT: true,
      },
    },
    {
      select: (data) => data.suggestion.map(({ id, combinedName }) => ({ id, label: combinedName })),
      placeholderData: keepPreviousData,
    },
  );

  return (
    <AutosuggestionField
      id={"crops"}
      isLoading={isLoading}
      searchValue={searchValue}
      data={data}
      selectedValue={selected || ""}
      onChangeSearchValue={setSearchValue}
      onChangeSelectedValue={(selectedId, searchValue) => {
        // return selectedValue to the parent component because API doesn't allow to filter by crop ID for now
        const selectedName = data?.find((item) => item.id === selectedId)?.label;
        setFilter(selectedId, selectedName || "", searchValue);
      }}
    />
  );
};
export const FieldsAutosuggestion = () => {
  const {
    actions: { setField: setFilter },
    values: { field: selected },
    searched: { field: initialSearched },
    apiFilter,
  } = useFiltersStore();
  const [searchValue, setSearchValue] = useState<string>(() => initialSearched || "");

  const { data, isLoading } = useFieldsSuggestionsQuery(
    {
      filters: {
        ...apiFilter,
        field: { iContains: searchValue },
        fieldId: null,
        DISTINCT: true,
      },
    },
    {
      select: (data) => data.suggestion.map(({ id, name }) => ({ id, label: name })),
      placeholderData: keepPreviousData,
    },
  );

  return (
    <AutosuggestionField
      id={"fields"}
      isLoading={isLoading}
      searchValue={searchValue}
      data={data}
      selectedValue={selected || ""}
      onChangeSearchValue={setSearchValue}
      onChangeSelectedValue={(selectedId, searchValue) => {
        setFilter(selectedId, searchValue);
      }}
    />
  );
};
export const BlocksAutosuggestion = () => {
  const {
    actions: { setBlock: setFilter },
    values: { block: selected },
    searched: { block: initialSearched },
    apiFilter,
  } = useFiltersStore();
  const [searchValue, setSearchValue] = useState<string>(initialSearched || "");

  const { data, isLoading } = useBlocksSuggestionsQuery(
    {
      filters: {
        ...apiFilter,
        blockId: null,
        block: { iContains: searchValue },
        DISTINCT: true,
      },
    },
    {
      select: (data) => data.suggestion.map(({ id, name }) => ({ id, label: name })),
      placeholderData: keepPreviousData,
    },
  );

  return (
    <AutosuggestionField
      id={"blocks"}
      isLoading={isLoading}
      searchValue={searchValue}
      data={data}
      selectedValue={selected || ""}
      onChangeSearchValue={setSearchValue}
      onChangeSelectedValue={(selectedId, searchValue) => {
        setFilter(selectedId, searchValue);
      }}
    />
  );
};
