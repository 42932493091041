import type { DefaultParametrizationValue } from "@/api/sdk";

export function mapRemoteDefaultParametrizationValue(value: DefaultParametrizationValue | null) {
  if (value?.__typename === "FloatValue") {
    return value.floatValue;
  }

  if (value?.__typename === "IntListValue") {
    return value.intListValue;
  }

  if (value?.__typename === "IntValue") {
    return value.intValue;
  }

  if (value?.__typename === "BoolValue") {
    return value.boolValue;
  }

  if (value?.__typename === "StringValue") {
    return value.stringValue;
  }

  if (value?.__typename === "PlantSpacingPatternValue") {
    return value.patternValue;
  }

  return undefined;
}
