import { Select } from "@roboton/ui";
import { type ComponentProps, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

type CountrySelectProps = Omit<ComponentProps<typeof Select>, "children">;
export const CountrySelect = forwardRef<HTMLSelectElement, CountrySelectProps>((props, ref) => {
  // Put the full name of the namespace here. Don't use the variable,
  // it's not supported by the i18next-parser.
  const { t } = useTranslation("countries");

  // HTML Select statement implementation of the Alpha-2 COdes for the ISO-3166-1 Country Code specification
  // source of options: https://gist.github.com/intltechventures/4242911
  const options = useMemo(
    () =>
      [
        { code: "AF", name: t("Afghanistan") },
        { code: "AX", name: t("Åland Islands") },
        { code: "AL", name: t("Albania") },
        { code: "DZ", name: t("Algeria") },
        { code: "AS", name: t("American Samoa") },
        { code: "AD", name: t("Andorra") },
        { code: "AO", name: t("Angola") },
        { code: "AI", name: t("Anguilla") },
        { code: "AQ", name: t("Antarctica") },
        { code: "AG", name: t("Antigua and Barbuda") },
        { code: "AR", name: t("Argentina") },
        { code: "AM", name: t("Armenia") },
        { code: "AW", name: t("Aruba") },
        { code: "AU", name: t("Australia") },
        { code: "AT", name: t("Austria") },
        { code: "AZ", name: t("Azerbaijan") },
        { code: "BS", name: t("Bahamas") },
        { code: "BH", name: t("Bahrain") },
        { code: "BD", name: t("Bangladesh") },
        { code: "BB", name: t("Barbados") },
        { code: "BY", name: t("Belarus") },
        { code: "BE", name: t("Belgium") },
        { code: "BZ", name: t("Belize") },
        { code: "BJ", name: t("Benin") },
        { code: "BM", name: t("Bermuda") },
        { code: "BT", name: t("Bhutan") },
        { code: "BO", name: t("Bolivia, Plurinational State of") },
        { code: "BQ", name: t("Bonaire, Sint Eustatius and Saba") },
        { code: "BA", name: t("Bosnia and Herzegovina") },
        { code: "BW", name: t("Botswana") },
        { code: "BV", name: t("Bouvet Island") },
        { code: "BR", name: t("Brazil") },
        { code: "IO", name: t("British Indian Ocean Territory") },
        { code: "BN", name: t("Brunei Darussalam") },
        { code: "BG", name: t("Bulgaria") },
        { code: "BF", name: t("Burkina Faso") },
        { code: "BI", name: t("Burundi") },
        { code: "KH", name: t("Cambodia") },
        { code: "CM", name: t("Cameroon") },
        { code: "CA", name: t("Canada") },
        { code: "CV", name: t("Cape Verde") },
        { code: "KY", name: t("Cayman Islands") },
        { code: "CF", name: t("Central African Republic") },
        { code: "TD", name: t("Chad") },
        { code: "CL", name: t("Chile") },
        { code: "CN", name: t("China") },
        { code: "CX", name: t("Christmas Island") },
        { code: "CC", name: t("Cocos (Keeling) Islands") },
        { code: "CO", name: t("Colombia") },
        { code: "KM", name: t("Comoros") },
        { code: "CG", name: t("Congo") },
        { code: "CD", name: t("Congo, the Democratic Republic of the") },
        { code: "CK", name: t("Cook Islands") },
        { code: "CR", name: t("Costa Rica") },
        { code: "CI", name: t("Côte d'Ivoire") },
        { code: "HR", name: t("Croatia") },
        { code: "CU", name: t("Cuba") },
        { code: "CW", name: t("Curaçao") },
        { code: "CY", name: t("Cyprus") },
        { code: "CZ", name: t("Czech Republic") },
        { code: "DK", name: t("Denmark") },
        { code: "DJ", name: t("Djibouti") },
        { code: "DM", name: t("Dominica") },
        { code: "DO", name: t("Dominican Republic") },
        { code: "EC", name: t("Ecuador") },
        { code: "EG", name: t("Egypt") },
        { code: "SV", name: t("El Salvador") },
        { code: "GQ", name: t("Equatorial Guinea") },
        { code: "ER", name: t("Eritrea") },
        { code: "EE", name: t("Estonia") },
        { code: "ET", name: t("Ethiopia") },
        { code: "FK", name: t("Falkland Islands (Malvinas)") },
        { code: "FO", name: t("Faroe Islands") },
        { code: "FJ", name: t("Fiji") },
        { code: "FI", name: t("Finland") },
        { code: "FR", name: t("France") },
        { code: "GF", name: t("French Guiana") },
        { code: "PF", name: t("French Polynesia") },
        { code: "TF", name: t("French Southern Territories") },
        { code: "GA", name: t("Gabon") },
        { code: "GM", name: t("Gambia") },
        { code: "GE", name: t("Georgia") },
        { code: "DE", name: t("Germany") },
        { code: "GH", name: t("Ghana") },
        { code: "GI", name: t("Gibraltar") },
        { code: "GR", name: t("Greece") },
        { code: "GL", name: t("Greenland") },
        { code: "GD", name: t("Grenada") },
        { code: "GP", name: t("Guadeloupe") },
        { code: "GU", name: t("Guam") },
        { code: "GT", name: t("Guatemala") },
        { code: "GG", name: t("Guernsey") },
        { code: "GN", name: t("Guinea") },
        { code: "GW", name: t("Guinea-Bissau") },
        { code: "GY", name: t("Guyana") },
        { code: "HT", name: t("Haiti") },
        { code: "HM", name: t("Heard Island and McDonald Islands") },
        { code: "VA", name: t("Holy See (Vatican City State)") },
        { code: "HN", name: t("Honduras") },
        { code: "HK", name: t("Hong Kong") },
        { code: "HU", name: t("Hungary") },
        { code: "IS", name: t("Iceland") },
        { code: "IN", name: t("India") },
        { code: "ID", name: t("Indonesia") },
        { code: "IR", name: t("Iran, Islamic Republic of") },
        { code: "IQ", name: t("Iraq") },
        { code: "IE", name: t("Ireland") },
        { code: "IM", name: t("Isle of Man") },
        { code: "IL", name: t("Israel") },
        { code: "IT", name: t("Italy") },
        { code: "JM", name: t("Jamaica") },
        { code: "JP", name: t("Japan") },
        { code: "JE", name: t("Jersey") },
        { code: "JO", name: t("Jordan") },
        { code: "KZ", name: t("Kazakhstan") },
        { code: "KE", name: t("Kenya") },
        { code: "KI", name: t("Kiribati") },
        { code: "KP", name: t("Korea, Democratic People's Republic of") },
        { code: "KR", name: t("Korea, Republic of") },
        { code: "KW", name: t("Kuwait") },
        { code: "KG", name: t("Kyrgyzstan") },
        { code: "LA", name: t("Lao People's Democratic Republic") },
        { code: "LV", name: t("Latvia") },
        { code: "LB", name: t("Lebanon") },
        { code: "LS", name: t("Lesotho") },
        { code: "LR", name: t("Liberia") },
        { code: "LY", name: t("Libya") },
        { code: "LI", name: t("Liechtenstein") },
        { code: "LT", name: t("Lithuania") },
        { code: "LU", name: t("Luxembourg") },
        { code: "MO", name: t("Macao") },
        { code: "MK", name: t("Macedonia, The Former Yugoslav Republic of") },
        { code: "MG", name: t("Madagascar") },
        { code: "MW", name: t("Malawi") },
        { code: "MY", name: t("Malaysia") },
        { code: "MV", name: t("Maldives") },
        { code: "ML", name: t("Mali") },
        { code: "MT", name: t("Malta") },
        { code: "MH", name: t("Marshall Islands") },
        { code: "MQ", name: t("Martinique") },
        { code: "MR", name: t("Mauritania") },
        { code: "MU", name: t("Mauritius") },
        { code: "YT", name: t("Mayotte") },
        { code: "MX", name: t("Mexico") },
        { code: "FM", name: t("Micronesia, Federated States of") },
        { code: "MD", name: t("Moldova, Republic of") },
        { code: "MC", name: t("Monaco") },
        { code: "MN", name: t("Mongolia") },
        { code: "ME", name: t("Montenegro") },
        { code: "MS", name: t("Montserrat") },
        { code: "MA", name: t("Morocco") },
        { code: "MZ", name: t("Mozambique") },
        { code: "MM", name: t("Myanmar") },
        { code: "NA", name: t("Namibia") },
        { code: "NR", name: t("Nauru") },
        { code: "NP", name: t("Nepal") },
        { code: "NL", name: t("Netherlands") },
        { code: "NC", name: t("New Caledonia") },
        { code: "NZ", name: t("New Zealand") },
        { code: "NI", name: t("Nicaragua") },
        { code: "NE", name: t("Niger") },
        { code: "NG", name: t("Nigeria") },
        { code: "NU", name: t("Niue") },
        { code: "NF", name: t("Norfolk Island") },
        { code: "MP", name: t("Northern Mariana Islands") },
        { code: "NO", name: t("Norway") },
        { code: "OM", name: t("Oman") },
        { code: "PK", name: t("Pakistan") },
        { code: "PW", name: t("Palau") },
        { code: "PS", name: t("Palestinian Territory, Occupied") },
        { code: "PA", name: t("Panama") },
        { code: "PG", name: t("Papua New Guinea") },
        { code: "PY", name: t("Paraguay") },
        { code: "PE", name: t("Peru") },
        { code: "PH", name: t("Philippines") },
        { code: "PN", name: t("Pitcairn") },
        { code: "PL", name: t("Poland") },
        { code: "PT", name: t("Portugal") },
        { code: "PR", name: t("Puerto Rico") },
        { code: "QA", name: t("Qatar") },
        { code: "RE", name: t("Réunion") },
        { code: "RO", name: t("Romania") },
        { code: "RU", name: t("Russian Federation") },
        { code: "RW", name: t("Rwanda") },
        { code: "BL", name: t("Saint Barthélemy") },
        { code: "SH", name: t("Saint Helena, Ascension and Tristan da Cunha") },
        { code: "KN", name: t("Saint Kitts and Nevis") },
        { code: "LC", name: t("Saint Lucia") },
        { code: "MF", name: t("Saint Martin (French part)") },
        { code: "PM", name: t("Saint Pierre and Miquelon") },
        { code: "VC", name: t("Saint Vincent and the Grenadines") },
        { code: "WS", name: t("Samoa") },
        { code: "SM", name: t("San Marino") },
        { code: "ST", name: t("Sao Tome and Principe") },
        { code: "SA", name: t("Saudi Arabia") },
        { code: "SN", name: t("Senegal") },
        { code: "RS", name: t("Serbia") },
        { code: "SC", name: t("Seychelles") },
        { code: "SL", name: t("Sierra Leone") },
        { code: "SG", name: t("Singapore") },
        { code: "SX", name: t("Sint Maarten (Dutch part)") },
        { code: "SK", name: t("Slovakia") },
        { code: "SI", name: t("Slovenia") },
        { code: "SB", name: t("Solomon Islands") },
        { code: "SO", name: t("Somalia") },
        { code: "ZA", name: t("South Africa") },
        { code: "GS", name: t("South Georgia and the South Sandwich Islands") },
        { code: "SS", name: t("South Sudan") },
        { code: "ES", name: t("Spain") },
        { code: "LK", name: t("Sri Lanka") },
        { code: "SD", name: t("Sudan") },
        { code: "SR", name: t("Suriname") },
        { code: "SJ", name: t("Svalbard and Jan Mayen") },
        { code: "SZ", name: t("Swaziland") },
        { code: "SE", name: t("Sweden") },
        { code: "CH", name: t("Switzerland") },
        { code: "SY", name: t("Syrian Arab Republic") },
        { code: "TW", name: t("Taiwan, Province of China") },
        { code: "TJ", name: t("Tajikistan") },
        { code: "TZ", name: t("Tanzania, United Republic of") },
        { code: "TH", name: t("Thailand") },
        { code: "TL", name: t("Timor-Leste") },
        { code: "TG", name: t("Togo") },
        { code: "TK", name: t("Tokelau") },
        { code: "TO", name: t("Tonga") },
        { code: "TT", name: t("Trinidad and Tobago") },
        { code: "TN", name: t("Tunisia") },
        { code: "TR", name: t("Turkey") },
        { code: "TM", name: t("Turkmenistan") },
        { code: "TC", name: t("Turks and Caicos Islands") },
        { code: "TV", name: t("Tuvalu") },
        { code: "UG", name: t("Uganda") },
        { code: "UA", name: t("Ukraine") },
        { code: "AE", name: t("United Arab Emirates") },
        { code: "GB", name: t("United Kingdom") },
        { code: "US", name: t("United States") },
        { code: "UM", name: t("United States Minor Outlying Islands") },
        { code: "UY", name: t("Uruguay") },
        { code: "UZ", name: t("Uzbekistan") },
        { code: "VU", name: t("Vanuatu") },
        { code: "VE", name: t("Venezuela, Bolivarian Republic of") },
        { code: "VN", name: t("Viet Nam") },
        { code: "VG", name: t("Virgin Islands, British") },
        { code: "VI", name: t("Virgin Islands, U.S.") },
        { code: "WF", name: t("Wallis and Futuna") },
        { code: "EH", name: t("Western Sahara") },
        { code: "YE", name: t("Yemen") },
        { code: "ZM", name: t("Zambia") },
        { code: "ZW", name: t("Zimbabwe") },
      ] as const satisfies readonly { code: string; name: string }[],
    [t],
  );

  return (
    <Select {...props} ref={ref}>
      {options.map((option) => (
        <option key={option.code} value={option.code}>
          {option.name}
        </option>
      ))}
    </Select>
  );
});

CountrySelect.displayName = "CountrySelect";
