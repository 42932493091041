import { MapComponent, MapUtils } from "@/components/Map";
import { DrawControl } from "@/components/Map/DrawControl";
import type { Coords, FeatureWithId, Polygon } from "@/components/Map/types";
import type MapboxDraw from "@mapbox/mapbox-gl-draw";
import { type ComponentProps, useMemo, useRef } from "react";

type DrawControlProps = ComponentProps<typeof DrawControl<Polygon>>;

type ManuallyBoundariesMapProps = {
  fieldId: string;
  initCoords: Coords[];
  onChange: (feature?: FeatureWithId<Polygon>) => void;
};

export const ManuallyBoundariesMap = ({ fieldId, initCoords, onChange }: ManuallyBoundariesMapProps) => {
  const initialFeature = useRef({
    ...MapUtils.getPolygonSourceProps(fieldId, initCoords).data,
  } satisfies FeatureWithId<Polygon>);
  const hasCoords = initCoords.length > 0;
  const bounds = useMemo(
    () => (hasCoords ? MapUtils.getLngLatBounds(initialFeature.current.geometry.coordinates[0]) : undefined),
    [hasCoords],
  );

  const mapDrawRef = useRef<MapboxDraw>();

  const handleMapLoad = () => {
    if (!hasCoords || !mapDrawRef.current) return;
    // set initial data to the map
    mapDrawRef.current?.set({ features: [initialFeature.current], type: "FeatureCollection" });
    mapDrawRef.current.changeMode("direct_select", { featureId: fieldId });
  };

  const handleLoad: DrawControlProps["onLoad"] = (draw) => {
    mapDrawRef.current = draw;
  };
  const handleChange: DrawControlProps["onUpdate"] = ({ features }) => onChange(features[0]);
  const handleCreate: DrawControlProps["onCreate"] = ({ features }) => {
    const newFeature = features[0];

    // only one feature is allowed, so delete all features before adding the new one
    const mapDraw = mapDrawRef.current;
    if (!mapDraw) return;

    mapDraw.deleteAll();
    mapDraw.set({ features: [newFeature], type: "FeatureCollection" });
    onChange(newFeature);
  };

  const handleDelete: DrawControlProps["onDelete"] = () => {
    const mapDraw = mapDrawRef.current;
    if (!mapDraw) return;

    mapDraw.deleteAll();
    onChange();
  };

  return (
    <MapComponent
      initialViewState={{ bounds, fitBoundsOptions: { padding: 20 } }}
      enableControls
      onLoad={handleMapLoad}
    >
      <DrawControl
        touchEnabled={true}
        controls={{ polygon: true, trash: true }}
        onLoad={handleLoad}
        onUpdate={handleChange}
        onCreate={handleCreate}
        onDelete={handleDelete}
      />
    </MapComponent>
  );
};
