import { MultipleMeter } from "@/components/MultipleMeter/MultipleMeter";
import { createStepsArray } from "@/utils/createStepsArray";
import { groupBy } from "@/utils/groupBy";
import { mapRemoteFloatValueStringValueToNumber } from "@/utils/mapRemoteFloatValueStringValueToNumber";
import { useTranslation } from "react-i18next";
import { useCropDetailLayoutContext } from "./CropDetailLayout";
import { CropThreatsList } from "./components/CropThreatsList";

export const AboutCropPage = () => {
  const { t } = useTranslation();

  const { cropSheet, cropThreats } = useCropDetailLayoutContext();
  const threatsGroupedByType = groupBy(cropThreats, (cropThreat) => cropThreat.threat.type);

  return (
    <>
      <h2 className={"typo-h2 sr-only"}>{cropSheet.commonName}</h2>
      <div className={"flex flex-col gap-4 lg:grid lg:grid-cols-[320px_1fr]"}>
        <div className={"space-y-4"}>
          <img
            src={cropSheet.mainImage.url}
            alt={cropSheet.mainImage.alt}
            className={"mx-auto h-auto w-full max-w-[180px] object-contain sm:max-h-[20rem] lg:max-w-full"}
          />
          {cropSheet.images.map((image) => {
            if (!image) return null;
            return (
              <img
                key={image.id}
                src={image.url}
                alt={image.alt}
                className={"mx-auto h-auto w-full max-w-[180px] object-contain sm:max-h-[20rem] lg:max-w-full"}
              />
            );
          })}
        </div>

        <article className={"flex flex-col gap-12 lg:gap-16"}>
          {/* basic info */}
          <section className={"flex flex-col gap-6"}>
            <h3 className={"typo-h3"}>{t("Basic Info")}</h3>

            <div className={"flex flex-col gap-4 lg:grid lg:grid-cols-2"}>
              <div>
                <div className={"font-bold"}>{t("Original Name")}</div>
                <div>{cropSheet.commonName}</div>
              </div>
              <div>
                <div className={"font-bold"}>{t("Alternative Names")}</div>
                <div>{cropSheet.alternativeNames?.length ? cropSheet.alternativeNames.join(", ") : "-"}</div>
              </div>
              <div>
                <div className={"font-bold"}>{t("Variant")}</div>
                <div>{cropSheet.variant}</div>
              </div>
              <div>
                <div className={"font-bold"}>{t("Scientific Name")}</div>
                <div>{cropSheet.scientificName}</div>
              </div>
            </div>

            <div>
              <div className={"font-bold"}>{t("Description")}</div>
              <div className={"prose"} dangerouslySetInnerHTML={{ __html: cropSheet.description || "-" }} />
            </div>

            {Object.entries(threatsGroupedByType).map(([type, threats]) => {
              return (
                <CropThreatsList
                  key={`${cropSheet.id}-${type}`}
                  cropSheetId={cropSheet.id}
                  cropThreats={threats}
                  threatType={type as keyof typeof threatsGroupedByType}
                />
              );
            })}
          </section>

          {/*  cultivation */}
          <div className={"flex flex-col gap-6"}>
            <h3 className={"typo-h3"}>{t("Cultivation")}</h3>

            <div>
              <div className={"font-bold"}>{t("Description")}</div>
              <div className={"prose"} dangerouslySetInnerHTML={{ __html: cropSheet.cultivation || "-" }} />
            </div>

            <div className={"flex flex-col gap-8"}>
              {cropSheet.conditions.map((condition) => {
                const { scaleExtremeLow, scaleExtremeHigh, scaleStep } = condition;
                if (
                  typeof scaleExtremeLow === "number" &&
                  typeof scaleExtremeHigh === "number" &&
                  typeof scaleStep === "number"
                ) {
                  const steps = createStepsArray(scaleExtremeLow, scaleExtremeHigh, scaleStep);
                  return (
                    <MultipleMeter
                      key={condition.id}
                      steps={steps}
                      limitValues={{
                        min: mapRemoteFloatValueStringValueToNumber(condition.healthThresholdLow),
                        max: mapRemoteFloatValueStringValueToNumber(condition.healthThresholdHigh),
                      }}
                      criticalValues={{
                        min: mapRemoteFloatValueStringValueToNumber(condition.thresholdLow),
                        max: mapRemoteFloatValueStringValueToNumber(condition.thresholdHigh),
                      }}
                      unit={condition.unit ?? undefined}
                      label={condition.name}
                    />
                  );
                }
                return (
                  <div key={condition.id}>
                    <div className={"font-bold"}>{condition.name}</div>
                    <ul className={"list-inside list-disc"}>
                      <li>
                        {t("Threshold Low")}: {mapRemoteFloatValueStringValueToNumber(condition.thresholdLow)}
                      </li>
                      <li>
                        {t("Threshold High")}: {mapRemoteFloatValueStringValueToNumber(condition.thresholdHigh)}
                      </li>
                      <li>
                        {t("Health Threshold Low")}:{" "}
                        {mapRemoteFloatValueStringValueToNumber(condition.healthThresholdLow)}
                      </li>
                      <li>
                        {t("Health Threshold High")}:{" "}
                        {mapRemoteFloatValueStringValueToNumber(condition.healthThresholdHigh)}
                      </li>
                      <li>
                        {t("Nominal Limit")}: {condition.nominalLimit?.join(", ") ?? "-"}
                      </li>
                      <li>
                        {t("Nominal Optimal")}: {condition.nominalOptimal?.join(", ") ?? "-"}
                      </li>
                      <li>
                        {t("Nominal Critical")}: {condition.nominalCritical?.join(", ") ?? "-"}
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </article>
      </div>
    </>
  );
};
