import { Button, Input } from "@roboton/ui";
import clsx from "clsx";
import type { ChangeEvent } from "react";
import { LoaderIcon } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const AutosuggestionField = ({
  id,
  isLoading,
  searchValue,
  data,
  selectedValue,
  onChangeSelectedValue,
  onChangeSearchValue,
}: {
  id: string;
  isLoading: boolean;
  searchValue: string;
  onChangeSearchValue: (searchValue: string) => void;
  data?: {
    id: string;
    label: string;
  }[];
  selectedValue: string;
  onChangeSelectedValue: (selected: string, searchValue: string) => void;
}) => {
  const { t } = useTranslation();

  const handleResetAll = () => {
    onChangeSearchValue("");
    onChangeSelectedValue("", "");
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeSearchValue(event.target.value);
  };

  const handleItemClick = (item: { id: string; label: string }) => () => {
    onChangeSelectedValue(item.id, searchValue);
  };

  return (
    <div className={"flex flex-col gap-2"}>
      <div className={"flex gap-[inherit] items-center"}>
        <Input size={"small"} id={`${id}-search`} value={searchValue} onChange={handleSearchChange} />
        <Button
          size={"small"}
          type={"button"}
          onClick={handleResetAll}
          aria-label={t("Reset search and selection")}
          icon={"close"}
        />
      </div>

      <div className={"flex flex-col text-dark-100 divide-dark-0 divide-y relative min-h-[2rem]"}>
        {isLoading && (
          <div className={"absolute inset-0 flex justify-center items-center text-center bg-light-0 bg-opacity-90"}>
            <LoaderIcon className={clsx("h-6 w-6 shrink-0")} />
          </div>
        )}

        {data?.map((item) => (
          <button
            disabled={isLoading}
            type={"button"}
            className={selectedValue === item.id ? "bg-brand-0" : "bg-light-0"}
            key={item.id}
            value={item.id}
            onClick={handleItemClick(item)}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};
