import type { AllRobotsGrowingPlansQuery } from "@/api/sdk";
import { Select } from "@roboton/ui";
import { useTranslation } from "react-i18next";

export const GrowingPlansSelect = ({
  plans,
  ...rest
}: JSX.IntrinsicElements["select"] & {
  plans?: AllRobotsGrowingPlansQuery["robot"]["growingPlans"];
  id?: never;
  children?: never;
  ref?: never;
}) => {
  const { t } = useTranslation();
  return (
    <Select {...rest} size={"small"} id={"growing-plans-filter"}>
      <option value={""}>{t("Show all growing plans")}</option>
      {plans?.map((growingPlan) => (
        <option key={growingPlan.id} value={growingPlan.id}>
          {growingPlan.name}
        </option>
      ))}
    </Select>
  );
};
