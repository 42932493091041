import type { GrowingPlanInfoRowFragment } from "@/api/sdk";
import { GrowingPlanState } from "@/api/sdk";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const mappedGrowingPlanStatusToBgColor = {
  [GrowingPlanState.Scheduled]: "bg-gold-50",
  [GrowingPlanState.InProgress]: "bg-brand-25",
  [GrowingPlanState.Finished]: "bg-dark-50",
  [GrowingPlanState.Archived]: "bg-dark-50",
} satisfies Record<GrowingPlanInfoRowFragment["state"], string>;

type Props = Omit<JSX.IntrinsicElements["div"], "children"> & {
  remoteGrowingPlan: GrowingPlanInfoRowFragment;
};

/**
 * UI around a grapwql GrowingPlanInfoRowFragment.
 */
export const GrowingPlanInfoRow = ({ remoteGrowingPlan, className, ...rest }: Props) => {
  const { t } = useTranslation();

  return (
    <div {...rest} className={clsx("typo-sm bg-light-50 border-light-75 flex gap-1 rounded border px-1", className)}>
      {/* dot */}
      <div
        className={clsx(
          mappedGrowingPlanStatusToBgColor[remoteGrowingPlan.state],
          "mt-1.5 inline-block h-3 w-3 shrink-0 rounded-full",
        )}
        aria-hidden={true}
      />

      {/* status info */}
      {remoteGrowingPlan.state === GrowingPlanState.Scheduled
        ? t("The Growing Plan {{ name }} starts in {{ days }}", {
            name: remoteGrowingPlan.name,
            days: t("dayWithCount", { count: remoteGrowingPlan.planScheduleInDays ?? 0 }),
          })
        : remoteGrowingPlan.state === GrowingPlanState.InProgress
          ? t("The Growing Plan {{ name }} is underway", { name: remoteGrowingPlan.name })
          : remoteGrowingPlan.state === GrowingPlanState.Finished
            ? t("The Growing Plan {{ name }} is finished", { name: remoteGrowingPlan.name })
            : remoteGrowingPlan.name}
    </div>
  );
};
