import { useUpdateManualBlocksMutation } from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { DashboardContent } from "@/components/DashboardContent";
import { DrawControl } from "@/components/Map/DrawControl";
import { BlockItem } from "@/field/blocks/components/BlockItem";
import { NewBlockMessage } from "@/field/blocks/components/NewBlockMessage";
import { FieldMap } from "@/field/components/FieldMap";
import { generatePath, routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { formatNumberToSquareMeters } from "@/utils/format";
import { useConfirm } from "@/utils/useConfirm";
import { Button } from "@roboton/ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PruneFieldConfirmationModal } from "../components/PruneFieldConfirmationModal";
import type { FieldModel } from "../field.model";
import type { BlockModel } from "./block.model";
import { useBlocksDraw } from "./utilities/useBlocksDraw";

type ManageBlocksWithoutRobotContentProps = {
  field: FieldModel;
  blocks: BlockModel[];
};

export const ManageBlocksWithoutRobotContent = ({
  field,
  blocks: blocksOrigin,
}: ManageBlocksWithoutRobotContentProps) => {
  const { t } = useTranslation();

  const {
    isOpen: isPruneModalOpen,
    onConfirm: onPruneModalConfirm,
    onDecline: onPruneModalDecline,
    getConfirmation: getPruneModalConfirmation,
  } = useConfirm();

  // Field
  const fieldDetailPath = generatePath(routes.FieldDetail, { id: field.id });

  const {
    blocks,

    isTouched,
    isStructureTouched,
    onBlocksSuccessSave,

    createBlockBasedOnFieldCoords,
    removeBlock,
    updateBlockColor,
    updateBlockName,

    getMapDrawProps,
    getMapProps,
  } = useBlocksDraw(blocksOrigin, { fieldId: field.id, fieldCoords: field.coords, fieldArea: field.details.area });

  const [isModeEdit] = useState(() => blocksOrigin.length > 0);
  const isPruningEnabled = isModeEdit && isStructureTouched;
  const isSaveEnabled = isTouched || isStructureTouched;

  const headline = isModeEdit ? t("Edit Blocks") : t("Create Blocks");

  const { mutate } = useUpdateManualBlocksMutation({
    onSuccess: ({ updateManualBlocks: { success } }) => {
      if (!success) return;
      onBlocksSuccessSave();
      notify.positive(t("All changes have been successfully saved."));
    },
  });

  const save = () => {
    mutate({
      fieldId: field.id,
      blocksInput: {
        blocks: blocks.map((block) => {
          return {
            id: block.isNew ? undefined : block.id,
            name: block.name,
            segments: block.segments.map((segment) => ({
              id: block.isNew ? undefined : segment.id,
              boundaries: segment.boundaries,
            })),
            color: block.color,
          };
        }),
      },
    });
  };

  const handleClickSave = async () => {
    if (isPruningEnabled) {
      const isConfirmed = await getPruneModalConfirmation();
      if (!isConfirmed) return;
    }
    save();
  };

  return (
    <DashboardContent
      headline={headline}
      navigateBackTo={fieldDetailPath}
      headerArea={
        <Button type={"button"} disabled={!isSaveEnabled} onClick={handleClickSave}>
          {t("Save")}
        </Button>
      }
    >
      <Bricks.Layout
        mapArea={
          <FieldMap field={field} {...getMapProps()}>
            <DrawControl {...getMapDrawProps()} userProperties={true} controls={{ polygon: true, trash: true }} />
          </FieldMap>
        }
        mapAreaSize={"large"}
      >
        <Bricks.Card headline={t("Blocks of the Field")}>
          <p>{t("You can create multiple different Blocks in the Field.")}</p>

          <div className={"flex flex-col gap-4 md:flex-row md:flex-wrap"}>
            <div className={"grow"}>
              <strong>{t("Field Name")}</strong>: {field.details.name}
            </div>
            <div className={"grow"}>
              <strong>{t("Field Area")}</strong>: {formatNumberToSquareMeters(field.details.area)}
            </div>
          </div>

          <NewBlockMessage />

          <Button type={"button"} icon={"plus"} size={"small"} onClick={createBlockBasedOnFieldCoords}>
            {t("Create a Block based on Field Area")}
          </Button>

          <div className={"flex flex-col gap-4"}>
            {blocks.map((block) => (
              <BlockItem
                key={block.id}
                block={block}
                onUpdateBlockName={updateBlockName}
                onUpdateBlockColor={updateBlockColor}
                onRemoveBlock={removeBlock}
              />
            ))}
          </div>
        </Bricks.Card>
      </Bricks.Layout>

      <PruneFieldConfirmationModal
        isOpen={isPruneModalOpen}
        onClose={onPruneModalDecline}
        onConfirm={onPruneModalConfirm}
      />
    </DashboardContent>
  );
};
