import { useUpdateSegmentBlocksMutation } from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { DashboardContent } from "@/components/DashboardContent";
import { Layer, Source } from "@/components/Map";
import { NewBlockMessage } from "@/field/blocks/components/NewBlockMessage";
import { FieldMap } from "@/field/components/FieldMap";
import { generatePath, routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { formatNumberToSquareMeters } from "@/utils/format";
import { useConfirm } from "@/utils/useConfirm";
import { Button, Select } from "@roboton/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import type { FieldModel } from "../field.model";
import { PruneFieldConfirmationModal } from "./../components/PruneFieldConfirmationModal";
import type { BlockModel } from "./block.model";
import { UncategorizedBlock } from "./block.model";
import { BlockItem } from "./components/BlockItem";
import { useBlocks } from "./utilities/useBlocks";

type ManageBlocksWithRobotContentProps = {
  field: FieldModel;
  blocks: BlockModel[];
};

export const ManageBlocksWithRobotContent = ({ field, blocks: blocksOrigin }: ManageBlocksWithRobotContentProps) => {
  const { t } = useTranslation();
  const headline = t("Edit Blocks");

  const {
    isOpen: isPruneModalOpen,
    onConfirm: onPruneModalConfirm,
    onDecline: onPruneModalDecline,
    getConfirmation: getPruneModalConfirmation,
  } = useConfirm();

  // Field
  const fieldDetailPath = generatePath(routes.FieldDetail, { id: field.id });

  const {
    isTouched,
    isSegmentsStructureTouched,
    isBlockBoundariesTouched,
    blocks,
    updateBlockName,
    updateBlockColor,
    moveSegmentToBlock,
    moveAllSegmentsToBlock,
    createBlock,
    removeBlock,
  } = useBlocks([new UncategorizedBlock(field.unassignedSegments), ...blocksOrigin]);

  // options for select
  const blocksOptions = blocks.map((block) => ({ value: block.id, label: block.name }));

  const navigate = useNavigate();
  const { mutate, isPending: isSaving } = useUpdateSegmentBlocksMutation({
    onSuccess: ({ updateSegmentBlocks: { success } }) => {
      if (!success) return;
      notify.positive(t("All changes have been successfully saved."));
      navigate(fieldDetailPath);
    },
  });

  const save = () => {
    const blockInput = blocks.flatMap((block) => {
      // remove block if it is uncategorized or has no segments
      const isSaveable = !block.isUncategorized && block.segments.length > 0;
      if (!isSaveable) return [];

      return [
        {
          id: block.remoteId,
          name: block.name,
          segmentsId: block.segments.map((segment) => segment.id),
          color: block.color,
        },
      ] satisfies Parameters<typeof mutate>[0]["input"]["blocks"];
    });

    mutate({ fieldId: field.id, input: { blocks: blockInput } });
  };

  const isStructureTouched = isSegmentsStructureTouched || isBlockBoundariesTouched;
  const isModeEdit = blocksOrigin.length > 0;
  const isPruningEnabled = isModeEdit && isStructureTouched;
  const isSaveEnabled = isTouched || isStructureTouched;

  const handleSaveClick = async () => {
    if (isPruningEnabled) {
      const isConfirmed = await getPruneModalConfirmation();
      if (!isConfirmed) return;
    }

    save();
  };

  return (
    <DashboardContent
      headline={headline}
      navigateBackTo={fieldDetailPath}
      isLoading={isSaving}
      headerArea={
        <Button type={"button"} disabled={!isSaveEnabled} onClick={handleSaveClick}>
          {t("Save")}
        </Button>
      }
    >
      <Bricks.Layout
        mapArea={
          <FieldMap field={field}>
            {blocks.map((block) =>
              block.segmentsForMap.map((segment) => {
                return (
                  <Source key={segment.id} {...segment.propsForMap.polygonProps.sourceProps}>
                    <Layer {...segment.propsForMap.polygonProps.layerProps} />
                    <Layer {...segment.propsForMap.lineProps.layerProps} />
                    <Layer {...segment.propsForMap.textProps.layerProps} />
                  </Source>
                );
              }),
            )}
          </FieldMap>
        }
        mapAreaSize={"large"}
      >
        <Bricks.Card headline={t("Blocks of the Field")}>
          <p>
            {t(
              "Setting the working areas of the field to be used for growing the crop. You can create multiple different Blocks in the Field.",
            )}
          </p>

          <div className={"flex flex-col gap-4 md:flex-row md:flex-wrap"}>
            <div className={"grow"}>
              <strong>{t("Field Name")}</strong>: {field.details.name}
            </div>
            <div className={"grow"}>
              <strong>{t("Field Area")}</strong>:{" "}
              <span className={"whitespace-nowrap"}>{formatNumberToSquareMeters(field.details.area)}</span>
            </div>
          </div>

          <NewBlockMessage />

          <div className={"flex flex-col gap-4"}>
            {blocks.map((block) => {
              const blockSelectLabel = t("Move all segments to block");
              const segmentSelectLabel = t("Move segment to block");
              const isSelectDisabled = blocks.length < 2 || block.segments.length < 0;
              return (
                <BlockItem
                  key={block.id}
                  block={block}
                  showSegmentsArea
                  onUpdateBlockName={updateBlockName}
                  onUpdateBlockColor={updateBlockColor}
                  onRemoveBlock={removeBlock}
                  renderBlockSelect={() => (
                    <Select
                      size={"small"}
                      aria-label={blockSelectLabel}
                      title={blockSelectLabel}
                      disabled={isSelectDisabled}
                      id={block.id}
                      value={block.id}
                      onChange={(e) => moveAllSegmentsToBlock(block, e.target.value)}
                    >
                      {blocksOptions.map((option) => (
                        <option key={option.value} value={option.value} disabled={option.value === block.id}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  )}
                  renderSegmentSelect={(segment) => (
                    <Select
                      size={"small"}
                      aria-label={segmentSelectLabel}
                      title={segmentSelectLabel}
                      disabled={isSelectDisabled}
                      id={segment.id}
                      value={block.id}
                      onChange={(e) => moveSegmentToBlock(segment, block.id, e.target.value)}
                    >
                      {blocksOptions.map((option) => (
                        <option key={option.value} value={option.value} disabled={option.value === block.id}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              );
            })}

            <Button type={"button"} onClick={() => createBlock()}>
              {t("Add a new Block")}
            </Button>
          </div>
        </Bricks.Card>
      </Bricks.Layout>

      <PruneFieldConfirmationModal
        isOpen={isPruneModalOpen}
        onClose={onPruneModalDecline}
        onConfirm={onPruneModalConfirm}
      />
    </DashboardContent>
  );
};
