import { FieldTaskPage } from "@/field-task/FieldTaskPage";
import type { routes } from "@/routes";
import { type ParamParseKey, useParams } from "react-router-dom";

const BlockDetailTaskRoute = () => {
  const { taskId } = useParams<ParamParseKey<typeof routes.BlockDetailTask>>();
  if (!taskId) throw new Error("Missing Task ID");

  return <FieldTaskPage taskId={taskId} />;
};

export default BlockDetailTaskRoute;
