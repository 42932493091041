import {
  type FieldTaskParametrizationCode,
  FieldTaskParametrizationType,
  type ParametrizationFragment,
  PlantSpacingPattern,
} from "@/api/sdk";
import { useLocalizeFieldTaskParametrizationCode } from "@/field-task/components/utils";
import { logger } from "@/logger";
import { mapRemoteDefaultParametrizationValue } from "@/utils/mapRemoteDefaultParametrizationValue";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { FormField, Input, Radio, Toggle } from "@roboton/ui";
import type { ChangeEvent } from "react";
import { type Control, Controller, type Path, type PathValue } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types/fields";
import { useTranslation } from "react-i18next";
import { RowsField } from "./RowsField";

type ParametrizationFieldsetData = Record<FieldTaskParametrizationCode | string, string | number | number[] | boolean>;

function getFallbackForParametrizationType(type: FieldTaskParametrizationType) {
  switch (type) {
    // avoid requiring the user to toggle the value to true and then back to false just to set it to false.
    case FieldTaskParametrizationType.Bool:
      return false;
    // return empty string to avoid changing input from uncontrolled to controlled
    case FieldTaskParametrizationType.Float:
    case FieldTaskParametrizationType.Int:
      return "";
    case FieldTaskParametrizationType.IntList:
      return [];
    case FieldTaskParametrizationType.PlantSpacingPattern:
      return PlantSpacingPattern.Rectangle;
    case FieldTaskParametrizationType.String:
      return "";
    default:
      logger.error("Unsupported parametrization type", type);
      return undefined;
  }
}

const ParametrizationFieldset = <T extends FieldValues = FieldValues>({
  name,
  control,
  keyPrefix,
  presetRows,
  parametrizations,
}: {
  name: Path<T>;
  control: Control<T>;
  parametrizations: ParametrizationFragment[];
  presetRows?: number[];
  keyPrefix: string;
}) => {
  const { t } = useTranslation();
  const { tEnums } = useTranslationEnums();
  const { localizeFieldTask } = useLocalizeFieldTaskParametrizationCode();
  const dynamicFields = parametrizations.map((parametrization) => {
    const remoteDefaultValue = mapRemoteDefaultParametrizationValue(parametrization.default);
    const defaultValue =
      typeof remoteDefaultValue !== "undefined"
        ? remoteDefaultValue
        : getFallbackForParametrizationType(parametrization.type);

    return {
      label: localizeFieldTask(parametrization),
      fieldProps: {
        name: `${name}.${parametrization.code}` as Path<T>,
        id: `${keyPrefix}.${parametrization.code}`,
        defaultValue: defaultValue as PathValue<T, Path<T>>,
      },
      ...parametrization,
    };
  });

  return (
    <fieldset className={"grid gap-4 sm:grid-cols-2"}>
      <legend>{t("Parametrization")}</legend>

      {dynamicFields?.map(({ fieldProps, ...dynamicField }) => {
        const key = `${keyPrefix}.${dynamicField.code}`;

        const minMaxValidationMessage = t("The value must be between {{ min }} and {{ max }}", {
          min: dynamicField.min,
          max: dynamicField.max,
        });

        return (
          <Controller
            key={key}
            name={fieldProps.name}
            control={control}
            defaultValue={fieldProps.defaultValue}
            rules={{
              required: dynamicField.type === FieldTaskParametrizationType.Bool ? false : t("This field is required"),
              min:
                typeof dynamicField.min === "number"
                  ? { value: dynamicField.min, message: minMaxValidationMessage }
                  : undefined,
              max:
                typeof dynamicField.max === "number"
                  ? { value: dynamicField.max, message: minMaxValidationMessage }
                  : undefined,
            }}
            shouldUnregister
            render={({ field, fieldState }) => {
              const { error } = fieldState;
              const errorMessage = error ? error.message : undefined;

              if (dynamicField.type === FieldTaskParametrizationType.IntList) {
                return (
                  <RowsField
                    className={"col-span-full"}
                    keyPrefix={keyPrefix}
                    presetValues={presetRows}
                    onChange={field.onChange}
                    defaultValue={fieldProps.defaultValue}
                    customOptionError={errorMessage}
                  />
                );
              }

              if (dynamicField.type === FieldTaskParametrizationType.Bool) {
                return (
                  <FormField
                    messages={
                      errorMessage ? [{ key: errorMessage, variant: "alert", children: errorMessage }] : undefined
                    }
                  >
                    <Toggle
                      id={fieldProps.id}
                      label={dynamicField.label}
                      {...field}
                      state={error ? "negative" : undefined}
                    />
                  </FormField>
                );
              }

              if (dynamicField.type === FieldTaskParametrizationType.PlantSpacingPattern) {
                return (
                  <div>
                    <div className={"typo-md--bold"}>{t("Plant Spacing Pattern")}</div>
                    <FormField
                      messages={
                        errorMessage ? [{ key: errorMessage, variant: "alert", children: errorMessage }] : undefined
                      }
                    >
                      <div className={"flex flex-wrap gap-4"}>
                        {Object.values(PlantSpacingPattern).map((pattern) => {
                          const id = `${key}.${pattern}`;
                          return (
                            <Radio
                              key={id}
                              id={id}
                              label={tEnums("plantSpacingPattern", pattern)}
                              {...field}
                              value={pattern}
                              checked={field.value === pattern}
                            />
                          );
                        })}
                      </div>
                    </FormField>
                  </div>
                );
              }

              const isString = dynamicField.type === FieldTaskParametrizationType.String;
              const isNumber = [FieldTaskParametrizationType.Float, FieldTaskParametrizationType.Int].includes(
                dynamicField.type,
              );

              if (isString || isNumber) {
                const handleInputChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(isNumber ? Number.parseFloat(value) : value);
                };
                return (
                  <FormField
                    messages={
                      errorMessage ? [{ key: errorMessage, variant: "alert", children: errorMessage }] : undefined
                    }
                  >
                    <Input
                      {...field}
                      onChange={handleInputChange}
                      id={fieldProps.id}
                      label={dynamicField.label}
                      type={isNumber ? "number" : "text"}
                      min={dynamicField.min ?? undefined}
                      max={dynamicField.max ?? undefined}
                      step={dynamicField.step ?? undefined}
                      state={error ? "negative" : undefined}
                    />
                  </FormField>
                );
              }

              logger.error(`Unsupported parametrization type: ${dynamicField.type}`);
              return <></>;
            }}
          />
        );
      })}
    </fieldset>
  );
};

export { ParametrizationFieldset, type ParametrizationFieldsetData };
