import { useCreateSwathsMutation } from "@/api/sdk";
import { DashboardContent } from "@/components/DashboardContent";
import { generatePath, routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { useAppTitle } from "@/utils/useAppTitle";
import { Button } from "@roboton/ui";
import { type ComponentProps, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFieldBasicTransformedQuery } from "../useFieldBasicTransformedQuery";
import { ManageWorkingLinesPageContent } from "./ManageWorkingLinesPageContent";

const ANGLES_FORM_ID = "working-lines-form";

type ContentProps = ComponentProps<typeof ManageWorkingLinesPageContent>;

export const ManageWorkingLinesPage = ({ fieldId }: { fieldId: string }) => {
  const { t } = useTranslation();

  const headline = t("Edit the Work Lines");
  useAppTitle(headline);

  const {
    data: { field } = {},
    isLoading,
  } = useFieldBasicTransformedQuery({ fieldId });

  const { isCultivatedByRobot, hasBoundaries } = field || {};
  const isAccessAllowed = isCultivatedByRobot || hasBoundaries;

  const navigate = useNavigate();
  const fieldPath = generatePath(routes.FieldDetail, { id: fieldId });

  const [isFormTouched, setIsFormTouched] = useState(false);

  const { mutate, isPending: isMutating } = useCreateSwathsMutation({
    onSuccess: () => {
      notify.positive(t("The working lines have been updated successfully"));
      navigate(fieldPath);
    },
  });

  const handleSubmit: ContentProps["onSubmit"] = async (data) => mutate({ fieldId, ...data });
  const handleChange: ContentProps["onChange"] = ({ isTouched, isValid }) => setIsFormTouched(isTouched && isValid);

  const isSaveEnabled = isFormTouched && !isMutating && !isLoading;

  return (
    <DashboardContent
      headline={headline}
      isLoading={isLoading || isMutating}
      navigateBackTo={fieldPath}
      className={"relative"}
      headerArea={
        <Button icon={"check"} form={ANGLES_FORM_ID} disabled={!isSaveEnabled}>
          {t("Save")}
        </Button>
      }
    >
      {isAccessAllowed && field ? (
        <ManageWorkingLinesPageContent
          field={field}
          formId={ANGLES_FORM_ID}
          onSubmit={handleSubmit}
          onChange={handleChange}
        />
      ) : (
        <>No data</>
      )}
    </DashboardContent>
  );
};
