import {
  type FieldTasksListFragment,
  useDeleteFieldTaskMutation,
  useMoveDownFieldTaskMutation,
  useMoveUpFieldTaskMutation,
  useStartNowTaskMutation,
} from "@/api/sdk";
import { LoadingContent } from "@/components/LoadingContent";
import { notify } from "@/utils/Notifications";
import { groupBy } from "@/utils/groupBy";
import clsx from "clsx";
import { type ComponentPropsWithoutRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { FieldTaskTypesTransformedQuery } from "../useFieldTaskTypesTransformedQuery";
import { FieldTaskCard } from "./FieldTaskCard";
import { FieldTaskGroup } from "./FieldTaskGroup";

export const FieldTasksList = ({
  tasks,
  fieldTaskTypes,
  className,
  allowExtendedActions,
  isPending,
  onClickCreateTask,
  onDeleteSuccess,
  onMoveUpSuccess,
  onMoveDownSuccess,
  onStartSuccess,
  ...rest
}: JSX.IntrinsicElements["div"] & {
  tasks: FieldTasksListFragment;
  fieldTaskTypes: FieldTaskTypesTransformedQuery["fieldTaskTypes"];
  allowExtendedActions: boolean;
  isPending?: boolean;
  onClickCreateTask?: ComponentPropsWithoutRef<typeof FieldTaskGroup>["onClickCreateTask"];
  onDeleteSuccess?: () => void;
  onStartSuccess?: () => void;
  onMoveUpSuccess?: () => void;
  onMoveDownSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const [pendingTaskId, setPendingTaskId] = useState<string>();

  const { mutate: deleteTaskMutate } = useDeleteFieldTaskMutation({
    onMutate: ({ fieldTaskId }) => setPendingTaskId(fieldTaskId),
    onSettled: (data) => {
      setPendingTaskId(undefined);
      if (!data?.result.success) return;
      notify.positive(t("Task deleted successfully"));
      onDeleteSuccess?.();
    },
  });

  const { mutate: moveUpTask } = useMoveUpFieldTaskMutation({
    onMutate: ({ fieldTaskId }) => setPendingTaskId(fieldTaskId),
    onSettled: (data) => {
      setPendingTaskId(undefined);
      if (!data?.result.success) return;
      notify.positive(t("Task was moved up successfully"));
      onMoveUpSuccess?.();
    },
  });

  const { mutate: moveDownTask } = useMoveDownFieldTaskMutation({
    onMutate: ({ fieldTaskId }) => setPendingTaskId(fieldTaskId),
    onSettled: (data) => {
      setPendingTaskId(undefined);
      if (!data?.result.success) return;
      notify.positive(t("Task was moved down successfully"));
      onMoveDownSuccess?.();
    },
  });

  const { mutate: startNowTask } = useStartNowTaskMutation({
    onMutate: ({ fieldTaskId }) => setPendingTaskId(fieldTaskId),
    onSettled: (data) => {
      setPendingTaskId(undefined);
      if (!data?.result.success) return;
      notify.positive(t("Task was started successfully"));
      onStartSuccess?.();
    },
  });

  const handleDeleteTaskClick = (id: string) => deleteTaskMutate({ fieldTaskId: id });
  const handleMoveUpTaskClick = (id: string) => moveUpTask({ fieldTaskId: id });
  const handleMoveDownTaskClick = (id: string) => moveDownTask({ fieldTaskId: id });
  const handleStartNowTaskClick = (id: string) => startNowTask({ fieldTaskId: id });

  const extendedTasks: ComponentPropsWithoutRef<typeof FieldTaskCard>["task"][] = useMemo(
    () =>
      tasks.nodes.map((task) => ({
        ...task,
        procedures: task.procedures?.map((procedure) => {
          const taskType = fieldTaskTypes.find(({ type }) => type === procedure.type);
          return {
            ...procedure,
            taskTypeCommonName: taskType?.commonName,
            groupCommonName: taskType?.group.commonName,
          };
        }),
      })),
    [tasks, fieldTaskTypes],
  );

  const groupedTasks = useMemo(
    () =>
      Object.entries(groupBy(extendedTasks, (task) => task.startAt?.split("T")[0] || "no-start-at"))
        .map(([key, tasks]) => ({ date: key, tasks }))
        .sort((a, b) => a.date.localeCompare(b.date)),
    [extendedTasks],
  );

  return (
    <div {...rest} className={clsx("relative mx-auto flex w-[40rem] max-w-full flex-col gap-8", className)}>
      {groupedTasks.map(({ date, tasks }) => {
        const isDateInThePast = new Date(date) < new Date();
        return (
          <FieldTaskGroup
            key={date}
            date={date}
            robotId={tasks[0].robotAssignment.id}
            onClickCreateTask={isDateInThePast ? undefined : onClickCreateTask}
          >
            {tasks.map((task) => {
              const isPending = pendingTaskId === task.id;
              return (
                <FieldTaskCard
                  key={task.id}
                  task={task}
                  isPending={isPending}
                  onDeleteClick={handleDeleteTaskClick}
                  {...(allowExtendedActions
                    ? {
                        onStartClick: handleStartNowTaskClick,
                        onMoveUpClick: handleMoveUpTaskClick,
                        onMoveDownClick: handleMoveDownTaskClick,
                      }
                    : {})}
                />
              );
            })}
          </FieldTaskGroup>
        );
      })}
      {isPending && <LoadingContent className={"bg-light-25 absolute inset-0 grow bg-opacity-70"} />}
    </div>
  );
};
