import type { FillLayer, LayerProps, LineLayer, SourceProps, SymbolLayer } from "react-map-gl/maplibre";
import type { Coords } from "./types";

type LinePaint = Required<LineLayer>["paint"];
type FillPaint = Required<FillLayer>["paint"];
type SymbolPaint = Required<SymbolLayer>["paint"];

function getLineSourceProps<P extends Record<string, unknown> | null>(id: string, points: Coords[], properties?: P) {
  return {
    id,
    type: "geojson" as const,
    data: {
      id,
      type: "Feature" as const,
      properties: (properties || null) as P extends undefined ? null : P,
      geometry: { type: "LineString" as const, coordinates: points },
    },
  } satisfies SourceProps;
}

function getPolygonSourceProps<P extends Record<string, unknown> | null>(id: string, points: Coords[], properties?: P) {
  return {
    id,
    type: "geojson" as const,
    data: {
      id,
      type: "Feature" as const,
      properties: (properties || null) as P extends undefined ? null : P,
      geometry: { type: "Polygon" as const, coordinates: [points] },
    },
  } satisfies SourceProps;
}

const getLineLayerProps = (
  sourceId: string,
  {
    color = "#888",
    width = 4,
    opacity = 0.25,
  }: {
    color?: LinePaint["line-color"];
    width?: LinePaint["line-width"];
    opacity?: LinePaint["line-opacity"];
  } = {},
) => {
  return {
    source: sourceId,
    type: "line" as const,
    layout: { "line-join": "round", "line-cap": "round" },
    paint: { "line-color": color, "line-width": width, "line-opacity": opacity },
  } satisfies LayerProps;
};

function getPolygonLayerProps(
  sourceId: string,
  {
    color = "#63976c",
    opacity = 0.25,
  }: {
    color?: FillPaint["fill-color"];
    opacity?: FillPaint["fill-opacity"];
  } = {},
) {
  return {
    source: sourceId,
    type: "fill" as const,
    paint: { "fill-color": color, "fill-opacity": opacity },
  } satisfies LayerProps;
}

function getTextLayerProps(
  sourceId: string,
  content: string,
  { color = "black" }: { color?: SymbolPaint["text-color"] } = {},
) {
  return {
    source: sourceId,
    type: "symbol" as const,
    layout: { "text-field": content },
    paint: { "text-color": color },
  } satisfies LayerProps;
}

function getPointSourceProps<P extends Record<string, unknown> | null>(id: string, points: Coords, properties?: P) {
  return {
    id,
    type: "geojson" as const,
    data: {
      id,
      type: "Feature" as const,
      properties: (properties || null) as P extends undefined ? null : P,
      geometry: { type: "Point" as const, coordinates: points },
    },
  } satisfies SourceProps;
}

function getPointLayerProps(
  sourceId: string,
  {
    color = "#63976c",
    radius = 10,
  }: {
    color?: FillPaint["fill-color"];
    radius?: number;
  } = {},
) {
  return {
    source: sourceId,
    type: "circle" as const,
    paint: { "circle-radius": radius, "circle-color": color },
  } satisfies LayerProps;
}

// https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglatboundslike
function getLngLatBounds(coords: Coords[]): [west: number, south: number, east: number, north: number] {
  let west = Number.POSITIVE_INFINITY;
  let south = Number.POSITIVE_INFINITY;
  let east = Number.NEGATIVE_INFINITY;
  let north = Number.NEGATIVE_INFINITY;

  for (const [lon, lat] of coords) {
    west = Math.min(west, lon);
    south = Math.min(south, lat);
    east = Math.max(east, lon);
    north = Math.max(north, lat);
  }

  return [west, south, east, north];
}

const getCoords = (boundaries: { lon: number; lat: number }[]): Coords[] => {
  return boundaries.map((boundary) => [boundary.lon, boundary.lat]);
};

export const MapUtils = {
  // Source component props
  getLineSourceProps,
  getPolygonSourceProps,
  getPointSourceProps,
  // Line component props
  getLineLayerProps,
  getPolygonLayerProps,
  getTextLayerProps,
  getPointLayerProps,
  // utilities
  getCoords,
  getLngLatBounds,
};
