import { FieldDetailPage } from "@/field/field-detail/FieldDetailPage";
import type { routes } from "@/routes";
import { type ParamParseKey, useParams } from "react-router-dom";

const FieldRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.FieldDetail>>();
  if (!id) throw new Error("Missing ID");

  return <FieldDetailPage fieldId={id} />;
};

export default FieldRoute;
