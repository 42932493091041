import { type FieldsQuery, useDeleteFieldMutation, useFieldsQuery } from "@/api/sdk";
import { Card } from "@/components/Card";
import { DashboardContent } from "@/components/DashboardContent";
import { Pagination, usePaginationState } from "@/components/Pagination";
import { PlaceholderCard } from "@/components/PlaceholderCard";
import { routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { useAppTitle } from "@/utils/useAppTitle";
import { useConfirm } from "@/utils/useConfirm";
import { Button } from "@roboton/ui";
import { keepPreviousData } from "@tanstack/react-query";
import clsx from "clsx";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DeleteFieldConfirmationModal } from "./components/DeleteFieldConfirmationModal";
import { FieldCard } from "./components/FieldCard";

type FieldsContentProps = JSX.IntrinsicElements["div"] & {
  fields: Required<FieldsQuery["fields"]>["nodes"];
  pagination: Required<FieldsQuery["fields"]>["pageInfo"];
  onPageClick: ComponentProps<typeof Pagination>["onClick"];
  onFieldDeleteSuccess: () => void;
};
const FieldsContent = ({
  fields,
  pagination,
  onPageClick,
  onFieldDeleteSuccess,
  className,
  ...rest
}: FieldsContentProps) => {
  const { t } = useTranslation();
  const { page, lastPage } = pagination;
  const { mutate } = useDeleteFieldMutation({
    onSuccess: ({ deleteField }) => {
      if (!deleteField.success) return;
      notify.positive(t("The removal was successful."));
      onFieldDeleteSuccess();
    },
  });

  const { isOpen, onDecline, onConfirm, getConfirmation } = useConfirm();

  const handleDeleteClick = async (fieldId: string) => {
    const confirmed = await getConfirmation();
    confirmed && mutate({ fieldId });
  };

  return (
    <Card {...rest} as={"section"} className={clsx("space-y-6", className)}>
      <h2 className={"typo-h2"}>
        {t("List of Fields")} ({fields.length})
      </h2>

      <div className={"grid gap-4 lg:grid-cols-2 xl:grid-cols-3"}>
        {fields.map((field) => (
          <FieldCard key={field.id} field={field} onDeleteClick={handleDeleteClick} />
        ))}
      </div>

      <DeleteFieldConfirmationModal
        isOpen={isOpen}
        headline={t("Delete Field")}
        onConfirm={onConfirm}
        onClose={onDecline}
      />

      <Pagination currentPage={page} pageCount={lastPage} onClick={onPageClick} />
    </Card>
  );
};

export const FieldsPage = () => {
  const { t } = useTranslation();
  useAppTitle(t("Fields"));
  const [page, setPage] = usePaginationState();
  const {
    data: { fields } = {},
    isLoading,
    refetch,
  } = useFieldsQuery({ paginationInput: { page, perPage: 12 } }, { placeholderData: keepPreviousData });

  const hasData = !!((fields?.nodes || []).length > 0 && fields?.pageInfo);

  return (
    <DashboardContent
      headline={t("Fields")}
      headerArea={
        <Button as={Link} to={routes.CreateField} icon={"plus"}>
          {t("Create new Field")}
        </Button>
      }
      isLoading={isLoading}
    >
      {hasData ? (
        <FieldsContent
          fields={fields.nodes}
          pagination={fields.pageInfo}
          onPageClick={setPage}
          onFieldDeleteSuccess={refetch}
        />
      ) : (
        <PlaceholderCard
          icon={"farm"}
          description={t("Here you can see a list of your fields. Start with creating one.")}
        >
          <Button as={Link} to={routes.CreateField} icon={"plus"} size={"medium"}>
            {t("Create new Field")}
          </Button>
        </PlaceholderCard>
      )}
    </DashboardContent>
  );
};
