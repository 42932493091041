import { Button, Input } from "@roboton/ui";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormData = {
  angle: number;
};

type ManualAngleFormProps = Omit<JSX.IntrinsicElements["form"], "onSubmit"> & {
  defaultValues?: Partial<FormData>;
  disabled: boolean;
  onSuccessSubmit: SubmitHandler<FormData>;
};

export const ManualAngleForm = (props: ManualAngleFormProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: props.defaultValues,
  });

  return (
    <form className={"flex gap-2"} onSubmit={handleSubmit(props.onSuccessSubmit)}>
      <Input
        {...register("angle", { valueAsNumber: true, required: true })}
        id={`${props.id}-custom-angle-input`}
        size={"small"}
        type={"number"}
        disabled={props.disabled}
        required
      />
      <Button
        type={"submit"}
        icon={"check"}
        size={"small"}
        disabled={props.disabled}
        aria-label={t("Preview working lines with the custom angle")}
      >
        {t("OK")}
      </Button>
    </form>
  );
};
