import { Card } from "@/components/Card";
import clsx from "clsx";
import type { ComponentProps, ReactNode } from "react";

export const GrowingPlanCardLayout = (props: {
  checkbox: ReactNode;
  progress: ReactNode;
  name: ReactNode;
  crop: ReactNode;
  state: ReactNode;
  type: ReactNode;
  robot: ReactNode;
  field: ReactNode;
  block: ReactNode;
  start: ReactNode;
  end: ReactNode;
  action: ReactNode;
}) => {
  return (
    <Card variant={"flat"} className={clsx("c-growing-plan-card")}>
      <div className={"c-growing-plan-card__checkbox"}>{props.checkbox}</div>
      <div className={"c-growing-plan-card__progress-bar"}>{props.progress}</div>

      <div className={"c-growing-plan-card--middle-content"}>
        <div className={"c-growing-plan-card__name"}>{props.name}</div>
        <div className={"c-growing-plan-card__details-row"}>
          <div className={"c-growing-plan-card__crop"}>{props.crop}</div>
          <div className={"c-growing-plan-card__state"}>{props.state}</div>
          <div className={"c-growing-plan-card__type"}>{props.type}</div>
          <div className={"c-growing-plan-card__robot"}>{props.robot}</div>
          <div className={"c-growing-plan-card__field"}>{props.field}</div>
          <div className={"c-growing-plan-card__block"}>{props.block}</div>
          <div className={"c-growing-plan-card__start"}>{props.start}</div>
          <div className={"c-growing-plan-card__end"}>{props.end}</div>
        </div>
      </div>
      <div className={"c-growing-plan-card__action"}>{props.action}</div>
    </Card>
  );
};

export const GrowingPlanCardControlLayout = (
  props: {
    checkbox: ReactNode;
    crop: ReactNode;
    state: ReactNode;
    type: ReactNode;
    robot: ReactNode;
    field: ReactNode;
    block: ReactNode;
    start: ReactNode;
    end: ReactNode;
  } & Omit<ComponentProps<typeof Card>, "variant">,
) => {
  return (
    <Card variant={"flat"} className={"c-growing-plans-controls"}>
      <div className={"flex gap-1 truncate"}>{props.checkbox}</div>
      <div />

      <div className={"c-growing-plans-controls__details-row"}>
        <div className={"flex items-center gap-1 truncate"}>{props.crop}</div>
        <div className={"flex items-center gap-1 truncate"}>{props.state}</div>
        <div className={"flex items-center gap-1 truncate"}>{props.type}</div>
        <div className={"flex items-center gap-1 truncate"}>{props.robot}</div>
        <div className={"flex items-center gap-1 truncate"}>{props.field}</div>
        <div className={"flex items-center gap-1 truncate"}>{props.block}</div>
        <div className={"flex items-center gap-1 truncate"}>{props.start}</div>
        <div className={"flex items-center gap-1 truncate"}>{props.end}</div>
      </div>
      <div />
    </Card>
  );
};
