import { Ordering } from "@/api/sdk";
import { Icon } from "@roboton/ui";
import clsx from "clsx";

type SortButtonProps = {
  isActive: boolean;
  direction?: Ordering;
  onClick: JSX.IntrinsicElements["button"]["onClick"];
};

export function SortButton({ isActive, direction, ...rest }: SortButtonProps) {
  return (
    <button {...rest} type="button" className={clsx("rounded-sm px-1 text-sm", isActive && "bg-brand-50 text-light-0")}>
      <Icon width={"1rem"} type={direction === Ordering.Desc ? "sort_desc" : "sort_asc"} />
    </button>
  );
}
