// Group arrays together by a certain condition
// source: https://upmostly.com/typescript/implementing-groupby-in-typescript
export function groupBy<T, K extends string | number>(arr: readonly T[], fn: (item: T) => K) {
  const result: Record<K, T[]> = {} as Record<K, T[]>;

  for (const item of arr) {
    const groupKey = fn(item);

    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    result[groupKey].push(item);
  }

  return result;
}
