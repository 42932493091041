import { Spinner } from "@/components/Spinner";
import clsx from "clsx";

export const LoadingContent = ({ className, ...rest }: Omit<JSX.IntrinsicElements["div"], "children">) => {
  return (
    <div {...rest} className={clsx("flex items-center justify-center", className)}>
      <Spinner />
    </div>
  );
};
