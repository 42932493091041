import type { Coords } from "@/components/Map/types";
import { formatDuration, interval, intervalToDuration } from "date-fns";

// format ISO date to human-readable date
export function formatDate(date: string, locale: Intl.LocalesArgument) {
  const dateObj = new Date(date);

  const isValidDate = dateObj.toString() !== "Invalid Date";
  if (!isValidDate) {
    throw new Error(`Invalid date: ${date}`);
  }

  return dateObj.toLocaleDateString(locale);
}

export function formatDateTime(date: string, locale: Intl.LocalesArgument) {
  const dateObj = new Date(date);

  const isValidDate = dateObj.toString() !== "Invalid Date";
  if (!isValidDate) {
    throw new Error(`Invalid date: ${date}`);
  }

  return dateObj.toLocaleString(locale);
}

const COORDINATES_PRECISION = 5;

export function formatCoords([lon, lat]: Coords) {
  return `${lat.toFixed(COORDINATES_PRECISION)}, ${lon.toFixed(COORDINATES_PRECISION)}`;
}

export function formatCoordinates({ lat, lon }: { lat: number; lon: number }) {
  return `${lat.toFixed(COORDINATES_PRECISION)}, ${lon.toFixed(COORDINATES_PRECISION)}`;
}

// Format area to 2 decimal places with unit m²
export function formatNumberToSquareMeters(area: number) {
  return `${area.toFixed(2)} m²`;
}

export function roundToDecimal(number: number, maxDecimals: number) {
  if (!Number.isInteger(maxDecimals)) throw new Error("Decimal must be an integer.");
  if (maxDecimals < 0) throw new Error("Decimal must be a positive integer.");

  const factor = 10 ** maxDecimals;
  return Math.round(number * factor) / factor;
}

// Convert duration in hours to human readable format
// e.g. 1.5 hours -> 1 hour, 30 minutes
export function formatDurationToHumanize(durationInHours: number) {
  return formatDuration(intervalToDuration(interval(0, durationInHours * 60 * 60 * 1000)), { delimiter: ", " });
}

export function formatToRequiredString(value: string) {
  return `${value} *`;
}
