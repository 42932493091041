import type { GrowingPlanFilter } from "@/api/sdk";
import { CultivationMethod, GrowingPlanState } from "@/api/sdk";
import { useSearchParamsState } from "@/utils/useSearchParamsState";
import { type ReactNode, createContext, useContext, useMemo, useState } from "react";
import z, { type ZodType } from "zod";

// source: https://github.com/colinhacks/zod/issues/316#issuecomment-2081302212
function fallback<T>(value: T): ZodType<T> {
  return z.any().transform(() => value);
}

const storeSchema = z.object({
  cropName: z.string().nullish().or(fallback(null)),
  field: z.string().nullish().or(fallback(null)),
  block: z.string().nullish().or(fallback(null)),
  state: z.nativeEnum(GrowingPlanState).nullish().or(fallback(null)),
  cultivation: z
    .nativeEnum(CultivationMethod)
    .transform((v) => v || null)
    .nullish()
    .or(fallback(null)),
  startAt: z.string().nullish().or(fallback(null)),
  endAt: z.string().nullish().or(fallback(null)),
});

type ValuesState = z.infer<typeof storeSchema> & {
  crop?: string | null;
};

type SearchedState = {
  crop?: string;
  field?: string;
  block?: string;
};

type StateActions = {
  setCrop: (
    selected: ValuesState["crop"],
    selectedName: ValuesState["cropName"],
    searched: SearchedState["crop"],
  ) => void;
  setField: (selected: ValuesState["field"], searched: SearchedState["field"]) => void;
  setBlock: (selected: ValuesState["block"], searched: SearchedState["block"]) => void;
  setState: (state: ValuesState["state"]) => void;
  setCultivation: (cultivation: ValuesState["cultivation"]) => void;
  setStartAt: (startAt: ValuesState["startAt"]) => void;
  setEndAt: (endAt: ValuesState["endAt"]) => void;
  resetAll: () => void;
};

type ComputedState = {
  apiFilter: GrowingPlanFilter;
  isFilterActive: (name?: keyof ValuesState) => boolean;
};

type Context = {
  values: ValuesState;
  actions: StateActions;
  searched: SearchedState;
} & ComputedState;

const FiltersContext = createContext<Context | null>(null);

export const FiltersStoreProvider = ({ children }: { children: ReactNode }) => {
  const [crop, setCrop] = useState<ValuesState["crop"]>();
  // returned value is not directly used in the provider, it has to be validated first
  const [_store, setStore] = useSearchParamsState({
    cropName: { type: "string", default: null },
    field: { type: "string", default: null },
    block: { type: "string", default: null },
    state: { type: "string", default: null },
    cultivation: { type: "string", default: null },
    startAt: { type: "string", default: null },
    endAt: { type: "string", default: null },
  });

  const { cropName, field, block, state, cultivation, startAt, endAt } = storeSchema.safeParse(_store).data || {};

  const [searched, setSearched] = useState<SearchedState>({});

  // transform state to API filter value
  const apiFilter: ComputedState["apiFilter"] = useMemo(
    () => ({
      crop: cropName ? { iContains: cropName } : null,
      fieldId: field || null,
      blockId: block || null,
      cultivation: cultivation || null,
      startAt: startAt || null,
      endAt: endAt || null,
      state: state || null,
    }),
    [cropName, field, block, cultivation, startAt, endAt, state],
  );

  const isFilterActive: ComputedState["isFilterActive"] = (name) => {
    const values = {
      crop,
      cropName,
      field,
      block,
      cultivation,
      startAt,
      endAt,
      state,
    };
    return name ? !!values[name] : Object.values(values).some((value) => !!value);
  };

  return (
    <FiltersContext.Provider
      value={{
        values: {
          crop,
          cropName,
          field,
          block,
          state,
          cultivation,
          startAt,
          endAt,
        },
        searched,
        actions: {
          setCrop: (selected, selectedName, searched) => {
            setCrop(selected);
            setStore({ cropName: selectedName });
            setSearched((prev) => ({ ...prev, crop: searched }));
          },
          setBlock: (selected, searched) => {
            setStore({ block: selected });
            setSearched((prev) => ({ ...prev, block: searched }));
          },
          setField: (selected, searched) => {
            setStore({ field: selected });
            setSearched((prev) => ({ ...prev, field: searched }));
          },
          setState: (state) => {
            setStore({ state });
          },
          setCultivation: (cultivation) => {
            setStore({ cultivation });
          },
          setStartAt: (startAt) => {
            setStore({ startAt });
          },
          setEndAt: (endAt) => {
            setStore({ endAt });
          },
          resetAll: () => {
            setCrop(undefined);
            setStore({
              cropName: null,
              field: null,
              block: null,
              state: null,
              cultivation: null,
              startAt: null,
              endAt: null,
            });
            setSearched({});
          },
        },
        apiFilter,
        isFilterActive,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersStore = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error("useFilters must be used within a FiltersProvider");
  }
  return context;
};

export type { ValuesState as FiltersStoreData };
