import { EditFieldPage } from "@/field/manage-field/EditFieldPage";
import type { routes } from "@/routes";
import { type ParamParseKey, useParams } from "react-router-dom";

const EditFieldRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.EditField>>();
  if (!id) throw new Error("Missing ID");

  return <EditFieldPage fieldId={id} />;
};

export default EditFieldRoute;
