import type { Merge } from "@roboton/tools";
import clsx from "clsx";
import { type ReactNode, forwardRef } from "react";
import { Icon } from "../../Icon/Icon";

type CheckboxProps = Merge<
  Omit<JSX.IntrinsicElements["input"], "type" | "placeholder" | "children">,
  {
    label?: ReactNode;
    id: string;
    state?: "base" | "negative";
    size?: "large" | "medium" | "small";
    indeterminate?: boolean;
  }
>;

const stateToClassNameMap: Record<Required<CheckboxProps>["state"], string> = {
  base: "ui-checkbox--base",
  negative: "ui-checkbox--negative",
};

const sizeToClassNameMap: Record<Required<CheckboxProps>["size"], string> = {
  large: "ui-checkbox--large",
  medium: "ui-checkbox--medium",
  small: "ui-checkbox--small",
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { state = "base", size = "medium", label, id, indeterminate, disabled, readOnly, className, ...props },
    ref,
  ): JSX.Element => {
    const hasLabel = typeof label === "string" ? !!label.length : label !== undefined;
    const Element = hasLabel ? "label" : "div";
    const isTouchable: boolean = !disabled && !readOnly;

    return (
      <Element
        className={clsx(
          "ui-checkbox",
          isTouchable ? stateToClassNameMap[state] : "ui-checkbox--disabled",
          hasLabel && "ui-checkbox--has-label",
          sizeToClassNameMap[size],
          className,
        )}
        {...(hasLabel && { htmlFor: id })}
      >
        <input
          {...props}
          type="checkbox"
          className={"ui-checkbox__element"}
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          data-indeterminate={indeterminate ?? undefined}
          ref={ref}
        />
        {indeterminate ? (
          /* <span className={"ui-checkbox__indeterminate-element"} aria-hidden /> */
          <div className={"ui-checkbox__checked-icon"} aria-hidden />
        ) : (
          <Icon type={"check"} className={"ui-checkbox__checked-icon"} aria-hidden />
        )}

        {label ? <span className={"ui-checkbox__label"}>{label}</span> : null}
      </Element>
    );
  },
);

Checkbox.displayName = "Checkbox";
