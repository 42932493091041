import type { GrowingPlanYearPreviewQuery } from "@/api/sdk";
import { useGrowingPlanYearPreviewQuery } from "@/api/sdk";
import { LoadingContent } from "@/components/LoadingContent";
import { roundToDecimal } from "@/utils/format";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import type { AxisOptions } from "react-charts";
import { Chart } from "react-charts";

/*
---------------------------------------------------------------------
Chart
---------------------------------------------------------------------
 */
type ChartProps = ComponentProps<typeof Chart>;

type ContentProps = {
  // required to generate the gradient ID
  id: string;
  // source data for the chart
  data: GrowingPlanYearPreviewQuery["growingPlanYearPreview"];
  // data used to highlight the values in the chart
  growingInfo?: {
    startAt: string;
    endAt: string;
  };
};

const Content = ({ id, data, growingInfo }: ContentProps) => {
  const chartData = useMemo(() => {
    return [{ label: "yeld", data: data || [] }] satisfies ChartProps["options"]["data"];
  }, [data]);

  type ChartDataItem = (typeof chartData)[number]["data"][number];

  const primaryAxis = useMemo<AxisOptions<ChartDataItem>>(
    () => ({
      getValue: (datum) => new Date(datum.date),
      position: "bottom",
      scaleType: "localTime",
    }),
    [],
  );

  const secondaryAxes = useMemo<AxisOptions<ChartDataItem>[]>(
    () => [
      {
        getValue: (datum) => datum.yieldPercentage,
        formatters: {
          scale: (value: ChartDataItem["yieldPercentage"]) => `${roundToDecimal(value, 2)} %`,
        },
        hardMin: 0,
        hardMax: 100,
        position: "left",
        elementType: "bar",
      },
    ],
    [],
  );

  return (
    <Chart
      options={{
        data: chartData,
        primaryAxis,
        secondaryAxes,
        tooltip: false,
        getDatumStyle: (datum) => {
          const date = datum.originalDatum.date;
          const isHighlighted = growingInfo ? date >= growingInfo?.startAt && date <= growingInfo?.endAt : false;

          return {
            color: `url(#${id})`,
            opacity: isHighlighted ? 1 : 0.5,
          };
        },
        renderSVG: () => (
          <defs>
            <linearGradient id={id} x1="0" x2="0" y1="1" y2="0">
              <stop offset="0%" stopColor="red" />
              <stop offset="100%" stopColor="green" />
            </linearGradient>
          </defs>
        ),
      }}
    />
  );
};

/*
---------------------------------------------------------------------
Chart Wrapper
---------------------------------------------------------------------
 */
type YearPreviewChartProps = Pick<ContentProps, "growingInfo"> & {
  blockId: string;
  cropSheetId: string;
};

export function YearPreviewChart({ blockId, cropSheetId, growingInfo }: YearPreviewChartProps) {
  const {
    data: { growingPlanYearPreview } = {},
    isLoading,
  } = useGrowingPlanYearPreviewQuery({ blockId, cropSheetId });

  const uniqueKey = ["year-preview-chart", blockId, cropSheetId].join("-");

  return (
    <div className={"relative h-[24rem] w-full"}>
      {growingPlanYearPreview ? (
        <Content id={uniqueKey} data={growingPlanYearPreview} growingInfo={growingInfo} />
      ) : null}
      {isLoading ? <LoadingContent className={"absolute inset-0"} /> : null}
    </div>
  );
}
