import {
  type ClientAccountForEditPageQuery,
  useClientAccountForEditPageQuery,
  useUpdateClientAccountMutation,
} from "@/api/sdk";
import { Card } from "@/components/Card";
import { DashboardContent } from "@/components/DashboardContent";
import { LoadingContent } from "@/components/LoadingContent";
import { routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { useAppTitle } from "@/utils/useAppTitle"; /*
---------------------------------------------------------------------
Content with form
---------------------------------------------------------------------
 */
import type { Exact } from "@roboton/tools";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClientAccountForm } from "./components/ClientAccountForm";

/*
---------------------------------------------------------------------
Content with form
---------------------------------------------------------------------
 */
type ContentProps = {
  clientAccount: ClientAccountForEditPageQuery["clientAccount"];
};

const Content = ({ clientAccount }: ContentProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate } = useUpdateClientAccountMutation({
    onSuccess: ({ updateClientAccount: { success } }) => {
      if (!success) return;
      notify.positive(t("All changes have been successfully saved."));
      navigate(routes.ClientAccounts);
    },
  });

  const defaultValues: ComponentProps<typeof ClientAccountForm>["defaultValues"] = {
    address: clientAccount.address,
    businessName: clientAccount.businessName,
    city: clientAccount.city,
    country: clientAccount.country,
    postalCode: clientAccount.postalCode,
  };

  const clientAccountId = clientAccount.id;

  const handleSubmit: ComponentProps<typeof ClientAccountForm>["onSubmitValid"] = (formInputs) => {
    const input: Exact<typeof formInputs, Parameters<typeof mutate>[0]["clientAccountInput"]> = formInputs;
    mutate({ clientAccountId, clientAccountInput: input });
  };

  return (
    <ClientAccountForm defaultValues={defaultValues} submitButtonText={"Save changes"} onSubmitValid={handleSubmit} />
  );
};

/*
---------------------------------------------------------------------
Page
---------------------------------------------------------------------
 */
export const EditClientAccountPage = ({ clientAccountId }: { clientAccountId: string }) => {
  const { t } = useTranslation();
  useAppTitle(t("Edit Client Account"));

  const {
    data: { clientAccount } = {},
    isLoading,
  } = useClientAccountForEditPageQuery({ clientAccountId }, { gcTime: 0 });

  return (
    <DashboardContent headline={t("Edit Client Account")} navigateBackTo={routes.ClientAccounts}>
      <Card className={"relative mx-auto min-h-[30rem] w-[24rem] max-w-full"}>
        {isLoading ? <LoadingContent className={"absolute inset-0"} /> : null}
        {clientAccount ? <Content clientAccount={clientAccount} /> : null}
      </Card>
    </DashboardContent>
  );
};
