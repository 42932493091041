import { RouterTabs } from "@/components/RouterTabs";
import { generatePath, routes } from "@/routes";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type RobotDetailTabsProps = Omit<ComponentProps<typeof RouterTabs>, "id" | "items" | "size" | "panelProps"> & {
  robotId: string;
};

export const RobotDetailTabs = ({ robotId, children, ...rest }: RobotDetailTabsProps) => {
  const { t } = useTranslation();

  const items = useMemo(
    () => [
      {
        id: "general",
        label: t("General"),
        path: generatePath(routes.RobotDetailGeneral, { id: robotId }),
      },
      {
        id: "tasks",
        label: t("Tasks"),
        path: generatePath(routes.RobotDetailTasks, { id: robotId }),
      },
    ],
    [t, robotId],
  );

  return (
    <RouterTabs
      {...rest}
      id={"robot-detail-tabs"}
      items={items}
      size={"large"}
      panelProps={{ className: "mt-4 grow flex flex-col gap-6" }}
    >
      {children}
    </RouterTabs>
  );
};
