import { ThreatDetailPage } from "@/crop-sheet/threat-detail/ThreatDetailPage";
import type { routes } from "@/routes";
import type { ParamParseKey } from "react-router-dom";
import { useParams } from "react-router-dom";

const ThreatRoute = () => {
  const { threatId } = useParams<ParamParseKey<typeof routes.ThreatDetail>>();
  if (!threatId) throw new Error("Missing ID");

  return <ThreatDetailPage threatId={threatId} />;
};

export default ThreatRoute;
