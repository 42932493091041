import type { HomeStationsQuery } from "@/api/sdk";
import { useHomeStationsQuery, useUpdateFieldHomeStationPathMutation } from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { DashboardContent } from "@/components/DashboardContent";
import { Layer, MapUtils, Source } from "@/components/Map";
import { FieldMap } from "@/field/components/FieldMap";
import { generatePath, routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { formatCoordinates, formatDate } from "@/utils/format";
import { useAppTitle } from "@/utils/useAppTitle";
import { Button, Radio } from "@roboton/ui";
import clsx from "clsx";
import { type ComponentProps, Fragment, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { MapRef } from "react-map-gl/maplibre";
import { Marker } from "react-map-gl/maplibre";
import { useNavigate } from "react-router-dom";
import type { FieldBasicModel } from "../field.model";
import { useFieldBasicTransformedQuery } from "../useFieldBasicTransformedQuery";

type ContentProps = {
  field: FieldBasicModel;
  homeStations: HomeStationsQuery["homeStationGpsTraces"]["nodes"];
};

const FORM_ID = "add-home-station-form";

const Content = ({ field, homeStations }: ContentProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const headline = t("Add a Home Station");
  useAppTitle(headline);
  const [selectedHomeStationPathId, setSelectedHomeStationPathId] = useState<string>();

  const handleHomeStationChange: ComponentProps<typeof Radio>["onChange"] = (e) => {
    setSelectedHomeStationPathId(e.target.value);
    fitBoundsToPath(e.target.value);
  };

  const fieldDetailPath = generatePath(routes.FieldDetail, { id: field.id });
  const { coords } = field;

  const isSubmitDisabled = !selectedHomeStationPathId;

  const navigate = useNavigate();

  const { mutateAsync } = useUpdateFieldHomeStationPathMutation();

  const handleFormSubmit: JSX.IntrinsicElements["form"]["onSubmit"] = async (e) => {
    e.preventDefault();
    try {
      await mutateAsync({ fieldId: field.id, gpsTraceId: selectedHomeStationPathId });
      notify.positive(t("Home Station successfully added"));
      navigate(fieldDetailPath);
    } catch (error) {
      console.error(error);
    }
  };

  const mapRef = useRef<MapRef>(null);

  const fitBoundsToPath = (pathId: string) => {
    const path = homeStations.find((homeStation) => homeStation.id === pathId);
    if (!path) {
      return;
    }
    const pathCoords = MapUtils.getCoords(path.coordinates ?? []);
    const bounds = MapUtils.getLngLatBounds([...coords, ...pathCoords]);
    mapRef.current?.fitBounds(bounds, { padding: 40 });
  };

  return (
    <DashboardContent
      headline={headline}
      navigateBackTo={fieldDetailPath}
      headerArea={
        <Button form={FORM_ID} disabled={isSubmitDisabled} icon={"check"}>
          {t("Add Home Station")}
        </Button>
      }
    >
      <Bricks.Layout
        mapAreaSize={"large"}
        mapArea={
          <Bricks.MapArea>
            {homeStations.length ? (
              <FieldMap field={field} mapRef={mapRef}>
                {/* home stations */}
                {homeStations.map((homeStation) => {
                  const pathId = homeStation.id;
                  const isSelected = pathId === selectedHomeStationPathId;
                  const homeStationCoords = homeStation.coordinates?.length ? homeStation.coordinates[0] : null;
                  const handleClick = () => {
                    setSelectedHomeStationPathId(pathId);
                    fitBoundsToPath(pathId);
                  };
                  return (
                    <Fragment key={pathId}>
                      {homeStationCoords ? (
                        <Marker
                          latitude={homeStationCoords.lat}
                          longitude={homeStationCoords.lon}
                          onClick={handleClick}
                        >
                          <div
                            className={clsx(
                              "bg-brand-25 border-brand-25 h-6 w-6 rounded-full border-2 border-dashed hover:cursor-pointer",
                              !isSelected && "bg-opacity-25",
                            )}
                          />
                        </Marker>
                      ) : null}

                      <Source
                        {...MapUtils.getLineSourceProps(
                          homeStation.id,
                          MapUtils.getCoords(homeStation.coordinates ?? []),
                        )}
                      >
                        <Layer
                          id={homeStation.id}
                          {...MapUtils.getLineLayerProps(homeStation.id, {
                            color: isSelected ? "#63976c" : undefined,
                            opacity: isSelected ? 0.8 : undefined,
                          })}
                        />
                      </Source>
                    </Fragment>
                  );
                })}
              </FieldMap>
            ) : (
              <p className={"whitespace-pre-wrap text-center"}>
                {t(
                  "We haven't been able to find any roads.\n\nMake sure you have a path to the field recorded by the robot in the mobile app and that your mobile phone is connected to net.",
                )}
              </p>
            )}
          </Bricks.MapArea>
        }
      >
        <Bricks.Card headline={t("Paths form the mobile app")}>
          <form id={FORM_ID} onSubmit={handleFormSubmit}>
            <fieldset className={"flex flex-col gap-4"}>
              <legend className={"mb-4"}>{t("Select one Home Station from the found data.")}</legend>
              {homeStations.length === 0 ? <p>{t("Waiting for data from the mobile app...")}</p> : null}
              {homeStations.map((homeStation) => (
                <Radio
                  label={
                    <>
                      <strong className={"block"} aria-label={t("Home station name")}>
                        {homeStation.name}
                      </strong>
                      {homeStation.coordinates?.[0] ? (
                        <span aria-hidden>
                          {formatDate(homeStation.createdAt, language)} -{" "}
                          {formatCoordinates(homeStation.coordinates[0])}
                        </span>
                      ) : null}
                    </>
                  }
                  key={homeStation.id}
                  id={homeStation.id}
                  name={"home-station"}
                  value={homeStation.id}
                  checked={homeStation.id === selectedHomeStationPathId}
                  onChange={handleHomeStationChange}
                />
              ))}
            </fieldset>
          </form>
        </Bricks.Card>
      </Bricks.Layout>
    </DashboardContent>
  );
};

export const ManageHomeStationPage = ({ fieldId }: { fieldId: string }) => {
  const { t } = useTranslation();
  useAppTitle(t("Manage Home Station"));

  const {
    data: { field } = {},
    isLoading: isFieldLoading,
  } = useFieldBasicTransformedQuery({ fieldId }, { refetchOnWindowFocus: false });

  const { data, isLoading: isHomeStationsLoading } = useHomeStationsQuery({ fieldId }, { refetchOnWindowFocus: false });

  const homeStations = useMemo(() => {
    return data?.homeStationGpsTraces.nodes ?? [];
  }, [data]);

  const isLoading = isFieldLoading || isHomeStationsLoading;

  return field ? (
    <Content field={field} homeStations={homeStations} />
  ) : (
    <DashboardContent isLoading={isLoading}>No Data</DashboardContent>
  );
};
