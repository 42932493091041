import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

/**
 * React Query as a state manager.
 * Value is saved in the Storage.
 *
 * The benefit of this method is that another component can read it
 * simultaneously, and the updated value will be read.
 *
 * inspired by: https://daily-dev-tips.com/posts/react-query-as-a-persistent-state-manager/
 */
export function usePersistentStateQuery<T extends string>(
  key: string,
  options: {
    // for example: window.localStorage
    storage: Storage;
  },
): [T | null, (value: T | null) => Promise<void>] {
  const { storage } = options;

  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [key], [key]);
  const { data } = useQuery({
    queryKey,
    queryFn: () => storage.getItem(key) as T | null,
    initialData: storage.getItem(key) as T | null,
  });

  const { mutateAsync: updateValue } = useMutation<void, unknown, T | null>({
    mutationKey: queryKey,
    mutationFn: async (value: T | null) =>
      value === null ? storage.removeItem(key) : storage.setItem(key, JSON.stringify(value)),
    onMutate: (mutatedData) => {
      const current = data;
      queryClient.setQueryData(queryKey, mutatedData);
      return current;
    },
    onError: (_, __, rollback) => {
      queryClient.setQueryData(queryKey, rollback);
    },
  });

  return [data || null, updateValue];
}
