import { Message } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

export const NewBlockMessage = (props: Omit<ComponentProps<typeof Message>, "color" | "icon" | "children">) => {
  const { t } = useTranslation();
  return (
    <Message {...props} color={"blue"} icon={"information"}>
      {t("New blocks contain the default color. The color of the block can be changed by clicking on the color icon.")}
    </Message>
  );
};
