import { Button } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useCreateGrowingPlanMutation } from "@/api/sdk";
import { DashboardContent } from "@/components/DashboardContent";
import { routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { useAppTitle } from "@/utils/useAppTitle";
import { useNavigate } from "react-router-dom";
import { useCreateGrowingPlanBaseInfoForm } from "./base-info/BasicInfoForm";
import { CreateGrowingPlanBaseInfoContent } from "./base-info/CreateGrowingPlanBaseInfoContent";
import { CreateGrowingPlanStartDayInfoContent } from "./start-at-info/CreateGrowingPlanStartDayInfoContent";
import type { CreateGrowingPlanStartAtFormData } from "./start-at-info/StartAtForm";

const steps = { Base: "base", StartDay: "startDay" } as const;

type BaseInfoContentProps = ComponentProps<typeof CreateGrowingPlanBaseInfoContent>;
type StartDayContentProps = ComponentProps<typeof CreateGrowingPlanStartDayInfoContent>;

const FORM_ID = {
  BaseInfo: "create-growing-plan-base-info",
  StartDayInfo: "create-growing-plan-start-day-info",
} as const;

const INIITIAL_START_AT = new Date().toISOString().split("T")[0];

type Store = {
  cropSheetId: string;
  blockId: string;
  startAt: string;
};

export const CreateGrowingPlanPage = () => {
  const { t } = useTranslation();
  const headline = t("Create a new growing plan");
  useAppTitle(headline);

  const [store, setStore] = useState<Store>(() => ({
    cropSheetId: "",
    blockId: "",
    startAt: INIITIAL_START_AT,
  }));

  const baseInfoForm = useCreateGrowingPlanBaseInfoForm();
  const startAtInfoForm = useForm<CreateGrowingPlanStartAtFormData>({
    defaultValues: {
      startAt: store.startAt,
    },
  });

  const isBaseInfoFormValid = baseInfoForm.formState.isValid;
  const isBaseInfoStored = store.cropSheetId && store.blockId;
  const isStartDayInfoFormValid = startAtInfoForm.formState.isValid;
  const currentStep = isBaseInfoStored ? steps.StartDay : steps.Base;

  const navigate = useNavigate();
  const { mutate, isPending: isCreating } = useCreateGrowingPlanMutation({
    onSuccess: ({ createGrowingPlan: { success } }) => {
      if (!success) return;
      notify.positive(t("Creation was successful."));
      navigate(routes.GrowingPlans);
    },
  });

  const handleBaseInfoSubmit: BaseInfoContentProps["onSuccessSubmit"] = (data) => {
    setStore((prev) => ({ ...prev, cropSheetId: data.cropId, blockId: data.blockId }));
  };
  const handleStartDayInfoSubmit: StartDayContentProps["onSuccessSubmit"] = (data) => {
    setStore((prev) => ({ ...prev, startAt: data.startAt }));
  };

  const handleCreateClick = () => {
    mutate({
      cropSheetId: store.cropSheetId,
      blockId: store.blockId,
      startAt: store.startAt,
    });
  };

  const submitButtonProps: ComponentProps<typeof Button<"button">> =
    currentStep === steps.Base
      ? {
          type: "submit",
          form: FORM_ID.BaseInfo,
          children: t("Continue"),
          icon: "arrow_right",
          iconAlign: "right",
          disabled: !isBaseInfoFormValid || isCreating,
        }
      : {
          type: "button",
          children: t("Schedule Growing Plan"),
          icon: "check",
          disabled: !isStartDayInfoFormValid || isCreating,
          onClick: handleCreateClick,
        };

  return (
    <DashboardContent
      headline={headline}
      navigateBackTo={routes.GrowingPlans}
      headerArea={<Button {...submitButtonProps} />}
      isLoading={isCreating}
    >
      {currentStep === steps.Base ? (
        <CreateGrowingPlanBaseInfoContent
          form={baseInfoForm}
          formId={FORM_ID.BaseInfo}
          onSuccessSubmit={handleBaseInfoSubmit}
        />
      ) : null}

      {currentStep === steps.StartDay ? (
        <CreateGrowingPlanStartDayInfoContent
          form={startAtInfoForm}
          formId={FORM_ID.StartDayInfo}
          cropSheetId={store.cropSheetId}
          blockId={store.blockId}
          onSuccessSubmit={handleStartDayInfoSubmit}
        />
      ) : null}
    </DashboardContent>
  );
};
