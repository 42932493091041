import type { Modes } from "@mapbox/mapbox-gl-draw";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import type { GeoJsonProperties, Geometry } from "geojson";
import { useCallback } from "react";
import type { MapContextValue } from "react-map-gl/dist/esm/components/map";
import type { ControlPosition } from "react-map-gl/maplibre";
import { useControl } from "react-map-gl/maplibre";
import type { FeatureWithId } from "./types";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { drawTheme } from "@/components/Map/drawTheme";

type DrawControlProps<T extends Geometry, P extends GeoJsonProperties = GeoJsonProperties> = ConstructorParameters<
  typeof MapboxDraw
>[0] & {
  defaultMode?: keyof Modes;
  position?: ControlPosition;
  onCreate?: (event: {
    // Array of GeoJSON objects representing the features that were created
    features: FeatureWithId<T, P>[];
  }) => void;
  onUpdate?: (event: {
    // Array of features that were updated
    features: FeatureWithId<T, P>[];
    // Name of the action that triggered the update
    action: string;
  }) => void;
  onDelete?: (event: {
    // Array of GeoJSON objects representing the features that were deleted
    features: FeatureWithId<T, P>[];
  }) => void;
  onSelectionChange?: (event: {
    // // Array of features that are selected after the change
    features: FeatureWithId<T, P>[];
  }) => void;
  onLoad?: (mapboxDraw: MapboxDraw) => void;
};

export function DrawControl<T extends Geometry = Geometry, P extends GeoJsonProperties = GeoJsonProperties>(
  props: DrawControlProps<T, P>,
) {
  const {
    position = "top-left",
    displayControlsDefault = false,
    styles = [],
    onCreate,
    onUpdate,
    onDelete,
    onSelectionChange,
    onLoad,
  } = props;

  const handle = useCallback(
    ({ map }: MapContextValue) => {
      // MapboxDraw requires the canvas's class order to have the class
      // "mapboxgl-canvas" first in the list for the key bindings to work
      // source: https://github.com/maplibre/maplibre-gl-js/issues/2601#issuecomment-1564747778
      map.getCanvas().className = "mapboxgl-canvas maplibregl-canvas";
      map.getContainer().classList.add("mapboxgl-map");
      // @ts-expect-error
      const canvasContainer = map.getCanvasContainer();
      canvasContainer.classList.add("mapboxgl-canvas-container");
      if (canvasContainer.classList.contains("maplibregl-interactive")) {
        canvasContainer.classList.add("mapboxgl-interactive");
      }

      // Available methods:
      // @see https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md#events
      onCreate && map.on("draw.create", onCreate);
      onUpdate && map.on("draw.update", onUpdate);
      onDelete && map.on("draw.delete", onDelete);
      onSelectionChange && map.on("draw.selectionchange", onSelectionChange);
    },
    [onCreate, onDelete, onSelectionChange, onUpdate],
  );

  useControl<MapboxDraw>(
    () => {
      const mergedStyles = [...drawTheme, ...styles]; // merge default styles with own
      const mapboxDraw = new MapboxDraw({
        ...props,
        displayControlsDefault,
        styles: mergedStyles,
      });
      onLoad?.(mapboxDraw);

      // maplibre v3 dropped mapbox class names, so we need to add them back
      // source: https://github.com/maplibre/maplibre-gl-js/issues/2601#issuecomment-1564747778
      const originalOnAdd = mapboxDraw.onAdd.bind(mapboxDraw);
      mapboxDraw.onAdd = (map) => {
        const controlContainer = originalOnAdd(map);
        controlContainer.classList.add("maplibregl-ctrl", "maplibregl-ctrl-group");
        return controlContainer;
      };

      return mapboxDraw;
    },
    handle,
    handle,
    { position },
  );

  return null;
}
