import {
  CultivationMethod,
  FieldIrrigation,
  FieldLighting,
  FieldTaskJobWarningMessage,
  FieldTaskStartOption,
  FieldTaskState,
  GrowingPlanState,
  PlantSpacingPattern,
  RiRobotBatteryChargingState,
  RiRobotDeviceType,
  RiRobotToolMode,
  RiRobotToolType,
  SoilStatus,
} from "@/api/sdk";
import { useTranslation } from "react-i18next";
import type { I18NEnumsKey } from "../i18n";

/**
 * Enums maps to translation keys.
 * The keys are not parsed by i18next-parser.
 * The name of the locale file is "enums".
 */
type EnumMap<T extends string> = Record<T, I18NEnumsKey>;

const enums = {
  cultivationMethod: {
    [CultivationMethod.Manual]: "cultivationMethod.manual",
    [CultivationMethod.Autonomus]: "cultivationMethod.autonomus",
  } satisfies EnumMap<CultivationMethod>,
  soilStatus: {
    [SoilStatus.AddedSoilAmendments]: "soilStatus.addedSoilAmendments",
    [SoilStatus.BrokenUp]: "soilStatus.brokenUp",
    [SoilStatus.EarthedUp]: "soilStatus.earthedUp",
    [SoilStatus.Leveled]: "soilStatus.leveled",
    [SoilStatus.Loosened]: "soilStatus.loosened",
    [SoilStatus.Overgrown]: "soilStatus.overgrown",
    [SoilStatus.Spreaded]: "soilStatus.spreaded",
  } satisfies EnumMap<SoilStatus>,

  fieldIrrigation: {
    [FieldIrrigation.Drip]: "fieldIrrigation.drip",
    [FieldIrrigation.No]: "fieldIrrigation.no",
    [FieldIrrigation.Sprinkler]: "fieldIrrigation.sprinkler",
  } satisfies EnumMap<FieldIrrigation>,

  fieldLighting: {
    [FieldLighting.FShade]: "fieldLighting.fullShade",
    [FieldLighting.FSun]: "fieldLighting.fullSun",
    [FieldLighting.PShade]: "fieldLighting.partialShade",
    [FieldLighting.PSun]: "fieldLighting.partialSun",
  } satisfies EnumMap<FieldLighting>,

  fieldTaskJobWarningMessage: {
    [FieldTaskJobWarningMessage.ExceedingMaxPowerConsumption]:
      "fieldTaskJobWarningMessage.exceedingMaxPowerConsumption",
    [FieldTaskJobWarningMessage.ExceedingMaxWaterConsumption]:
      "fieldTaskJobWarningMessage.exceedingMaxWaterConsumption",
    [FieldTaskJobWarningMessage.FieldTaskTimeOverflow]: "fieldTaskJobWarningMessage.fieldTaskTimeOverflow",
  } satisfies EnumMap<FieldTaskJobWarningMessage>,

  fieldTaskStartOption: {
    [FieldTaskStartOption.AfterLastTask]: "fieldTaskStartOption.afterLastTask",
    [FieldTaskStartOption.AsSoonAsPossible]: "fieldTaskStartOption.asSoonAsPossible",
    [FieldTaskStartOption.ScheduleStart]: "fieldTaskStartOption.scheduleStart",
  } satisfies EnumMap<FieldTaskStartOption>,

  fieldTaskState: {
    [FieldTaskState.Aborted]: "fieldTaskState.aborted",
    [FieldTaskState.Completed]: "fieldTaskState.completed",
    [FieldTaskState.Created]: "fieldTaskState.created",
    [FieldTaskState.Expired]: "fieldTaskState.expired",
    [FieldTaskState.Failed]: "fieldTaskState.failed",
    [FieldTaskState.Invalid]: "fieldTaskState.invalid",
    [FieldTaskState.InProgress]: "fieldTaskState.inProgress",
    [FieldTaskState.PartiallyCompleted]: "fieldTaskState.partiallyCompleted",
    [FieldTaskState.Scheduled]: "fieldTaskState.scheduled",
    [FieldTaskState.WaitingForRouteProcessing]: "fieldTaskState.waitingForRouteProcessing",
  } satisfies EnumMap<FieldTaskState>,

  riRobotBatteryChargingState: {
    [RiRobotBatteryChargingState.Balancing]: "riRobotBatteryChargingState.balancing",
    [RiRobotBatteryChargingState.ChargingError]: "riRobotBatteryChargingState.chargingError",
    [RiRobotBatteryChargingState.ChargingFinished]: "riRobotBatteryChargingState.chargingFinished",
    [RiRobotBatteryChargingState.Disconnect]: "riRobotBatteryChargingState.disconnect",
    [RiRobotBatteryChargingState.MainCharging]: "riRobotBatteryChargingState.mainCharging",
    [RiRobotBatteryChargingState.Precharging]: "riRobotBatteryChargingState.precharging",
    [RiRobotBatteryChargingState.Preheating]: "riRobotBatteryChargingState.preheating",
  } satisfies EnumMap<RiRobotBatteryChargingState>,

  riRobotToolType: {
    [RiRobotToolType.EmptySlot_1]: "riRobotToolType.emptySlot1",
    [RiRobotToolType.EmptySlot_2]: "riRobotToolType.emptySlot2",
    [RiRobotToolType.GardenBoy]: "riRobotToolType.gardenBoy",
    [RiRobotToolType.JetNozzle]: "riRobotToolType.jetNozzle",
    [RiRobotToolType.None]: "riRobotToolType.none",
    [RiRobotToolType.Plough]: "riRobotToolType.plough",
    [RiRobotToolType.Rake]: "riRobotToolType.rake",
    [RiRobotToolType.ShowerHead]: "riRobotToolType.showerHead",
    [RiRobotToolType.Stake]: "riRobotToolType.stake",
  } satisfies EnumMap<RiRobotToolType>,

  riRobotDeviceType: {
    [RiRobotDeviceType.ActiveTool]: "riRobotDeviceType.activeTool",
    [RiRobotDeviceType.LeftMotor]: "riRobotDeviceType.leftMotor",
    [RiRobotDeviceType.Lidar]: "riRobotDeviceType.lidar",
    [RiRobotDeviceType.RightMotor]: "riRobotDeviceType.rightMotor",
    [RiRobotDeviceType.RoboticArm]: "riRobotDeviceType.roboticArm",
    [RiRobotDeviceType.Tracker]: "riRobotDeviceType.tracker",
    [RiRobotDeviceType.TransverseAxis]: "riRobotDeviceType.transverseAxis",
    [RiRobotDeviceType.WaterPump]: "riRobotDeviceType.waterPump",
  } satisfies EnumMap<RiRobotDeviceType>,

  riRobotToolMode: {
    [RiRobotToolMode.Off]: "riRobotToolMode.off",
    [RiRobotToolMode.RunningProgramWithTool]: "riRobotToolMode.runningProgramWithTool",
  } satisfies EnumMap<RiRobotToolMode>,

  growingPlanState: {
    [GrowingPlanState.Archived]: "growingPlanState.archived",
    [GrowingPlanState.Finished]: "growingPlanState.finished",
    [GrowingPlanState.InProgress]: "growingPlanState.inProgress",
    [GrowingPlanState.Scheduled]: "growingPlanState.scheduled",
  } satisfies EnumMap<GrowingPlanState>,

  plantSpacingPattern: {
    [PlantSpacingPattern.Rectangle]: "plantSpacingPattern.rectangle",
    [PlantSpacingPattern.Triangle]: "plantSpacingPattern.triangle",
  },
};

const useTranslationEnums = () => {
  const { t } = useTranslation("enums");

  const tEnums = <Kind extends keyof typeof enums>(kind: Kind, value: keyof (typeof enums)[Kind]) => {
    // check the value because backend can return new value which is not in the enums
    return enums[kind][value] ? t(enums[kind][value] as string) : null;
  };

  return { tEnums };
};

export { useTranslationEnums };
