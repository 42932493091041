import { DiseaseCategory } from "@/api/sdk";
import { PrimitiveInfoList } from "@/components/PrimitiveInfoList";
import type { CropSheetTransformedQuery } from "@/crop-sheet/crop-detail/CropDetailLayout";
import { generatePath, routes } from "@/routes";
import type { ValueOf } from "@roboton/tools";
import { Button } from "@roboton/ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type ThreatsListProps = {
  cropSheetId: string;
  cropThreats: CropSheetTransformedQuery["cropThreats"];
  threatType: DiseaseCategory;
};

const mapTypeToRoute = {
  [DiseaseCategory.Pest]: routes.CropThreatsPests,
  [DiseaseCategory.Disease]: routes.CropThreatsDiseases,
  [DiseaseCategory.Weed]: routes.CropThreatsWeeds,
} satisfies Record<DiseaseCategory, ValueOf<typeof routes>>;

const mapTypeToHeadlineId = {
  [DiseaseCategory.Pest]: "diseases-pests",
  [DiseaseCategory.Disease]: "diseases-diseases",
  [DiseaseCategory.Weed]: "diseases-weeds",
};

export const CropThreatsList = ({ threatType, cropSheetId, cropThreats }: ThreatsListProps) => {
  const { t } = useTranslation();

  const mapTypeToHeadline = useMemo(
    () => ({
      [DiseaseCategory.Pest]: t("Pests"),
      [DiseaseCategory.Disease]: t("Diseases"),
      [DiseaseCategory.Weed]: t("Weeds"),
    }),
    [t],
  );

  const mapTypeToSeeMoreText = useMemo(
    () => ({
      [DiseaseCategory.Pest]: t("See more about pests"),
      [DiseaseCategory.Disease]: t("See more about diseases"),
      [DiseaseCategory.Weed]: t("See more about weeds"),
    }),
    [t],
  );

  if (!cropThreats.length) {
    return null;
  }

  const headlineId = mapTypeToHeadlineId[threatType];
  const headline = mapTypeToHeadline[threatType];
  const seeMoreText = mapTypeToSeeMoreText[threatType];
  const seeMorePath = generatePath(mapTypeToRoute[threatType], { id: cropSheetId });

  return (
    <div>
      <h3 className={"typo-h3"} id={headlineId}>
        {headline}
      </h3>
      <PrimitiveInfoList.Root aria-labelledby={headlineId}>
        {cropThreats.map((cropThread) => {
          const detailPath = generatePath(routes.ThreatDetail, { threatId: cropThread.threat.id });
          return (
            <PrimitiveInfoList.Item
              key={cropThread.threat.id}
              image={cropThread.mainImage}
              itemTitle={cropThread.threat.commonName}
              to={detailPath}
              linkText={t("View details")}
            />
          );
        })}
      </PrimitiveInfoList.Root>

      <Button as={Link} to={seeMorePath}>
        {seeMoreText}
      </Button>
    </div>
  );
};
