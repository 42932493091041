import clsx from "clsx";
import { Icon, type IconType } from "../Icon/Icon";

type MessageProps = JSX.IntrinsicElements["div"] & {
  color: "green" | "blue" | "yellow" | "red" | "gray";
  icon?: IconType;
};

const colorToClassMap: Record<Required<MessageProps>["color"], string> = {
  green: "bg-brand-0 text-brand-100",
  blue: "bg-blue-0 text-blue-100",
  yellow: "bg-gold-0 text-gold-100",
  red: "bg-red-0 text-red-100",
  gray: "bg-light-50 text-dark-100",
};

const colorToIconClassMap: Record<Required<MessageProps>["color"], string> = {
  green: "text-brand-25",
  blue: "text-blue-50",
  yellow: "text-gold-50",
  red: "text-red-50",
  gray: "text-dark-0",
};

export const Message = ({ color, icon, className, children, ...rest }: MessageProps) => {
  return (
    <div
      {...rest}
      className={clsx("flex gap-2.5 rounded-lg", colorToClassMap[color], icon ? "p-2" : "px-4 py-2", className)}
    >
      {icon ? <Icon type={icon} className={clsx("h-6 w-6 shrink-0", colorToIconClassMap[color])} aria-hidden /> : null}
      <p className={"typo-sm"}>{children}</p>
    </div>
  );
};
