import { LoadingContent } from "@/components/LoadingContent";
import clsx from "clsx";
import type { ComponentProps, ReactNode } from "react";
import { Card } from "./Card";

type CardWithActionProps = Omit<ComponentProps<typeof Card>, "as"> & { headline?: string; actionArea?: ReactNode };
const CardWithAction = ({ headline, actionArea, className, children, ...rest }: CardWithActionProps) => {
  const hasHeader = !!(headline || actionArea);
  return (
    <Card {...rest} as={"section"} className={clsx("space-y-6", className)}>
      {hasHeader ? (
        <div className={"flex flex-wrap items-center justify-between gap-4"}>
          <h2 className={"typo-h2"}>{headline}</h2>
          {actionArea}
        </div>
      ) : null}

      {children}
    </Card>
  );
};

type LayoutProps = JSX.IntrinsicElements["div"] & { mapArea: JSX.Element; mapAreaSize?: "base" | "large" };

const mapSizeToClass = {
  base: "lg:grid-cols-2",
  large: "lg:grid-cols-2 2xl:grid-cols-[2fr_1fr]",
} satisfies Record<Required<LayoutProps>["mapAreaSize"], string>;

const Layout = ({ mapArea, mapAreaSize = "base", className, children, ...rest }: LayoutProps) => {
  return (
    <>
      <div
        {...rest}
        className={clsx(
          "flex grow flex-col gap-6 lg:grid lg:items-stretch",
          mapSizeToClass[mapAreaSize],
          // required to make children elements scrollable if they overflow vertically
          "lg:h-0",
          className,
        )}
      >
        <div className={"grid min-h-[50vh]"}>{mapArea}</div>
        <div className={"flex flex-col gap-y-6 overflow-auto scrollbar-gutter-stable"}>{children}</div>
      </div>
    </>
  );
};

type MapAreaProps = JSX.IntrinsicElements["div"] & { isLoading?: boolean };
const MapArea = ({ className, children, isLoading, ...rest }: MapAreaProps) => {
  return (
    <div
      {...rest}
      className={clsx("bg-light-50 relative flex grow flex-col items-center justify-center gap-2", className)}
    >
      {isLoading ? <LoadingContent className={"inset-0"} /> : children}
    </div>
  );
};

export const Bricks = { Layout, MapArea, Card: CardWithAction };
