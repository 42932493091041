import { routes } from "@/routes";
import { Button } from "@roboton/ui";
import { useUserInfoForAuthPageQuery } from "api/sdk";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuthStore } from "./store";
import { getCognitoLoginUrl } from "./utils";

const loginUrl = getCognitoLoginUrl();

export const AuthPage = () => {
  const { t } = useTranslation("public");
  const { isLogged, onLogout } = useAuthStore();

  const {
    data: { myProfile } = {},
    isError,
    fetchStatus,
    status,
  } = useUserInfoForAuthPageQuery(undefined, {
    retry: false,
    enabled: isLogged,
  });

  const isLoading = fetchStatus === "fetching" && status === "pending";

  const hasClientAccount = !!myProfile?.clientAccounts?.length;
  const dashboardRoute = hasClientAccount ? routes.Dashboard : routes.CreateClientAccount;

  return (
    <main className="flex grow flex-col items-center justify-center">
      {isLoading
        ? t("Loading client account...")
        : isError
          ? t("Something went wrong.")
          : isLogged
            ? t("You're logged.")
            : t("Please login first.")}

      <div className={"w-64"}>
        <p>{t("First Name: {{ firstName }}", { firstName: myProfile?.firstName ?? "-" })}</p>
        <p>{t("Last Name: {{ lastName }}", { lastName: myProfile?.lastName ?? "-" })}</p>
        <p>{t("E-mail: {{ email }}", { email: myProfile?.email ?? "-" })}</p>
      </div>

      <div className={"mt-10 flex flex-col gap-2"}>
        <Button as={"a"} href={loginUrl} disabled={isLogged}>
          {t("Login")}
        </Button>
        <Button as={Link} to={routes.Register} variant={"primary-positive"}>
          {t("Create an Account")}
        </Button>

        <hr className={"mt-10 border-none"} aria-hidden />

        <Button as={Link} to={dashboardRoute} disabled={!isLogged}>
          {t("Go to the Dashboard")}
        </Button>

        <Button type={"button"} variant={"primary-negative"} disabled={!isLogged} onClick={onLogout}>
          {t("Logout")}
        </Button>
      </div>
    </main>
  );
};
