import { useThreatsQuery } from "@/api/sdk";
import { LoadingContent } from "@/components/LoadingContent";
import { generatePath, routes } from "@/routes";
import { resolveImage } from "@/utils/image";
import { useAppTitle } from "@/utils/useAppTitle";
import { useTranslation } from "react-i18next";
import { CropSheetCard } from "./components/CropSheetCard";

export const ThreatsOverviewPage = () => {
  const { t } = useTranslation();
  useAppTitle(t("Diseases Overview"));

  const {
    data: { threats } = {},
    isLoading,
  } = useThreatsQuery(undefined, {
    select: (data) => ({
      ...data,
      threats: data.threats.map((threat) => ({ ...threat, mainImage: resolveImage(threat.mainImage) })),
    }),
  });

  if (isLoading) {
    return <LoadingContent className={"h-full"} />;
  }

  return (
    <>
      <div>
        <h2 className={"sr-only"}>{t("List of Crops")}</h2>

        <div className={"flex flex-col gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"}>
          {threats?.map((threat) => {
            return (
              <CropSheetCard
                key={threat.id}
                image={threat.mainImage}
                headline={threat.commonName}
                detailTo={generatePath(routes.ThreatDetail, { threatId: threat.id })}
              >
                {threat.alternativeNames?.length ? (
                  <p>{t("Also {{ names }}", { names: threat.alternativeNames.join(", ") })}</p>
                ) : null}
              </CropSheetCard>
            );
          })}
        </div>
      </div>
    </>
  );
};
