import type { RobotQuery } from "@/api/sdk";
import { useRobotQuery } from "@/api/sdk";
import { formatDate } from "@/utils/format";
import { useTranslation } from "react-i18next";

function transform({ robotAssignment }: RobotQuery, options: { language: string }) {
  const position = robotAssignment.robot.stateReport?.position;
  const target = robotAssignment.robot.stateReport?.target;

  const { registeredAt, createdAt } = robotAssignment;

  return {
    robotAssignment: {
      ...robotAssignment,
      robot: {
        ...robotAssignment.robot,

        position:
          typeof position?.lat === "number" && typeof position?.lon === "number"
            ? {
                lat: position.lat,
                lon: position.lon,
              }
            : undefined,
        target:
          typeof target?.lat === "number" && typeof target?.lon === "number"
            ? {
                lat: target.lat,
                lon: target.lon,
              }
            : undefined,
      },
      createdAtFormatted: formatDate(createdAt, options.language),
      registeredAtFormatted: registeredAt ? formatDate(registeredAt, options.language) : undefined,
    },
  };
}

type QueryParams = Parameters<typeof useRobotQuery>;
type Variables = QueryParams[0];
type Options = QueryParams[1] & { select?: never };

export const useRobotTransformedQuery = (variables: Variables, options?: Options) => {
  const {
    i18n: { language },
  } = useTranslation();

  return useRobotQuery(variables, {
    ...options,
    select: (data) => transform(data, { language }),
  });
};

export type RobotTransformedQuery = ReturnType<typeof transform>;
