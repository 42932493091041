import clsx from "clsx";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { computePercentageOffset } from "./utils";

type MultipleMeterProps = Omit<JSX.IntrinsicElements["div"], "children"> & {
  label?: ReactNode;
  steps: number[];
  unit?: string;
  limitValues: { min: number; max: number };
  criticalValues: { min: number; max: number };
};

export const MultipleMeter = ({
  label,
  unit,
  steps,
  limitValues,
  criticalValues,
  className,
  ...rest
}: MultipleMeterProps) => {
  const { t } = useTranslation();
  if (steps.length < 2) {
    throw new Error("Steps must be an array of at least 2 numbers.");
  }

  const range = { min: steps[0], max: steps[steps.length - 1] };
  const limitOffsets = computePercentageOffset(range, limitValues);
  const criticalOffsets = computePercentageOffset(range, criticalValues);

  return (
    <div
      {...rest}
      className={clsx(
        "pb-3", // padding bottom to make room for the steps labels
        className,
      )}
    >
      {label ? (
        <div className={"pb-1 font-bold"}>
          {label}
          <span aria-hidden>{unit ? ` (${unit})` : null}</span>
        </div>
      ) : null}

      <div className={"bg-light-50 relative h-5"}>
        {/* a11y */}
        <div className={"sr-only"}>
          {`${t("Range")}: ${range.min} - ${range.max}`}
          {unit ? `${t("Unit")}: ${unit}` : null}
          {`${t("Limit")}: ${limitValues.min} - ${limitValues.max}`}
          {`${t("Critical")}: ${criticalValues.min} - ${criticalValues.max}`}
        </div>

        {/* limit bar */}
        <div
          className={"bg-gold-25 absolute bottom-0 top-0"}
          style={{ left: `${limitOffsets.left}%`, right: `${limitOffsets.right}%` }}
          aria-hidden
        />
        {/* critical bar */}
        <div
          className={"bg-brand-25 absolute bottom-0 top-0"}
          style={{ left: `${criticalOffsets.left}%`, right: `${criticalOffsets.right}%` }}
          aria-hidden
        />

        {/* steps labels */}
        {steps.map((step, index, array) => {
          const totalLength = array.length - 1;
          const leftOffset = index * (100 / totalLength || 1);

          const isFirst = index === 0;
          const isLast = index === totalLength;

          const left = isFirst ? 0 : isLast ? "auto" : `${leftOffset}%`;
          const right = isLast ? 0 : "auto";

          return (
            <span
              key={step}
              className={clsx(
                "bg-brand-100 b-0 absolute bottom-0 top-0 flex w-px",
                isFirst ? "justify-start" : isLast ? "justify-end" : "justify-center",
                "before:relative before:top-full before:text-sm before:content-[attr(data-label)]",
              )}
              style={{ left, right }}
              data-label={step}
              aria-hidden
            />
          );
        })}
      </div>
    </div>
  );
};
