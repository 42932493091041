import { DashboardContent } from "@/components/DashboardContent";
import { LoadingContent } from "@/components/LoadingContent";
import { ImportedBoundariesPageContent } from "@/field/boundaries/contents/ImportedBoundariesPageContent";
import { ManualBoundariesPageContent } from "@/field/boundaries/contents/ManualBoundariesPageContent";
import { SelectEntryPointPageContent } from "@/field/boundaries/contents/SelectEntryPointPageContent";
import { useFieldBasicTransformedQuery } from "@/field/useFieldBasicTransformedQuery";
import { useAppTitle } from "@/utils/useAppTitle";
import { useTranslation } from "react-i18next";

export const ManageBoundariesPage = ({ fieldId }: { fieldId: string }) => {
  const {
    data: { field } = {},
    isLoading,
    dataUpdatedAt,
  } = useFieldBasicTransformedQuery({ fieldId }, { refetchOnWindowFocus: false });
  const { t } = useTranslation();

  const isEditMode = field?.hasBoundaries;
  const headline = isEditMode ? t("Edit Boundaries") : t("Add Boundaries");
  useAppTitle(t(headline));

  if (isLoading) {
    return <LoadingContent />;
  }

  if (!field) {
    return <DashboardContent>{t("No Field  data")}</DashboardContent>;
  }

  const { isCultivatedByRobot, hasBoundaries, canBoundariesBeEdited } = field;

  // manual cultivation
  if (!isCultivatedByRobot) {
    return isEditMode && !canBoundariesBeEdited ? (
      <DashboardContent>{t("Action is not allowed")}</DashboardContent>
    ) : (
      <ManualBoundariesPageContent key={dataUpdatedAt} field={field} headline={headline} isEditMode={isEditMode} />
    );
  }

  // robot cultivation
  if (isCultivatedByRobot) {
    return hasBoundaries ? (
      <SelectEntryPointPageContent key={dataUpdatedAt} field={field} headline={headline} isEditMode={isEditMode} />
    ) : (
      <ImportedBoundariesPageContent key={dataUpdatedAt} field={field} headline={headline} />
    );
  }

  return null;
};
