import { Button, FormField, Input } from "@roboton/ui";
import type { FormEventHandler } from "react";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormInputs = {
  maximumLength: number;
  gap: number;
};

type SegmentSplittingFormProps = Omit<JSX.IntrinsicElements["form"], "onSubmit" | "onReset"> & {
  defaultValues?: FormInputs;
  onSubmitValid: SubmitHandler<FormInputs>;
  onSubmitInvalid?: SubmitErrorHandler<FormInputs>;
  onReset?: FormEventHandler<HTMLFormElement>;
  disabled?: boolean;
};
export const SegmentsSplittingForm = ({
  defaultValues,
  onSubmitValid,
  onSubmitInvalid,
  onReset,
  disabled,
  ...rest
}: SegmentSplittingFormProps) => {
  const { t } = useTranslation();
  const { formState, handleSubmit, register } = useForm<FormInputs>({ defaultValues });
  const { errors } = formState;

  const msgRequired = t("This field is required");

  return (
    <form {...rest} onSubmit={handleSubmit(onSubmitValid, onSubmitInvalid)} onReset={onReset}>
      <fieldset className={"grid-cols-2 gap-4 md:grid"} onSubmit={handleSubmit(onSubmitValid, onSubmitInvalid)}>
        <FormField messages={[{ key: "error", children: errors.maximumLength?.message, variant: "alert" }]}>
          <Input
            {...register("maximumLength", { valueAsNumber: true, required: msgRequired })}
            type={"number"}
            id={"segment-lenght"}
            label={t("Maximum length of one block (in meters)")}
            state={errors.maximumLength && "negative"}
            min={0}
            step={"any"}
            aria-required
            aria-invalid={!!errors.maximumLength}
          />
        </FormField>
        <FormField messages={[{ key: "error", children: errors.gap?.message, variant: "alert" }]}>
          <Input
            {...register("gap", { valueAsNumber: true, required: msgRequired })}
            type={"number"}
            id={"segment-to"}
            label={t("Gap between a block in a segment (in meters)")}
            state={errors.gap && "negative"}
            min={0}
            step={"any"}
            aria-required
            aria-invalid={!!errors.gap}
          />
        </FormField>
        <Button type={"submit"} variant={"primary-base"} className={"col-start-2 row-start-2"} disabled={disabled}>
          {t("Apply the splitting")}
        </Button>
        <Button type={"reset"} variant={"text-base"} className={"col-start-1 row-start-2"} disabled={disabled}>
          {t("Reset")}
        </Button>
      </fieldset>
    </form>
  );
};
