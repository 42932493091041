import type { ImageType } from "@/api/sdk";
import { Card } from "@/components/Card";
import { useTranslation } from "react-i18next";
import { useCropDetailLayoutContext } from "./CropDetailLayout";

const PLACEHOLDER_IMAGE = {
  id: "placeholder",
  url: "/assets/placeholder-image.png",
  alt: "",
} satisfies ImageType;

export const CropStagesPage = () => {
  const { t } = useTranslation();
  const { cropStages } = useCropDetailLayoutContext();

  // sort stages by order
  const stages = cropStages.stages.sort((a, b) => a.order - b.order);

  return (
    <>
      <h2 className={"sr-only"}>{"Crop Stages"}</h2>
      <div className={"mx-auto max-w-[40rem] space-y-6"}>
        {stages.map((stage, index, array) => {
          const mainImage = stage.images.length ? stage.images[0] : PLACEHOLDER_IMAGE;
          const isBeforePlanStart = !stage.continuous && index === 0;
          const isAfterPlanEnd = !stage.continuous && array.length - 1 === index;

          return (
            <Card key={stage.id} className={"flex flex-col gap-4 lg:flex-row"}>
              <div>
                <img
                  src={mainImage.url}
                  alt={mainImage.alt}
                  width={80}
                  height={80}
                  className={"mx-auto h-auto w-full max-w-[180px] object-contain sm:h-[80px] sm:w-[80px]"}
                />
              </div>
              <div>
                <h3 className={"typo-h3"}>{stage.name}</h3>
                <div className={"text-light-100 space-x-1 text-sm"}>
                  <span>
                    {isBeforePlanStart
                      ? t("Step you need to take before starting the plan")
                      : isAfterPlanEnd
                        ? t("Step you need to take after the end of the plan")
                        : stage.startAtDay
                          ? t("Start at {{ day }}. day", { day: stage.startAtDay })
                          : "-"}
                  </span>

                  <span>
                    {typeof stage.durationDays === "number" ? (
                      <>
                        [{t("Duration")} {t("dayWithCount", { count: stage.durationDays })}]
                      </>
                    ) : null}
                  </span>
                </div>

                <div className={"mt-4 font-bold"}>{t("Description of the Crop Stage")}</div>
                <p>{stage.description}</p>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
};
