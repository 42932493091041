import { Button, Modal } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

type ModalProps = ComponentProps<typeof Modal>;
type ButtonProps = ComponentProps<typeof Button<"button">>;

export const DeleteFieldConfirmationModal = ({
  isOpen,
  headline,
  onClose,
  onConfirm,
}: {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  onConfirm: ButtonProps["onClick"];
  headline: ModalProps["heading"];
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      size={"medium"}
      a11y={{ closeButtonLabel: t("Close"), dialogId: "delete-field-confirmation-modal" }}
      heading={headline}
      variant={"negative"}
      footerChildren={
        <>
          <Button type={"button"} variant={"primary-negative"} onClick={onConfirm}>
            {t("Delete with all")}
          </Button>
          <Button type={"button"} variant={"text-negative"} onClick={onClose}>
            {t("Cancel")}
          </Button>
        </>
      }
      onClose={onClose}
    >
      {t(
        "You're removing the Field. Any linked Blocks, Growing Plans and other items associated with this Field will be removed with it.",
      )}
      <br />
      {t("This action is irreversible, are you sure you want to continue?")}
    </Modal>
  );
};
