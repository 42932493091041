import { ManageWorkingLinesPage } from "@/field/working-lines/ManageWorkingLinesPage";
import type { routes } from "@/routes";
import { type ParamParseKey, useParams } from "react-router-dom";

const ManageWorkingLinesRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.ManageWorkingLines>>();
  if (!id) throw new Error("Missing ID");

  return <ManageWorkingLinesPage fieldId={id} />;
};

export default ManageWorkingLinesRoute;
