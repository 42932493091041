import type { FieldQuery } from "@/api/sdk";
import { useFieldQuery } from "@/api/sdk";
import { FieldModel } from "@/field/field.model";

function transform({ field, ...data }: FieldQuery) {
  return {
    ...data,
    field: new FieldModel(field),
  };
}

type QueryParams = Parameters<typeof useFieldQuery>;
type Variables = QueryParams[0];
type Options = QueryParams[1] & { select?: never };

export const useFieldTransformedQuery = (variables: Variables, options?: Options) =>
  useFieldQuery(variables, { ...options, select: transform });

useFieldTransformedQuery.getKey = (variables: Variables) => useFieldQuery.getKey(variables);

export type FieldTransformedQuery = ReturnType<typeof transform>;
