import { ManageBoundariesPage } from "@/field/boundaries/ManageBoundariesPage";
import type { routes } from "@/routes";
import { type ParamParseKey, useParams } from "react-router-dom";

const ManageBoundariesRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.ManageBoundaries>>();
  if (!id) throw new Error("Missing ID");

  return <ManageBoundariesPage fieldId={id} />;
};

export default ManageBoundariesRoute;
