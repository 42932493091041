import { useEffect, useState } from "react";

const STORAGE_APP_PREFIX = "@rbtn-webapp";

const BACK_TO_STATE_KEY = `${STORAGE_APP_PREFIX}/backTo`;

/**
 * This utility is used to store the current path in the session storage.
 * This is used to redirect the user back to the previous page when it's needed.
 *
 * Don't use state from the react-router-dom to store the previous path, because it will be lost when you use redirect in the loader.
 */
export const backToUtils = {
  get: () => sessionStorage.getItem(BACK_TO_STATE_KEY),
  set: () => {
    const path = `${window.location.pathname}${window.location.search}`;
    sessionStorage.setItem(BACK_TO_STATE_KEY, path);
  },
  clear: () => sessionStorage.removeItem(BACK_TO_STATE_KEY),
};

export const useGetBackToPath = () => {
  const [backTo] = useState(() => backToUtils.get());

  useEffect(() => {
    return () => {
      backToUtils.clear();
    };
  }, []);

  return backTo;
};
