import type { GrowingPlanQuery } from "@/api/sdk";
import { useGrowingPlanQuery } from "@/api/sdk";
import { formatDate, roundToDecimal } from "@/utils/format";
import { mapRemoteFloatValueStringValueToNumber } from "@/utils/mapRemoteFloatValueStringValueToNumber";
import { useTranslation } from "react-i18next";

function transform({ growingPlan: plan, ...data }: GrowingPlanQuery, options: { language: string }) {
  return {
    ...data,
    growingPlan: {
      ...plan,
      startAtFormatted: formatDate(plan.startAt, options.language),
      endAtFormatted: formatDate(plan.endAt, options.language),
      createdAtFormatted: formatDate(plan.createdAt, options.language),
      // stages
      stages: plan.stages
        .sort((a, b) => a.order - b.order)
        .map((stage) => ({
          ...stage,
          startAtFormatted: stage.startAt ? formatDate(stage.startAt, options.language) : undefined,
          endAtFormatted: stage.endAt ? formatDate(stage.endAt, options.language) : undefined,
          // stages - conditions
          conditions: stage.conditions.map((condition) => ({
            ...condition,
            thresholdLow: mapRemoteFloatValueStringValueToNumber(condition.thresholdLow),
            thresholdHigh: mapRemoteFloatValueStringValueToNumber(condition.thresholdHigh),
            // stages - conditions - reduceDays
            reduceDays: condition.reduceDays?.map((reduceDay) => {
              return {
                ...reduceDay,
                yieldDecrementPercentageFormatted: `${roundToDecimal(reduceDay.yieldDecrement, 2)} %`,
                dateFormatted: formatDate(reduceDay.date, options.language),
                metricFormatted: roundToDecimal(reduceDay.metric, 2),
              };
            }),
          })),
        })),
      // conditions
      conditions: plan.conditions.map((condition) => ({
        ...condition,
        thresholdLow: mapRemoteFloatValueStringValueToNumber(condition.thresholdLow),
        thresholdHigh: mapRemoteFloatValueStringValueToNumber(condition.thresholdHigh),
      })),
      expectedYieldFormatted: `${roundToDecimal(plan.expectedYield, 2)} kg`,
      expectedYieldPercentageFormatted: `${plan.expectedYieldPercentage} %`,
      // conditionsSummary
      conditionsSummary: plan.conditionsSummary.map((condition) => ({
        ...condition,
        yieldDecrementFormatted: `${roundToDecimal(condition.yieldDecrementPercentage, 2)} kg`,
        yieldDecrementPercentageFormatted: `${condition.yieldDecrementPercentage} %`,
      })),
    },
  };
}

type QueryParams = Parameters<typeof useGrowingPlanQuery>;
type Variables = QueryParams[0];
type Options = QueryParams[1] & { select?: never };

export const useGrowingPlanTransformedQuery = (variables: Variables, options?: Options) => {
  const {
    i18n: { language },
  } = useTranslation();

  return useGrowingPlanQuery(variables, {
    ...options,
    select: (data) => transform(data, { language }),
  });
};

export type GrowingPlanTransformedQuery = ReturnType<typeof transform>;
