import { Pagination as UIPagination } from "@roboton/ui";
import { type ComponentProps, type ComponentPropsWithoutRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

/*
---------------------------------------------------------------------
PAGINATION - UTILITIES
---------------------------------------------------------------------
 */
const PAGE_PARAM = "p";
type PageParamValue = number;

export const usePaginationState = () => {
  const [params, setParams] = useSearchParams();
  const page = Number(params.get(PAGE_PARAM)) || 1;
  const setPage = useCallback(
    (page: PageParamValue) =>
      setParams((prevParams) => {
        prevParams.set(PAGE_PARAM, page.toString());
        return prevParams;
      }),
    [setParams],
  );

  return [page, setPage] as const;
};

const getUrlWithPaginationParam = (pathname: string, page: PageParamValue, searchParams?: URLSearchParams) => {
  const params = new URLSearchParams(searchParams);
  params.set(PAGE_PARAM, page.toString(10));
  return `${pathname}?${params.toString()}`;
};

/*
---------------------------------------------------------------------
PAGINATION
---------------------------------------------------------------------
 */
const { Next, Page, Prev } = UIPagination;

type PaginationProps = Omit<
  ComponentPropsWithoutRef<typeof UIPagination>,
  "renderPrev" | "renderPage" | "renderNext" | "onClick"
> & {
  onClick: (page: PageParamValue) => void;
};

/**
 * UI Pagination component with router integration.
 */
export const Pagination = ({ onClick = () => {}, ...rest }: PaginationProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getAnchorProps = (page: PageParamValue, isActive: boolean) => {
    const path = getUrlWithPaginationParam(pathname, page, searchParams);
    return {
      href: path,
      tabIndex: isActive ? 0 : -1,
      disabled: !isActive,
      "aria-disabled": !isActive,
      onClick: (e) => {
        e.preventDefault();
        navigate(path);
        onClick(page);
      },
    } satisfies ComponentProps<typeof Prev>;
  };

  return (
    <UIPagination
      {...rest}
      renderPrev={(value, isActive) => (
        <Prev {...getAnchorProps(value, isActive)} icon={"arrow_left"} aria-label={t("Visit previous page")} />
      )}
      renderPage={(value, isActive) => (
        <Page
          {...getAnchorProps(value, isActive)}
          value={value}
          isActive={isActive}
          aria-label={t("Visit page {{ pageNumber }}", { pageNumber: value })}
        />
      )}
      renderNext={(value, isActive) => (
        <Next {...getAnchorProps(value, isActive)} aria-label={t("Visit next page")} icon={"arrow_right"} />
      )}
    />
  );
};
