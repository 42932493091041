import type { CropThreatsQuery } from "@/api/sdk";
import { DiseaseCategory, useCropThreatsQuery } from "@/api/sdk";
import { DashboardContent } from "@/components/DashboardContent";
import { generatePath, routes } from "@/routes";
import { useAppTitle } from "@/utils/useAppTitle";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const CropThreatItem = ({ cropThreat }: { cropThreat: CropThreatsQuery["cropThreats"][number] }) => {
  const { t } = useTranslation();

  const images = [cropThreat.mainImage, ...cropThreat.images];
  const { threat } = cropThreat;
  return (
    <article>
      <div className={"flex flex-col gap-4 lg:grid lg:grid-cols-[320px_1fr]"}>
        <div className={"space-y-4"}>
          {images.map((image) => {
            if (!image) return null;
            return (
              <img
                key={image.id}
                src={image.url}
                alt={image.alt}
                className={"mx-auto h-auto w-full max-w-[180px] object-contain sm:max-h-[20rem] lg:max-w-full"}
              />
            );
          })}
        </div>

        {/* basic info */}
        <section className={"flex flex-col gap-6"}>
          <h2 className={"sr-only"}>{t("Basic Info")}</h2>
          <h3 className={"typo-h3"}>{threat.commonName}</h3>

          <ul className={"flex flex-col gap-4 lg:grid lg:grid-cols-2"}>
            <li>
              <div className={"font-bold"}>{t("Alternative Names")}</div>
              <div>{threat.alternativeNames?.length ? threat.alternativeNames.join(", ") : "-"}</div>
            </li>
            <li>
              <div className={"font-bold"}>{t("Scientific Name")}</div>
              <div>{threat.scientificName}</div>
            </li>
          </ul>

          <div>
            <h3 className={"typo-h3"}>{t("Description and ecology")}</h3>
            <div className={"prose"} dangerouslySetInnerHTML={{ __html: threat.description || "-" }} />
          </div>
        </section>
      </div>

      <section>
        <h3 className={"typo-h3"}>{t("How to protect the crop")}</h3>
        <div className={"prose"} dangerouslySetInnerHTML={{ __html: cropThreat.treatment || "-" }} />
      </section>
    </article>
  );
};

function getHeadline(t: TFunction, threatType: DiseaseCategory, cropName: string) {
  if (threatType === DiseaseCategory.Pest) {
    return t("Pests of {{ cropName }}", { cropName });
  }
  if (threatType === DiseaseCategory.Disease) {
    return t("Diseases of {{ cropName }}", { cropName });
  }
  if (threatType === DiseaseCategory.Weed) {
    return t("Weeds of {{ cropName }}", { cropName });
  }
  return "";
}

export const CropThreatsPage = ({ cropId, threatType }: { cropId: string; threatType: DiseaseCategory }) => {
  const { t } = useTranslation();

  const {
    data: { cropThreats, cropSheet } = {},
    isLoading,
  } = useCropThreatsQuery({ cropSheetId: cropId, threatType });

  const cropName = cropSheet?.commonName;
  const headline = cropName ? getHeadline(t, threatType, cropName) : undefined;
  useAppTitle(headline);

  const hasData = cropThreats && cropSheet;
  const cropDetailPath = generatePath(routes.CropDetail, { id: cropId });

  return (
    <DashboardContent headline={headline} isLoading={isLoading} navigateBackTo={cropDetailPath}>
      {hasData ? (
        <div className={"space-y-20"}>
          {cropThreats.map((cropThreat) => {
            return <CropThreatItem key={cropThreat.threat.id} cropThreat={cropThreat} />;
          })}
        </div>
      ) : (
        <>No data</>
      )}
    </DashboardContent>
  );
};
