import type { PC, PolymorphicPropsWithRef, PolymorphicRef } from "@roboton/tools";
import clsx from "clsx";
import { type ElementType, forwardRef } from "react";

/*
---------------------------------------------------------------------
Tabs - Tab
---------------------------------------------------------------------
 */

type TabOwnProps = {
  size: "large" | "small";
  isSelected: boolean;
};

const tabSizeToClassMap: Record<TabOwnProps["size"], string> = {
  large: "px-4 py-2 rounded-[0.875rem]",
  small: "px-3 py-0.5 rounded-2xl",
};

const defaultAs = "button";
const Tab: PC<TabOwnProps, typeof defaultAs> = forwardRef(
  <T extends ElementType = typeof defaultAs>(
    { as, isSelected, size, children, className, ...rest }: PolymorphicPropsWithRef<T, TabOwnProps>,
    ref: PolymorphicRef<T>,
  ) => {
    const As = as || defaultAs;

    return (
      <As
        {...rest}
        ref={ref}
        className={clsx(
          "block",
          tabSizeToClassMap[size],
          isSelected && "bg-light-0 text-brand-25 shadow-sm",
          className,
        )}
      >
        {children}
      </As>
    );
  },
);

Tab.displayName = "TabsTab";

/*
---------------------------------------------------------------------
Tabs
---------------------------------------------------------------------
 */
type RootProps = JSX.IntrinsicElements["div"] & { size: "large" | "small" };

const sizeToClassMap: Record<RootProps["size"], string> = {
  large: "p-1 rounded-[1.125rem] gap-1",
  small: "p-0.5 rounded-[0.875rem] gap-0.5",
};

const List = ({ size, className, children, ...rest }: RootProps) => {
  return (
    <div
      {...rest}
      className={clsx(
        "bg-light-25 flex w-fit max-w-full snap-x snap-proximity overflow-auto",
        sizeToClassMap[size],
        className,
      )}
    >
      {children}
    </div>
  );
};

/*
---------------------------------------------------------------------
Tab
---------------------------------------------------------------------
 */

export const Tabs = { List, Tab };
