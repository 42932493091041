import { GrowingPlanState } from "@/api/sdk";
import type { Badge } from "@roboton/ui";
import type { ComponentProps } from "react";

export const growingPlanStateToBadgeColorMap: Record<GrowingPlanState, ComponentProps<typeof Badge>["color"]> = {
  [GrowingPlanState.Archived]: "gray",
  [GrowingPlanState.Finished]: "green",
  [GrowingPlanState.InProgress]: "blue",
  [GrowingPlanState.Scheduled]: "yellow",
};
