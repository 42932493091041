import type { ComponentProps } from "react";
import { useSearchParams } from "react-router-dom";
import type { DateForm } from "./DateForm";

const SEARCH_PARAM_KEY = "startAt";
const INITIAL_START_AT = new Date().toISOString().split("T")[0];

export const useDateForm = (config?: { initialStartAt?: string }) => {
  const { initialStartAt = INITIAL_START_AT } = config ?? {};

  const [searchParams, setSearch] = useSearchParams({ [SEARCH_PARAM_KEY]: initialStartAt });
  const startAt = searchParams.get(SEARCH_PARAM_KEY) || initialStartAt;

  const handleSubmit: ComponentProps<typeof DateForm>["onSuccessSubmit"] = (data) => {
    setSearch((prev) => {
      const next = new URLSearchParams(prev);
      next.set(SEARCH_PARAM_KEY, data.startAt);
      return next;
    });
  };

  return {
    startAt,
    handleSubmit,
  };
};
