import { RouterTabs } from "@/components/RouterTabs";
import { routes } from "@/routes";
import type { ValueOf } from "@roboton/tools";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const TABS = ["crops", "diseases"] as const;

const mapTabToPath = {
  crops: routes.CropsOverview,
  diseases: routes.ThreatsOverview,
} satisfies Record<(typeof TABS)[number], ValueOf<typeof routes>>;

type CropSheetTabsProps = Omit<ComponentProps<typeof RouterTabs>, "id" | "items" | "size" | "panelClassName">;

export const CropSheetTabs = ({ children }: CropSheetTabsProps) => {
  const { t } = useTranslation();

  const tabItems = useMemo(() => {
    const mapTabToLabel: Record<(typeof TABS)[number], string> = {
      crops: t("Crops Overview"),
      diseases: t("Diseases Overview"),
    };

    return TABS.map((tab) => ({
      id: tab,
      label: t(mapTabToLabel[tab]),
      path: mapTabToPath[tab],
    }));
  }, [t]);

  return (
    <RouterTabs id={"crop-sheet-tabs"} items={tabItems} size={"large"} panelProps={{ className: "mt-4 grow" }}>
      {children}
    </RouterTabs>
  );
};
