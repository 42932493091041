import clsx from "clsx";
import {
  type ComponentPropsWithoutRef,
  Fragment,
  type HTMLAttributes,
  type MouseEvent,
  type ReactNode,
  forwardRef,
  useMemo,
} from "react";
import { Button } from "../Button/Button";
import { PaginationItem } from "./PaginationItem";
import { calculatePages } from "./utils";

/*
---------------------------------------------------------------------
PAGINATION - CONTROL (prev/next element)
---------------------------------------------------------------------
 */
type ControlProps = Omit<ComponentPropsWithoutRef<typeof Button<"a">>, "as" | "size" | "variant">;
const Control = forwardRef<HTMLAnchorElement, ControlProps>(({ className, ...rest }, ref) => (
  <Button
    {...rest}
    as={"a"}
    ref={ref}
    size={"small"}
    variant={"primary-base"}
    className={clsx("cursor-pointer", className)}
  />
));
Control.displayName = "Pagination.Control";

/*
---------------------------------------------------------------------
PAGINATION
---------------------------------------------------------------------
 */
type PaginationProps = Omit<HTMLAttributes<HTMLDivElement>, "children" | "onClick" | "role"> & {
  currentPage: number;
  pageCount: number;
  renderPage?: (page: number, isActive: boolean) => ReactNode;
  renderNext?: (nextPage: number, hasNext: boolean) => ReactNode;
  renderPrev?: (previousPage: number, hasPrevious: boolean) => ReactNode;
  onClick?: (event: MouseEvent<HTMLAnchorElement>, page: number) => void;
};

export const Pagination = ({
  currentPage,
  pageCount,
  renderPage,
  renderNext,
  renderPrev,
  onClick = () => null,
  className,
  ...rest
}: PaginationProps) => {
  const hasPrevious = currentPage > 1;
  const hasNext = currentPage < pageCount;
  const pages = useMemo(() => calculatePages(currentPage, pageCount), [currentPage, pageCount]);

  const previousPage = hasPrevious ? currentPage - 1 : 1;
  const nextPage = hasNext ? currentPage + 1 : pageCount;

  const handleClickPrevious = (e: MouseEvent<HTMLAnchorElement>) => onClick(e, previousPage);
  const handleClickNext = (e: MouseEvent<HTMLAnchorElement>) => onClick(e, nextPage);

  return (
    <div
      {...rest}
      role="navigation"
      className={clsx(className, "flex flex-wrap items-center justify-center gap-1 md:max-w-none md:justify-start")}
    >
      {renderPrev ? (
        renderPrev(previousPage, hasPrevious)
      ) : (
        <Control icon={"arrow_left"} disabled={!hasPrevious} onClick={handleClickPrevious} />
      )}

      {pages.map((item, index) => {
        const key = `${item}_${index}`;
        const isActive = item === currentPage;

        // return "..."
        if (item === "...") return <PaginationItem.More key={key} />;

        // return custom render
        if (renderPage) return <Fragment key={key}>{renderPage(item, isActive)}</Fragment>;

        // return default render
        return <PaginationItem.Value key={key} value={item} isActive={isActive} onClick={(e) => onClick(e, item)} />;
      })}

      {renderNext ? (
        renderNext(nextPage, hasNext)
      ) : (
        <Control icon={"arrow_right"} disabled={!hasNext} onClick={handleClickNext} />
      )}
    </div>
  );
};

Pagination.Page = PaginationItem.Value;
Pagination.Next = Control;
Pagination.Next.defaultProps = { rel: "next" };
Pagination.Prev = Control;
Pagination.Prev.defaultProps = { rel: "prev" };
