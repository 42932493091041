import type { Config } from "tailwindcss";
import gridDynamicColumns from "./libs/tailwindcss-grid-dynamic-columns";
import typography from "./libs/tailwindcss-typography";

export default {
  content: [],
  theme: {
    boxShadow: {
      none: "none",
      sm: "0px 4px 8px -4px rgba(26, 26, 26, 0.25)",
      md: "0px 8px 32px rgba(26, 26, 26, 0.25)",
      lg: "0px 24px 40px rgba(26, 26, 26, 0.15)",
    },
    colors: {
      brand: {
        0: "#d8ecdc",
        25: "#63976c",
        50: "#3a6141",
        75: "#204126",
        100: "#05200a",
      },
      light: {
        0: "#ffffff",
        25: "#f6f6f6",
        50: "#d9d9d9",
        75: "#b0b0b0",
        100: "#909090",
      },
      dark: {
        0: "#707070",
        25: "#565656",
        50: "#393939",
        75: "#1d1d1d",
        100: "#000000",
      },
      blue: {
        0: "#d9e6ff",
        25: "#6295e3",
        50: "#3152c5",
        75: "#2a3a91",
        100: "#142b64",
      },
      gold: {
        0: "#ffe9b0",
        25: "#eec459",
        50: "#dc9e00",
        75: "#8f6701",
        100: "#422f00",
      },
      red: {
        0: "#ffdddd",
        25: "#ee6475",
        50: "#dc0040",
        75: "#a70131",
        100: "#700020",
      },
    },
    zIndex: {
      navigation: "100",
      dropdown: "200",
      modal: "300",
      tooltip: "400",
      overlay: "500",
      auto: "auto",
    },
    extend: {
      borderRadius: {
        inherit: "inherit",
      },
    },
  },
  plugins: [typography, gridDynamicColumns],
} satisfies Config;
