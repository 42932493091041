import type { ClientAccountCardFragment } from "@/api/sdk";
import { Card } from "@/components/Card";
import { Spacer } from "@/components/Spacer";
import { formatDate } from "@/utils/format";
import clsx from "clsx";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

type ClientAccountCardProps = Omit<ComponentProps<typeof Card>, "variant"> & {
  account: ClientAccountCardFragment;
};

export const ClientAccountCard = ({ account, children, className, ...rest }: ClientAccountCardProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Card {...rest} variant={"flat"} className={clsx("flex flex-col gap-4", className)}>
      <h3 className={"typo-h3"}>{account.businessName}</h3>

      <div className={"typo-md text-light-100"}>
        <p>{account.address}</p>
        <p>
          {account.postalCode} {account.city}
        </p>
      </div>

      <Spacer />

      <div className={"typo-md text-light-100"}>
        <p>
          {t("Created at")}: {formatDate(account.createdAt, language)}
        </p>
        <p className={"typo-md text-light-100"}>
          {account.approvedAt ? (
            <>
              {t("Approved at")}: {formatDate(account.approvedAt, language)}
            </>
          ) : (
            t("Account is not approved yet")
          )}
        </p>
      </div>

      {children}
    </Card>
  );
};
