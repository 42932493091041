import { useRobotTasksQuery } from "@/api/sdk";
import { DateForm } from "@/components/DateForm/DateForm";
import { useDateForm } from "@/components/DateForm/useDateForm";
import { LoadingContent } from "@/components/LoadingContent";
import { Pagination, usePaginationState } from "@/components/Pagination";
import { PlaceholderCard } from "@/components/PlaceholderCard";
import { FieldTasksList } from "@/field-task/components/FieldTasksList";
import { useFieldTaskTypesTransformedQuery } from "@/field-task/useFieldTaskTypesTransformedQuery";
import { GrowingPlansSelect } from "@/robot/components/GrowingPlansSelect/GrowingPlansSelect";
import { useGrowingPlansSelect } from "@/robot/components/GrowingPlansSelect/useGrowingPlansSelect";
import { useQueryRefetch } from "@/utils/useQueryRefetch";
import { useTranslation } from "react-i18next";
import { useRobotDetailLayoutContext } from "./RobotDetailLayout";

export const RobotDetailTasksPage = () => {
  const { robotId } = useRobotDetailLayoutContext();
  const { t } = useTranslation();

  const { handleSubmit, startAt } = useDateForm();
  const [page, setPage] = usePaginationState();

  const {
    data: { fieldTaskTypes } = {},
    isLoading: isLoadingFieldTaskTypes,
  } = useFieldTaskTypesTransformedQuery({
    availableJustForBlock: false,
  });
  const { isLoading: isLoadingAllGrowingPlans, ...growingPlansSelectProps } = useGrowingPlansSelect({ robotId });

  const {
    data: { tasks } = {},
    isLoading: isLoadingTasks,
    refetch,
  } = useRobotTasksQuery({
    robotAssignmentId: robotId,
    paginationInput: { page, perPage: 12 },
    growingPlanId: growingPlansSelectProps.value || undefined,
    startAt,
  });

  const { isRefetching, handleRefetch } = useQueryRefetch(refetch);

  const isLoading = isLoadingTasks || isLoadingFieldTaskTypes || isLoadingAllGrowingPlans;

  const hasData = !!tasks?.nodes.length && !!fieldTaskTypes?.length;

  return (
    <>
      <h2 className={"sr-only"}>{t("Robot Tasks")}</h2>

      <div className={"flex flex-col flex-wrap items-center gap-4 sm:flex-row sm:items-start sm:justify-between"}>
        <DateForm label={t("Showing from")} defaultValue={startAt} onSuccessSubmit={handleSubmit} />

        <GrowingPlansSelect {...growingPlansSelectProps} disabled={isLoadingAllGrowingPlans} />
      </div>

      {isLoading ? (
        <LoadingContent className={"grow"} />
      ) : !hasData ? (
        <PlaceholderCard
          icon={"information"}
          description={t(
            "There are no Tasks created for the robot. You can create a new Task on the Growing Plan page.",
          )}
        />
      ) : (
        <>
          <FieldTasksList
            tasks={tasks}
            fieldTaskTypes={fieldTaskTypes}
            allowExtendedActions
            isPending={isRefetching}
            onDeleteSuccess={handleRefetch}
            onStartSuccess={handleRefetch}
            onMoveUpSuccess={handleRefetch}
            onMoveDownSuccess={handleRefetch}
          />

          <Pagination
            className={"mx-auto mt-4"}
            currentPage={tasks.pageInfo.page}
            pageCount={tasks.pageInfo.lastPage}
            onClick={setPage}
          />
        </>
      )}
    </>
  );
};
