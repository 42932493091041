import { AccessPermissionsProvider } from "@/access-permissions/provider";
import { ApiProvider } from "@/api/provider";
import { AuthProvider } from "@/auth/provider";
import { Router } from "@/router";
import { NotificationsProvider } from "@/utils/Notifications";
import { initI18n } from "./i18n";
import "./globals.css";

// Don't use `import "./i18n"` from the i18next docs.
// The production build will fail because of the missing that file.
initI18n().then();

export function App() {
  return (
    <>
      <ApiProvider>
        <AuthProvider>
          <AccessPermissionsProvider>
            <Router />
          </AccessPermissionsProvider>
        </AuthProvider>
      </ApiProvider>

      <NotificationsProvider />
    </>
  );
}
