import { getAuthToken } from "@/auth/storage";
import { getConfig } from "@/utils/config";
import { GraphQLError } from "./errors";

const { graphqlApiUrl: apiEndpoint } = getConfig();

/**
 * Fetcher function for react-query, referenced in config for codegen.
 */
export function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const authToken = await getAuthToken();
    const res = await fetch(apiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
      },
      mode: "cors",
      body: JSON.stringify({ query, variables }),
    }).catch(() => {
      throw new GraphQLError();
    });

    const json = await res.json();

    if (json.errors) {
      throw new GraphQLError(json.errors[0].message, json.errors[0].extensions?.code, json.errors[0].extensions?.error);
    }

    return json.data;
  };
}
