import { scalarUtils } from "@/utils/scalars";
import { Button, Input } from "@roboton/ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const INPUT_NAME = "growing-plan-start-at";

export const StartAtForm = ({
  initDate,
  disabled,
  onSuccessSubmit,
  onCancel,
}: {
  initDate: string;
  disabled: boolean;
  onSuccessSubmit: (date: string) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);

  const handleSubmit: JSX.IntrinsicElements["form"]["onSubmit"] = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());

    const value = fieldValues[INPUT_NAME];

    if (typeof value !== "string" || !value.trim().length) {
      setIsError(true);
      return;
    }
    onSuccessSubmit(scalarUtils.toDate(value));
  };

  const resetError = () => setIsError(false);

  return (
    <form className={"flex flex-wrap gap-1"} onSubmit={handleSubmit}>
      <Input
        name={INPUT_NAME}
        id={INPUT_NAME}
        type={"date"}
        size={"small"}
        className={"grow"}
        state={isError ? "negative" : "base"}
        defaultValue={initDate}
        disabled={disabled}
        onChange={resetError}
      />
      <Button size={"small"} variant={"primary-negative"} type={"button"} disabled={disabled} onClick={onCancel}>
        {t("Cancel")}
      </Button>
      <Button size={"small"} type="submit" disabled={disabled}>
        {t("Confirm")}
      </Button>
    </form>
  );
};
