import { type RobotsQuery, useRobotsQuery } from "@/api/sdk";
import { Card } from "@/components/Card";
import { DashboardContent } from "@/components/DashboardContent";
import { PlaceholderCard } from "@/components/PlaceholderCard";
import { generatePath, routes } from "@/routes";
import { useAppTitle } from "@/utils/useAppTitle";
import { Button } from "@roboton/ui";
import { keepPreviousData } from "@tanstack/react-query";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type ContentProps = JSX.IntrinsicElements["div"] & {
  robotAssignments: Required<RobotsQuery>["robotAssignments"];
};
const Content = ({ robotAssignments, className, ...rest }: ContentProps) => {
  const { t } = useTranslation();

  return (
    <Card {...rest} as={"section"} className={clsx("space-y-6", className)}>
      <h2 className={"typo-h2"}>
        {t("List of Robots")} ({robotAssignments.length})
      </h2>

      <div className={"grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"}>
        {robotAssignments.map((robotAssignment) => {
          return (
            <Card key={robotAssignment.id} variant={"flat"} className={"flex grow flex-col items-start"}>
              <h3 className={"typo-h3"}>{robotAssignment.name}</h3>
              <span className={"typo-md text-light-100"}>
                {t("Serial Number")}: {robotAssignment.robot.serialNumber}
              </span>
              <Button
                as={Link}
                to={generatePath(routes.RobotDetail, { id: robotAssignment.id })}
                icon={"arrow_right"}
                iconAlign={"right"}
              >
                {t("See more about Robot")}
              </Button>
            </Card>
          );
        })}
      </div>
    </Card>
  );
};

const CreateRobotAction = () => {
  const { t } = useTranslation();
  return (
    <Button as={Link} to={routes.RegisterRobot} icon={"plus"} size={"medium"}>
      {t("Create a new Robot")}
    </Button>
  );
};

export const RobotsPage = () => {
  const { t } = useTranslation();
  useAppTitle(t("Robots"));

  const {
    data: { robotAssignments } = {},
    isLoading,
  } = useRobotsQuery(undefined, {
    placeholderData: keepPreviousData,
  });
  const hasData = !!(robotAssignments && robotAssignments.length > 0);

  return (
    <DashboardContent headline={t("Robots")} isLoading={isLoading} headerArea={hasData ? <CreateRobotAction /> : null}>
      {hasData ? (
        <Content robotAssignments={robotAssignments} />
      ) : (
        <PlaceholderCard icon={"robot"} description={t("There are no Robots.")}>
          <CreateRobotAction />
        </PlaceholderCard>
      )}
    </DashboardContent>
  );
};
