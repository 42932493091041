import { GrowingPlanState, useFinishGrowingPlanMutation } from "@/api/sdk";
import { DashboardContent } from "@/components/DashboardContent";
import { GrowingPlanDetailTabs } from "@/growing-plan/growing-plan-detail/components/GrowingPlanDetailTabs";
import { routes } from "@/routes";
import { useAppTitle } from "@/utils/useAppTitle";
import { Button } from "@roboton/ui";
import { useTranslation } from "react-i18next";
import { Outlet, useOutletContext } from "react-router-dom";
import { type GrowingPlanTransformedQuery, useGrowingPlanTransformedQuery } from "./useGrowingPlanTransformedQuery";

type GrowingPlanDetailLayoutContext = {
  remoteGrowingPlan: GrowingPlanTransformedQuery["growingPlan"];
  onChange: () => void;
};

export const useGrowingPlanDetailLayoutContext = () => {
  return useOutletContext<GrowingPlanDetailLayoutContext>();
};

export const GrowingPlanDetailLayout = ({ growingPlanId }: { growingPlanId: string }) => {
  const { t } = useTranslation();

  const {
    data: { growingPlan: remoteGrowingPlan } = {},
    isLoading,
    refetch,
  } = useGrowingPlanTransformedQuery({
    growingPlanId,
  });

  const headline = remoteGrowingPlan ? remoteGrowingPlan.name : t("Growing Plan Detail");
  useAppTitle(headline);

  const { mutate: finishGrowingPlan } = useFinishGrowingPlanMutation({
    onSuccess: (data) => {
      if (!data.finishGrowingPlan.success) {
        return;
      }
      refetch();
    },
  });

  const shouldManuallyFinishPlan = remoteGrowingPlan?.state === GrowingPlanState.InProgress;

  return (
    <DashboardContent
      headline={headline}
      headerArea={
        <div>
          {shouldManuallyFinishPlan ? (
            <Button
              type={"button"}
              onClick={() => {
                finishGrowingPlan({ growingPlanId });
              }}
            >
              Finish plan manually
            </Button>
          ) : null}
        </div>
      }
      navigateBackTo={routes.GrowingPlans}
      isLoading={isLoading}
    >
      <GrowingPlanDetailTabs growingPlanId={growingPlanId} hasRobot={!!remoteGrowingPlan?.robot?.id}>
        {remoteGrowingPlan ? (
          <Outlet context={{ remoteGrowingPlan, onChange: refetch } satisfies GrowingPlanDetailLayoutContext} />
        ) : null}
      </GrowingPlanDetailTabs>
    </DashboardContent>
  );
};
