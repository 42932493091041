import { useDeleteGrowingPlanMutation } from "@/api/sdk";
import { LoadingContent } from "@/components/LoadingContent";
import { logger } from "@/logger";
import { notify } from "@/utils/Notifications";
import { Button, Modal } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

type ModalProps = ComponentProps<typeof Modal>;

export const DeleteGrowingPlanConfirmationModal = ({
  isOpen,
  onClose,
  data,
  onSuccess,
}: {
  isOpen: ModalProps["isOpen"];
  data: {
    growingPlanId: string;
  };
  onClose: ModalProps["onClose"];
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();

  const { mutate: deleteGrowingPlan, isPending: isGrowingPlanDeleting } = useDeleteGrowingPlanMutation({
    onSuccess: ({ deleteGrowingPlan: { success } }) => {
      if (!success) {
        notify.negative(t("The removal was not successful."));
        logger.error({
          message: t("The Growing Plan removal was not successful."),
          data: { growingPlanId: data.growingPlanId },
        });
        return;
      }

      notify.positive(t("The removal was successful."));
      onSuccess();
    },
  });

  const handleConfirm = () => deleteGrowingPlan({ growingPlanId: data.growingPlanId });
  const handleClose = !isGrowingPlanDeleting ? onClose : () => {};

  return (
    <Modal
      isOpen={isOpen}
      size={"medium"}
      a11y={{ closeButtonLabel: t("Close"), dialogId: "delete-growing-plan-confirmation-modal" }}
      heading={t("You're removing the Growing Plan")}
      variant={"negative"}
      footerChildren={
        <>
          <Button type={"button"} variant={"primary-negative"} onClick={handleConfirm}>
            {t("Delete with all")}
          </Button>
          <Button type={"button"} variant={"text-negative"} onClick={handleClose} disabled={isGrowingPlanDeleting}>
            {t("Cancel")}
          </Button>
        </>
      }
      onClose={handleClose}
    >
      <div className={"relative"}>
        {isGrowingPlanDeleting ? <LoadingContent className={"bg-light-0 absolute inset-0"} /> : null}
        {t("This action is irreversible, are you sure you want to continue?")}
      </div>
    </Modal>
  );
};
