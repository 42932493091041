import { LoadingContent } from "@/components/LoadingContent";
import type { ComponentProps } from "react";
import { useEffect } from "react";
import { CreateGrowingPlanBaseInfoForm } from "./BasicInfoForm";
import { useCreateGrowingPlanBaseInfoData } from "./useCreateGrowingPlanBaseInfoData";

type CreateGrowingPlanBaseInfoContentProps = Pick<
  ComponentProps<typeof CreateGrowingPlanBaseInfoForm>,
  "onSuccessSubmit" | "formId" | "form"
>;

// Fetch data and render form
const CreateGrowingPlanBaseInfoContent = ({ form, ...formComponentProps }: CreateGrowingPlanBaseInfoContentProps) => {
  const { watch, setValue } = form;
  const fieldId = watch("fieldId");

  const { isLoadingData, isBlockOptionsListFetching, options } = useCreateGrowingPlanBaseInfoData({ fieldId });

  const initialCropId = options.crops[0]?.value;
  const initialFieldId = options.fields.find((field) => field.isReady)?.value || "";
  const initialBlockId = options.blocks.find((block) => block.isReady)?.value || "";

  // Set default value for fieldId and cropId
  useEffect(() => {
    setValue("fieldId", initialFieldId, { shouldValidate: true });
    setValue("cropId", initialCropId, { shouldValidate: true });
  }, [initialCropId, initialFieldId, setValue]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: it's a expected behavior
  useEffect(() => {
    setValue("blockId", initialBlockId, { shouldValidate: true });
  }, [fieldId, initialBlockId, setValue]);

  return isLoadingData ? (
    <LoadingContent className={"h-full flex-grow"} />
  ) : (
    <CreateGrowingPlanBaseInfoForm
      options={options}
      isBlockOptionsListFetching={isBlockOptionsListFetching}
      form={form}
      {...formComponentProps}
    />
  );
};

export { CreateGrowingPlanBaseInfoContent };
