import { useAllRobotsGrowingPlansQuery } from "@/api/sdk";
import type { GrowingPlansSelect } from "@/robot/components/GrowingPlansSelect/GrowingPlansSelect";
import type { ComponentProps } from "react";
import { useSearchParams } from "react-router-dom";

type GrowingPlansSelectProps = ComponentProps<typeof GrowingPlansSelect>;

const SEARCH_PARAM_KEY = "growingPlanId";

export const useGrowingPlansSelect = (config: { robotId: string; initialPlanId?: string }) => {
  const { initialPlanId = "", robotId } = config;

  const {
    data: { plans } = {},
    isLoading,
  } = useAllRobotsGrowingPlansQuery(
    { robotAssignmentId: robotId },
    { select: (data) => ({ plans: data.robot?.growingPlans }) },
  );

  const [searchParams, setSearch] = useSearchParams({ [SEARCH_PARAM_KEY]: initialPlanId });
  const growingPlanId = searchParams.get(SEARCH_PARAM_KEY) || initialPlanId;

  const handleChange: GrowingPlansSelectProps["onChange"] = (event) => {
    setSearch((prev) => {
      const next = new URLSearchParams(prev);
      event.target.value ? next.set(SEARCH_PARAM_KEY, event.target.value) : next.delete(SEARCH_PARAM_KEY);
      return next;
    });
  };

  return {
    plans,
    value: growingPlanId,
    onChange: handleChange,
    isLoading,
  } satisfies GrowingPlansSelectProps & { isLoading: boolean };
};
