import { Card } from "@/components/Card";
import { useTranslation } from "react-i18next";
import { useGrowingPlanDetailLayoutContext } from "./GrowingPlanDetailLayout";

export const GrowingPlanDetailTimelinePage = () => {
  const { t } = useTranslation();

  const {
    remoteGrowingPlan: { stages },
  } = useGrowingPlanDetailLayoutContext();

  return (
    <>
      <h2 className={"sr-only"}>{"Timeline"}</h2>
      <div className={"mx-auto max-w-[40rem] space-y-6"}>
        {stages.map((stage, index, array) => {
          const isBeforePlanStart = !stage.continuous && index === 0;
          const isAfterPlanEnd = !stage.continuous && array.length - 1 === index;
          return (
            <Card key={stage.id} className={"flex flex-col"}>
              <h3 className={"typo-h3"}>{stage.name}</h3>

              <div className={"text-light-100 text-sm"}>
                {isBeforePlanStart
                  ? t("Step you need to take before starting the plan")
                  : isAfterPlanEnd
                    ? t("Step you need to take after the end of the plan")
                    : stage.startAtFormatted && stage.endAtFormatted
                      ? t("Since {{ startAt }} to {{ endAt }}.", {
                          startAt: stage.startAtFormatted,
                          endAt: stage.endAtFormatted,
                        })
                      : stage.startAt
                        ? t("Start at {{ date }}.", { date: stage.startAtFormatted })
                        : "-"}
              </div>

              <div className={"mt-4 font-bold"}>{t("Description of the Crop Stage")}</div>
              <p>{stage.description}</p>
            </Card>
          );
        })}
      </div>
    </>
  );
};
