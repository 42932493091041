import { useCreateFieldTaskMutation, useCreateFieldTasksMutation } from "@/api/sdk";
import { logger } from "@/logger";
import { notify } from "@/utils/Notifications";
import { useTranslation } from "react-i18next";

export const useCreateMultitaskMutation = ({ onAfterSuccess }: { onAfterSuccess: () => void }) => {
  const { t } = useTranslation();
  const { mutate } = useCreateFieldTaskMutation({
    onSuccess: ({ createFieldTask: { success, error } }) => {
      if (success) {
        onAfterSuccess();
        notify.positive(t("Multitask has been created successfully"));
        return;
      }

      logger.error(error);
      notify.negative(error?.message || t("An error occurred"));
    },
  });

  return { mutate };
};

export const useCreateTaskMutation = ({ onAfterSuccess }: { onAfterSuccess: () => void }) => {
  const { t } = useTranslation();

  const { mutate } = useCreateFieldTasksMutation({
    onSuccess: ({ createFieldTaskBatch: { success, errors } }) => {
      if (success) {
        onAfterSuccess();
        notify.positive(t("Field tasks have been created successfully"));
        return;
      }

      logger.error(errors);
      notify.negative(errors?.join(", ") || t("An error occurred"));
    },
  });

  return { mutate };
};
