import { Bricks } from "@/components/Bricks";
import { GrowingPlanInfoRow } from "@/components/GrowingPlanInfoRow/GrowingPlanInfoRow";
import { Layer, Source } from "@/components/Map";
import { EntryPoint } from "@/field/boundaries/components/EntryPoint";
import { generatePath, routes } from "@/routes";
import { formatDate, formatNumberToSquareMeters } from "@/utils/format";
import { Button, Toggle } from "@roboton/ui";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FieldMap } from "../components/FieldMap";
import type { FieldBasicModel } from "../field.model";
import type { BlockModel } from "./block.model";

type BlockDetailPageContentProps = {
  field: FieldBasicModel;
  block: BlockModel;
};
export const BlockDetailPageContent = ({ block, field }: BlockDetailPageContentProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { propsForMap: isCultivatedByRobot } = field;
  const { pathPropsForMap } = block;

  const [selectedPointId, setSelectedPointId] = useState(() => pathPropsForMap?.pointsProps[0]?.sourceProps.data.id);
  const [isPathVisible, setIsPathVisible] = useState(true);

  return (
    <Bricks.Layout
      mapArea={
        <Bricks.MapArea key={"general"}>
          <FieldMap field={field}>
            {isCultivatedByRobot && isPathVisible && pathPropsForMap ? (
              <>
                <Source {...pathPropsForMap.lineProps.sourceProps}>
                  <Layer {...pathPropsForMap.lineProps.layerProps} />
                </Source>
              </>
            ) : null}
            {/* force reload segments layers to handle the map's layers order */}
            <Fragment key={`${isPathVisible}`}>
              {block.segmentsForMap.map((segment) => (
                <Source key={segment.id} {...segment.propsForMap.polygonProps.sourceProps}>
                  <Layer
                    {...{
                      ...segment.propsForMap.polygonProps.layerProps,
                      paint: {
                        ...segment.propsForMap.polygonProps.layerProps.paint,
                        // fill the polygon to hide the path going through it
                        "fill-opacity":
                          isPathVisible && pathPropsForMap
                            ? 1
                            : segment.propsForMap.polygonProps.layerProps.paint["fill-opacity"],
                      },
                    }}
                  />
                  <Layer {...segment.propsForMap.lineProps.layerProps} />
                  <Layer {...segment.propsForMap.textProps.layerProps} />
                </Source>
              ))}
            </Fragment>
            {/* Temp solution to let the user see the robot's path */}
            {isPathVisible &&
              pathPropsForMap?.pointsProps.map((pointProps) => {
                const isSelected = selectedPointId === pointProps.sourceProps.data.id;
                return (
                  <EntryPoint.Marker
                    key={pointProps.sourceProps.data.id}
                    point={{
                      id: pointProps.sourceProps.data.id,
                      name: pointProps.textProps.layout["text-field"],
                      latitude: pointProps.sourceProps.data.geometry.coordinates[1],
                      longitude: pointProps.sourceProps.data.geometry.coordinates[0],
                    }}
                    onClick={setSelectedPointId}
                    // Use isCurrent instead of isSelected to highlight the selected point.
                    // The isCurrent UI fits better here.
                    isSelected={isSelected}
                    isCurrent={isSelected}
                  />
                );
              })}
          </FieldMap>
        </Bricks.MapArea>
      }
    >
      <Bricks.Card headline={t("General Info")}>
        <div className={"flex flex-col gap-4 md:grid md:grid-cols-2"}>
          <div>
            <strong>{t("Block Name")}</strong>: {block.name}
          </div>
          <div>
            <strong>{t("Created")}</strong>: {formatDate(block.createdAt, language)}
          </div>
          <div>
            <strong>{t("Block Area")}</strong>: {formatNumberToSquareMeters(block.area)}
          </div>
        </div>
      </Bricks.Card>

      {pathPropsForMap ? (
        <Toggle
          id={"path-points-toggle"}
          label={"Show robot's path points in the map"}
          checked={isPathVisible}
          onChange={() => setIsPathVisible((p) => !p)}
        />
      ) : null}

      {block.remoteGrowingPlans.length ? (
        <Bricks.Card headline={t("Growing Plans of the Block")}>
          <div className={"flex flex-col gap-6"}>
            {block.remoteGrowingPlans.map((growingPlan) => {
              return (
                <div key={growingPlan.id}>
                  <GrowingPlanInfoRow remoteGrowingPlan={growingPlan} />
                  <Button
                    as={Link}
                    to={generatePath(routes.GrowingPlanDetail, { id: growingPlan.id })}
                    size={"small"}
                    variant={"text-base"}
                    icon={"arrow_right"}
                    iconAlign={"right"}
                  >
                    {t("View Growing Plan")}
                  </Button>
                </div>
              );
            })}
          </div>
        </Bricks.Card>
      ) : null}
    </Bricks.Layout>
  );
};
