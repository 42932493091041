import clsx from "clsx";

type BadgeProps = Omit<JSX.IntrinsicElements["div"], "children"> & {
  count?: number;
  color: "green" | "blue" | "yellow" | "red" | "gray" | "black";
  size?: "large" | "small";
};

const sizeToClassMap: Record<Required<BadgeProps>["size"], string> = {
  large: "min-w-[1rem] h-4 border-2 text-[0.6975rem]",
  small: "min-w-[0.5rem] h-2 border text-[0.4375rem]",
};

const colorToClassMap: Record<Required<BadgeProps>["color"], string> = {
  green: "bg-brand-25",
  blue: "bg-blue-25",
  yellow: "bg-gold-25",
  red: "bg-red-50",
  gray: "bg-light-75",
  black: "bg-dark-50",
};

export const Badge = ({ count, color, size = "large", className, ...rest }: BadgeProps) => {
  const isCountLong = typeof count === "number" && (count > 9 || count < 0);
  return (
    <span
      {...rest}
      className={clsx(
        "border-light-0 text-light-0 box-content inline-flex items-center justify-center rounded-full text-center font-bold leading-none",
        sizeToClassMap[size],
        colorToClassMap[color],
        isCountLong && "px-0.5",
        className,
      )}
    >
      {count}
    </span>
  );
};
