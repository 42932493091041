import { Button, Input } from "@roboton/ui";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormValues = {
  startAt: string;
};

type DateFormProps = {
  label: string;
  defaultValue: FormValues["startAt"];
  disabled?: boolean;
  onSuccessSubmit: SubmitHandler<FormValues>;
};

export const DateForm = ({ label, disabled, defaultValue, onSuccessSubmit }: DateFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, register, formState, reset } = useForm<FormValues>({
    defaultValues: {
      startAt: defaultValue,
    },
    resetOptions: {
      keepValues: true,
    },
  });

  const isSubmitDisabled = !formState.isDirty || disabled;

  return (
    <form
      className={"flex flex-col items-center gap-4 sm:flex-row"}
      onSubmit={handleSubmit((data) => {
        onSuccessSubmit(data);
        reset(data);
      })}
    >
      <label htmlFor={"start-at"} className={"font-bold"}>
        {label}
        <span aria-hidden>:</span>
      </label>
      <Input {...register("startAt", { required: true })} id={"start-at"} type={"date"} />
      <Button icon={"check"} disabled={isSubmitDisabled}>
        {t("Confirm selection")}
      </Button>
    </form>
  );
};
