import { FieldTaskStartOption } from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { LoadingContent } from "@/components/LoadingContent";
import { Layer, MapUtils, Source } from "@/components/Map";
import { StartFieldset, type StartFieldsetData } from "@/field-task/components/create-task/StartFieldset";
import { BlockModel } from "@/field/blocks/block.model";
import { FieldMap } from "@/field/components/FieldMap";
import type { FieldModel } from "@/field/field.model";
import { useFieldTransformedQuery } from "@/field/useFieldTransformedQuery";
import { logger } from "@/logger";
import { groupBy } from "@/utils/groupBy";
import { Button, Message, Modal } from "@roboton/ui";
import clsx from "clsx";
import { type ComponentProps, Fragment, type ReactNode, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFieldTaskTypesQueries } from "./../useFieldTaskTypesTransformedQuery";
import { GroupAndTaskFieldset } from "./create-task/GroupAndTaskFieldset";
import { ParametrizationFieldset, type ParametrizationFieldsetData } from "./create-task/ParametrizationFieldset";

/*
---------------------------------------------------------------------
Modal which fetch data too
---------------------------------------------------------------------
 */
type EntityData = {
  id: string;
  name: string;
  selectedRows?: number[];
  fieldId: string;
  blockId: string;
};

export const CreateNewMultiTaskModal = ({
  isOpen,
  onClose,
  onSuccessSubmit,
  entities,
}: {
  isOpen: boolean;
  onClose: () => void;
  entities: EntityData[];
} & Pick<ComponentProps<typeof Form>, "onSuccessSubmit">) => {
  const { t } = useTranslation();

  if (isOpen && entities.length < 1) {
    logger.error("No entities provided to CreateNewMultiTaskModal");
    return null;
  }

  const fieldId = entities?.[0]?.fieldId;

  if (isOpen && !fieldId) {
    logger.error("No fieldId provided to CreateNewMultiTaskModal");
    return null;
  }

  const blocksIds = entities.map((e) => e.blockId);

  return (
    <Modal
      isOpen={isOpen}
      size={"full"}
      heading={t("Create a new Multitask")}
      variant={"base"}
      a11y={{ closeButtonLabel: t("Close"), dialogId: "create-task-modal" }}
      onClose={onClose}
    >
      <FetchDataComponent entityIds={entities.map((e) => e.id)} fieldId={fieldId || ""}>
        {({ field, taskTypes }) => {
          return (
            <div className={"flex gap-4 w-full overflow-auto max-md:flex-col h-full"}>
              <div className={"min-h-[30rem] md:w-1/2 shrink-0 max-md:hidden"}>
                <FieldMap field={field}>
                  {field.blocks
                    // transform blocks to BlockModel
                    .flatMap((block) => (blocksIds.includes(block.id) ? [new BlockModel(block)] : []))
                    // map each block to a source with layers
                    .map((block) => {
                      return (
                        <Fragment key={block.id}>
                          {block.segmentsForMap.map((segment) => (
                            <Source key={segment.id} {...segment.propsForMap.polygonProps.sourceProps}>
                              <Layer {...segment.propsForMap.polygonProps.layerProps} />
                              <Layer {...segment.propsForMap.lineProps.layerProps} />
                              <Layer {...segment.propsForMap.textProps.layerProps} />
                            </Source>
                          ))}
                        </Fragment>
                      );
                    })}

                  {field.homeStation?.pathCoords ? (
                    <Source {...MapUtils.getLineSourceProps(field.homeStation.pathId, field.homeStation.pathCoords)}>
                      <Layer {...MapUtils.getLineLayerProps(field.homeStation.pathId)} />
                    </Source>
                  ) : null}
                  {field.homeStation?.coords ? (
                    <Source {...MapUtils.getPointSourceProps(field.homeStation.id, field.homeStation?.coords)}>
                      <Layer {...MapUtils.getPointLayerProps(field.homeStation.id)} />
                    </Source>
                  ) : null}
                </FieldMap>
              </div>

              <Form
                taskTypes={taskTypes}
                entities={entities.map((entity) => ({
                  ...entity,
                  blockColor: field.blocks.find((block) => block.id === entity.blockId)?.color,
                }))}
                onSuccessSubmit={onSuccessSubmit}
                className={"min-h-[30rem]"}
              />
            </div>
          );
        }}
      </FetchDataComponent>
    </Modal>
  );
};

type FetchData = {
  taskTypes: ReturnType<typeof useFieldTaskTypesQueries>["data"];
  field: FieldModel;
};

// Fetches data for each entity and passes it to the children.
// It also shows a loading indicator while fetching data.
const FetchDataComponent = ({
  entityIds,
  fieldId,
  children,
}: {
  entityIds: string[];
  fieldId: string;
  children: (data: FetchData) => ReactNode;
}) => {
  const { data: taskTypes, isPending: isPendingFieldTaskTypes } = useFieldTaskTypesQueries(
    entityIds.map((id) => ({ growingPlanId: id })),
  );

  const {
    data: { field } = {},
    isPending: isFieldPending,
  } = useFieldTransformedQuery({ fieldId }, { refetchOnWindowFocus: false });

  const isLoading = isPendingFieldTaskTypes || isFieldPending;
  const hasData = taskTypes && field;

  if (isLoading) {
    return <LoadingContent className={"h-full w-full"} />;
  }

  if (hasData) {
    return <>{children({ taskTypes, field })}</>;
  }

  logger.error("No data provided to FetchData");
  return null;
};

/*
---------------------------------------------------------------------
Form
---------------------------------------------------------------------
 */
type FormData = StartFieldsetData & {
  entities: {
    id: string;
    procedures: {
      group: string;
      taskType: string;
      parametrization: ParametrizationFieldsetData;
    }[];
  }[];
};

type ProcedureValue = FormData["entities"][number]["procedures"][number];

const Form = ({
  taskTypes,
  entities,
  className,
  onSuccessSubmit,
}: {
  taskTypes: FetchData["taskTypes"];
  entities: (EntityData & { blockColor?: string })[];
  className?: string;
  onSuccessSubmit: (data: FormData) => void;
}) => {
  const { t } = useTranslation();
  const mappedDataToForm = useMemo(() => {
    const groupedByGrowingPlan = groupBy(taskTypes, (i) => i.entity.id);
    return entities.map((entity) => {
      const groups = groupedByGrowingPlan[entity.id].map((task) => ({
        value: task.group.code,
        label: task.group.commonName,
        types: task.type.map((t) => ({
          value: t.type,
          label: t.commonName,
          parametrization: t.parametrization,
        })),
      })) satisfies ComponentProps<typeof GroupAndTaskFieldset>["groups"];

      const defaultGroup = groups[0];
      const defaultTaskType = defaultGroup.types[0];
      const defaultParametrization = defaultTaskType.parametrization[0];

      const defaultProcedureValue: ProcedureValue = {
        group: "",
        taskType: "",
        parametrization: {},
      };

      return {
        ...entity,
        groups,
        defaultProcedureValue,
      };
    });
  }, [taskTypes, entities]);

  const [noOperationError, setNoOperationError] = useState(false);
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      startOption: FieldTaskStartOption.AsSoonAsPossible,
      entities: mappedDataToForm.map((entity) => ({
        id: entity.id,
        procedures: [],
      })),
    },
    reValidateMode: "onChange",
  });

  const handleSuccessSubmit = (data: FormData) => {
    const isOperationMissing = !data.entities.some((e) => e.procedures.length > 0);
    if (isOperationMissing) {
      setNoOperationError(true);
      return;
    }
    setNoOperationError(false);
    onSuccessSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleSuccessSubmit)} className={clsx("flex flex-col overflow-auto gap-2", className)}>
      <StartFieldset control={control} />

      <div className={"overflow-auto flex flex-col p-2 min-h-[30rem] h-full"}>
        <div className={"flex flex-col gap-4"}>
          {mappedDataToForm.map((entity, entityIndex) => {
            return (
              <Bricks.Card key={entity.id} className={"bg-gold-50 p-2"}>
                <strong className={"inline-flex items-center gap-2"}>
                  <span
                    className={"inline-block h-4 w-4 shrink-0 rounded-full"}
                    style={{ background: `#${entity.blockColor}` }}
                  />
                  {entity.name}
                </strong>

                {(() => {
                  const { fields, insert, append, remove } = useFieldArray({
                    control,
                    name: `entities.${entityIndex}.procedures`,
                  });

                  return (
                    <div className={"flex flex-col gap-4"}>
                      {fields.map((field, index) => {
                        return (
                          <Fragment key={field.id}>
                            <div className={"p-4 bg-light-25 rounded-md"}>
                              <div className={"flex flex-wrap justify-between items-center"}>
                                <div className={"typo-h5"}>Operation {index + 1}</div>
                                <Button
                                  type={"button"}
                                  variant={"primary-negative"}
                                  icon={"trash"}
                                  size={"small"}
                                  onClick={() => remove(index)}
                                />
                              </div>
                              <Controller
                                control={control}
                                name={`entities.${entityIndex}.procedures.${index}`}
                                defaultValue={entity.defaultProcedureValue}
                                rules={{
                                  validate: {
                                    isFilled: (v) => !!v.group && !!v.taskType,
                                  },
                                }}
                                render={({ field: { value, onChange }, fieldState }) => {
                                  const { error } = fieldState;
                                  const parametrizations = entity.groups
                                    .find((g) => g.value === value.group)
                                    ?.types.find((t) => t.value === value.taskType)?.parametrization;

                                  return (
                                    <>
                                      <GroupAndTaskFieldset
                                        groups={entity.groups}
                                        value={value}
                                        error={!!error}
                                        showEmptyValues
                                        onChange={onChange}
                                      />
                                      {parametrizations?.length ? (
                                        <ParametrizationFieldset
                                          name={`entities.${entityIndex}.procedures.${index}.parametrization`}
                                          control={control}
                                          parametrizations={parametrizations}
                                          presetRows={entity.selectedRows}
                                          keyPrefix={`${entity.id}.${index}`}
                                        />
                                      ) : null}
                                    </>
                                  );
                                }}
                              />
                            </div>
                            {/* create a new operation for entity */}
                            <Button
                              type={"button"}
                              icon={"plus"}
                              size={"small"}
                              variant={"primary-positive"}
                              className={"mx-auto"}
                              onClick={() => insert(index + 1, entity.defaultProcedureValue)}
                            >
                              {t("Add operation")}
                            </Button>
                          </Fragment>
                        );
                      })}
                      {/* create a new operation for entity without any operations */}
                      {fields.length === 0 ? (
                        <Button
                          type={"button"}
                          icon={"plus"}
                          size={"small"}
                          variant={"primary-positive"}
                          className={"mx-auto"}
                          onClick={() => append(entity.defaultProcedureValue)}
                        >
                          {t("Add operation")}
                        </Button>
                      ) : null}
                    </div>
                  );
                })()}
              </Bricks.Card>
            );
          })}
        </div>
      </div>

      {noOperationError ? (
        <Message color={"red"} className={"text-red-500"}>
          {t("At least one operation is required")}
        </Message>
      ) : null}
      <Button type={"submit"} icon={"check"}>
        {t("Create a Field Multitask")}
      </Button>
    </form>
  );
};
