import type { CropSheetQuery } from "@/api/sdk";
import { useCropSheetQuery } from "@/api/sdk";
import { DashboardContent } from "@/components/DashboardContent";
import { routes } from "@/routes";
import { resolveImage } from "@/utils/image";
import { useAppTitle } from "@/utils/useAppTitle";
import { Outlet, useOutletContext } from "react-router-dom";
import { CropDetailTabs } from "./components/CropDetailTabs";

function transform(data: CropSheetQuery) {
  return {
    ...data,
    cropThreats: data.cropThreats.map((cropThreat) => ({
      ...cropThreat,
      mainImage: resolveImage(cropThreat.mainImage),
    })),
    cropSheet: {
      ...data.cropSheet,
      mainImage: resolveImage(data.cropSheet.mainImage),
    },
  };
}
const useCropSheetTransformedQuery = (
  variables: Parameters<typeof useCropSheetQuery>[0],
  options?: Parameters<typeof useCropSheetQuery>[1] & { select?: never },
) => useCropSheetQuery(variables, { ...options, select: transform });
export type CropSheetTransformedQuery = ReturnType<typeof transform>;

type CropDetailLayoutContext = CropSheetTransformedQuery;
export const useCropDetailLayoutContext = () => {
  return useOutletContext<CropDetailLayoutContext>();
};

export const CropDetailLayout = ({ cropId }: { cropId: string }) => {
  const { data, isLoading } = useCropSheetTransformedQuery({ cropSheetId: cropId });
  useAppTitle(data?.cropSheet?.commonName);

  // add placeholder invisible element to prevent flickering during loading
  const headline = data?.cropSheet?.commonName ?? (
    <span className={"opacity-0"} aria-hidden>
      P
    </span>
  );

  const hasData = !!(data?.cropSheet && data?.cropStages);

  return (
    <DashboardContent headline={headline} navigateBackTo={routes.CropSheet} isLoading={isLoading}>
      <CropDetailTabs cropId={cropId}>{hasData ? <Outlet context={data} /> : null}</CropDetailTabs>
    </DashboardContent>
  );
};
