import { useUpdateFieldHomeStationPathMutation } from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { DashboardContent } from "@/components/DashboardContent";
import { GrowingPlanInfoRow } from "@/components/GrowingPlanInfoRow/GrowingPlanInfoRow";
import { Layer, MapUtils, Source } from "@/components/Map";
import { FieldMap } from "@/field/components/FieldMap";
import { generatePath, routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { formatNumberToSquareMeters } from "@/utils/format";
import { useAppTitle } from "@/utils/useAppTitle";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { Button } from "@roboton/ui";
import { useQueryClient } from "@tanstack/react-query";
import type { ComponentProps } from "react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BlockModel, UncategorizedBlock } from "../blocks/block.model";
import type { FieldModel } from "../field.model";
import { RemoveHomeStationConfirmationModal } from "../home-station/components/RemoveHomeStationConfirmationModal";
import { useFieldTransformedQuery } from "./../useFieldTransformedQuery";

type ContentProps = {
  field: FieldModel;
  /** re-mount Map to prevent changing IDs of the layers */
  isFetchingMapData: boolean;
};

export const Content = ({ field, isFetchingMapData }: ContentProps) => {
  const {
    isCultivatedByRobot,
    hasBoundaries,
    hasHomeStation,
    hasSwaths,
    hasBlocks,
    unassignedSegments,
    canManageBlocks,
    canBoundariesBeEdited,
  } = field;

  const blocks = field?.blocks?.map((block) => new BlockModel(block));
  const uncategorizedBlock = new UncategorizedBlock(unassignedSegments);

  const { t } = useTranslation();
  const { tEnums } = useTranslationEnums();
  const queryClient = useQueryClient();

  const editFieldPath = generatePath(routes.EditField, { id: field.id });
  const boundariesPath = generatePath(routes.ManageBoundaries, { id: field.id });
  const segmentsPath = generatePath(routes.ManageSegments, { id: field.id });
  const blocksPath = generatePath(routes.ManageBlocks, { id: field.id });
  const workLinesPath = generatePath(routes.ManageWorkingLines, { id: field.id });
  const homeStationPath = generatePath(routes.ManageHomeStation, { id: field.id });

  const editBoundariesActionText = isCultivatedByRobot ? t("Edit Entry Point & Direction") : t("Edit boundaries");
  const isDisabledEditBoundariesAction = isCultivatedByRobot ? false : !canBoundariesBeEdited;
  const createBoundariesActionText = isCultivatedByRobot ? t("Add Boundaries & Entry Point") : t("Add boundaries");
  const [isRemoveHomeStationModalOpen, setIsRemoveHomeStationModalOpen] = useState(false);

  const handleRemoveHomeStationClick = () => setIsRemoveHomeStationModalOpen(true);
  const handleRemoveHomeStationModalClose = () => setIsRemoveHomeStationModalOpen(false);

  const { mutateAsync: removeHomeStation } = useUpdateFieldHomeStationPathMutation();

  const handleRemoveHomeStationModalConfirm = async () => {
    try {
      await removeHomeStation({ fieldId: field.id });
      notify.positive(t("Home station removed successfully"));
      queryClient.refetchQueries({
        queryKey: useFieldTransformedQuery.getKey({ fieldId: field.id }),
      });
    } catch (e) {
      console.error(e);
    }
    setIsRemoveHomeStationModalOpen(false);
  };

  const blocksActionButtonProps: ComponentProps<typeof Button<typeof Link>> = {
    as: Link,
    to: blocksPath,
    icon: hasBlocks ? "pencil" : "plus",
    variant: hasBlocks ? "primary-positive" : "primary-base",
    children: hasBlocks ? t("Edit Blocks") : t("Create Blocks"),
    disabled: !canManageBlocks,
    size: "small",
  };

  const workLinesActionButtonProps: ComponentProps<typeof Button<typeof Link>> = {
    as: Link,
    to: workLinesPath,
    icon: hasSwaths ? "pencil" : "plus",
    variant: hasSwaths ? "primary-positive" : "primary-base",
    children: hasSwaths ? t("Edit Swaths") : t("Define Swaths"),
    disabled: !hasBoundaries,
    size: "small",
  };

  const homeStationActionButtonProps: ComponentProps<typeof Button> = hasHomeStation
    ? ({
        type: "button",
        onClick: handleRemoveHomeStationClick,
        icon: "trash",
        variant: "primary-negative",
        children: t("Remove the Home Station"),
        disabled: !hasBoundaries,
        size: "small",
      } satisfies ComponentProps<typeof Button<"button">>)
    : ({
        as: Link,
        to: homeStationPath,
        icon: "plus",
        children: t("Add a Home Station"),
        disabled: !hasBoundaries,
        size: "small",
      } satisfies ComponentProps<typeof Button<typeof Link>>);

  return (
    <Bricks.Layout
      mapArea={
        <Bricks.MapArea isLoading={isFetchingMapData}>
          {hasBoundaries ? (
            <FieldMap field={field}>
              {blocks.map((block) => {
                return (
                  <Fragment key={block.id}>
                    {block.segmentsForMap.map((segment) => (
                      <Source key={segment.id} {...segment.propsForMap.polygonProps.sourceProps}>
                        <Layer {...segment.propsForMap.polygonProps.layerProps} />
                        <Layer {...segment.propsForMap.lineProps.layerProps} />
                        <Layer {...segment.propsForMap.textProps.layerProps} />
                      </Source>
                    ))}
                  </Fragment>
                );
              })}

              {uncategorizedBlock.segmentsForMap.map((segment) => {
                return (
                  <Source key={segment.id} {...segment.propsForMap.polygonProps.sourceProps}>
                    <Layer {...segment.propsForMap.polygonProps.layerProps} />
                    <Layer {...segment.propsForMap.lineProps.layerProps} />
                    <Layer {...segment.propsForMap.textProps.layerProps} />
                  </Source>
                );
              })}

              {field.homeStation?.pathCoords ? (
                <Source {...MapUtils.getLineSourceProps(field.homeStation.pathId, field.homeStation.pathCoords)}>
                  <Layer {...MapUtils.getLineLayerProps(field.homeStation.pathId)} />
                </Source>
              ) : null}
              {field.homeStation?.coords ? (
                <Source {...MapUtils.getPointSourceProps(field.homeStation.id, field.homeStation?.coords)}>
                  <Layer {...MapUtils.getPointLayerProps(field.homeStation.id)} />
                </Source>
              ) : null}

              <div className={"absolute right-6 top-6 flex flex-col gap-2"}>
                <Button
                  as={Link}
                  to={boundariesPath}
                  icon={"pencil"}
                  size={"small"}
                  disabled={isDisabledEditBoundariesAction}
                  variant={"primary-base"}
                >
                  {editBoundariesActionText}
                </Button>
              </div>
            </FieldMap>
          ) : (
            <>
              <span>{t("This field has no defined boundaries")}</span>
              <Button as={Link} to={boundariesPath} icon={"plus"}>
                {createBoundariesActionText}
              </Button>
            </>
          )}
        </Bricks.MapArea>
      }
    >
      {isCultivatedByRobot ? (
        <Bricks.Card headline={t("Swaths")} actionArea={<Button {...workLinesActionButtonProps} />}>
          {hasSwaths ? (
            <p className={"flex flex-wrap justify-between"}>
              {typeof field.swathAngle === "number" ? (
                <>
                  <span>{t("Angle of working lines")}</span>
                  <span>{field.swathAngle}°</span>
                </>
              ) : null}
              {field.swathPathSegment ? (
                <>
                  <span>{t("Swath path segment ID")}</span>
                  <span>{field.swathPathSegment.id}</span>
                </>
              ) : null}
            </p>
          ) : (
            <p className={"text-light-100 text-center"}>{t("This field has no defined working lines")}</p>
          )}
        </Bricks.Card>
      ) : null}

      {field.shouldManageSegments ? (
        <Bricks.Card
          headline={t("Segments of the field")}
          actionArea={
            <Button as={Link} to={segmentsPath} icon={"pencil"} size={"small"}>
              {t("Manage Segments")}
            </Button>
          }
        >
          <p className={"text-light-100 text-center"}>{t("This field has no defined segments")}</p>
        </Bricks.Card>
      ) : null}

      <Bricks.Card headline={t("Blocks of the Field")} actionArea={<Button {...blocksActionButtonProps} />}>
        {hasBlocks ? (
          <div className={"space-y-4"}>
            {blocks.map((block) => {
              const blockPath = generatePath(routes.BlockDetail, { id: field.id, blockId: block.id });
              return (
                <div key={block.id}>
                  <div className={"flex flex-col gap-2"}>
                    <div className={"flex items-start"}>
                      <h3 className={"typo-h3 grow"}>
                        <span
                          className={"inline-block h-4 w-4 shrink-0 rounded-full"}
                          style={{ backgroundColor: block.formattedColor }}
                          aria-hidden
                        />{" "}
                        {block.name}
                      </h3>

                      <div aria-label={t("Block Area")} className={"shrink-0"}>
                        [{formatNumberToSquareMeters(block.area)}]
                      </div>
                    </div>

                    {block.remoteGrowingPlans.map((remoteGrowingPlan) => {
                      return <GrowingPlanInfoRow key={remoteGrowingPlan.id} remoteGrowingPlan={remoteGrowingPlan} />;
                    })}

                    <Button
                      type={"button"}
                      as={Link}
                      to={blockPath}
                      icon={"arrow_right"}
                      iconAlign={"right"}
                      size={"small"}
                      variant={"text-base"}
                      className={"ml-auto"}
                    >
                      {t("Go on the Detail")}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p className={"text-light-100 text-center"}>{t("This field has no blocks defined")}</p>
        )}
      </Bricks.Card>

      {isCultivatedByRobot ? (
        <Bricks.Card headline={t("Home Station")} actionArea={<Button {...homeStationActionButtonProps} />}>
          {field.homeStation ? (
            <div className={"space-y-4"}>{field.homeStation.name}</div>
          ) : (
            <p className={"text-light-100 text-center"}>{t("This field has no defined home station.")}</p>
          )}
        </Bricks.Card>
      ) : null}

      <Bricks.Card
        headline={t("About the Field")}
        actionArea={
          <Button
            as={Link}
            to={editFieldPath}
            icon={"pencil"}
            aria-label={t("Edit the Field")}
            size={"small"}
            variant={"primary-positive"}
          >
            {t("Edit")}
          </Button>
        }
      >
        <div className={"flex flex-col gap-4 md:grid md:grid-cols-2"}>
          <div>
            <strong>{t("Name")}</strong>: {field.details.name}
          </div>
          <div>
            <strong>{t("Position")}</strong>: TODO
          </div>
          <div>
            <strong>{t("Area")}</strong>: {formatNumberToSquareMeters(field.details.area)}
          </div>
          <div>
            <strong>{t("Cultivation method")}</strong>: {tEnums("cultivationMethod", field.cultivationMethod)}
          </div>
          <div>
            <strong>{t("Soil status")}</strong>: {tEnums("soilStatus", field.details.soilStatus)}
          </div>
          <div>
            <strong>{t("Soil type")}</strong>: {field.details.soilType}
          </div>
          <div>
            <strong>{t("Soil PH level")}</strong>: {field.details.ph}
          </div>
          <div>
            <strong>{t("Soil nitrogen supply [N]")}</strong>: {field.details.nitrogen}
          </div>
          <div>
            <strong>{t("Soil phosphorus supply [P]")}</strong>: {field.details.phosphorus}
          </div>
          <div>
            <strong>{t("Soil potassium supply [K]")}</strong>: {field.details.potassium}
          </div>
          <div>
            <strong>{t("Irrigation")}</strong>: {tEnums("fieldIrrigation", field.details.irrigation)}
          </div>
          <div>
            <strong>{t("Lighting")}</strong>: {tEnums("fieldLighting", field.details.lighting)}
          </div>
        </div>
      </Bricks.Card>

      <RemoveHomeStationConfirmationModal
        isOpen={isRemoveHomeStationModalOpen}
        onClose={handleRemoveHomeStationModalClose}
        onConfirm={handleRemoveHomeStationModalConfirm}
      />
    </Bricks.Layout>
  );
};

export const FieldDetailPage = ({ fieldId }: { fieldId: string }) => {
  const { t } = useTranslation();

  const {
    data: { field } = {},
    isLoading,
    isFetching,
  } = useFieldTransformedQuery(
    { fieldId },
    {
      refetchOnWindowFocus: false,
    },
  );

  const headline = field ? t("Field {{ name }}", { name: field.details.name }) : t("Field");
  useAppTitle(headline);

  return (
    <DashboardContent headline={headline} isLoading={isLoading} navigateBackTo={routes.Fields}>
      {field ? <Content isFetchingMapData={isFetching} field={field} /> : <>No data</>}
    </DashboardContent>
  );
};
