import { BlockDetailPageContent } from "@/field/blocks/BlockDetailPageContent";
import { FieldBasicModel } from "@/field/field.model";
import { useAppTitle } from "@/utils/useAppTitle";
import { useTranslation } from "react-i18next";
import { useBlockDetailLayoutContext } from "./BlockDetailLayout";
import { BlockModel } from "./block.model";

export const BlockDetailGeneralPage = () => {
  const { t } = useTranslation();
  useAppTitle(t("Block Detail"));

  const { remoteBlock } = useBlockDetailLayoutContext();

  const block = new BlockModel(remoteBlock);
  const field = new FieldBasicModel(remoteBlock.field);

  return <BlockDetailPageContent block={block} field={field} />;
};
