import { ClientAccountState, useAccessPermissionsQuery } from "@/api/sdk";
import { useAuthStore } from "@/auth/store";
import { routes } from "@/routes";
import { type ReactNode, useMemo } from "react";
import { AccessPermissionsContext, type useAccessPermissionsStore } from "./store";

type AccessPermissionsStore = ReturnType<typeof useAccessPermissionsStore>;

export const AccessPermissionsProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { isLogged, isLoading: isAuthLoading } = useAuthStore();

  // fetch user info to check if the token is valid
  const {
    data: { myProfile } = {},
    isLoading: isQueryLoading,
    isFetchedAfterMount,
  } = useAccessPermissionsQuery(
    {},
    {
      retry: false,
      enabled: isLogged,
    },
  );

  // not show any previously cached data if the query is still loading
  const isInitLoading: boolean = isLogged && isQueryLoading && !isFetchedAfterMount;
  const isLoading: boolean = isInitLoading || isAuthLoading;

  const hasActiveClientAccount: boolean = myProfile?.activeClientAccount?.state === ClientAccountState.Approved;

  const allowedRoutes = useMemo(
    () => [
      routes.Home,
      routes.Register,
      ...(isLogged
        ? [
            routes.Dashboard,
            routes.ClientAccounts,
            routes.CreateClientAccount,
            ...(hasActiveClientAccount
              ? [
                  routes.EditClientAccount,

                  routes.Fields,
                  routes.FieldDetail,
                  routes.CreateField,
                  routes.EditField,
                  routes.ManageBoundaries,
                  routes.ManageSegments,
                  routes.ManageBlocks,
                  routes.ManageWorkingLines,
                  routes.ManageHomeStation,
                  routes.BlockDetail,
                  routes.BlockDetailGeneral,
                  routes.BlockDetailTasks,
                  routes.BlockDetailTask,

                  routes.Robots,
                  routes.RegisterRobot,
                  routes.RobotDetail,
                  routes.RobotDetailGeneral,
                  routes.RobotDetailTasks,

                  routes.CropSheet,
                  routes.CropsOverview,
                  routes.ThreatsOverview,

                  routes.CropDetail,
                  routes.AboutCrop,
                  routes.CropStages,
                  routes.CropThreatsPests,
                  routes.CropThreatsDiseases,
                  routes.CropThreatsWeeds,

                  routes.ThreatDetail,

                  routes.UserProfile,

                  routes.GrowingPlans,
                  routes.CreateGrowingPlan,
                  routes.GrowingPlanDetail,
                  routes.GrowingPlanDetailGeneral,
                  routes.GrowingPlanDetailTimeline,
                  routes.GrowingPlanDetailRobotTasks,
                  routes.GrowingPlanDetailRobotTask,
                  routes.GrowingPlanDetailPlants,
                ]
              : []),
          ]
        : []),
    ],
    [isLogged, hasActiveClientAccount],
  ) satisfies AccessPermissionsStore["allowedRoutes"];

  const value: AccessPermissionsStore = {
    isLoading,
    allowedRoutes,
  };

  return <AccessPermissionsContext.Provider value={value}>{children}</AccessPermissionsContext.Provider>;
};
