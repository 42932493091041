import type { Ordering } from "@/api/sdk";
import { Icon } from "@roboton/ui";
import clsx from "clsx";
import { forwardRef } from "react";

type FilterButtonProps = {
  isActive: boolean;
  direction?: Ordering;
  onClick?: JSX.IntrinsicElements["button"]["onClick"];
};

export const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(
  ({ isActive, direction, ...rest }, ref) => {
    return (
      <button
        {...rest}
        ref={ref}
        type="button"
        className={clsx("rounded-sm px-1 text-sm", isActive && "bg-brand-50 text-light-0")}
      >
        <Icon type={"filter"} width={"1rem"} />
      </button>
    );
  },
);
