import { KeyPress } from "@roboton/tools";
import clsx from "clsx";
import { type ComponentProps, Fragment, type MouseEvent, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Icon } from "../Icon/Icon";

/*
---------------------------------------------------------------------
Modal Portal
---------------------------------------------------------------------
 */
type ModalPortalProps = { children?: Parameters<typeof createPortal>[0] };
const ModalPortal = ({ children }: ModalPortalProps) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return isBrowser ? createPortal(children, document.body) : null;
};

/*
---------------------------------------------------------------------
Modal Factory
---------------------------------------------------------------------
 */
type ModalFactoryProps = Pick<ModalPortalProps, "children"> & {
  isOpen: boolean;
  keepMounted?: boolean;
  disablePortal?: boolean;
};
const ModalFactory = ({ isOpen, keepMounted = false, disablePortal = false, children }: ModalFactoryProps) => {
  if (!isOpen && !keepMounted) return null;

  const Wrapper = disablePortal ? Fragment : ModalPortal;

  return <Wrapper>{children}</Wrapper>;
};

/**
 * ---------------------------------------------------------------------
 * Modal Basic
 * contains the basic modal structure with the overlay, close button and content
 * ---------------------------------------------------------------------
 */
type ModalProps = ModalFactoryProps & {
  size: "small" | "medium" | "large" | "full";
  a11y: {
    dialogId: string;
    closeButtonLabel: string;
  };
  heading: string;
  variant: "base" | "informative" | "negative" | "positive";
  footerChildren?: ModalFactoryProps["children"];
  onClose: (event: MouseEvent | KeyboardEvent) => void;
};

const sizeToClassNameMap: Record<Required<ModalProps["size"]>, string> = {
  small: "w-[31.5rem]",
  medium: "w-[38.5rem]",
  large: "w-[56rem]",
  full: "w-full h-full",
};

const variantToClassNameMap: Record<
  Exclude<Required<ModalProps["variant"]>, "base">,
  { iconProps: ComponentProps<typeof Icon> }
> = {
  informative: {
    iconProps: {
      type: "information",
      className: "text-brand-25",
    },
  },
  negative: {
    iconProps: {
      type: "warning",
      className: "text-red-25",
    },
  },
  positive: {
    iconProps: {
      type: "check_circle",
      className: "text-brand-25",
    },
  },
};

const Modal = ({
  heading,
  variant,
  size,
  a11y: { dialogId, closeButtonLabel },
  onClose,
  footerChildren,
  // ModalFactory props
  isOpen,
  children,
  ...rest
}: ModalProps) => {
  // based on the dialogId, required for a11y
  const headingId = `${dialogId}-label`;

  const { iconProps } = variant !== "base" ? variantToClassNameMap[variant] : { iconProps: undefined };

  // handle overflow hidden on body
  useEffect(() => {
    isOpen ? document.body.classList.add("overflow-hidden") : document.body.classList.remove("overflow-hidden");
    return () => document.body.classList.remove("overflow-hidden");
  }, [isOpen]);

  return (
    <ModalFactory {...rest} isOpen={isOpen}>
      {isOpen ? <KeyPress keyCode={"Escape"} onPress={onClose} /> : null}
      <div
        className={clsx(isOpen ? "flex" : "hidden", "z-modal fixed inset-0 flex-col items-center justify-center p-2")}
      >
        {/* backdrop */}
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: TODO: fix lint */}
        <div role={"presentation"} className={"bg-dark-75/90 absolute inset-0"} onClick={onClose} />

        <div
          role={"dialog"}
          id={dialogId}
          aria-modal={"true"}
          aria-labelledby={headingId}
          className={clsx(
            "bg-light-0 relative max-h-full max-w-full rounded-lg px-8 pb-8 pt-12",
            sizeToClassNameMap[size],
          )}
        >
          <div className={"flex flex-col gap-8 overflow-auto max-h-full"}>
            {/* close button */}
            <button
              type={"button"}
              className={"absolute right-0 top-0 shrink-0 p-4"}
              aria-label={closeButtonLabel}
              onClick={onClose}
            >
              <Icon type={"close"} className={"text-light-75 h-4 w-4"} />
            </button>

            {iconProps ? (
              <Icon {...iconProps} className={clsx(iconProps.className, "h-16 w-16 shrink-0")} aria-hidden />
            ) : null}

            <h2 id={headingId} className={"typo-h2 text-center"}>
              {heading}
            </h2>

            {typeof children === "string" ? (
              <p className={"text-light-100 typo-md text-center"}>{children}</p>
            ) : (
              children
            )}

            {footerChildren ? (
              <div className={"flex flex-wrap items-center justify-center gap-8"}>{footerChildren}</div>
            ) : null}
          </div>
        </div>
      </div>
    </ModalFactory>
  );
};

export { ModalFactory, Modal };
