export function validateClientEnv(value: unknown, name: string): string {
  const isValid = typeof value === "string" && value.length;

  if (!isValid) throw new Error(`ENV variable ${name} must be defined.`);

  return value;
}

export function getConfig() {
  return {
    graphqlApiUrl: validateClientEnv(import.meta.env.VITE_GRAPHQL_API_URL, "VITE_GRAPHQL_API_URL"),
    cognitoLoginUrl: validateClientEnv(import.meta.env.VITE_COGNITO_LOGIN_URL, "VITE_COGNITO_LOGIN_URL"),
    cognitoClientId: validateClientEnv(import.meta.env.VITE_COGNITO_CLIENT_ID, "VITE_COGNITO_CLIENT_ID"),
    sentryDns: validateClientEnv(import.meta.env.VITE_SENTRY_DNS, "VITE_SENTRY_DNS"),
  };
}
