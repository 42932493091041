import { GrowingPlanInfoRow } from "@/components/GrowingPlanInfoRow/GrowingPlanInfoRow";
import { unformatColor } from "@/utils/color";
import { formatNumberToSquareMeters } from "@/utils/format";
import { Button, Input } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import type { BlockModel } from "./../block.model";

type BlockItemProps = JSX.IntrinsicElements["div"] & {
  children?: never;
  block: BlockModel;
  onUpdateBlockName: (blockId: string, name: string) => void;
  onUpdateBlockColor: (blockId: string, color: string) => void;
  onRemoveBlock: (block: BlockModel) => void;
  showSegmentsArea?: boolean;
  renderBlockSelect?: () => JSX.Element;
  renderSegmentSelect?: (segment: BlockModel["segments"][number]) => JSX.Element;
};

export const BlockItem = ({
  block,
  onRemoveBlock,
  onUpdateBlockName,
  onUpdateBlockColor,
  showSegmentsArea,
  renderBlockSelect,
  renderSegmentSelect,
  ...rest
}: BlockItemProps) => {
  const { t } = useTranslation();

  const handleRemoveBlockClick = () => {
    onRemoveBlock(block);
  };

  const handleNameChange: ComponentProps<typeof Input>["onChange"] = (event) => {
    onUpdateBlockName(block.id, event.target.value);
  };

  const handleColorChange: ComponentProps<typeof Input>["onChange"] = (event) => {
    onUpdateBlockColor(block.id, unformatColor(event.target.value));
  };

  const removeBlockLabel = t("Remove block {{ name }}", { name: block.name });

  const hasChildren = block.remoteGrowingPlans.length > 0 || block.segments.length > 0;

  return (
    <div {...rest} className={"bg-light-25 rounded-lg p-4"}>
      <div className={"flex items-center gap-2"}>
        {block.isColorable ? (
          <input
            className={"inline-block h-4 w-4 shrink-0 cursor-pointer rounded-full [&::-webkit-color-swatch]:border-0"}
            type={"color"}
            style={{ backgroundColor: block.formattedColor }}
            onChange={handleColorChange}
            value={block.formattedColor}
            title={t("Select color")}
          />
        ) : (
          <span
            className={"inline-block h-4 w-4 shrink-0 rounded-full"}
            style={{ backgroundColor: block.formattedColor }}
            aria-hidden
          />
        )}

        {block.isRenameable ? (
          <Input
            id={block.id}
            size={"small"}
            aria-label={t("Block name")}
            defaultValue={block.name}
            className={"md:w-[7rem]"}
            onChange={handleNameChange}
          />
        ) : (
          <strong>{block.name}</strong>
        )}

        {showSegmentsArea ? <span>{formatNumberToSquareMeters(block.segmentsArea)}</span> : null}

        <div className={"ml-auto flex items-center gap-1"}>
          {renderBlockSelect?.()}

          <Button
            type={"button"}
            size={"small"}
            variant={"primary-negative"}
            aria-label={removeBlockLabel}
            icon={"trash"}
            onClick={handleRemoveBlockClick}
            // let the button be invisible, but still take up space
            aria-hidden={!block.isDeletable}
            className={block.isDeletable ? "" : "invisible"}
          />
        </div>
      </div>

      {hasChildren ? (
        <div className={"bg-light-0 mt-2 flex flex-col gap-1.5 rounded-md p-2"}>
          {block.remoteGrowingPlans.map((growingPlan) => (
            <GrowingPlanInfoRow remoteGrowingPlan={growingPlan} key={growingPlan.id} />
          ))}

          {block.segments.map((segment) => {
            return (
              <div key={segment.id} className={"flex items-center gap-2 md:max-w-[20rem]"}>
                <span>{segment.name}</span>
                <span className={"ml-auto"}>{formatNumberToSquareMeters(segment.area)}</span>
                {renderSegmentSelect?.(segment)}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
