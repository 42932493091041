import { DashboardContent } from "@/components/DashboardContent";
import { useAppTitle } from "@/utils/useAppTitle";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { CropSheetTabs } from "./components/CropSheetTabs";

export const CropSheetLayout = () => {
  const { t } = useTranslation();
  const headline = t("Crop Sheet");
  useAppTitle(headline);

  return (
    <DashboardContent headline={headline}>
      <CropSheetTabs>
        <Outlet />
      </CropSheetTabs>
    </DashboardContent>
  );
};
