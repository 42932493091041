// Takes a string input and generates a hex color code
export function getColorFromString(input: string) {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    const hexValue = value.toString(16);
    if (hexValue.length < 2) {
      color += "0";
    }
    color += hexValue;
  }

  return color;
}

// The `color` means the hex code without the `#` prefix. It's used in the database.
export function formatColor(color: string) {
  return `#${color}`;
}

export function unformatColor(color: string) {
  return color.replace("#", "");
}
