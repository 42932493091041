import mapLibreQl from "maplibre-gl";
import type { ComponentProps } from "react";
import type { ControlPosition } from "react-map-gl/maplibre";
import { FullscreenControl, GeolocateControl, Map as MapGl, NavigationControl } from "react-map-gl/maplibre";
import "maplibre-gl/dist/maplibre-gl.css";

type MapQlProps = ComponentProps<typeof MapGl>;

const MAP_STYLE = "https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json";
const CONTROLS_POSITION: ControlPosition = "bottom-right";

type MapProps = Omit<MapQlProps, "mapStyle" | "mapLib" | "workerCount" | "attributionControl" | "ref"> & {
  enableControls?: boolean;
  mapRef?: MapQlProps["ref"];
};

export const MapComponent = ({ children, enableControls, mapRef, ...maQlProps }: MapProps) => {
  return (
    <MapGl {...maQlProps} mapStyle={MAP_STYLE} mapLib={mapLibreQl} attributionControl ref={mapRef}>
      {children}
      {enableControls ? (
        <>
          <NavigationControl showCompass={false} position={CONTROLS_POSITION} />
          <FullscreenControl position={CONTROLS_POSITION} />
          <GeolocateControl position={CONTROLS_POSITION} />
        </>
      ) : null}
    </MapGl>
  );
};
