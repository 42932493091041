import { type FieldTaskWorkReportPoint, WorkReportState, useFieldTaskQuery } from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { DashboardContent } from "@/components/DashboardContent";
import { Layer, MapUtils, Source } from "@/components/Map";
import type { Coords } from "@/components/Map/types";
import { WarningMessage } from "@/field-task/components/WarningMessage";
import { useFieldTaskTypesTransformedQuery } from "@/field-task/useFieldTaskTypesTransformedQuery";
import { FieldMap } from "@/field/components/FieldMap";
import { FieldBasicModel } from "@/field/field.model";
import { formatDateTime, formatDurationToHumanize } from "@/utils/format";
import { useAppTitle } from "@/utils/useAppTitle";
import clsx from "clsx";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

const useTask = (taskId: string) => {
  const {
    data: { fieldTaskTypes } = {},
    isLoading: isLoadingTypes,
  } = useFieldTaskTypesTransformedQuery({
    availableJustForBlock: false,
  });
  const {
    data: { task } = {},
    isLoading: isLoadingTask,
  } = useFieldTaskQuery(
    {
      fieldTaskId: taskId,
    },
    {
      select: (data) => {
        const { task } = data;
        if (!task) return;

        // TODO: WAP-164 - update to use new field model
        const type = fieldTaskTypes?.find((type) => type.type === task.procedures?.[0].type);

        return {
          task: {
            ...task,
            typeCommonName: type?.commonName,
            groupCommonName: type?.group.commonName,
          },
        };
      },
    },
  );

  const isLoading = isLoadingTask || isLoadingTypes;

  // TODO: WAP-164 - update to use new field model
  const fieldSource = task?.procedures?.[0]?.growingPlan?.field || task?.procedures?.[0]?.block?.field;

  const field = fieldSource ? new FieldBasicModel(fieldSource) : undefined;

  return {
    task,
    field,
    isLoading,
  };
};

const Item = ({ label, className, children, ...rest }: JSX.IntrinsicElements["div"] & { label: ReactNode }) => {
  return (
    <div {...rest} className={clsx("text-sm", className)}>
      <div className={"font-bold"}>{label}</div>
      {children}
    </div>
  );
};

const pointStateToColorMap: Record<WorkReportState, string | undefined> = {
  [WorkReportState.Completed]: undefined,
  [WorkReportState.Failed]: "red",
  [WorkReportState.Pending]: "blue",
};

const MapOfPoints = ({
  points,
  field,
}: {
  points: (FieldTaskWorkReportPoint & { coords: Coords })[];
  field: FieldBasicModel;
}) => {
  const bounds = MapUtils.getLngLatBounds(points.map((p) => p.coords));

  return (
    <FieldMap field={field} bounds={bounds}>
      {points.map((point) => (
        <Source key={point.id} {...MapUtils.getPointSourceProps(point.id, point.coords)}>
          <Layer {...MapUtils.getPointLayerProps(point.id, { radius: 10, color: pointStateToColorMap[point.state] })} />
        </Source>
      ))}
    </FieldMap>
  );
};

const Content = ({
  task,
  field,
}: {
  task: NonNullable<ReturnType<typeof useTask>["task"]>;
  field: FieldBasicModel;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const points = (task.workTaskReports || []).flatMap((item) => {
    return item.__typename === "FieldTaskWorkReportPoint"
      ? // return first coords only, because we don't support multiple coords per point
        [{ ...item, coords: MapUtils.getCoords([item.point])[0] }]
      : [];
  });

  return (
    <Bricks.Layout
      mapArea={
        <Bricks.MapArea>
          {points?.length ? <MapOfPoints points={points} field={field} /> : <div>The Task has no points to show.</div>}
        </Bricks.MapArea>
      }
    >
      <Bricks.Card headline={task.typeCommonName}>
        <div className={"text-light-100"}>{task.groupCommonName}</div>

        <div className={"grid gap-2 sm:grid-cols-2"}>
          <h4 className={"typo-h4 col-span-full"}>{t("Times")}</h4>
          <Item label={t("Planned start")}>{formatDateTime(task.startAt, language)}</Item>
          <Item label={t("Planned completition")}>{formatDateTime(task.completionAt, language)}</Item>

          {task.delay ? (
            <WarningMessage className={"col-span-full"}>
              Delay: <WarningMessage className={"col-span-full"}>{formatDurationToHumanize(task.delay)}</WarningMessage>
            </WarningMessage>
          ) : null}

          <Item label={t("Estimated start")}>{formatDateTime(task.estimatedStartAt, language)}</Item>
          <Item label={t("Estimated completion")}>{formatDateTime(task.estimatedCompletionAt, language)}</Item>
          <Item label={t("Real start")}>{task.startedAt ? formatDateTime(task.startedAt, language) : "-"}</Item>
          <Item label={t("Real completion")}>
            {task.completedAt ? formatDateTime(task.completedAt, language) : "-"}
          </Item>
        </div>

        <div className={"grid gap-2 sm:grid-cols-2"}>
          <h4 className={"typo-h4 col-span-full"}>{t("Consumption")}</h4>

          <Item label={t("Expected battery power consumption")}>
            {task.estimatedPercentagePowerConsumption.toFixed(2) || "-"} %
          </Item>
          <Item label={t("Expected water consumption")}>{task.estimatedWaterConsumption.toFixed(2) || "-"} l</Item>
        </div>

        <div className={"grid gap-2 sm:grid-cols-2"}>
          <h4 className={"typo-h4 col-span-full"}>{t("Success Rate")}</h4>
          <Item label={t("Plant coordinate")}>{task.numberOfWorkTasks}</Item>
          <Item label={t("Reached coordinate")}>{task.numberOfCompletedWorkTasks}</Item>
        </div>
      </Bricks.Card>
    </Bricks.Layout>
  );
};

const loader = ({ backToResolver }: { backToResolver: () => string }) => {
  // The page could be loaded with a different route. We need to redirect back to the source route.
  return {
    backTo: backToResolver(),
  };
};

export const FieldTaskPage = ({ taskId }: { taskId: string }) => {
  const { backTo } = useLoaderData() as ReturnType<typeof loader>;

  const { t } = useTranslation();
  const headline = t("Task");
  useAppTitle(headline);

  const { task, field, isLoading } = useTask(taskId);

  return (
    <DashboardContent headline={headline} navigateBackTo={backTo} isLoading={isLoading}>
      {task && field ? <Content task={task} field={field} /> : null}
    </DashboardContent>
  );
};

FieldTaskPage.getLoader = loader;
