import { EditClientAccountPage } from "@/client-account/EditClientAccountPage";
import type { routes } from "@/routes";
import { type ParamParseKey, useParams } from "react-router-dom";

const CreateClientAccountRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.CreateField>>();
  if (!id) throw new Error("Missing ID");

  return <EditClientAccountPage clientAccountId={id} />;
};

export default CreateClientAccountRoute;
