import { Button, Modal } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

type ModalProps = ComponentProps<typeof Modal>;
type ButtonProps = ComponentProps<typeof Button<"button">>;

export const SelectBoundariesConfirmationModal = ({
  isOpen,
  headline,
  onClose,
  onConfirm,
}: {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  onConfirm: ButtonProps["onClick"];
  headline?: ModalProps["heading"];
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      size={"medium"}
      a11y={{ closeButtonLabel: t("Close"), dialogId: "prune-field-modal" }}
      heading={headline || t("Are you sure?")}
      variant={"negative"}
      footerChildren={
        <>
          <Button type={"button"} variant={"primary-negative"} onClick={onConfirm}>
            {t("I'm sure of my selection")}
          </Button>
          <Button type={"button"} variant={"text-negative"} onClick={onClose}>
            {t("Cancel")}
          </Button>
        </>
      }
      onClose={onClose}
    >
      {t(
        "Make sure to select the correct boundaries for this field. Boundaries for fields cannot be changed in the future.",
      )}
    </Modal>
  );
};
