import { LoadingContent } from "@/components/LoadingContent";
import { useGetBackToPath } from "@/utils/backToUtils";
import { Button, Icon } from "@roboton/ui";
import clsx from "clsx";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type DashboardContentProps = JSX.IntrinsicElements["div"] & {
  headline?: ReactNode;
  headerArea?: ReactNode;
  navigateBackTo?: string;
  isLoading?: boolean;
};

export const DashboardContent = ({
  headline,
  headerArea,
  navigateBackTo,
  isLoading,
  className,
  children,
  ...rest
}: DashboardContentProps) => {
  const { t } = useTranslation();
  const hasHeader = !!(headline || headerArea);

  const backToFromStorage = useGetBackToPath();
  const backTo = backToFromStorage || navigateBackTo;

  return (
    <main {...rest} className={clsx("flex grow flex-col", className)}>
      {hasHeader ? (
        <div className={"flex flex-wrap items-center justify-between gap-4 sm:gap-6"}>
          <Icon type={"layout_show"} className={"text-light-75 h-6 w-6"} />
          {headline ? <h1 className={"typo-h1 grow"}>{headline}</h1> : null}
          {headerArea ? <div>{headerArea}</div> : null}
        </div>
      ) : null}

      {backTo ? (
        <Button className={"self-start"} as={Link} to={backTo} icon={"arrow_left"} variant={"text-base"}>
          {t("Back")}
        </Button>
      ) : null}

      {hasHeader || backTo ? <hr className={"invisible mt-6"} aria-hidden /> : null}

      {isLoading ? (
        <div className={"relative grow"}>
          <LoadingContent className={"absolute inset-0"} />
        </div>
      ) : (
        children
      )}
    </main>
  );
};
