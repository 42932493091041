import { Card } from "@/components/Card";
import { Icon } from "@roboton/ui";
import clsx from "clsx";
import type { ComponentProps, ReactNode } from "react";

type PlaceholderCardProps = ComponentProps<typeof Card> & {
  icon: ComponentProps<typeof Icon>["type"];
  description: ReactNode;
};

export const PlaceholderCard = ({ icon, description, children, className, ...rest }: PlaceholderCardProps) => {
  return (
    <Card {...rest} className={clsx("flex grow flex-col items-center justify-center gap-4 text-center", className)}>
      <Icon type={icon} className={"text-light-100 h-14 w-14"} />
      <p className={"typo-sm text-light-100 max-w-[20rem]"}>{description}</p>
      {children}
    </Card>
  );
};
