import { Card } from "@/components/Card";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";

type CropSheetCardProps = {
  image: {
    url: string;
    alt: string;
  };
  headline: ReactNode;
  detailTo: string;
  children?: ReactNode;
};

export const CropSheetCard = ({ image, detailTo, headline, children }: CropSheetCardProps) => {
  return (
    <Card as={Link} to={detailTo} className={"group relative flex flex-col gap-4 overflow-hidden"}>
      <div className={"flex flex-col"}>
        <div className={"h-[180px] overflow-hidden rounded-md text-center"}>
          <img
            src={image.url}
            alt={image.alt}
            width={180}
            height={180}
            className={"rounded-inherit h-full w-full object-cover transition-transform group-hover:scale-105"}
          />
        </div>

        <h4 className={"typo-h4 mt-2"}>{headline}</h4>

        {children}
      </div>
    </Card>
  );
};
