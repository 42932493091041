import { useRegisterMutation } from "@/api/sdk";
import type { Exact } from "@roboton/tools";
import { Message } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { RegisterForm } from "./RegisterForm";

type RegisterContentProps = {
  onSuccess: () => void;
};

export const RegisterContent = ({ onSuccess }: RegisterContentProps) => {
  const { t } = useTranslation("public");
  const { isError, mutate, data, isPending } = useRegisterMutation({
    onSuccess: ({ register }) => register && onSuccess(),
  });

  const isApiError = isError || data?.register === false;

  type RegisterFormProps = ComponentProps<typeof RegisterForm>;
  const submit: RegisterFormProps["onSubmitValid"] = (formInputs) => {
    // The input is defined for type checking only. The data from the form has same shape as the data
    // for the API call, so we can just pass it directly without mapping.
    const input: Exact<typeof formInputs, Parameters<typeof mutate>[0]["input"]> = formInputs;
    mutate({ input });
  };

  return (
    <>
      <h1 className={"typo-h1 text-center"}>{t("Create a New Profile")}</h1>
      {isApiError && (
        <Message className={"w-full"} color={"red"}>
          {t("Something went wrong. Please try again.")}
        </Message>
      )}

      <section className={"relative"}>
        <RegisterForm onSubmitValid={submit} />
        {isPending ? (
          <div className={"bg-light-0 absolute inset-0 flex items-center justify-center bg-opacity-80"}>
            <span>{t("Loading...")}</span>
          </div>
        ) : null}
      </section>
    </>
  );
};
