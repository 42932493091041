import { ClientAccountState, useClientAccountsQuery, useSwitchClientAccountMutation } from "@/api/sdk";
import { ClientAccountCard } from "@/client-account/components/ClientAccountCard";
import { Card } from "@/components/Card";
import { DashboardContent } from "@/components/DashboardContent";
import { PlaceholderCard } from "@/components/PlaceholderCard";
import { Spacer } from "@/components/Spacer";
import { generatePath, routes } from "@/routes";
import { useAppTitle } from "@/utils/useAppTitle";
import { Badge, Button } from "@roboton/ui";
import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ClientAccountsPage = () => {
  const { t } = useTranslation();
  useAppTitle(t("Client Accounts"));
  const queryClient = useQueryClient();

  const {
    data: { myProfile } = {},
    isLoading,
  } = useClientAccountsQuery(undefined, {
    // prevent flickering during switching accounts
    placeholderData: keepPreviousData,
  });
  const { mutate } = useSwitchClientAccountMutation({
    onSuccess: ({ switchClientAccount: { success } }) => {
      success && queryClient.removeQueries();
    },
  });

  const accounts = myProfile?.clientAccounts || [];
  const activeAccount = myProfile?.activeClientAccount;

  const handleClickSwitchAccount = (clientAccountId: string) => () => {
    mutate({ clientAccountId });
  };

  const activeAccounts = accounts.filter((account) => account.state === ClientAccountState.Approved);
  const pendingAccounts = accounts.filter((account) => account.state === ClientAccountState.Created);

  const hasAccounts = accounts.length > 0;
  const hasActiveAccounts = hasAccounts && activeAccounts.length > 0;
  const hasPendingAccounts = hasAccounts && pendingAccounts.length > 0;

  return (
    <DashboardContent
      isLoading={isLoading}
      headline={t("Client Accounts")}
      headerArea={
        hasAccounts ? (
          <Button as={Link} to={routes.CreateClientAccount} icon={"plus"} size={"medium"}>
            {t("Create a new Account")}
          </Button>
        ) : null
      }
    >
      {hasAccounts ? (
        <Card className={"flex flex-col gap-8"}>
          {/* active accounts */}
          {hasActiveAccounts ? (
            <section>
              <h2 className={"typo-h2 mb-6"}>
                {t("Approved")} ({activeAccounts.length})
              </h2>
              <div className={"grid grow gap-4 lg:grid-cols-2 xl:grid-cols-3"}>
                {activeAccounts.map((account) => {
                  const isActive = account.id === activeAccount?.id;
                  const isActivable = !isActive && account.state === ClientAccountState.Approved;
                  const isUpdatable = account.state === ClientAccountState.Approved;
                  const editPath = generatePath(routes.EditClientAccount, { id: account.id });

                  return (
                    <ClientAccountCard key={account.id} account={account}>
                      <Spacer />

                      <div className={"flex items-center justify-between gap-1"}>
                        {isActive ? (
                          <div className={"text-dark-100 typo-sm--bold flex items-center gap-1"}>
                            <Badge color={"green"} />
                            {t("Current Account")}
                          </div>
                        ) : null}
                        {isActivable ? (
                          <Button
                            onClick={handleClickSwitchAccount(account.id)}
                            variant={"text-base"}
                            type={"button"}
                            size={"small"}
                          >
                            {t("Switch to this account")}
                          </Button>
                        ) : null}
                        {isUpdatable ? (
                          <Button as={Link} to={editPath} variant={"text-base"} size={"small"} icon={"pencil"}>
                            {t("Edit")}
                          </Button>
                        ) : null}
                      </div>
                    </ClientAccountCard>
                  );
                })}
              </div>
            </section>
          ) : null}

          {/* pending accounts  */}
          {hasPendingAccounts ? (
            <section>
              <h2 className={"typo-h2 mb-6"}>
                {t("Approval Pending")} ({pendingAccounts.length})
              </h2>

              <div className={"grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"}>
                {pendingAccounts.map((account) => {
                  return <ClientAccountCard key={account.id} account={account} />;
                })}
              </div>
            </section>
          ) : null}
        </Card>
      ) : (
        <PlaceholderCard
          icon={"users"}
          description={t("Here you can see a list of your approved accounts. Start with creating one.")}
        >
          <Button as={Link} to={routes.CreateClientAccount} icon={"plus"} size={"medium"}>
            {t("Create a new Account")}
          </Button>
        </PlaceholderCard>
      )}
    </DashboardContent>
  );
};
