import { GrowingPlanDetailPlantsPage } from "@/growing-plan/growing-plan-detail/plant-spacing/GrowingPlanDetailPlantsPage";
import type { routes } from "@/routes";
import type { ParamParseKey } from "react-router-dom";
import { useParams } from "react-router-dom";

const GrowingPlanDetailPlantsRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.GrowingPlanDetailPlants>>();
  if (!id) throw new Error("Missing Growing Plan ID");

  return <GrowingPlanDetailPlantsPage growingPlanId={id} />;
};

export default GrowingPlanDetailPlantsRoute;
