import { useRegisterRobotMutation } from "@/api/sdk";
import { Card } from "@/components/Card";
import { DashboardContent } from "@/components/DashboardContent";
import { routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { useAppTitle } from "@/utils/useAppTitle";
import type { Exact } from "@roboton/tools";
import { Button, Input } from "@roboton/ui";
import clsx from "clsx";
import type { ComponentProps } from "react";
import { type SubmitErrorHandler, type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type FormInputs = {
  name: string;
  serialNumber: string;
  secretKey: string;
};

type RegisterRobotFormProps = Omit<JSX.IntrinsicElements["form"], "onSubmit"> & {
  onSubmitValid: SubmitHandler<FormInputs>;
  onSubmitInvalid?: SubmitErrorHandler<FormInputs>;
};

const RegisterRobotForm = ({ onSubmitValid, onSubmitInvalid, className, ...rest }: RegisterRobotFormProps) => {
  const { t } = useTranslation();
  const { formState, handleSubmit, register } = useForm<FormInputs>();
  const { errors } = formState;

  return (
    <form {...rest} onSubmit={handleSubmit(onSubmitValid, onSubmitInvalid)} className={clsx(className, "space-y-6")}>
      <Input
        {...register("name", { minLength: 1, maxLength: 50, required: true })}
        id={"robot-name"}
        label={t("Name of the Robot")}
        state={errors.name && "negative"}
        required
        aria-invalid={!!errors.name}
      />
      <Input
        {...register("serialNumber", { minLength: 1, maxLength: 128, required: true })}
        id={"robot-serial-number"}
        label={t("Robot's Serial Number")}
        state={errors.serialNumber && "negative"}
        required
        aria-invalid={!!errors.serialNumber}
      />
      <Input
        {...register("secretKey", { minLength: 1, maxLength: 128, required: true })}
        id={"robot-secret-key"}
        label={t("Robot's Secret Key")}
        state={errors.secretKey && "negative"}
        required
        aria-invalid={!!errors.secretKey}
      />
      <div className={"text-center"}>
        <Button type={"submit"} size={"large"} icon={"check"}>
          {t("Register")}
        </Button>
      </div>
    </form>
  );
};

export const RegisterRobotPage = () => {
  const { t } = useTranslation();
  const headline = t("Add a new Robot");
  useAppTitle(headline);

  const navigate = useNavigate();

  const { mutate } = useRegisterRobotMutation({
    onSuccess: ({ registerRobot: { success } }) => {
      if (!success) return;
      notify.positive(t("Registration was successful."));
      navigate(routes.Robots);
    },
    onError: ({ message }) => {
      notify.negative(t("Something went wrong: {{message}}", { message }));
    },
  });

  const handleSubmit: ComponentProps<typeof RegisterRobotForm>["onSubmitValid"] = (formInputs) => {
    const input: Exact<typeof formInputs, Parameters<typeof mutate>[0]["robotAssignmentInput"]> = formInputs;
    mutate({ robotAssignmentInput: input });
  };

  return (
    <DashboardContent headline={headline} navigateBackTo={routes.Robots}>
      <Card className={"mx-auto w-[24rem] max-w-full md:max-w-2xl"}>
        <RegisterRobotForm onSubmitValid={handleSubmit} />
      </Card>
    </DashboardContent>
  );
};
