import { type UserQuery, useUserQuery } from "@/api/sdk";
import { DashboardContent } from "@/components/DashboardContent";
import { useAppTitle } from "@/utils/useAppTitle";
import { Icon } from "@roboton/ui";
import { useTranslation } from "react-i18next";

const Content = ({ user }: { user: UserQuery["myProfile"] }) => {
  const { t } = useTranslation();

  return (
    <div className={"space-y-6"}>
      <Icon type={"user_profile_avatar"} className={"text-brand-50 p mx-auto h-32 w-32"} />

      <div className={"flex flex-col gap-4 md:flex-row md:justify-center md:gap-16"}>
        <section className={"space-y-2"}>
          <h2 className={"typo-h2"}>{t("User Details")}</h2>
          <div>
            <strong className={"block"}>{t("Name")}</strong>
            <span>{user.firstName}</span>
          </div>

          <div>
            <strong className={"block"}>{t("Surname")}</strong>
            <span>{user.lastName}</span>
          </div>

          <div>
            <strong className={"block"}>{t("Phone Number")}</strong>
            <span>{user.phone}</span>
          </div>
        </section>

        <section className={"space-y-2"}>
          <h2 className={"typo-h2"}>{t("Login Details")}</h2>
          <div>
            <strong className={"block"}>{t("E-mail")}</strong>
            <span>{user.email}</span>
          </div>
        </section>
      </div>
    </div>
  );
};

export const UserProfilePage = () => {
  const { t } = useTranslation();
  const {
    data: { myProfile } = {},
    isLoading,
  } = useUserQuery();

  const headline = t("My Profile");
  useAppTitle(headline);

  return (
    <DashboardContent headline={headline} isLoading={isLoading}>
      {myProfile ? <Content user={myProfile} /> : <>No data</>}
    </DashboardContent>
  );
};
