import { getConfig } from "@/utils/config";

export const getHashValue = (key: string) => {
  const hash = window.location.hash;
  const params = new URLSearchParams(hash.substring(1));
  return params.get(key);
};

export const getCognitoLoginUrl = () => {
  const config = getConfig();
  const params = new URLSearchParams({
    client_id: config.cognitoClientId,
    response_type: "token",
    scope: "email openid phone",
    redirect_uri: window.location.origin,
  });

  return `${config.cognitoLoginUrl}?${params.toString()}`;
};
