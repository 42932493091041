import type { PC, PolymorphicPropsWithRef, PolymorphicRef } from "@roboton/tools";
import clsx from "clsx";
import { type ElementType, forwardRef } from "react";
import { Icon, type IconType } from "../Icon/Icon";

type Variant = "primary-base" | "primary-positive" | "primary-negative" | "text-base" | "text-negative";

type Size = "large" | "medium" | "small";

type ButtonOwnProps = {
  /* allow disabled property for the elements that doesn't have the property natively */
  disabled?: boolean;
  variant?: Variant;
  size?: Size;
  icon?: IconType;
  iconAlign?: "left" | "right";
};

const variantToClassNameMap: Record<Variant, string> = {
  // primary
  "primary-base": "ui-button--primary ui-button--base",
  "primary-positive": "ui-button--primary ui-button--positive",
  "primary-negative": "ui-button--primary ui-button--negative",
  // text
  "text-base": "ui-button--text ui-button--base",
  "text-negative": "ui-button--text ui-button--negative",
};

const sizeToClassNameMap: Record<Size, string> = {
  large: "ui-button--large",
  medium: "ui-button--medium",
  small: "ui-button--small",
};

const defaultAs = "button";
export const Button: PC<ButtonOwnProps, typeof defaultAs> = forwardRef(
  <T extends ElementType = typeof defaultAs>(
    {
      as,
      variant = "primary-base",
      size = "medium",
      icon,
      iconAlign = "left",
      disabled,

      children,
      className,
      ...rest
    }: PolymorphicPropsWithRef<T, ButtonOwnProps>,
    ref: PolymorphicRef<T>,
  ) => {
    const isSquare = !children;
    const isReversed = iconAlign === "right";

    const As = as || defaultAs;

    return (
      <As
        {...rest}
        ref={ref}
        className={clsx(
          "ui-button",
          isReversed && "ui-button-is-reversed",
          isSquare && "ui-button--is-square",
          disabled && "ui-button--is-disabled",
          variantToClassNameMap[variant],
          sizeToClassNameMap[size],
          className,
        )}
        disabled={as === "a" ? undefined : disabled}
      >
        {icon ? <Icon type={icon} className={"ui-button__icon"} aria-hidden /> : null}
        {children}
      </As>
    );
  },
);

Button.displayName = "Button";
