import { logger } from "@/logger";
import { useState } from "react";
import { type BlockModel, NewBlock, UncategorizedBlock } from "../block.model";

type RemoteSegment = BlockModel["segments"][number];

export const useBlocks = (initialBlocks: (BlockModel | UncategorizedBlock)[]) => {
  const [blocks, setBlocks] = useState(() => [...initialBlocks]);
  const [isTouched, setIsTouched] = useState(false);
  const [isBlockBoundariesTouched, setIsBlockBoundariesTouched] = useState(false);
  const [isSegmentsStructureTouched, setIsSegmentsStructureTouched] = useState(false);

  const updateBlockBoundaries = (blockId: string, newBoundaries: { lat: number; lon: number }[]) => {
    setIsTouched(true);
    setIsBlockBoundariesTouched(true);
    setBlocks((prevBlocks) =>
      [...prevBlocks].map((prevBlock) => {
        if (blockId !== prevBlock.id) return prevBlock;

        const block = prevBlock.clone() as BlockModel;
        block.updateBoundaries(newBoundaries);
        return block;
      }),
    );
  };

  const updateBlockName = (blockId: string, newName: string, onSuccess?: (block: BlockModel) => void) => {
    setIsTouched(true);
    setBlocks((prevBlocks) =>
      [...prevBlocks].map((prevBlock) => {
        if (blockId !== prevBlock.id) return prevBlock;

        const block = prevBlock.clone();
        const isSuccess = block.updateName(newName);

        if (isSuccess) {
          onSuccess?.(block);
          return block;
        }
        return prevBlocks;
      }),
    );
  };

  const updateBlockColor = (blockId: string, newColor: string, onSuccess?: (block: BlockModel) => void) => {
    setIsTouched(true);
    setBlocks((prevBlocks) =>
      [...prevBlocks].map((prevBlock) => {
        if (blockId !== prevBlock.id) return prevBlock;

        const block = prevBlock.clone();
        const isSuccess = block.updateColor(newColor);

        if (isSuccess) {
          onSuccess?.(block);
          return block;
        }
        return prevBlock;
      }),
    );
  };

  const moveSegmentToBlock = (segment: RemoteSegment, sourceBlockId: string, targetBlockId: string) => {
    let isRemoved = false;
    let isAdded = false;

    setIsTouched(true);

    setBlocks((prevBlocks) => {
      const newBlocks: typeof prevBlocks = [];

      for (const prevBlock of prevBlocks) {
        const block = prevBlock.clone();

        // remove segment from current Block
        if (prevBlock.id === sourceBlockId) {
          block.removeSegment(segment.id);
          isRemoved = true;
        }

        // add segment to new Block
        if (prevBlock.id === targetBlockId) {
          block.addSegment(segment);
          isAdded = true;
        }

        newBlocks.push(block);
      }

      const isSuccessfulMoved = isRemoved && isAdded;
      if (isSuccessfulMoved) {
        setIsSegmentsStructureTouched(true);
        return newBlocks;
      }

      if (!isAdded) {
        logger.warn(`Failed to move the Segment. Target Block with the ID ${targetBlockId} does not exist.`);
      }
      if (!isRemoved) {
        logger.warn(`Failed to move the Segment. Source Block with the ID ${sourceBlockId} does not exist.`);
      }

      return prevBlocks;
    });
  };

  const createBlock = (params?: ConstructorParameters<typeof NewBlock>[0]) => {
    setIsTouched(true);
    const newBlock = new NewBlock(params);
    setBlocks((prevBlocks) => [...prevBlocks, newBlock]);
    return newBlock;
  };

  const removeBlock = (block: BlockModel) => {
    let isRemoved = false;

    setBlocks((prevBlocks) => {
      // remove the block and existing uncategorized block from current blocks
      const newBlocks = [...prevBlocks].filter((prevBlock) => {
        const isBlockToRemove = prevBlock.id === block.id || prevBlock.isUncategorized;
        if (isBlockToRemove) isRemoved = true;
        return !isBlockToRemove;
      });

      // Move all segments from the removed block and current uncategorized block to the new one.
      // But only if the blocks contain an uncategorized block.
      let newUncategorizedBlock: UncategorizedBlock | undefined;
      const currentUncategorizedBlock = prevBlocks.find((block) => block.isUncategorized);
      if (currentUncategorizedBlock) {
        const currentUncategorizedSegments = currentUncategorizedBlock.segments || [];
        newUncategorizedBlock = new UncategorizedBlock([...currentUncategorizedSegments, ...block.segments]);
      }

      return newUncategorizedBlock ? [newUncategorizedBlock, ...newBlocks] : newBlocks;
    });

    if (!isRemoved) return logger.warn(`Failed to remove the Block ${block.id}.`);
    setIsTouched(true);
    setIsSegmentsStructureTouched(true);
  };

  const moveAllSegmentsToBlock = (sourceBlock: BlockModel, targetBlockId: string) => {
    setIsTouched(true);

    let isRemoved = false;
    let isAdded = false;

    setBlocks((prevBlocks) => {
      const newBlocks: typeof prevBlocks = [];

      for (const prevBlock of prevBlocks) {
        const block = prevBlock.clone();

        // remove segments from current Block
        if (prevBlock.id === sourceBlock.id) {
          block.removeAllSegments();
          isRemoved = true;
        }

        // add segment to new Block
        if (prevBlock.id === targetBlockId) {
          block.addSegments(sourceBlock.segments);
          isAdded = true;
        }

        newBlocks.push(block);
      }

      const isSuccessfulMoved = isRemoved && isAdded;
      if (isSuccessfulMoved) {
        setIsSegmentsStructureTouched(true);
        return newBlocks;
      }

      if (!isAdded) {
        logger.warn(`Failed to move the Segments. Target Block with the ID ${targetBlockId} does not exist.`);
      }
      if (!isRemoved) {
        logger.warn(`Failed to move the Segments. Source Block with the ID ${sourceBlock.id} does not exist.`);
      }

      return prevBlocks;
    });
  };

  const onBlocksSuccessSave = () => {
    setIsTouched(false);
    setIsSegmentsStructureTouched(false);
  };

  return {
    isTouched,
    isSegmentsStructureTouched,
    isBlockBoundariesTouched,

    blocks,
    updateBlockName,
    updateBlockColor,
    updateBlockBoundaries,
    moveSegmentToBlock,
    moveAllSegmentsToBlock,
    createBlock,
    removeBlock,

    onBlocksSuccessSave,
  };
};
