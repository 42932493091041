import type { DeepLeafKey } from "@/utils/deepKey";
import i18n from "i18next";
import backend, { type HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import type nsEnums from "../public/locales/en/enums.json";
import type nsErrorCodes from "../public/locales/en/error-code.json";

export const i18nOptions = {
  locales: ["en"],
  defaultLocale: "en",
  // default namespace used in the `t` function
  defaultNS: "dashboard",
};

export const initI18n = () =>
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init<HttpBackendOptions>({
      // if you're using a language detector, do not define the lng option
      lng: i18nOptions.defaultLocale,
      // Default value of the fallbackLng is "dev". It's  causing calls to get the /locales/dev/common.json.
      // It's not an expected behavior. So, I'm setting it to the default locale.
      fallbackLng: i18nOptions.defaultLocale,
      defaultNS: i18nOptions.defaultNS,
      load: "languageOnly",

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      // error codes are not loaded dynamically, so we need to load them manually
      ns: ["error-code", "enums"],

      returnNull: false,

      debug: false,
    });

export const translate = i18n.t;

export type I18NEnumsKey = DeepLeafKey<typeof nsEnums>;
export type I18NErrorCodesKey = DeepLeafKey<typeof nsErrorCodes>;
