import { useGrowingPlanPreviewQuery } from "@/api/sdk";
import { Card } from "@/components/Card";
import { LoadingContent } from "@/components/LoadingContent";
import { formatDate, roundToDecimal } from "@/utils/format";
import type { ComponentProps } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateGrowingPlanStartAtInfoForm } from "./StartAtForm";
import { YearPreviewChart } from "./components/YearPreviewChart";

type CreateGrowingPlanStartDayInfoContentProps = {
  cropSheetId: string;
  blockId: string;
} & Pick<ComponentProps<typeof CreateGrowingPlanStartAtInfoForm>, "form" | "formId" | "onSuccessSubmit">;

export const CreateGrowingPlanStartDayInfoContent = ({
  cropSheetId,
  blockId,
  form,
  onSuccessSubmit,
  ...formProps
}: CreateGrowingPlanStartDayInfoContentProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { formState } = form;
  const [startAt, setStartAt] = useState<string | undefined>(formState.defaultValues?.startAt);
  const isFetchEnabled = !!startAt;

  const {
    data: { growingPlanPreview } = {},
    isLoading,
  } = useGrowingPlanPreviewQuery({ startAt: startAt || "", cropSheetId, blockId }, { enabled: isFetchEnabled });
  const isReallyLoading = isLoading && isFetchEnabled;

  const handleSuccessSubmit: ComponentProps<typeof CreateGrowingPlanStartAtInfoForm>["onSuccessSubmit"] = (data) => {
    setStartAt(data.startAt);
    onSuccessSubmit?.(data);
  };

  const growingInfo = growingPlanPreview ? { startAt: startAt || "", endAt: growingPlanPreview?.endAt } : undefined;

  return (
    <>
      <div className={"mt-4 space-y-4"}>
        <Card role={"presentation"}>
          <h3 className={"typo-h3"}>{t("Annual overview of estimated yield")}</h3>

          <YearPreviewChart cropSheetId={cropSheetId} blockId={blockId} growingInfo={growingInfo} />
        </Card>

        <div className={"relative flex grow flex-col gap-4 md:grid md:grid-cols-3"}>
          {isReallyLoading ? <LoadingContent className={"absolute inset-0"} /> : null}

          <Card className={"relative"}>
            <h2 className={"typo-h2"}>{t("When I want to start growing")}</h2>
            <CreateGrowingPlanStartAtInfoForm
              {...formProps}
              isSubmitDisabled={isLoading}
              form={form}
              onSuccessSubmit={handleSuccessSubmit}
            />
          </Card>

          {growingPlanPreview ? (
            <>
              <Card>
                <h3 className={"typo-h3"}>{t("Growing Time")}</h3>
                <p>{t("dayWithCount", { count: growingPlanPreview.planDuration })}</p>
              </Card>
              <Card>
                <h3 className={"typo-h3"}>{t("End Day")}</h3>
                <p>{formatDate(growingPlanPreview.endAt, language)}</p>
              </Card>
              <Card>
                <h3 className={"typo-h3"}>{t("Expected percentage yield")}</h3>
                <p>{roundToDecimal(growingPlanPreview.expectedYieldPercentage, 2)} %</p>
              </Card>
              <Card>
                <h3 className={"typo-h3"}>{t("Expected Yield")}</h3>
                <p>{roundToDecimal(growingPlanPreview.expectedYield, 2)}</p>
              </Card>
              <Card>
                <h3 className={"typo-h3"}>{t("Potential Yield")}</h3>
                <p>{roundToDecimal(growingPlanPreview.potentialYield, 2)}</p>
              </Card>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
