import { useEffect, useRef } from "react";

/*
---------------------------------------------------------------------
Hook
---------------------------------------------------------------------
 */
function useKeyPress(key: string, callback: (e: KeyboardEvent) => void) {
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (e.key === key) savedCallback.current(e);
    }
    window.addEventListener("keyup", onKeyup);
    return () => {
      window.removeEventListener("keyup", onKeyup);
    };
  }, [key]);
}

/*
---------------------------------------------------------------------
Component
---------------------------------------------------------------------
 */
type KeyPressProps = {
  keyCode: Parameters<typeof useKeyPress>[0];
  onPress: Parameters<typeof useKeyPress>[1];
};

const KeyPress = ({ keyCode, onPress }: KeyPressProps): null => {
  useKeyPress(keyCode, onPress);

  return null;
};

export { useKeyPress, KeyPress };
