import { DashboardContent } from "@/components/DashboardContent";
import { routes } from "@/routes";
import { useAppTitle } from "@/utils/useAppTitle";
import { useTranslation } from "react-i18next";
import type { ParamParseKey } from "react-router-dom";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { RobotDetailTabs } from "./components/RobotDetailTabs";
import type { RobotTransformedQuery } from "./useRobotTransformedQuery";
import { useRobotTransformedQuery } from "./useRobotTransformedQuery";

type RobotDetailLayoutContext = {
  robotAssignment: RobotTransformedQuery["robotAssignment"];
  robotId: string;
};

export const useRobotDetailLayoutContext = () => {
  return useOutletContext<RobotDetailLayoutContext>();
};

export const RobotDetailLayout = () => {
  const { t } = useTranslation();

  const { id: robotId } = useParams<ParamParseKey<typeof routes.RobotDetail>>();
  if (!robotId) throw new Error("Missing Robot ID");

  const {
    data: { robotAssignment } = {},
    isLoading,
  } = useRobotTransformedQuery({
    robotAssignmentId: robotId,
  });

  const headline = robotAssignment?.name || t("Robot");
  useAppTitle(headline);

  const context = robotAssignment
    ? ({
        robotAssignment,
        robotId,
      } satisfies RobotDetailLayoutContext)
    : undefined;

  return (
    <DashboardContent headline={headline} navigateBackTo={routes.Robots} isLoading={isLoading}>
      <RobotDetailTabs robotId={robotId}>{robotAssignment ? <Outlet context={context} /> : null}</RobotDetailTabs>
    </DashboardContent>
  );
};
