export function computePercentageOffset(range: { min: number; max: number }, item: { min: number; max: number }) {
  const { min: rangeStart, max: rangeEnd } = range;
  if (rangeEnd < rangeStart) {
    throw new Error("Invalid range. Range end must be greater than range start.");
  }

  const rangeSize = rangeEnd - rangeStart;

  const min = item.min < rangeStart ? rangeStart : item.min;
  const max = item.max > rangeEnd ? rangeEnd : item.max;

  const left = ((min - rangeStart) / rangeSize) * 100;
  const right = ((rangeEnd - max) / rangeSize) * 100;
  return { left, right };
}
