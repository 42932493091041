type PageItem = number | "...";

// Scripts are based on https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#gistcomment-3238804

export const getRange = (start: number, end: number): PageItem[] => {
  if (end < start) throw Error(`End number must be higher then start number: start ${start}, end ${start}`);

  return Array(end - start + 1)
    .fill(0)
    .map((v, i) => i + start);
};

export const calculatePages = (currentPage: number, pageCount: number): PageItem[] => {
  let _currentPage = currentPage;
  if (pageCount < 1) {
    console.warn("page count has negative value");
    return [];
  }

  if (_currentPage < 1) {
    console.warn("current page has negative value");
    _currentPage = 1;
  }

  if (_currentPage > pageCount) {
    console.warn("current page is higher than page count");
    _currentPage = pageCount;
  }

  const delta = pageCount <= 7 ? 7 : _currentPage > 4 && _currentPage < pageCount - 3 ? 2 : 4;
  const range = { start: Math.round(_currentPage - delta / 2), end: Math.round(_currentPage + delta / 2) };

  if (range.start - 1 === 1 || range.end + 1 === pageCount) {
    range.start += 1;
    range.end += 1;
  }

  let pages =
    _currentPage > delta
      ? getRange(Math.min(range.start, pageCount - delta), Math.min(range.end, pageCount))
      : getRange(1, Math.min(pageCount, delta + 1));

  const withDots = (value: number, pair: PageItem[]) => (pages.length + 1 !== pageCount ? pair : [value]);

  // Start dots
  if (pages[0] !== 1) pages = withDots(1, [1, "..."]).concat(pages);
  // End dots
  const lastPage = pages[pages.length - 1];
  if (typeof lastPage === "number" && lastPage < pageCount) {
    pages = pages.concat(withDots(pageCount, ["...", pageCount]));
  }

  return pages;
};
