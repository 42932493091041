import type { TraceType } from "@/api/sdk";
import { Card } from "@/components/Card";
import { formatDate } from "@/utils/format";
import { Icon } from "@roboton/ui";
import clsx from "clsx";

type Props = {
  gpsTrace: Pick<TraceType, "name" | "id" | "createdAt">;
  onClick: (gpsTraceId: string) => void;
  isSelected?: boolean;
};

export const GpsTraceRadio = ({ gpsTrace, isSelected, onClick }: Props) => {
  // todo: refactor into radio button card to be used natively in forms
  return (
    <button type={"button"} className={"flex w-full"} onClick={() => onClick(gpsTrace.id)}>
      <Card
        className={clsx("relative flex w-full items-center gap-4", isSelected && "outline outline-1 outline-blue-50")}
      >
        <div className={"flex flex-1 flex-col justify-start gap-2 text-left"}>
          <span className={"font-bold"}>{gpsTrace.name}</span>
          <span>{formatDate(gpsTrace.createdAt, "cs-cz")}</span>
        </div>
        <div className={"flex"}>
          <Icon type={"check_circle"} className={clsx("h-8 w-8", isSelected ? "text-blue-50" : "text-light-75")} />
        </div>
      </Card>
    </button>
  );
};
