import { Card } from "@/components/Card";
import { MapComponent } from "@/components/Map";
import { PlaceholderCard } from "@/components/PlaceholderCard";
import { formatCoordinates, formatDate } from "@/utils/format";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { Icon } from "@roboton/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Marker } from "react-map-gl/maplibre";
import { useRobotDetailLayoutContext } from "./RobotDetailLayout";

const Item = ({ label, children, className, ...rest }: JSX.IntrinsicElements["div"] & { label: string }) => {
  return (
    <div
      {...rest}
      className={clsx(
        "border-light-25 flex flex-col gap-x-2 border-b-2 py-2 sm:flex-row sm:justify-between",
        className,
      )}
    >
      <div className={"font-bold"}>{label}: </div>
      <div>{children || "-"}</div>
    </div>
  );
};

export const RobotDetailGeneralPage = () => {
  const { robotAssignment } = useRobotDetailLayoutContext();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { tEnums } = useTranslationEnums();

  const devices = robotAssignment.robot.stateReport?.general?.devices || [];

  return (
    <div className={"grid grid-cols-1 gap-6 lg:grid-cols-2"}>
      <Card className={"col-span-full"}>
        <h2 className={"typo-h2"}>{t("Robot Location")}</h2>
        {robotAssignment.robot.position ? (
          <div className={"h-[30rem] w-full"}>
            <MapComponent
              initialViewState={{
                zoom: 15,
                latitude: robotAssignment.robot.position.lat,
                longitude: robotAssignment.robot.position.lon,
              }}
              enableControls
            >
              <Marker latitude={robotAssignment.robot.position.lat} longitude={robotAssignment.robot.position.lon}>
                <div className={"bg-brand-25 border-brand-25 h-6 w-6 rounded-full border-2 hover:cursor-pointer"} />
              </Marker>
            </MapComponent>
          </div>
        ) : (
          <PlaceholderCard
            variant={"none"}
            icon={"robot"}
            className={"h-full"}
            description={t("No location data available")}
          />
        )}
      </Card>

      <Card>
        <h2 className={"typo-h2"}>{t("Current Info")}</h2>

        <Item label={t("Status")}>{robotAssignment.robot.stateReport?.general?.status}</Item>
        <Item label={t("Active devices")}>
          {devices.length > 0 ? (
            <ul className={"list-inside list-disc"}>
              {robotAssignment.robot.stateReport?.general?.devices.map((device) => (
                <li key={device.type}>
                  {tEnums("riRobotDeviceType", device.type)}
                  {device.error ? (
                    <span className={"text-red-50"}>
                      {" "}
                      [<Icon type={"warning"} className={"-mt-1 mr-0.5 inline-block h-4 w-4"} />
                      {t("Error")}]
                    </span>
                  ) : null}
                </li>
              ))}
            </ul>
          ) : null}
        </Item>

        <Item label={t("Position")}>
          {robotAssignment.robot.position
            ? formatCoordinates({ lat: robotAssignment.robot.position.lat, lon: robotAssignment.robot.position.lon })
            : null}
        </Item>
        <Item label={t("Speed")}>{robotAssignment.robot.stateReport?.position?.speed}</Item>
        <Item label={t("Distance travelled")}>{robotAssignment.robot.stateReport?.position?.dist}</Item>
        <Item label={t("Target")}>
          {robotAssignment.robot.target
            ? formatCoordinates({ lat: robotAssignment.robot.target.lat, lon: robotAssignment.robot.target.lon })
            : null}
        </Item>
        <Item label={t("Battery level")}>{robotAssignment.robot.stateReport?.battery?.estimEnergy}</Item>
        <Item label={t("Battery charging state")}>
          {robotAssignment.robot.stateReport?.battery?.chargingState
            ? tEnums("riRobotBatteryChargingState", robotAssignment.robot.stateReport.battery.chargingState)
            : null}
        </Item>
        <Item label={t("Tool")}>
          {robotAssignment.robot.stateReport?.tool?.type
            ? tEnums("riRobotToolType", robotAssignment.robot.stateReport.tool.type)
            : null}
        </Item>
        <Item label={t("Tool operational mode")}>
          {robotAssignment.robot.stateReport?.tool?.mode
            ? tEnums("riRobotToolMode", robotAssignment.robot.stateReport.tool.mode)
            : null}
        </Item>
      </Card>

      <Card>
        <h2 className={"typo-h2 pb-4"}>{t("General Info")}</h2>

        <Item label={t("Name")}>{robotAssignment.name}</Item>
        <Item label={t("Register State")}>
          {robotAssignment.registeredAt ? formatDate(robotAssignment.registeredAt, language) : null}
        </Item>
        <Item label={t("Created at")}>
          {robotAssignment.createdAt ? formatDate(robotAssignment.createdAt, language) : null}
        </Item>
        <Item label={t("Serial number")}>{robotAssignment.robot.serialNumber}</Item>
      </Card>
    </div>
  );
};
