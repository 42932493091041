import { DashboardContent } from "@/components/DashboardContent";
import { generatePath, routes } from "@/routes";
import { useAppTitle } from "@/utils/useAppTitle";
import { useTranslation } from "react-i18next";
import { useFieldTransformedQuery } from "./../useFieldTransformedQuery";
import { ManageBlocksWithRobotContent } from "./ManageBlocksWithRobotContent";
import { ManageBlocksWithoutRobotContent } from "./ManageBlocksWithoutRobotContent";
import { BlockModel } from "./block.model";

export const ManageBlockPage = ({ fieldId }: { fieldId: string }) => {
  const { t } = useTranslation();
  useAppTitle(t("Manage Blocks"));

  const {
    data: { field } = {},
    isLoading,
    dataUpdatedAt,
  } = useFieldTransformedQuery({ fieldId }, { refetchOnMount: false, refetchOnWindowFocus: false });
  const blocks = field?.blocks?.map((block) => new BlockModel(block)) || [];

  const { isCultivatedByRobot, hasBoundaries } = field || {};

  const fieldPath = generatePath(routes.FieldDetail, { id: fieldId });

  const hasData = field && hasBoundaries;
  const Content = isCultivatedByRobot ? ManageBlocksWithRobotContent : ManageBlocksWithoutRobotContent;

  if (hasData) {
    return <Content key={dataUpdatedAt} field={field} blocks={blocks} />;
  }

  return (
    <DashboardContent navigateBackTo={fieldPath} isLoading={isLoading}>
      No data
    </DashboardContent>
  );
};
