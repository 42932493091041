import { FieldIrrigation, FieldLighting, SoilStatus } from "@/api/sdk";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { Button, FormField, Input, Select } from "@roboton/ui";
import clsx from "clsx";
import { type SubmitErrorHandler, type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormInputs = {
  name: string;
  soilType: string;
  soilPhLevel: number;
  soilStatus: SoilStatus;
  soilNitrogen?: number;
  soilPhosphorus?: number;
  soilPotassium?: number;
  irrigation: FieldIrrigation;
  lighting: FieldLighting;
  cultivationMethod?: string;
};

type FieldFormProps = Omit<JSX.IntrinsicElements["form"], "onSubmit"> & {
  submitButtonLabel: string;
  options: {
    soilTypes: { value: string; label: string }[];
    robots: { value: string; label: string }[];
  };
  defaultValues?: FormInputs;
  disabledFields?: (keyof FormInputs)[];
  onSubmitValid: SubmitHandler<FormInputs>;
  onSubmitInvalid?: SubmitErrorHandler<FormInputs>;
};

const CULTIVATION_METHOD_MANUALLY_VALUE = "manually";

export const FieldForm = ({
  submitButtonLabel,
  options: { soilTypes, robots },
  defaultValues,
  disabledFields = [],
  onSubmitValid,
  onSubmitInvalid,
  className,
  ...rest
}: FieldFormProps) => {
  const { t } = useTranslation();
  const { tEnums } = useTranslationEnums();
  const { formState, handleSubmit, register } = useForm<FormInputs>({ defaultValues });
  const { errors } = formState;

  const msgRequired = t("This field is required");
  const msgInvalidValue = t("Invalid value");

  return (
    <form
      {...rest}
      onSubmit={handleSubmit(onSubmitValid, onSubmitInvalid)}
      className={clsx(className, "flex flex-col gap-6 md:grid md:grid-cols-2")}
    >
      <FormField messages={[{ key: "error", children: errors.name?.message, variant: "alert" }]}>
        <Input
          {...register("name", { minLength: 1, maxLength: 50, required: msgRequired })}
          id={"field-name"}
          label={t("Name of the Field")}
          state={errors.name && "negative"}
          disabled={disabledFields.includes("name")}
          aria-required
          aria-invalid={!!errors.name}
        />
      </FormField>

      <FormField messages={[{ key: "error", children: errors.cultivationMethod?.message, variant: "alert" }]}>
        <Select
          {...register("cultivationMethod", { required: msgRequired })}
          id={"field-cultivation-method"}
          label={t("Cultivation method")}
          state={errors.cultivationMethod && "negative"}
          disabled={disabledFields.includes("cultivationMethod")}
          aria-required
          aria-invalid={!!errors.cultivationMethod}
        >
          <option value={CULTIVATION_METHOD_MANUALLY_VALUE}>{t("Manually")}</option>
          {robots.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </FormField>

      <FormField messages={[{ key: "error", children: errors.soilType?.message, variant: "alert" }]}>
        <Select
          {...register("soilType", { required: msgRequired })}
          id={"field-soil-type"}
          label={t("Soil type")}
          state={errors.soilType && "negative"}
          disabled={disabledFields.includes("soilType")}
          aria-required
          aria-invalid={!!errors.soilType}
        >
          {soilTypes.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </FormField>

      <FormField messages={[{ key: "error", children: errors.soilPhLevel?.message, variant: "alert" }]}>
        <Input
          {...register("soilPhLevel", {
            required: msgRequired,
            valueAsNumber: true,
            min: { value: 0.0, message: msgInvalidValue },
            max: { value: 14.0, message: msgInvalidValue },
          })}
          type={"number"}
          step={0.01}
          id={"field-soil-ph-level"}
          label={t("Soil PH Level")}
          state={errors.soilPhLevel && "negative"}
          disabled={disabledFields.includes("soilPhLevel")}
          aria-required
          aria-invalid={!!errors.soilPhLevel}
        />
      </FormField>

      <FormField messages={[{ key: "error", children: errors.soilNitrogen?.message, variant: "alert" }]}>
        <Input
          {...register("soilNitrogen", {
            valueAsNumber: true,
            min: { value: 0.0, message: msgInvalidValue },
            max: { value: 999.0, message: msgInvalidValue },
          })}
          type={"number"}
          step={0.01}
          id={"field-soil-nitrogen"}
          label={t("Soil Nitrogen Supply")}
          state={errors.soilNitrogen && "negative"}
          disabled={disabledFields.includes("soilNitrogen")}
          aria-invalid={!!errors.soilNitrogen}
        />
      </FormField>

      <FormField messages={[{ key: "error", children: errors.soilPhosphorus?.message, variant: "alert" }]}>
        <Input
          {...register("soilPhosphorus", {
            valueAsNumber: true,
            min: { value: 0.0, message: msgInvalidValue },
            max: { value: 999.0, message: msgInvalidValue },
          })}
          type={"number"}
          step={0.01}
          id={"field-soil-phosphorus"}
          label={t("Soil Phosphorus Supply")}
          state={errors.soilPhosphorus && "negative"}
          disabled={disabledFields.includes("soilPhosphorus")}
          aria-invalid={!!errors.soilPhosphorus}
        />
      </FormField>

      <FormField messages={[{ key: "error", children: errors.soilPotassium?.message, variant: "alert" }]}>
        <Input
          {...register("soilPotassium", {
            valueAsNumber: true,
            min: { value: 0.0, message: msgInvalidValue },
            max: { value: 999.0, message: msgInvalidValue },
          })}
          type={"number"}
          step={0.01}
          id={"field-soil-potassium"}
          label={t("Soil Potassium Supply")}
          state={errors.soilPotassium && "negative"}
          disabled={disabledFields.includes("soilPotassium")}
          aria-invalid={!!errors.soilPotassium}
        />
      </FormField>

      <FormField messages={[{ key: "error", children: errors.soilStatus?.message, variant: "alert" }]}>
        <Select
          {...register("soilStatus", { required: msgRequired })}
          id={"field-soil-status"}
          label={t("Soil Status")}
          state={errors.soilStatus && "negative"}
          disabled={disabledFields.includes("soilStatus")}
          aria-required
          aria-invalid={!!errors.soilStatus}
        >
          {Object.values(SoilStatus).map((v) => (
            <option key={v} value={v}>
              {tEnums("soilStatus", v)}
            </option>
          ))}
        </Select>
      </FormField>

      <FormField messages={[{ key: "error", children: errors.irrigation?.message, variant: "alert" }]}>
        <Select
          {...register("irrigation", { required: msgRequired })}
          id={"field-irrigation"}
          label={t("Irrigation")}
          state={errors.irrigation && "negative"}
          disabled={disabledFields.includes("irrigation")}
          aria-required
          aria-invalid={!!errors.irrigation}
        >
          {Object.values(FieldIrrigation).map((v) => (
            <option key={v} value={v}>
              {tEnums("fieldIrrigation", v)}
            </option>
          ))}
        </Select>
      </FormField>

      <FormField messages={[{ key: "error", children: errors.lighting?.message, variant: "alert" }]}>
        <Select
          {...register("lighting", { required: msgRequired })}
          id={"field-lighting"}
          label={t("Lighting")}
          state={errors.lighting && "negative"}
          disabled={disabledFields.includes("lighting")}
          aria-required
          aria-invalid={!!errors.lighting}
        >
          {Object.values(FieldLighting).map((v) => (
            <option key={v} value={v}>
              {tEnums("fieldLighting", v)}
            </option>
          ))}
        </Select>
      </FormField>

      <div className={"col-span-full text-center"}>
        <Button type={"submit"} size={"large"} icon={"check"}>
          {submitButtonLabel}
        </Button>
      </div>
    </form>
  );
};

FieldForm.CULTIVATION_METHOD_MANUALLY_VALUE = CULTIVATION_METHOD_MANUALLY_VALUE;
