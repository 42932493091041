import { DiseaseCategory } from "@/api/sdk";
import { CropThreatsPage } from "@/crop-sheet/crop-detail/CropThreatsPage";
import type { routes } from "@/routes";
import type { ParamParseKey } from "react-router-dom";
import { useParams } from "react-router-dom";

const CropThreatsDiseasesRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.CropThreatsDiseases>>();
  if (!id) throw new Error("Missing ID");

  return <CropThreatsPage cropId={id} threatType={DiseaseCategory.Disease} />;
};

export default CropThreatsDiseasesRoute;
