import type { Merge } from "@roboton/tools";
import { type SVGAttributes, forwardRef } from "react";
import type { icons } from "./icons";

type IconType = keyof typeof icons;

type IconProps = Merge<
  SVGAttributes<SVGElement>,
  {
    /** Name of the icon to render */
    type: IconType;
  }
>;

const DEFAULT_SIZE = 24;

/**
 * Icons are colored by stroke.
 * Stroke color is set to "currentColor".
 * Sometimes you want to fill icon (heart or star, for example). Use tailwindcss class "fill-current".
 */
const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ type, width = DEFAULT_SIZE, height = DEFAULT_SIZE, children, ...rest }, ref): JSX.Element => (
    <svg {...rest} ref={ref} width={width} height={height}>
      {children}
      <use href={`/assets/icons-sprite.svg#${type}`} />
    </svg>
  ),
);

Icon.displayName = "Icon";

export { Icon };
export type { IconType };
