import { Button, Modal } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

type ModalProps = ComponentProps<typeof Modal>;
type ButtonProps = ComponentProps<typeof Button>;

export const RemoveHomeStationConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  onConfirm: ButtonProps["onClick"];
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      size={"medium"}
      a11y={{ closeButtonLabel: t("Close"), dialogId: "cultivated-by-changing-modal" }}
      heading={"Do you really want to remove Home Station?"}
      variant={"negative"}
      footerChildren={
        <>
          <Button type={"button"} variant={"primary-negative"} onClick={onConfirm}>
            {t("Delete the Home Station")}
          </Button>
          <Button type={"button"} variant={"text-negative"} onClick={onClose}>
            {t("Cancel")}
          </Button>
        </>
      }
      onClose={onClose}
    >
      {t("Deleting the Home Station will also delete the path to the Home Station.")}
    </Modal>
  );
};
