import { ManageBlockPage } from "@/field/blocks/ManageBlocksPage";
import type { routes } from "@/routes";
import { type ParamParseKey, useParams } from "react-router-dom";

const ManageBlocksRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.ManageBlocks>>();
  if (!id) throw new Error("Missing ID");

  return <ManageBlockPage fieldId={id} />;
};

export default ManageBlocksRoute;
