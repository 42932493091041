import { type Merge, callAllEventHandlers, composeRefs } from "@roboton/tools";
import {
  type HTMLProps,
  type KeyboardEventHandler,
  type Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export const useTabs = <Tabs extends string[] | Readonly<string[]>>(userProps: {
  id: string;
  tabs: Tabs;
  initTab?: Tabs[number];
  options?: {
    disableScrollIntoView?: boolean;
  };
}) => {
  type Tab = Tabs[number];

  const { id, tabs: allTabs, initTab, options } = userProps;
  const { disableScrollIntoView = false } = options || {};

  /* tabs which ref doesn't contain `disable: true` prop */
  const [tabs, setTabs] = useState(allTabs);

  // utility
  const getTabId = useCallback((tab: Tab) => `${id}-tab-${tab}`, [id]);
  const getPanelId = useCallback((tab: Tab) => `${id}-tab-panel-${tab}`, [id]);
  const getTabIndex = useCallback((tab: Tab) => tabs.indexOf(tab), [tabs]);

  const tabRefs = useRef<Record<ReturnType<typeof getTabId>, (HTMLElement & { disabled?: boolean }) | null>>({});

  // update tabs state when component is mounted
  useEffect(() => {
    // filter out tabs that has `disable: true` prop
    setTabs(() => allTabs.filter((tab) => !tabRefs.current[getTabId(tab)]?.disabled) as Tabs);
  }, [getTabId, allTabs]);

  const [selectedTab, setSelectedTab] = useState<Tab>(initTab || tabs[0]);

  const selectedIndex = useMemo(() => (selectedTab ? getTabIndex(selectedTab) : -1), [selectedTab, getTabIndex]);

  const nextIndex = selectedIndex < tabs.length - 1 ? selectedIndex + 1 : 0;
  const prevIndex = selectedIndex > 0 ? selectedIndex - 1 : tabs.length - 1;

  const nextTab = tabs[nextIndex];
  const prevTab = tabs[prevIndex];
  const firstTab = tabs.length > 0 ? tabs[0] : null;
  const lastTab = tabs.length > 0 ? tabs[tabs.length - 1] : null;

  const setFocus = useCallback(
    (tab: Tab) => {
      const tabId = getTabId(tab);
      const tabRef = tabRefs.current[tabId];
      tabRef?.focus();
      !disableScrollIntoView && tabRef?.scrollIntoView();
    },
    [getTabId, disableScrollIntoView],
  );

  const setTab = useCallback(
    (tab: Tab, focus?: boolean) => {
      setSelectedTab(tab);
      if (focus) setFocus(tab);
    },
    [setFocus],
  );

  const setFirstTab = useCallback((focus?: boolean) => firstTab && setTab(firstTab, focus), [firstTab, setTab]);
  const setLastTab = useCallback((focus?: boolean) => lastTab && setTab(lastTab, focus), [lastTab, setTab]);
  const setPreviousTab = useCallback((focus?: boolean) => setTab(prevTab, focus), [prevTab, setTab]);
  const setNextTab = useCallback((focus?: boolean) => setTab(nextTab, focus), [nextTab, setTab]);

  const handleTabKeyDown: KeyboardEventHandler = useCallback(
    (event) => {
      switch (event.key) {
        case "Home":
          setFirstTab(true);
          break;
        case "End":
          setLastTab(true);
          break;
        case "ArrowLeft":
          setPreviousTab(true);
          break;
        case "ArrowRight":
          setNextTab(true);
          break;
      }
    },
    [setFirstTab, setLastTab, setNextTab, setPreviousTab],
  );

  const getListProps: <T extends HTMLElement>() => Pick<Required<HTMLProps<T>>, "role" | "aria-orientation"> =
    useCallback(
      () => ({
        id,
        role: "tablist",
        "aria-orientation": "horizontal",
      }),
      [id],
    );

  const getTabProps: <T extends HTMLElement>(
    tabProps: Merge<
      HTMLProps<T>,
      {
        tab: Tab;
        // override the type of the ref prop from the LegacyRef type to the Ref type
        ref?: Ref<T>;
      }
    >,
  ) => Pick<Required<HTMLProps<T>>, "id" | "role" | "aria-selected" | "aria-controls" | "tabIndex"> = useCallback(
    ({ tab, ref, onClick, onKeyDown, disabled, ...rest }) => {
      const isSelected = tab === selectedTab;
      const id = getTabId(tab);
      const panelId = getPanelId(tab);
      return {
        id,
        role: "tab",
        "aria-selected": isSelected,
        "aria-controls": panelId,
        tabIndex: isSelected ? 0 : -1,
        ref: composeRefs(ref, (tabNode) => {
          if (tabNode) tabRefs.current[id] = tabNode;
        }),
        ...(disabled
          ? { disabled }
          : {
              onClick: disabled ? onClick : callAllEventHandlers(onClick, () => setTab(tab, true)),
              onKeyDown: disabled ? onKeyDown : callAllEventHandlers(onKeyDown, handleTabKeyDown),
            }),
        ...rest,
      };
    },
    [getPanelId, getTabId, handleTabKeyDown, selectedTab, setTab],
  );

  const getPanelProps: <T extends HTMLElement = HTMLElement>(
    tabProps: Merge<HTMLProps<T>, { tab: Tab }>,
  ) => Required<Pick<HTMLProps<T>, "id" | "role" | "aria-labelledby" | "tabIndex">> = useCallback(
    ({ tab, ...rest }) => {
      const isSelected = tab === selectedTab;
      const id = getPanelId(tab);
      const tabId = getTabId(tab);
      return {
        id,
        role: "tabpanel",
        "aria-labelledby": tabId,
        tabIndex: isSelected ? 0 : -1,
        ...rest,
      };
    },
    [getPanelId, getTabId, selectedTab],
  );

  return {
    selectedTab,
    setSelectedTab,

    getListProps,
    getTabProps,
    getPanelProps,
  };
};
