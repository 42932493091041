import { generatePath, routes } from "@/routes";
import { Link } from "react-router-dom";

const Error404Page = () => {
  return (
    <main>
      <h1>404</h1>

      <Link to={generatePath(routes.Home)}>Go to the Home</Link>
    </main>
  );
};

export default Error404Page;
