import { Button } from "@roboton/ui";
import clsx from "clsx";
import { Link } from "react-router-dom";

type ItemProps = JSX.IntrinsicElements["li"] & {
  image: {
    url: string;
    alt: string;
  };
  itemTitle: string;

  to: string;
  linkText: string;
};

const Item = ({ image, itemTitle, children, to, linkText, className, ...rest }: ItemProps) => {
  return (
    <li {...rest} className={clsx("flex items-center gap-4", className)}>
      <img src={image.url} alt={image.alt} width={64} className={"h-16 w-16 object-contain"} />
      <div>
        <div className={"font-bold"}>{itemTitle}</div>
        {children ? <div>{children}</div> : null}
        <Button as={Link} to={to} variant={"text-base"} size={"small"} icon={"arrow_right"} iconAlign={"right"}>
          {linkText}
        </Button>
      </div>
    </li>
  );
};

type RootProps = JSX.IntrinsicElements["ul"];

const Root = ({ className, children, ...rest }: RootProps) => {
  return (
    <ul {...rest} className={clsx("flex flex-col gap-6 sm:grid sm:grid-cols-2 2xl:grid-cols-3", className)}>
      {children}
    </ul>
  );
};

export const PrimitiveInfoList = { Root, Item };
