import { Modal } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { CreateNewTaskForm } from "./CreateNewTaskForm";

type CreateNewTaskFormProps = ComponentProps<typeof CreateNewTaskForm>;

export const CreateNewTaskModal = ({
  isOpen,
  defaultValues,
  onClose,

  onSuccessSubmit,
  entities,
}: {
  isOpen: boolean;
  defaultValues?: CreateNewTaskFormProps["defaultStartValues"];
  onClose: CreateNewTaskFormProps["onCancel"];
} & Pick<CreateNewTaskFormProps, "entities" | "onSuccessSubmit">) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      size={"large"}
      a11y={{ closeButtonLabel: t("Close"), dialogId: "create-task-modal" }}
      heading={t("Create a new Task")}
      variant={"base"}
      onClose={onClose}
    >
      <CreateNewTaskForm
        className={"w-full"}
        entities={entities}
        defaultStartValues={defaultValues}
        onSuccessSubmit={onSuccessSubmit}
        onCancel={onClose}
      />
    </Modal>
  );
};
