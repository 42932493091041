import clsx from "clsx";
import { forwardRef } from "react";

const cssShape = clsx("inline-flex w-8 h-8 shrink-0 items-center justify-center rounded");

/*
---------------------------------------------------------------------
PAGINATION ITEM - VALUE
---------------------------------------------------------------------
 */
type ValueProps = JSX.IntrinsicElements["a"] & { value: number; isActive: boolean };
const Value = forwardRef<HTMLAnchorElement, ValueProps>(({ value = 1, isActive = false, className, ...rest }, ref) => (
  <a
    {...rest}
    ref={ref}
    {...(isActive ? { rel: "current" } : {})}
    className={clsx(
      cssShape,
      "typo-md--bold cursor-pointer transition",
      isActive ? "bg-brand-50 text-light-0 hover:bg-brand-25" : "text-brand-25 hover:bg-brand-0",
      className,
    )}
  >
    {value}
  </a>
));
Value.displayName = "PaginationItem.Value";

/*
---------------------------------------------------------------------
PAGINATION ITEM - DOTS
---------------------------------------------------------------------
 */
type MoreProps = Omit<JSX.IntrinsicElements["div"], "children">;
const More = forwardRef<HTMLDivElement, MoreProps>(({ className, ...rest }, ref) => (
  <div {...rest} ref={ref} className={clsx(cssShape, "gap-0.5", className)}>
    <span className={"bg-brand-25 h-1 w-1 rounded-full"} />
    <span className={"bg-brand-25 h-1 w-1 rounded-full"} />
    <span className={"bg-brand-25 h-1 w-1 rounded-full"} />
  </div>
));
More.displayName = "PaginationItem.More";

export const PaginationItem = { Value, More };
