import { FieldTaskPage } from "@/field-task/FieldTaskPage";
import type { routes } from "@/routes";
import type { ParamParseKey } from "react-router-dom";
import { useParams } from "react-router-dom";

const GrowingPlanDetailRobotTaskRoute = () => {
  const { taskId } = useParams<ParamParseKey<typeof routes.GrowingPlanDetailRobotTask>>();
  if (!taskId) throw new Error("Missing Task ID");

  return <FieldTaskPage taskId={taskId} />;
};

export default GrowingPlanDetailRobotTaskRoute;
