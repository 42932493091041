import { AccessPermissionsProtectedLayer } from "@/access-permissions/AccessPermissionsProtectedLayer";
import { FieldTaskPage } from "@/field-task/FieldTaskPage";
import { generatePath, routes } from "@/routes";
import { wrapCreateBrowserRouter } from "@sentry/react";
import { type ReactNode, Suspense, lazy } from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, redirect } from "react-router-dom";
import Error404Page from "./pages/Error404Page";
import GeneralErrorPage from "./pages/GeneralErrorPage";
import ClientAccounts from "./routes/dashboard.clientAccounts";
import EditClientAccount from "./routes/dashboard.clientAccounts.$id.edit";
import CreateClientAccount from "./routes/dashboard.clientAccounts.create";
import CropSheet from "./routes/dashboard.cropSheet";
import CropsOverview from "./routes/dashboard.cropSheet.crops";
import Crop from "./routes/dashboard.cropSheet.crops.$id";
import AboutCrop from "./routes/dashboard.cropSheet.crops.$id.about";
import CropThreatsDiseases from "./routes/dashboard.cropSheet.crops.$id.diseases";
import CropThreatsPests from "./routes/dashboard.cropSheet.crops.$id.pests";
import CropStages from "./routes/dashboard.cropSheet.crops.$id.stages";
import CropThreatsWeeds from "./routes/dashboard.cropSheet.crops.$id.weeds";
import ThreatsOverview from "./routes/dashboard.cropSheet.diseases";
import ThreatDetail from "./routes/dashboard.cropSheet.diseases.$id";
import Fields from "./routes/dashboard.fields";
import Field from "./routes/dashboard.fields.$id";
import ManageBlocks from "./routes/dashboard.fields.$id.blocks";
import BlockDetail from "./routes/dashboard.fields.$id.blocks.$blockId";
import BlockDetailGeneralRoute from "./routes/dashboard.fields.$id.blocks.$blockId.general";
import BlockDetailTasksRoute from "./routes/dashboard.fields.$id.blocks.$blockId.tasks";
import BlockDetailTaskRoute from "./routes/dashboard.fields.$id.blocks.$blockId.tasks.$taskId";
import ManageBoundaries from "./routes/dashboard.fields.$id.boundaries";
import EditField from "./routes/dashboard.fields.$id.edit";
import ManageHomeStation from "./routes/dashboard.fields.$id.homeStation";
import ManageSegmentsRoute from "./routes/dashboard.fields.$id.segments";
import ManageWorkingLines from "./routes/dashboard.fields.$id.workingLines";
import CreateField from "./routes/dashboard.fields.create";
import GrowingPlans from "./routes/dashboard.growingPlans";
import GrowingPlanDetailRoute from "./routes/dashboard.growingPlans.$id";
import GrowingPlanDetailGeneral from "./routes/dashboard.growingPlans.$id.general";
import GrowingPlanDetailPlantsRoute from "./routes/dashboard.growingPlans.$id.plants";
import GrowingPlanDetailRobotTask from "./routes/dashboard.growingPlans.$id.robotTasks";
import GrowingPlanDetailRobotTaskRoute from "./routes/dashboard.growingPlans.$id.robotTasks.$taskId";
import GrowingPlanDetailTimeline from "./routes/dashboard.growingPlans.$id.timeline";
import CreateGrowingPlan from "./routes/dashboard.growingPlans.create";
import Robots from "./routes/dashboard.robots";
import Robot from "./routes/dashboard.robots.$id";
import RobotDetailGeneral from "./routes/dashboard.robots.$id.general";
import RobotDetailTasks from "./routes/dashboard.robots.$id.tasks";
import RegisterRobot from "./routes/dashboard.robots.add";
import UserProfile from "./routes/dashboard.user";
import HomeRoute from "./routes/index";
import RegisterRoute from "./routes/register";

// Lazy loaded pages.
const LazyRoutes = {
  Dashboard: lazy(() => import("./routes/dashboard")),
};

// General loader component.
const Loader = () => {
  return <div className={"bg-light-0 typo-md--bold absolute inset-0 flex items-center justify-center"}>loading...</div>;
};

// <Suspense> component wrapper with the predefined loader.
function suspend(page: ReactNode, options: { loader?: boolean } = {}): ReactNode {
  const { loader = false } = options;
  return <Suspense fallback={loader ? <Loader /> : null}>{page}</Suspense>;
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

// react-router-dom router
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<GeneralErrorPage />}>
      <Route element={<AccessPermissionsProtectedLayer />}>
        <Route path={routes.Home} element={<HomeRoute />} />
        <Route path={routes.Register} element={<RegisterRoute />} />

        <Route path={routes.Dashboard} element={suspend(<LazyRoutes.Dashboard />, { loader: true })}>
          <Route index loader={() => redirect(routes.ClientAccounts)} />

          <Route path={routes.ClientAccounts} element={<ClientAccounts />} />
          <Route path={routes.CreateClientAccount} element={<CreateClientAccount />} />
          <Route path={routes.EditClientAccount} element={<EditClientAccount />} />

          <Route path={routes.Fields} element={<Fields />} />
          <Route path={routes.CreateField} element={<CreateField />} />
          <Route path={routes.EditField} element={<EditField />} />
          <Route path={routes.FieldDetail} element={<Field />} />
          <Route path={routes.ManageSegments} element={<ManageSegmentsRoute />} />
          <Route path={routes.ManageBoundaries} element={<ManageBoundaries />} />
          <Route path={routes.ManageBlocks} element={<ManageBlocks />} />
          <Route path={routes.ManageWorkingLines} element={<ManageWorkingLines />} />
          <Route path={routes.ManageHomeStation} element={<ManageHomeStation />} />

          <Route path={routes.BlockDetail} element={<BlockDetail />}>
            <Route
              index
              loader={({ params: { id, blockId } = {} }) => {
                if (!id || !blockId) return null;
                return redirect(generatePath(routes.BlockDetailGeneral, { id, blockId }));
              }}
            />
            <Route path={routes.BlockDetailGeneral} element={<BlockDetailGeneralRoute />} />
            <Route path={routes.BlockDetailTasks} element={<BlockDetailTasksRoute />} />
          </Route>
          <Route
            path={routes.BlockDetailTask}
            loader={({ params: { id, blockId } = {} }) => {
              if (!id || !blockId) return null;
              return FieldTaskPage.getLoader({
                backToResolver: () => generatePath(routes.BlockDetailTasks, { id, blockId }),
              });
            }}
            element={<BlockDetailTaskRoute />}
          />

          <Route path={routes.Robots} element={<Robots />} />
          <Route path={routes.RegisterRobot} element={<RegisterRobot />} />

          <Route path={routes.RobotDetail} element={<Robot />}>
            <Route
              index
              loader={({ params: { id } = {} }) => {
                if (!id) return null;
                return redirect(generatePath(routes.RobotDetailGeneral, { id }));
              }}
            />
            <Route path={routes.RobotDetailGeneral} element={<RobotDetailGeneral />} />
            <Route path={routes.RobotDetailTasks} element={<RobotDetailTasks />} />
          </Route>

          <Route path={routes.CropSheet} element={<CropSheet />}>
            <Route index loader={() => redirect(routes.CropsOverview)} />

            <Route path={routes.CropsOverview} element={<CropsOverview />} />
            <Route path={routes.ThreatsOverview} element={<ThreatsOverview />} />
          </Route>

          <Route path={routes.CropDetail} element={<Crop />}>
            <Route
              index
              loader={({ params: { id } = {} }) => {
                if (!id) return null;
                return redirect(generatePath(routes.AboutCrop, { id }));
              }}
            />
            <Route path={routes.AboutCrop} element={<AboutCrop />} />
            <Route path={routes.CropStages} element={<CropStages />} />
          </Route>

          <Route path={routes.CropThreatsPests} element={<CropThreatsPests />} />
          <Route path={routes.CropThreatsDiseases} element={<CropThreatsDiseases />} />
          <Route path={routes.CropThreatsWeeds} element={<CropThreatsWeeds />} />

          <Route path={routes.ThreatDetail} element={<ThreatDetail />} />

          <Route path={routes.GrowingPlans} element={<GrowingPlans />} />

          <Route path={routes.CreateGrowingPlan} element={<CreateGrowingPlan />} />

          <Route path={routes.GrowingPlanDetail} element={<GrowingPlanDetailRoute />}>
            <Route
              index
              loader={({ params: { id } = {} }) => {
                if (!id) return null;
                return redirect(generatePath(routes.GrowingPlanDetailGeneral, { id }));
              }}
            />
            <Route path={routes.GrowingPlanDetailGeneral} element={<GrowingPlanDetailGeneral />} />
            <Route path={routes.GrowingPlanDetailTimeline} element={<GrowingPlanDetailTimeline />} />
            <Route path={routes.GrowingPlanDetailRobotTasks} element={<GrowingPlanDetailRobotTask />} />
            <Route path={routes.GrowingPlanDetailPlants} element={<GrowingPlanDetailPlantsRoute />} />
          </Route>

          <Route
            path={routes.GrowingPlanDetailRobotTask}
            loader={({ params: { id } = {} }) => {
              if (!id) return null;
              return FieldTaskPage.getLoader({
                backToResolver: () => generatePath(routes.GrowingPlanDetailRobotTasks, { id }),
              });
            }}
            element={<GrowingPlanDetailRobotTaskRoute />}
          />

          <Route
            path={routes.RobotDetailTask}
            loader={({ params: { id } = {} }) => {
              if (!id) return null;
              return FieldTaskPage.getLoader({
                backToResolver: () => generatePath(routes.RobotDetailTasks, { id }),
              });
            }}
            element={<GrowingPlanDetailRobotTaskRoute />}
          />

          <Route path={routes.UserProfile} element={<UserProfile />} />
        </Route>
      </Route>

      <Route path="*" element={<Error404Page />} />
    </Route>,
  ),
);

export const Router = () => {
  return <RouterProvider router={router} />;
};
