import clsx from "clsx";

type SpinnerProps = Omit<JSX.IntrinsicElements["div"], "children">;
export const Spinner = ({ className, ...rest }: SpinnerProps): JSX.Element => {
  return (
    <div {...rest} className={clsx("sk-fold", className)}>
      <div className="sk-fold-cube" />
      <div className="sk-fold-cube" />
      <div className="sk-fold-cube" />
      <div className="sk-fold-cube" />
    </div>
  );
};
