import {
  GrowingPlanState,
  type PlantSpacingGrowingPlanFragment,
  PlantSpacingPattern,
  type RowBasedPlantSpacingInput,
  useCreatePlantSpacingMutation,
  useDeletePlantSpacingMutation,
  usePlantSpacingQuery,
} from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { LoadingContent } from "@/components/LoadingContent";
import { Layer, MapUtils, Source } from "@/components/Map";
import { PlaceholderCard } from "@/components/PlaceholderCard";
import { BlockModel, UncategorizedBlock } from "@/field/blocks/block.model";
import { FieldMap } from "@/field/components/FieldMap";
import type { FieldModel } from "@/field/field.model";
import { useFieldTransformedQuery } from "@/field/useFieldTransformedQuery";
import { segmentIndexToNameMap } from "@/growing-plan/growing-plan-detail/plant-spacing/utils";
import { theme } from "@/theme";
import { notify } from "@/utils/Notifications";
import { formatNumberToSquareMeters } from "@/utils/format";
import { useConfirm } from "@/utils/useConfirm";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { Button, Icon } from "@roboton/ui";
import { useQueryClient } from "@tanstack/react-query";
import { type ComponentProps, Fragment, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGrowingPlanDetailLayoutContext } from "./../GrowingPlanDetailLayout";
import { PlantSpacingSettingsForm } from "./components/PlantSpacingSettingsForm";
import { RemovePlantSpacingConfirmationModal } from "./components/RemovePlantSpacingConfirmationModal";
import { useRowBasedPlantSpacingPreview } from "./hooks/useRowBasedPlantSpacingPreview";

type Props = {
  growingPlanId: string;
};

// Double type check. The form data and query input have the same data.
const INITIAL_PREVIEW_VALUES: RowBasedPlantSpacingInput = {
  spacing: 20,
  pattern: PlantSpacingPattern.Rectangle,
  endMargin: 0,
  selectedRows: [],
} satisfies Parameters<ComponentProps<typeof PlantSpacingSettingsForm>["onSubmit"]>[number];

const Content = ({
  growingPlan,
  field,
  blocks,
  uncategorizedBlock,
}: {
  growingPlan: PlantSpacingGrowingPlanFragment;
  field: FieldModel;
  blocks?: BlockModel[];
  uncategorizedBlock?: UncategorizedBlock;
}) => {
  const { t } = useTranslation();
  const { tEnums } = useTranslationEnums();
  let submitType = useRef<"preview" | "save">("preview").current;

  const { id: growingPlanId } = growingPlan;

  const {
    isOpen: isRemoveModalOpen,
    onConfirm: onRemoveModalConfirm,
    onDecline: onRemoveModalDecline,
    getConfirmation: getRemoveModalConfirmation,
  } = useConfirm();

  const queryClient = useQueryClient();

  const { mutate: deletePlantSpacingMutate, isPending: isDeletingSpacing } = useDeletePlantSpacingMutation({
    onSuccess: (res) => {
      queryClient.setQueryData(usePlantSpacingQuery.getKey({ growingPlanId }), { growingPlan: res.deletePlantSpacing });
      notify.positive(t("The removal was successful."));
    },
  });

  const { mutate: createPlantSpacingMutate, isPending: isCreatingSpacing } = useCreatePlantSpacingMutation({
    onSuccess: () => {
      setIsEditMode(false);

      queryClient.refetchQueries({ queryKey: usePlantSpacingQuery.getKey({ growingPlanId }) });
      notify.positive(t("All changes have been successfully saved."));
    },
  });

  const [isEditMode, setIsEditMode] = useState(false);

  const {
    mutate: previewPlantSpacing,
    data: previewData,
    isPending: isPreviewFetching,
    reset: resetPlantSpacingPreview,
  } = useRowBasedPlantSpacingPreview();

  const plantSpacingData = useMemo(
    () => previewData?.previewRowBasedPlantSpacing ?? growingPlan.plantSpacing,
    [previewData, growingPlan],
  );

  const isFinished = growingPlan.state === GrowingPlanState.Finished;
  const hasPlantSpacing = !!growingPlan.plantSpacing?.id;

  const canRenderPlantSpacingActions = !isFinished;
  const isRemoveSettingsDisabled = !hasPlantSpacing;
  const isSetPlantSpacingDisabled = hasPlantSpacing;

  const handleSetPlantSpacingClick = () => {
    setIsEditMode(true);
    previewPlantSpacing({ growingPlanId, input: INITIAL_PREVIEW_VALUES });
  };

  const saveSettings: ComponentProps<typeof PlantSpacingSettingsForm>["onSubmit"] = (data) => {
    if (submitType === "preview") {
      previewPlantSpacing({
        growingPlanId,
        input: data,
      });
      return;
    }

    createPlantSpacingMutate({
      growingPlanId,
      plantSpacingInput: data,
    });
    resetPlantSpacingPreview();
  };

  const deletePlantSpacing = async () => {
    if (!growingPlan.plantSpacing?.id) return;

    const isConfirmed = await getRemoveModalConfirmation();
    if (!isConfirmed) return;

    deletePlantSpacingMutate({ plantSpacingId: growingPlan.plantSpacing.id });
    resetPlantSpacingPreview();
  };

  const resetPreview = () => {
    setIsEditMode(false);
    resetPlantSpacingPreview();
  };

  const isPending = isPreviewFetching || isDeletingSpacing || isCreatingSpacing;

  return (
    <>
      <h2 className={"sr-only"}>{t("Plant spacing")}</h2>
      <Bricks.Layout
        mapArea={
          <Bricks.MapArea className={"relative h-full"}>
            <FieldMap
              field={field}
              bounds={
                growingPlan.block.segments
                  ? MapUtils.getLngLatBounds(
                      MapUtils.getCoords(growingPlan.block.segments.flatMap((s) => s.boundaries)),
                    )
                  : undefined
              }
            >
              {blocks?.map((block) => {
                return (
                  <Fragment key={block.id}>
                    {block.segmentsForMap.map((segment) => (
                      <Source key={segment.id} {...segment.propsForMap.polygonProps.sourceProps}>
                        <Layer {...segment.propsForMap.polygonProps.layerProps} />
                        <Layer {...segment.propsForMap.lineProps.layerProps} />
                        <Layer {...segment.propsForMap.textProps.layerProps} />
                      </Source>
                    ))}
                  </Fragment>
                );
              })}

              {uncategorizedBlock?.segmentsForMap.map((segment) => {
                return (
                  <Source key={segment.id} {...segment.propsForMap.polygonProps.sourceProps}>
                    <Layer {...segment.propsForMap.polygonProps.layerProps} />
                    <Layer {...segment.propsForMap.lineProps.layerProps} />
                    <Layer {...segment.propsForMap.textProps.layerProps} />
                  </Source>
                );
              })}

              {growingPlan.block.segments.map((segment) => (
                <Source
                  key={segment.id}
                  {...MapUtils.getPolygonSourceProps(segment.id, MapUtils.getCoords(segment.boundaries))}
                >
                  <Layer {...MapUtils.getPolygonLayerProps(segment.id, { color: theme.colors.blue["50"] })} />
                  <Layer {...MapUtils.getTextLayerProps(segment.id, segment.name)} />
                </Source>
              ))}

              {/* Segments of the block */}
              {plantSpacingData?.segments.map((segment) => (
                <Fragment key={segment.segmentId}>
                  <Source
                    {...MapUtils.getPolygonSourceProps(
                      `prev-${segment.segmentId}`,
                      MapUtils.getCoords(segment.innerBoundaries),
                    )}
                  >
                    <Layer
                      {...MapUtils.getPolygonLayerProps(`prev-${segment.segmentId}`, {
                        color: theme.colors.blue["0"],
                        opacity: 0.8,
                      })}
                    />
                  </Source>
                  {segment.rows.map((row, index) => (
                    <Source
                      key={`${segment.segmentId}-row-${index}`}
                      {...MapUtils.getLineSourceProps(
                        `${segment.segmentId}-row-${index}`,
                        MapUtils.getCoords([row.startPoint, row.endPoint]),
                      )}
                    >
                      <Layer
                        {...MapUtils.getLineLayerProps(`${segment.segmentId}-rows`, {
                          color: theme.colors.blue["50"],
                          opacity: 1,
                          width: 1,
                        })}
                      />
                      <Layer {...MapUtils.getTextLayerProps(segment.segmentId, segmentIndexToNameMap[index])} />
                    </Source>
                  ))}
                </Fragment>
              ))}

              {/* Plant on the map */}
              {plantSpacingData?.plants ? (
                <Source
                  id={"plants"}
                  type={"geojson"}
                  data={{
                    type: "FeatureCollection",
                    features: plantSpacingData.plants.map((plant) => ({
                      type: "Feature",
                      properties: {},
                      geometry: { type: "Point", coordinates: [plant.coordinates.lon, plant.coordinates.lat] },
                    })),
                  }}
                >
                  <Layer
                    id={"plant-circle"}
                    type={"circle"}
                    paint={{ "circle-color": theme.colors.blue["50"], "circle-radius": 4 }}
                  />
                </Source>
              ) : null}

              {isPending ? <LoadingContent className={"bg-light-25 absolute inset-0 bg-opacity-60"} /> : null}
            </FieldMap>
          </Bricks.MapArea>
        }
      >
        {growingPlan ? (
          <>
            <Bricks.Card headline={t("Block")}>
              <div className={"prose bg-light-25 flex items-baseline gap-x-4 rounded p-2"}>
                <details className={"flex w-full flex-col gap-2 [&_svg]:open:-rotate-180 group"}>
                  <summary className={"flex justify-between hover:cursor-pointer"}>
                    <strong className={"inline-flex items-center gap-2"}>
                      <span
                        className={"inline-block h-4 w-4 shrink-0 rounded-full"}
                        style={{ background: `#${growingPlan.block.color}` }}
                      />
                      {growingPlan.block.name}
                    </strong>
                    <div className={"flex gap-2 items-center"}>
                      {growingPlan.block.area ? formatNumberToSquareMeters(growingPlan.block.area) : "-"}
                      <Icon type={"down"} className={"svg transition-transform"} />
                    </div>
                  </summary>
                  <div className={"bg-light-0 flex flex-col gap-2 rounded p-2"}>
                    {growingPlan.block.segments.map((segment) => (
                      <div key={segment.id} className={"flex justify-between"}>
                        <div className={"flex items-baseline gap-x-2"}>
                          <span className={"inline-block h-2 w-2 shrink-0 bg-blue-50 bg-opacity-80"} />
                          <strong>{segment.name}</strong>
                        </div>
                        <div>{formatNumberToSquareMeters(segment.area)}</div>
                      </div>
                    ))}
                  </div>
                </details>
              </div>
            </Bricks.Card>
            <Bricks.Card headline={t("Settings")}>
              {!isEditMode ? (
                <>
                  <div className={"prose grid gap-x-4 sm:grid-cols-2"}>
                    <div>
                      <h4>{t("PlantSpacing")}</h4> {growingPlan.plantSpacing?.spacing ?? "-"}
                    </div>
                    <div>
                      <h4>{t("Swath end margin")}</h4> {growingPlan.plantSpacing?.endMargin ?? "-"} cm
                    </div>
                    <div>
                      <h4>{t("Pattern")}</h4>{" "}
                      {growingPlan.plantSpacing?.pattern
                        ? tEnums("plantSpacingPattern", growingPlan.plantSpacing.pattern)
                        : "-"}
                    </div>
                    <div>
                      <h4>{t("Active Rows")}</h4>{" "}
                      {growingPlan.plantSpacing?.selectedRows.map((v) => segmentIndexToNameMap[v]).join(", ") ?? "-"}
                    </div>
                  </div>
                  {canRenderPlantSpacingActions ? (
                    <div className={"flex flex-wrap justify-end gap-4"}>
                      <Button
                        type={"button"}
                        variant={"primary-negative"}
                        onClick={deletePlantSpacing}
                        disabled={isRemoveSettingsDisabled}
                        icon={"trash"}
                      >
                        {t("Remove settings")}
                      </Button>
                      <Button
                        type={"button"}
                        variant={"primary-positive"}
                        onClick={handleSetPlantSpacingClick}
                        disabled={isSetPlantSpacingDisabled}
                      >
                        {t("Set Plant Spacing")}
                      </Button>
                    </div>
                  ) : null}
                </>
              ) : null}
              {isEditMode ? (
                <>
                  <PlantSpacingSettingsForm
                    id={"plant-spacing-settings-form"}
                    defaultValues={INITIAL_PREVIEW_VALUES}
                    onSubmit={saveSettings}
                  />
                  <div className={"flex flex-wrap justify-between gap-2"}>
                    <Button
                      type={"reset"}
                      form={"plant-spacing-settings-form"}
                      variant={"text-base"}
                      onClick={resetPreview}
                    >
                      {t("Cancel")}
                    </Button>
                    <div className={"flex flex-wrap gap-4"}>
                      <Button
                        type={"submit"}
                        form={"plant-spacing-settings-form"}
                        variant={"primary-positive"}
                        onClick={() => {
                          submitType = "preview";
                        }}
                      >
                        {t("Preview settings")}
                      </Button>
                      <Button
                        type={"submit"}
                        form={"plant-spacing-settings-form"}
                        onClick={() => {
                          submitType = "save";
                        }}
                      >
                        {t("Save settings")}
                      </Button>
                    </div>
                  </div>
                </>
              ) : null}
            </Bricks.Card>
            <Bricks.Card headline={t("Summary")}>
              <div className={"prose grid gap-x-4 sm:grid-cols-2"}>
                <div>
                  <h4>{t("Number of plants")}</h4> {growingPlan.plantSpacing?.plantsCount ?? "-"}
                </div>
                <div>
                  <h4>{t("Number of plants per m²")}</h4>
                  {growingPlan.plantSpacing?.plantsCountPerSquareMeter.toFixed(2) ?? "-"}
                </div>
              </div>
            </Bricks.Card>
          </>
        ) : null}
      </Bricks.Layout>
      <RemovePlantSpacingConfirmationModal
        isOpen={isRemoveModalOpen}
        onClose={onRemoveModalDecline}
        onConfirm={onRemoveModalConfirm}
      />
    </>
  );
};
export const GrowingPlanDetailPlantsPage = ({ growingPlanId }: Props) => {
  const { t } = useTranslation();

  // Get field data to display the field shape on the map
  const { remoteGrowingPlan } = useGrowingPlanDetailLayoutContext();
  const {
    data: { field } = {},
    isLoading: isLoadingField,
  } = useFieldTransformedQuery({
    fieldId: remoteGrowingPlan.block.field.id,
  });

  const {
    data: { growingPlan } = {},
    isLoading: isLoadingPlantSpacing,
  } = usePlantSpacingQuery({ growingPlanId });

  const blocks = field?.blocks?.map((block) => new BlockModel(block));
  const uncategorizedBlock = field ? new UncategorizedBlock(field.unassignedSegments) : undefined;

  const hasData = !!field && !!growingPlan;
  const isLoading = isLoadingField || isLoadingPlantSpacing;

  if (isLoading) {
    return <LoadingContent className={"h-full flex-grow"} />;
  }

  if (!hasData) {
    return <PlaceholderCard icon={"information"} description={t("No data")} />;
  }

  return <Content growingPlan={growingPlan} field={field} blocks={blocks} uncategorizedBlock={uncategorizedBlock} />;
};
