import type { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";

type ProtectedLayerProps = {
  isAllowed: boolean;
  redirectTo: string;
  children?: ReactNode;
};

// Mostly known as a ProtectedRoute component. But it's not a Route.
export const ProtectedLayer = ({ isAllowed, redirectTo, children }: ProtectedLayerProps): JSX.Element => {
  if (!isAllowed) {
    return <Navigate to={redirectTo} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
