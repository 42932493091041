import { useUpdateFieldEntryPointMutation } from "@/api/sdk";
import { Bricks } from "@/components/Bricks";
import { DashboardContent } from "@/components/DashboardContent";
import { LoadingContent } from "@/components/LoadingContent";
import { EntryPoint } from "@/field/boundaries/components/EntryPoint";
import { FieldMap } from "@/field/components/FieldMap";
import { PruneFieldConfirmationModal } from "@/field/components/PruneFieldConfirmationModal";
import type { FieldBasicModel } from "@/field/field.model";
import { useFieldBasicTransformedQuery } from "@/field/useFieldBasicTransformedQuery";
import { generatePath, routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { useConfirm } from "@/utils/useConfirm";
import { padWithZeros } from "@roboton/tools";
import { removeDuplicates } from "@roboton/tools/src/removeDuplicates";
import { Button } from "@roboton/ui";
import { useQueryClient } from "@tanstack/react-query";
import type { ComponentProps } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type ContentProps = { field: FieldBasicModel; isEditMode?: boolean; headline: string };

export const SelectEntryPointPageContent = ({ field, headline, isEditMode }: ContentProps) => {
  const { id: fieldId } = field;

  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const fieldDetailPath = generatePath(routes.FieldDetail, { id: fieldId });
  const navigate = useNavigate();

  const points = removeDuplicates(field.coords, ([lon, lat]) => `${lon}${lat}`).map(
    ([lon, lat], index) =>
      ({
        id: `${lon}${lat}`,
        name: padWithZeros(index + 1, 2),
        longitude: lon,
        latitude: lat,
      }) satisfies ComponentProps<typeof EntryPoint.Marker>["point"],
  );

  const [selectedPointId, setSelectedPointId] = useState(points[0]?.id);
  const [entryPointId, setEntryPointId] = useState(points[0]?.id);

  const changeEntryPoint = (id: string) => {
    setEntryPointId(id);
    setSelectedPointId(id);
  };

  const { mutateAsync: updateEntryPoint, isPending: isUpdateEntryPointLoading } = useUpdateFieldEntryPointMutation();

  const saveEntryPoint = async () => {
    const entryPoint = points.find((point) => point.id === entryPointId);
    if (!entryPoint) return;

    try {
      const res = await updateEntryPoint({
        fieldId,
        entryPoint: {
          lon: entryPoint.longitude,
          lat: entryPoint.latitude,
        },
      });

      if (!res.updateFieldEntryPoint.success) {
        notify.negative(t("Something went wrong. Please try again later."));
        return;
      }

      await queryClient.refetchQueries({ queryKey: useFieldBasicTransformedQuery.getKey({ fieldId }) });
      notify.positive(t("All changes have been successfully saved."));

      navigate(fieldDetailPath);
    } catch (e) {
      console.error(e);
    }
  };

  const reverseDirectionOfTravel = async () => {
    const entryPoint = points.find((point) => point.id === entryPointId);
    if (!entryPoint) return;

    const isConfirmed = await getPruneModalConfirmation();
    if (!isConfirmed) return;

    try {
      const res = await updateEntryPoint({
        fieldId,
        entryPoint: {
          lon: entryPoint.longitude,
          lat: entryPoint.latitude,
        },
        reverseDirection: true,
      });

      if (!res.updateFieldEntryPoint.success) {
        notify.negative(t("Something went wrong. Please try again later."));
        return;
      }

      await queryClient.refetchQueries({ queryKey: useFieldBasicTransformedQuery.getKey({ fieldId }) });
      notify.positive(t("All changes have been successfully saved."));

      navigate(fieldDetailPath);
    } catch (e) {
      console.error(e);
    }
  };

  const isSaveDisabled = !entryPointId;

  const {
    isOpen: isPruneModalOpen,
    onConfirm: onPruneModalConfirm,
    onDecline: onPruneModalDecline,
    getConfirmation: getPruneModalConfirmation,
  } = useConfirm();

  const handleSaveClick = async () => {
    if (isEditMode) {
      const isConfirmed = await getPruneModalConfirmation();
      isConfirmed && saveEntryPoint();
      return;
    }
    saveEntryPoint();
  };

  return (
    <DashboardContent
      headline={headline}
      navigateBackTo={fieldDetailPath}
      headerArea={
        <Button type={"button"} disabled={isSaveDisabled} onClick={handleSaveClick} icon={"check"}>
          {t("Save Entry Point")}
        </Button>
      }
    >
      <Bricks.Layout
        mapArea={
          <Bricks.MapArea>
            {isUpdateEntryPointLoading ? <LoadingContent /> : null}
            <FieldMap field={field}>
              {points.map((point) => (
                <EntryPoint.Marker
                  key={point.id}
                  point={point}
                  isSelected={selectedPointId === point.id}
                  isCurrent={entryPointId === point.id}
                  onClick={setSelectedPointId}
                />
              ))}
            </FieldMap>
          </Bricks.MapArea>
        }
        mapAreaSize={"large"}
      >
        <Bricks.Card headline={t("Boundary Points")}>
          <p>{t("The first aiming point was marked as the Entry Point. You can select another Entry Point.")}</p>

          {points.length ? (
            <div className={"flex justify-center"}>
              <Button icon={"reverse"} variant={"primary-positive"} onClick={reverseDirectionOfTravel}>
                {t("Reverse direction of travel")}
              </Button>
            </div>
          ) : null}

          <ul className={"divide-light-50 divide-y overflow-auto"}>
            {points.map((marker) => {
              return (
                <EntryPoint.ListItem
                  key={marker.id}
                  point={marker}
                  isSelected={selectedPointId === marker.id}
                  isCurrent={entryPointId === marker.id}
                  onClick={changeEntryPoint}
                />
              );
            })}
          </ul>
        </Bricks.Card>
      </Bricks.Layout>

      <PruneFieldConfirmationModal
        isOpen={isPruneModalOpen}
        onClose={onPruneModalDecline}
        onConfirm={onPruneModalConfirm}
      />
    </DashboardContent>
  );
};
