import {
  FloatingFocusManager,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import clsx from "clsx";
import { type ReactNode, cloneElement, useEffect, useMemo, useRef, useState } from "react";

type TooltipProps = Omit<JSX.IntrinsicElements["div"], "content"> & {
  size: "large" | "medium" | "small";
  children: Parameters<typeof cloneElement>[0];
  content: ReactNode;
  /*
   * Enable to ensure the floating element remains anchored to its reference element while scrolling or resizing.
   *
   * Warning: autoUpdate is expensive and should only be called when both the reference and floating elements are mounted.
   * If your floating element is NOT conditionally rendered, and instead relies on an opacity or visibility style for
   * instance, use an effect instead of this prop.
   * */
  enableAutoUpdate?: boolean;
};

const sizeToClassNameMap: Record<Required<TooltipProps>["size"], string> = {
  large: "w-[12.5rem]",
  medium: "w-[11.25rem]",
  small: "w-[10rem]",
};

const sizeToArrowStyleMap: Record<Required<TooltipProps>["size"], number> = {
  large: 18,
  medium: 13,
  small: 10,
};

export const Tooltip = ({ size, enableAutoUpdate, className, children, content, ...rest }: TooltipProps) => {
  const arrowRef = useRef(null);
  const arrowSize = sizeToArrowStyleMap[size];

  const [isOpen, setIsOpen] = useState(false);

  const {
    refs,
    strategy,
    middlewareData: {
      arrow: { x: arrowX, y: arrowY } = {},
    },
    placement,
    update,
    context,
    floatingStyles,
  } = useFloating<HTMLDivElement>({
    whileElementsMounted: enableAutoUpdate ? autoUpdate : undefined,
    middleware: [
      // The offset() middleware is one that should be before the most other ones, because they should modify their
      // coordinates based on the offsetted ones.
      // @source: https://floating-ui.com/docs/tutorial#offset-middleware
      offset(arrowSize),
      flip(),
      shift({ padding: 8 }),
      arrow({ element: arrowRef }),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(context);
  const focus = useFocus(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss]);

  useEffect(() => {
    isOpen && update();
  }, [isOpen, update]);

  const staticSide = useMemo(
    () =>
      ({
        top: "bottom",
        right: "left",
        bottom: "top",
        left: "right",
      })[placement.split("-")[0]] as string,
    [placement],
  );

  return (
    <>
      {cloneElement(children, { ref: refs.setReference, ...getReferenceProps() })}

      <FloatingFocusManager context={context} modal={false}>
        <div
          {...rest}
          role={"tooltip"}
          ref={refs.setFloating}
          className={clsx(
            "bg-dark-50 text-light-0 typo-sm max-w-full rounded-lg p-2 text-center",
            isOpen ? "" : "sr-only",
            sizeToClassNameMap[size],
            className,
          )}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {content}

          {/* arrow element */}
          <div
            ref={arrowRef}
            className={"rotate-45 bg-[inherit]"}
            style={{
              position: strategy,
              left: arrowX != null ? `${arrowX}px` : "auto",
              top: arrowY != null ? `${arrowY}px` : "auto",
              right: "auto",
              bottom: "auto",
              [staticSide]: `-${arrowSize / 2}px`,
              width: `${arrowSize}px`,
              height: `${arrowSize}px`,
            }}
            aria-hidden
          />
        </div>
      </FloatingFocusManager>
    </>
  );
};
