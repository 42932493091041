import { useCreateFieldMutation, useRobotAssignmentsQuery, useSoilTypesQuery } from "@/api/sdk";
import { Card } from "@/components/Card";
import { DashboardContent } from "@/components/DashboardContent";
import { generatePath, routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { useAppTitle } from "@/utils/useAppTitle";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FieldForm } from "./components/FieldForm";

export const CreateFieldPage = () => {
  const { t } = useTranslation();
  useAppTitle(t("Create Field"));
  const navigate = useNavigate();

  const { mutate } = useCreateFieldMutation({
    onSuccess: ({ createField: { success, field } }) => {
      if (!success) return;
      notify.positive(t("New field successfully created."));

      const redirectPath = field ? generatePath(routes.ManageBoundaries, { id: field.id }) : routes.Fields;
      navigate(redirectPath);
    },
  });

  const handleSubmit: ComponentProps<typeof FieldForm>["onSubmitValid"] = (formInputs) => {
    const robotId =
      formInputs.cultivationMethod !== FieldForm.CULTIVATION_METHOD_MANUALLY_VALUE
        ? formInputs.cultivationMethod
        : undefined;

    mutate({
      fieldInput: {
        irrigation: formInputs.irrigation,
        lighting: formInputs.lighting,
        name: formInputs.name,
        nitrogen: formInputs.soilNitrogen,
        ph: formInputs.soilPhLevel,
        phosphorus: formInputs.soilPhosphorus,
        potassium: formInputs.soilPotassium,
        soilStatus: formInputs.soilStatus,
        soilType: formInputs.soilType,
        robotAssignmentId: robotId,
      },
    });
  };

  const {
    data: { soilTypes } = {},
    isLoading: isLoadingSoilTypes,
  } = useSoilTypesQuery(undefined);
  const {
    data: { robotAssignments } = {},
    isLoading: isLoadingRobotAssignments,
  } = useRobotAssignmentsQuery(undefined);

  const isLoading = isLoadingSoilTypes || isLoadingRobotAssignments;

  const options: ComponentProps<typeof FieldForm>["options"] = {
    soilTypes: soilTypes?.map(({ code, name }) => ({ value: code, label: name })) ?? [],
    robots: robotAssignments?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
  };

  return (
    <DashboardContent headline={t("Create Field")} navigateBackTo={routes.Fields} isLoading={isLoading}>
      <Card className={"mx-auto min-h-[30rem] w-[40rem] max-w-full"}>
        <FieldForm submitButtonLabel={t("Create New Field")} options={options} onSubmitValid={handleSubmit} />
      </Card>
    </DashboardContent>
  );
};
