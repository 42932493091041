import type { ErrorCode, PreviewSegmentsQuery, PreviewSegmentsQueryVariables } from "@/api/sdk";
import { PreviewSegmentsDocument } from "@/api/sdk";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { fetcher } from "../../../../api/fetcher";

export const usePreviewSegments = <TData = PreviewSegmentsQuery, TError = { message: string; code: ErrorCode }>(
  options?: UseMutationOptions<PreviewSegmentsQuery, TError, PreviewSegmentsQueryVariables, TData>,
) =>
  useMutation<PreviewSegmentsQuery, TError, PreviewSegmentsQueryVariables, TData>({
    mutationKey: ["previewPlantSpacing"],
    mutationFn: (variables?: PreviewSegmentsQueryVariables) =>
      fetcher<PreviewSegmentsQuery, PreviewSegmentsQueryVariables>(PreviewSegmentsDocument, variables)(),
    ...options,
  });
