import { logger } from "@/logger";

export function createStepsArray(min: number, max: number, step: number): number[] {
  if (max < min) {
    logger.error("Min must be less than max", { min, max, step });
    return [];
  }

  if (step <= 0) {
    logger.error("Step must be greater than zero", { min, max, step });
    return [];
  }

  const stepsCount = Math.floor((max - min) / step) + 1;

  const steps = Array(stepsCount)
    .fill(0)
    .map((_, index) => min + index * step);

  if (steps[0] !== min) {
    steps.unshift(min);
  }

  if (steps[steps.length - 1] !== max) {
    steps.push(max);
  }

  return steps;
}
