export const authStorage = window.localStorage;

export const authStorageKey = "@roboton-cloud-webapp/auth-token";

// Get authorization token directly from the storage.
export const getAuthToken = (): string | null => {
  try {
    const token = authStorage.getItem(authStorageKey);
    return typeof token === "string" && token.length ? JSON.parse(token) : null;
  } catch (e) {
    return null;
  }
};

// Write authorization token directly to the storage.
export const setAuthToken = (value: string): void => {
  try {
    authStorage.setItem(authStorageKey, JSON.stringify(value));
  } catch (e) {}
};

export const removeAuthToken = (): void => {
  try {
    authStorage.removeItem(authStorageKey);
  } catch (e) {}
};
