import {
  CultivationMethod,
  type GrowingPlanCardFragment,
  type GrowingPlanOrder,
  GrowingPlanState,
  type GrowingPlansQuery,
  type Ordering,
  useGrowingPlansQuery,
  useIsFieldTaskPossibleQuery,
} from "@/api/sdk";
import { Card } from "@/components/Card";
import { DashboardContent } from "@/components/DashboardContent";
import { Pagination, usePaginationState } from "@/components/Pagination";
import { PlaceholderCard } from "@/components/PlaceholderCard";
import { useCreateMultitaskMutation, useCreateTaskMutation } from "@/field-task/apiHooks";
import { CreateNewMultiTaskModal } from "@/field-task/components/CreateNewMultiTaskModal";
import type { CreateNewTaskForm } from "@/field-task/components/CreateNewTaskForm";
import { CreateNewTaskModal } from "@/field-task/components/CreateNewTaskModal";
import { useFieldTaskTypesTransformedQuery } from "@/field-task/useFieldTaskTypesTransformedQuery";
import { fieldTaskUtils } from "@/field-task/utils";
import { GrowingPlanUtils } from "@/growing-plan/utils";
import { routes } from "@/routes";
import { useAppTitle } from "@/utils/useAppTitle";
import { useConfirm } from "@/utils/useConfirm";
import { useSorting } from "@/utils/useSorting";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { Button, Checkbox, Input, Popover, Select } from "@roboton/ui";
import { keepPreviousData } from "@tanstack/react-query";
import clsx from "clsx";
import { type ComponentProps, type Dispatch, type SetStateAction, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GrowingPlanCard } from "./components/GrowingPlanCard/GrowingPlanCard";
import { GrowingPlanCardControlLayout } from "./components/GrowingPlanCard/GrowingPlanCardLayout";
import { PerPageSelect, usePerPage } from "./components/PerPageSelect";
import { FiltersStoreProvider, useFiltersStore } from "./filters/FiltersStore";
import { BlocksAutosuggestion, CropsAutosuggestion, FieldsAutosuggestion } from "./filters/autosuggestions";
import { FilterButton } from "./filters/ui/FilterButton";
import { SortButton } from "./filters/ui/SortButton";

type SortByValue = keyof Required<GrowingPlanOrder>;

type FieldsContentProps = JSX.IntrinsicElements["div"] & {
  remoteGrowingPlans: GrowingPlansQuery["growingPlanFilter"];
  isFetching: boolean;
  sort: {
    by: SortByValue;
    direction: Ordering;
  } | null;
  perPageSlot: JSX.Element;
  onSortClick: (sortBy: SortByValue) => void;
  onPageClick: ComponentProps<typeof Pagination>["onClick"];
  selectedPlansIds: GrowingPlanCardFragment["id"][];
  onSelectedPlansChange: Dispatch<SetStateAction<GrowingPlanCardFragment["id"][]>>;
};

const GrowingPlansContent = ({
  remoteGrowingPlans,
  isFetching,
  sort,
  perPageSlot,
  selectedPlansIds,
  onSelectedPlansChange,
  onPageClick,
  onSortClick,
  className,
  ...rest
}: FieldsContentProps) => {
  const { t } = useTranslation();
  const { tEnums } = useTranslationEnums();
  const { pageInfo, totalCount, nodes: growingPlans } = remoteGrowingPlans;
  const { page, lastPage } = pageInfo;

  const getSortButtonProps = (sortBy: SortByValue) => {
    return {
      isActive: sort?.by === sortBy,
      direction: sort?.by === sortBy ? sort.direction : undefined,
      onClick: () => onSortClick(sortBy),
    } satisfies ComponentProps<typeof SortButton>;
  };

  const selectablePlansIds = growingPlans.flatMap((growingPlan) =>
    GrowingPlanUtils.canCreateTask(growingPlan) ? [growingPlan.id] : [],
  );

  const handlePlanCheckboxClick = (_checked: boolean, growingPlan: GrowingPlanCardFragment) => {
    onSelectedPlansChange((prevSelectedPlans) => {
      const isCheckboxChecked = prevSelectedPlans?.includes(growingPlan.id);
      const newSelectedPlans = isCheckboxChecked
        ? prevSelectedPlans.filter((id) => id !== growingPlan.id)
        : [...(prevSelectedPlans || []), growingPlan.id];
      return newSelectedPlans;
    });
  };

  const handleSelectAllPlansClick: JSX.IntrinsicElements["input"]["onChange"] = (event) => {
    const newSelectedPlans = event.currentTarget.checked ? selectablePlansIds : [];
    onSelectedPlansChange(newSelectedPlans);
  };

  const filterStore = useFiltersStore();

  const isSelectAllIndeterminate = selectedPlansIds?.length > 0 && selectedPlansIds?.length < selectablePlansIds.length;
  const isAnyPlanSelectable = !!selectablePlansIds.length;
  const isSelectAllChecked = !!selectedPlansIds?.length && selectedPlansIds?.length === selectablePlansIds.length;

  return (
    <Card {...rest} as={"section"} className={clsx("space-y-6", className)}>
      <div className={"relative flex items-center justify-between gap-2"}>
        <p>{t("Count of selected plans: {{ total }}", { total: selectedPlansIds.length })}</p>

        <LoaderIcon className={clsx("ml-auto h-6 w-6 shrink-0", !isFetching && "opacity-0")} />
        {perPageSlot}
      </div>

      <GrowingPlanCardControlLayout
        checkbox={
          <Checkbox
            id={"select-growing-plans"}
            checked={isSelectAllChecked}
            indeterminate={isSelectAllIndeterminate}
            disabled={!isAnyPlanSelectable}
            onChange={handleSelectAllPlansClick}
          />
        }
        crop={
          <>
            <SortButton {...getSortButtonProps("crop")} />
            {t("Crop")}
            <Popover size={"small"} content={<CropsAutosuggestion />}>
              <FilterButton isActive={filterStore.isFilterActive("cropName")} />
            </Popover>
          </>
        }
        state={
          <>
            <SortButton {...getSortButtonProps("state")} />
            {t("State")}
            <Popover
              size={"small"}
              content={
                <Select
                  id={"state-filter"}
                  value={filterStore.values.state || ""}
                  onChange={(event) => {
                    filterStore.actions.setState(event.currentTarget.value as GrowingPlanState);
                  }}
                >
                  <option value={""}>{t("All")}</option>
                  {Object.values(GrowingPlanState).map((state) => (
                    <option key={state} value={state}>
                      {tEnums("growingPlanState", state)}
                    </option>
                  ))}
                </Select>
              }
            >
              <FilterButton isActive={filterStore.isFilterActive("state")} />
            </Popover>
          </>
        }
        type={
          <>
            <SortButton {...getSortButtonProps("cultivation")} />
            {t("Type")}
            <Popover
              size={"small"}
              content={
                <Select
                  id={"cultivation-filter"}
                  value={filterStore.values.cultivation || ""}
                  onChange={(event) => {
                    filterStore.actions.setCultivation(event.currentTarget.value as CultivationMethod);
                  }}
                >
                  <option value={""}>{t("All")}</option>
                  {Object.values(CultivationMethod).map((cultivation) => (
                    <option key={cultivation} value={cultivation}>
                      {tEnums("cultivationMethod", cultivation)}
                    </option>
                  ))}
                </Select>
              }
            >
              <FilterButton isActive={filterStore.isFilterActive("cultivation")} />
            </Popover>
          </>
        }
        robot={
          <>
            <SortButton {...getSortButtonProps("robot")} />
            {t("Robot")}
          </>
        }
        field={
          <>
            <SortButton {...getSortButtonProps("field")} />
            {t("Field")}
            <Popover size={"small"} content={<FieldsAutosuggestion />}>
              <FilterButton isActive={filterStore.isFilterActive("field")} />
            </Popover>
          </>
        }
        block={
          <>
            <SortButton {...getSortButtonProps("block")} />
            {t("Block")}
            <Popover size={"small"} content={<BlocksAutosuggestion />}>
              <FilterButton isActive={filterStore.isFilterActive("block")} />
            </Popover>
          </>
        }
        start={
          <>
            <SortButton {...getSortButtonProps("startAt")} />
            {t("Start")}
            <Popover
              size={"small"}
              content={
                <div>
                  <Input
                    id={"start-at--filter"}
                    type={"date"}
                    value={filterStore.values.startAt || ""}
                    onChange={(event) => {
                      filterStore.actions.setStartAt(event.target.value);
                    }}
                  />
                  <Button
                    type={"button"}
                    size={"small"}
                    variant={"primary-negative"}
                    onClick={() => {
                      filterStore.actions.setStartAt(undefined);
                    }}
                  >
                    {t("Clear")}
                  </Button>
                </div>
              }
            >
              <FilterButton isActive={filterStore.isFilterActive("startAt")} />
            </Popover>
          </>
        }
        end={
          <>
            <SortButton {...getSortButtonProps("endAt")} />
            {t("End")}
            <Popover
              size={"small"}
              content={
                <div>
                  <Input
                    id={"end-at-filter"}
                    type={"date"}
                    value={filterStore.values.endAt || ""}
                    onChange={(event) => {
                      filterStore.actions.setEndAt(event.target.value);
                    }}
                  />
                  <Button
                    type={"button"}
                    size={"small"}
                    variant={"primary-negative"}
                    onClick={() => {
                      filterStore.actions.setEndAt(undefined);
                    }}
                  >
                    {t("Clear")}
                  </Button>
                </div>
              }
            >
              <FilterButton isActive={filterStore.isFilterActive("endAt")} />
            </Popover>
          </>
        }
      />

      <div className={"flex flex-col gap-4"}>
        {totalCount > 0 ? (
          growingPlans.map((growingPlan) => (
            <GrowingPlanCard
              key={growingPlan.id}
              growingPlan={growingPlan}
              isSelected={selectedPlansIds?.includes(growingPlan.id)}
              onCheckboxChange={selectablePlansIds?.includes(growingPlan.id) ? handlePlanCheckboxClick : undefined}
            />
          ))
        ) : (
          <PlaceholderCard variant={"flat"} icon={"calendar"} description={"No Growing Plans"} className={"py-20"} />
        )}
      </div>

      <Pagination currentPage={page} pageCount={lastPage} onClick={onPageClick} />
    </Card>
  );
};

const Inner = () => {
  const { t } = useTranslation();
  const headline = t("Growing Plans");
  useAppTitle(headline);

  const { setSorting, sortState } = useSorting<SortByValue>(null);
  const {
    apiFilter,
    actions: { resetAll },
    isFilterActive,
  } = useFiltersStore();

  const [page, setPage] = usePaginationState();
  const { perPage, getSelectProps } = usePerPage();
  const {
    data: { growingPlanFilter: growingPlans } = {},
    isLoading: isGrowingPlansLoading,
    isFetching: isGrowingPlansListFetching,
  } = useGrowingPlansQuery(
    {
      paginationInput: { page, perPage },
      order: sortState ? { [sortState.by]: sortState.direction } : null,
      filters: apiFilter,
    },
    {
      placeholderData: keepPreviousData,
    },
  );
  const [selectedPlansIds, setSelectedPlansIds] = useState<GrowingPlanCardFragment["id"][]>([]);
  const isAnyPlanSelected = !!selectedPlansIds.length;

  const selectedPlans = growingPlans?.nodes.filter((plan) => selectedPlansIds.includes(plan.id));

  const hasData = !!growingPlans?.nodes;

  const {
    isOpen: isCreateTaskModalOpen,
    onConfirm: onCreateTaskModalConfirm,
    onDecline: onCreateTaskModalDecline,
    getConfirmation: getCreateTaskModalConfirmation,
  } = useConfirm();

  const {
    isOpen: isCreateMultiTaskModalOpen,
    onConfirm: onCreateMultiTaskModalConfirm,
    onDecline: onCreateMultiTaskModalDecline,
    getConfirmation: getCreateMultiTaskModalConfirmation,
  } = useConfirm();

  const { mutate: createTasks } = useCreateTaskMutation({ onAfterSuccess: onCreateTaskModalConfirm });
  const { mutate: createMultiTask } = useCreateMultitaskMutation({ onAfterSuccess: onCreateMultiTaskModalConfirm });

  const {
    data: { parametrization = [] } = {},
  } = useFieldTaskTypesTransformedQuery({ availableJustForBlock: false });

  const handleCreateTaskClick = () => getCreateTaskModalConfirmation();
  const handleCreateMultiTaskClick = () => getCreateMultiTaskModalConfirmation();

  const handleCreateNewTaskFormSuccessSubmit: ComponentProps<typeof CreateNewTaskForm>["onSuccessSubmit"] = (data) => {
    createTasks({
      fieldTaskInput: fieldTaskUtils.transformNewTaskFormDataToFieldTaskInput(data, parametrization),
    });
  };

  const handleCreateNewMultiTaskFormSuccessSubmit: ComponentProps<typeof CreateNewMultiTaskModal>["onSuccessSubmit"] = (
    data,
  ) => {
    createMultiTask({
      fieldTaskInput: fieldTaskUtils.transformMultitaskFormDataToFieldTaskInput(data, parametrization),
    });
  };

  const { isMultipleTaskPossible, isMultipleTaskPossibleLoading } = useIsMultipleTaskPossible(
    (selectedPlans || [])?.map((plan) => plan.block.id),
  );
  const isMultipleTaskDisabled = isMultipleTaskPossibleLoading || !isMultipleTaskPossible;

  const entitiesForTaskCreation = (selectedPlans || []).map((plan) => ({
    id: plan.id,
    name: plan.name,
    entityType: "growingPlan" as const,
    selectedRows: plan.plantSpacing?.selectedRows,
    fieldId: plan.field.id,
    blockId: plan.block.id,
  })) satisfies ComponentProps<typeof CreateNewMultiTaskModal>["entities"];

  return (
    <DashboardContent
      headline={headline}
      headerArea={
        <div className={"flex flex-wrap gap-2 md:gap-4"}>
          <Button
            type={"button"}
            icon={"plus"}
            variant={"primary-positive"}
            size={"small"}
            disabled={!isAnyPlanSelected}
            onClick={handleCreateTaskClick}
          >
            {t("New Field Task")}
          </Button>

          <Button
            type={"button"}
            icon={"plus"}
            variant={"primary-positive"}
            size={"small"}
            disabled={isMultipleTaskDisabled}
            onClick={handleCreateMultiTaskClick}
          >
            {t("New Field MultiTask")}
          </Button>

          <Button as={Link} to={routes.CreateGrowingPlan} icon={"plus"}>
            {t("New Growing Plan")}
          </Button>
        </div>
      }
      isLoading={isGrowingPlansLoading}
    >
      {hasData ? (
        <>
          <GrowingPlansContent
            remoteGrowingPlans={growingPlans}
            perPageSlot={
              <>
                <Button
                  disabled={!isFilterActive()}
                  size={"small"}
                  className={"self-end"}
                  type={"button"}
                  onClick={() => resetAll()}
                >
                  {t("Reset all filters")}
                </Button>
                <PerPageSelect
                  {...getSelectProps()}
                  label={t("Items per page")}
                  size={"small"}
                  id={"growing-plans-per-page"}
                />
              </>
            }
            onPageClick={setPage}
            onSelectedPlansChange={setSelectedPlansIds}
            selectedPlansIds={selectedPlansIds}
            isFetching={isGrowingPlansListFetching}
            sort={sortState}
            onSortClick={setSorting}
          />
          <CreateNewTaskModal
            entities={entitiesForTaskCreation}
            isOpen={isCreateTaskModalOpen}
            onClose={onCreateTaskModalDecline}
            onSuccessSubmit={handleCreateNewTaskFormSuccessSubmit}
          />
          <CreateNewMultiTaskModal
            entities={entitiesForTaskCreation}
            isOpen={isCreateMultiTaskModalOpen}
            onClose={onCreateMultiTaskModalDecline}
            onSuccessSubmit={handleCreateNewMultiTaskFormSuccessSubmit}
          />
        </>
      ) : (
        <PlaceholderCard icon={"calendar"} description={"No Growing Plans"} className={"py-20"} />
      )}
    </DashboardContent>
  );
};

export const GrowingPlansPage = () => {
  return (
    <FiltersStoreProvider>
      <Inner />
    </FiltersStoreProvider>
  );
};

const useIsMultipleTaskPossible = (blockIds: string[]) => {
  const {
    data: { isFieldTaskPossible } = {},
    isPending: isMultipleTaskPossibleLoading,
  } = useIsFieldTaskPossibleQuery(
    { blockIds: blockIds },
    {
      enabled: blockIds.length > 0,
    },
  );

  return {
    isMultipleTaskPossible: isFieldTaskPossible ? isFieldTaskPossible.possible : blockIds.length > 0,
    isMultipleTaskPossibleLoading,
  };
};
