import {
  type FieldForEditPageQuery,
  type FieldForEditPageQueryVariables,
  type SoilTypesQuery,
  useFieldForEditPageQuery,
  useSoilTypesQuery,
  useUpdateFieldMutation,
} from "@/api/sdk";
import { Card } from "@/components/Card";
import { DashboardContent } from "@/components/DashboardContent";
import { generatePath, routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { useAppTitle } from "@/utils/useAppTitle";
import { type ComponentProps, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FieldForm } from "./components/FieldForm";

/*
---------------------------------------------------------------------
Content with form
---------------------------------------------------------------------
 */
type ContentProps = {
  remoteField: FieldForEditPageQuery["field"];
  fieldId: FieldForEditPageQueryVariables["fieldId"];
  soilTypes: SoilTypesQuery["soilTypes"];
};

const Content = ({ remoteField, fieldId, soilTypes }: ContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const afterSuccessPath = useMemo(() => generatePath(routes.FieldDetail, { id: fieldId }), [fieldId]);

  const defaultValues: ComponentProps<typeof FieldForm>["defaultValues"] = {
    irrigation: remoteField.irrigation,
    lighting: remoteField.lighting,
    name: remoteField.name,
    soilNitrogen: remoteField.nitrogen ?? undefined,
    soilPhLevel: remoteField.ph,
    soilPhosphorus: remoteField.phosphorus ?? undefined,
    soilPotassium: remoteField.potassium ?? undefined,
    soilStatus: remoteField.soilStatus,
    soilType: remoteField.soilType,
    cultivationMethod: remoteField.robotAssignment?.id ?? FieldForm.CULTIVATION_METHOD_MANUALLY_VALUE,
  };

  const { mutate } = useUpdateFieldMutation({
    onSuccess: ({ updateField: { success } }) => {
      if (!success) return;
      notify.positive(t("All changes have been successfully saved."));
      navigate(afterSuccessPath);
    },
  });

  const handleSubmit: ComponentProps<typeof FieldForm>["onSubmitValid"] = (formInputs) => {
    mutate({
      fieldId,
      fieldInput: {
        irrigation: formInputs.irrigation,
        lighting: formInputs.lighting,
        name: formInputs.name,
        nitrogen: formInputs.soilNitrogen,
        ph: formInputs.soilPhLevel,
        phosphorus: formInputs.soilPhosphorus,
        potassium: formInputs.soilPotassium,
        soilStatus: formInputs.soilStatus,
        soilType: formInputs.soilType,
      },
    });
  };

  const options = {
    soilTypes: soilTypes.map(({ code, name }) => ({ value: code, label: name })),
    robots: remoteField.robotAssignment
      ? [{ value: remoteField.robotAssignment.id, label: remoteField.robotAssignment.name }]
      : [],
  };

  return (
    <FieldForm
      defaultValues={defaultValues}
      options={options}
      disabledFields={["cultivationMethod"]}
      submitButtonLabel={t("Save Field")}
      onSubmitValid={handleSubmit}
    />
  );
};

/*
---------------------------------------------------------------------
Page
---------------------------------------------------------------------
 */
export const EditFieldPage = ({ fieldId }: { fieldId: string }) => {
  const { t } = useTranslation();
  useAppTitle(t("Edit Field"));

  const fieldDetailPath = generatePath(routes.FieldDetail, { id: fieldId });
  const {
    data: { field } = {},
    isLoading: isFieldLoading,
  } = useFieldForEditPageQuery({ fieldId }, { gcTime: 0 });
  const {
    data: { soilTypes } = {},
    isLoading: isSoilTypesLoading,
  } = useSoilTypesQuery(undefined, { gcTime: 0 });

  const hasData = soilTypes && field;
  const isLoading = isFieldLoading || isSoilTypesLoading;

  return (
    <DashboardContent headline={t("Edit Field")} navigateBackTo={fieldDetailPath} isLoading={isLoading}>
      <Card className={"relative mx-auto min-h-[30rem] w-[40rem] max-w-full"}>
        {hasData ? <Content remoteField={field} fieldId={fieldId} soilTypes={soilTypes} /> : <>No data</>}
      </Card>
    </DashboardContent>
  );
};
