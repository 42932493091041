import type { MapComponent } from "@/components/Map";
import type { DrawControl } from "@/components/Map/DrawControl";
import type { Coords, FeatureWithId, Polygon } from "@/components/Map/types";
import type { BlockModel } from "@/field/blocks/block.model";
import { useBlocks } from "@/field/blocks/utilities/useBlocks";
import type MapboxDraw from "@mapbox/mapbox-gl-draw";
import { type ComponentProps, useCallback, useRef, useState } from "react";

type BlockFeatureProperties = {
  blockId: string;
  blockColor: string;
  blockFormattedColor: string;
  blockName: string;
  isBlockNew: boolean;
};

export type BlockFeature = FeatureWithId<Polygon, BlockFeatureProperties>;
type DrawControlProps = ComponentProps<typeof DrawControl<BlockFeature["geometry"], BlockFeatureProperties>>;

type Options = {
  fieldId: string;
  fieldCoords: Coords[];
  fieldArea: number;
};

/**
 * Wrapper around useBlocks hook with additional logic for map drawing
 */
export const useBlocksDraw = (sourceBlocks: BlockModel[], options: Options) => {
  const { fieldCoords, fieldArea } = options;

  const {
    isTouched,
    isBlockBoundariesTouched,

    blocks,
    updateBlockName,
    updateBlockColor,
    updateBlockBoundaries,
    createBlock,
    removeBlock,

    onBlocksSuccessSave,
  } = useBlocks(sourceBlocks);

  const isAnyBlockNew = blocks.some((block) => block.isNew);

  const [initialFeatures] = useState(() => blocks.flatMap((block) => (block.hasBoundaries ? [block.mapFeature] : [])));

  const mapDrawRef = useRef<MapboxDraw>();

  const updateBlockOnMap = useCallback((block: BlockModel) => {
    const mapDraw = mapDrawRef.current;
    if (!mapDraw) return;

    const feature = block.mapFeature;

    // add feature to the mapDraw instance if it doesn't exist
    const exist = mapDraw.get(feature.id);
    !exist && mapDraw.add(feature);

    // update feature properties in the mapDraw instance
    for (const [key, value] of Object.entries(feature)) {
      mapDraw.setFeatureProperty(feature.id, key, value);
    }

    // force mapDraw update if change comes from outside the map
    mapDraw.add(feature);
  }, []);

  const createBlockBasedOnFieldCoords = useCallback(async () => {
    // setIsTouched(true);
    const segmentId = new Date().toISOString();
    const swathId = `${segmentId}-swath`;
    const newBlock = createBlock({
      segments: [
        {
          id: segmentId,
          boundaries: fieldCoords.map(([lon, lat]) => ({ lat, lon })),
          name: "New segment",
          area: fieldArea,
          swath: { id: swathId },
          startPoint: null,
          endPoint: null,
          order: 1,
        },
      ],
    });
    updateBlockOnMap(newBlock);
  }, [fieldArea, createBlock, fieldCoords, updateBlockOnMap]);

  // Remove feature
  const removeFeature = useCallback(async (id: string) => {
    const mapDraw = mapDrawRef.current;
    if (!mapDraw) return;

    // remove feature from the mapDraw instance
    mapDraw.delete(id);

    // add default block to prevent empty map and to help user to start drawing without drawing a field shape
    const totalFeatures = mapDraw.getAll().features.length || 0;

    if (totalFeatures < 1) {
      // workaround to force re-render mapDraw to update when user click on the trash icon
      const allFeatures = mapDraw.getAll();
      mapDraw.deleteAll();
      mapDraw.set(allFeatures);
    }
  }, []);

  // MapDraw component props
  const getMapDrawProps = () => {
    const handleCreate: Required<DrawControlProps>["onCreate"] = ({ features }) => {
      for (const feature of features) {
        const segmentId = new Date().toISOString();
        const swathId = `${segmentId}-swath`;
        const block = createBlock({
          id: feature.id,
          segments: [
            {
              id: segmentId,
              boundaries: feature.geometry.coordinates[0].map(([lon, lat]) => ({ lat, lon })),
              name: "New segment",
              area: 0,
              swath: {
                id: swathId,
              },
              startPoint: null,
              endPoint: null,
              order: 1,
            },
          ],
        });
        // update feature properties in the mapDraw instance
        updateBlockOnMap(block);
      }
    };

    const handleChange: Required<DrawControlProps>["onCreate"] = ({ features }) => {
      for (const feature of features) {
        updateBlockBoundaries(
          feature.id,
          feature.geometry.coordinates[0].map(([lon, lat]) => ({ lat, lon })),
        );
      }
    };

    return {
      onCreate: handleCreate,
      onUpdate: handleChange,
      onLoad: (mapDraw) => {
        mapDrawRef.current = mapDraw;
      },
      onDelete: (e) => {
        for (const feature of e.features) {
          removeFeature(feature.id);
        }
      },
    } satisfies DrawControlProps;
  };

  // add polygons from props to the map draw
  const getMapProps = () =>
    ({
      onLoad: () => mapDrawRef.current?.add({ type: "FeatureCollection", features: initialFeatures }),
    }) satisfies ComponentProps<typeof MapComponent>;

  return {
    isTouched,
    isStructureTouched: isBlockBoundariesTouched || isAnyBlockNew,
    onBlocksSuccessSave,

    blocks,

    createBlockBasedOnFieldCoords,
    removeBlock: (block: BlockModel) => {
      removeBlock(block);
      removeFeature(block.id);
    },
    updateBlockColor: (blockId: string, color: string) => updateBlockColor(blockId, color, updateBlockOnMap),
    updateBlockName: (blockId: string, name: string) => updateBlockName(blockId, name, updateBlockOnMap),

    getMapDrawProps,
    getMapProps,
  };
};
