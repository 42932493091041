import clsx from "clsx";
import type { ReactNode } from "react";
import { Icon, type IconType } from "../Icon/Icon";

type NotificationProps = JSX.IntrinsicElements["div"] & {
  color: "green" | "blue" | "yellow" | "red" | "gray";
  icon?: IconType;
  headline?: ReactNode;
  onClose: JSX.IntrinsicElements["button"]["onClick"];
  a11y: {
    closeLabel: string;
  };
};

const colorToClassMap: Record<Required<NotificationProps>["color"], string> = {
  green: "bg-brand-0 text-brand-100",
  blue: "bg-blue-0 text-blue-100",
  yellow: "bg-gold-0 text-gold-100",
  red: "bg-red-0 text-red-100",
  gray: "bg-light-50 text-dark-100",
};

const colorToIconClassMap: Record<Required<NotificationProps>["color"], string> = {
  green: "text-brand-25",
  blue: "text-blue-50",
  yellow: "text-gold-50",
  red: "text-red-50",
  gray: "text-dark-0",
};

export const Notification = ({
  color,
  icon,
  headline,
  a11y,
  onClose,
  className,
  children,
  ...rest
}: NotificationProps) => {
  return (
    <div
      {...rest}
      className={clsx(
        "flex w-full max-w-[20rem] items-start gap-2.5 rounded-lg",
        colorToClassMap[color],
        icon ? "p-2" : "px-4 py-2",
        className,
      )}
    >
      {icon ? <Icon type={icon} className={clsx("h-6 w-6 shrink-0", colorToIconClassMap[color])} aria-hidden /> : null}
      <div className={"relative"}>
        <div className={"typo-md--bold"}>{headline}</div>
        <p className={"typo-sm"}>{children}</p>
      </div>

      <button
        type={"button"}
        className={clsx("ml-auto mt-0.5", icon ? "" : "-mr-1.5")}
        aria-label={a11y.closeLabel}
        onClick={onClose}
      >
        <Icon type={"close"} className={clsx("h-4 w-4 shrink-0", colorToIconClassMap[color])} aria-hidden />
      </button>
    </div>
  );
};
