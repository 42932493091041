export function removeDuplicates<T, K>(arr: T[], keyFn: (item: T) => K): T[] {
  const seenValues = new Set();
  const uniqueItems: T[] = [];

  for (const item of arr) {
    const key = keyFn(item);

    if (!seenValues.has(key)) {
      seenValues.add(key);
      uniqueItems.push(item);
    }
  }

  return uniqueItems;
}
