import { logger } from "@/logger";
import type { DefaultError, QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useState } from "react";

export const useQueryRefetch = <TData = unknown, TError = DefaultError>(
  refetchFn: (options?: RefetchOptions) => Promise<QueryObserverResult<TData, TError>>,
) => {
  const [isRefetching, setIsRefetching] = useState(false);
  const handleRefetch = async () => {
    setIsRefetching(true);
    try {
      await refetchFn();
    } catch (e) {
      logger.error(e);
    }
    setIsRefetching(false);
  };

  return {
    isRefetching,
    handleRefetch,
  };
};
