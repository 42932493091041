import type { FieldBasicModelFragment, FieldModelFragment } from "@/api/sdk";
import { CultivationMethod, FieldState } from "@/api/sdk";
import { MapUtils } from "@/components/Map";
import type { Coords } from "@/components/Map/types";

type RemoteFieldBasic = FieldBasicModelFragment;
type RemoteField = FieldModelFragment;

const GEOFENCING_COLOR = "#fdad62";
const FIELD_COLOR = "#b2b2b2";

export class FieldBasicModel<T extends RemoteFieldBasic = RemoteFieldBasic> {
  protected readonly remoteField: T;
  constructor(remoteField: T) {
    this.remoteField = remoteField;
  }

  get id() {
    return this.remoteField.id;
  }

  get isCultivatedByRobot() {
    return this.remoteField.cultivation === CultivationMethod.Autonomus;
  }

  get canBoundariesBeEdited() {
    if (this.isCultivatedByRobot) return false;
    // just manually cultivated fields can be edited
    return this.remoteField.state === FieldState.Active;
  }

  get cultivationMethod() {
    return this.remoteField.cultivation;
  }

  get boundaries() {
    return (this.isCultivatedByRobot ? this.remoteField.importedBoundaries : this.remoteField.boundaries) || [];
  }

  get hasBoundaries() {
    return !!this.boundaries?.length;
  }

  get hasHomeStation() {
    return !!this.remoteField.homeStation?.id;
  }

  get coords() {
    return MapUtils.getCoords(this.boundaries || []);
  }

  get homeStation() {
    if (!this.remoteField.homeStation) {
      return null;
    }

    return {
      id: this.remoteField.homeStation.id,
      pathId: this.remoteField.homeStation.homeStationPath.id,
      name: this.remoteField.homeStation.gpsTrace.name,
      coords: [this.remoteField.homeStation.coordinates.lon, this.remoteField.homeStation.coordinates.lat] as Coords,
      pathCoords: MapUtils.getCoords(this.remoteField.homeStation.homeStationPath.pathCoordinates),
    };
  }

  get geofencing() {
    if (!this.remoteField.geofencing?.length) return undefined;
    const id = `${this.id}-geofencing`;
    return {
      propsForMap: {
        polygon: {
          sourceProps: MapUtils.getPolygonSourceProps(id, MapUtils.getCoords(this.remoteField.geofencing || [])),
          layerProps: MapUtils.getPolygonLayerProps(id, { color: GEOFENCING_COLOR, opacity: 1 }),
        },
      },
    };
  }

  get hasSwaths() {
    return typeof this.remoteField.swathAngle === "number" || !!this.remoteField.swathPathSegment?.id;
  }

  get details() {
    const { name, area, soilStatus, soilType, ph, nitrogen, phosphorus, potassium, irrigation, lighting } =
      this.remoteField;
    return { name, area: area ?? 0, soilStatus, soilType, ph, nitrogen, phosphorus, potassium, irrigation, lighting };
  }

  get state() {
    return this.remoteField.state;
  }

  get swathAngle() {
    return this.remoteField.swathAngle;
  }

  get swathPathSegment() {
    return this.remoteField.swathPathSegment;
  }

  get propsForMap() {
    return {
      polygon: {
        sourceProps: MapUtils.getPolygonSourceProps(this.id, this.coords),
        layerProps: MapUtils.getPolygonLayerProps(this.id, { color: FIELD_COLOR, opacity: 1 }),
      },
    };
  }

  get bounds() {
    return MapUtils.getLngLatBounds(this.coords);
  }
}

export class FieldModel extends FieldBasicModel {
  constructor(protected readonly remoteField: RemoteField) {
    super(remoteField);
  }

  get blocks() {
    return this.remoteField.blocks || [];
  }

  get hasBlocks() {
    return !!this.blocks?.length;
  }

  get unassignedSegments() {
    return this.remoteField.unassignedSegments || [];
  }

  get shouldManageSegments() {
    return this.remoteField.state === FieldState.Swathed;
  }

  get canManageBlocks() {
    if (this.isCultivatedByRobot) {
      return this.remoteField.state === FieldState.Active;
    }
    return this.remoteField.state === FieldState.Active || this.remoteField.state === FieldState.Surveyed;
  }

  get robotId() {
    return this.remoteField.robotAssignment?.id;
  }
}
