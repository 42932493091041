import { PlantSpacingPattern } from "@/api/sdk";
import { segmentIndexToNameMap } from "@/growing-plan/growing-plan-detail/plant-spacing/utils";
import { formatToRequiredString } from "@/utils/format";
import { FormField, Input, Message, Select, Toggle } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

/*
---------------------------------------------------------------------
Helper functions
---------------------------------------------------------------------
 */
const getFormFieldProps = (errorMessage: string | undefined): ComponentProps<typeof FormField> => {
  return errorMessage ? { messages: [{ key: "error", children: errorMessage, variant: "alert" }] } : {};
};

/*
---------------------------------------------------------------------
Primary Component
---------------------------------------------------------------------
 */
export type PlantSpacingSettingsFormValues = {
  spacing: number;
  endMargin: number;
  pattern: PlantSpacingPattern;
  selectedRows: number[];
};

type Props = {
  id: string;
  onSubmit: (data: PlantSpacingSettingsFormValues) => void;
  defaultValues: PlantSpacingSettingsFormValues;
};

export const PlantSpacingSettingsForm = ({ id, defaultValues, onSubmit }: Props) => {
  const { register, formState, handleSubmit } = useForm<PlantSpacingSettingsFormValues>({
    defaultValues,
  });
  const { errors } = formState;
  const { t } = useTranslation();

  // omit rowWidth from the data, it's not needed
  const handleSuccessSubmit = (data: PlantSpacingSettingsFormValues) => {
    onSubmit({
      ...data,
      // values in the array are strings. It's a default type which input returns and this
      // is the quickest way to convert it to numbers
      selectedRows: (data.selectedRows as unknown as string[]).map((value) => Number.parseInt(value)),
    });
  };

  return (
    <form id={id} onSubmit={handleSubmit(handleSuccessSubmit)} className={"grid-cols-2 grid gap-x-4 gap-y-2"}>
      <FormField {...getFormFieldProps(errors.spacing?.message)}>
        <Input
          {...register("spacing", {
            valueAsNumber: true,
            required: t("This field is required"),
          })}
          type={"number"}
          id={"input-spacing"}
          label={formatToRequiredString(t("Plant spacing (1 cm to 200 cm)"))}
          state={errors.spacing && "negative"}
          min={1}
          max={200}
          aria-invalid={!!errors.spacing}
        />
      </FormField>
      <FormField {...getFormFieldProps(errors.pattern?.message)}>
        <Select
          {...register("pattern", {
            required: t("This field is required"),
          })}
          id={"input-pattern"}
          label={formatToRequiredString(t("Plant pattern"))}
          state={errors.pattern && "negative"}
          aria-invalid={!!errors.pattern}
        >
          {Object.values(PlantSpacingPattern).map((pattern) => (
            <option key={pattern} value={pattern}>
              {t(pattern)}
            </option>
          ))}
        </Select>
      </FormField>

      <FormField {...getFormFieldProps(errors.endMargin?.message)} className={"col-span-full"}>
        <Input
          {...register("endMargin", {
            valueAsNumber: true,
            required: t("This field is required"),
          })}
          type={"number"}
          id={"input-endMargin"}
          label={formatToRequiredString(t("Swath end margin (0 cm to 100 cm)"))}
          state={errors.endMargin && "negative"}
          min={0}
          max={100}
          aria-invalid={!!errors.endMargin}
        />
      </FormField>

      <fieldset className={"col-span-full"}>
        <div>{t("Active Rows")} *</div>
        <div className={"grid grid-cols-2 gap-x-4 gap-y-2"}>
          {segmentIndexToNameMap.map((value, index) => (
            <Toggle
              {...register("selectedRows", { validate: (v) => v.length > 0 || t("Select at least one row") })}
              id={`row-${value}`}
              label={`${t("Row")} ${segmentIndexToNameMap[index]}`}
              key={value}
              value={index}
            />
          ))}
        </div>
        {errors.selectedRows ? (
          <Message color={"red"} className={"mt-2"}>
            {errors.selectedRows.message}
          </Message>
        ) : null}
      </fieldset>
    </form>
  );
};
