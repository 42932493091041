import { ManageHomeStationPage } from "@/field/home-station/ManageHomeStationPage";
import type { routes } from "@/routes";
import { type ParamParseKey, useParams } from "react-router-dom";

const ManageHomeStationRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.ManageHomeStation>>();
  if (!id) throw new Error("Missing ID");

  return <ManageHomeStationPage fieldId={id} />;
};

export default ManageHomeStationRoute;
