import { useThreatQuery } from "@/api/sdk";
import { DashboardContent } from "@/components/DashboardContent";
import { PrimitiveInfoList } from "@/components/PrimitiveInfoList";
import { routes } from "@/routes";
import { resolveImage } from "@/utils/image";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";

const useThreatTransformedQuery = (...args: Parameters<typeof useThreatQuery>) =>
  useThreatQuery(args[0], {
    ...args[1],
    select: (data) => ({
      ...data,
      threat: {
        ...data.threat,
        threatenedCrops: data.threat.threatenedCrops.map((crop) => ({
          ...crop,
          mainImage: resolveImage(crop.mainImage),
        })),
      },
    }),
  });

export const ThreatDetailPage = ({ threatId }: { threatId: string }) => {
  const { t } = useTranslation();
  const {
    data: { threat } = {},
    isLoading,
  } = useThreatTransformedQuery({ threatId });

  const threatOverviewPath = generatePath(routes.ThreatsOverview);

  const images = [threat?.mainImage, ...(threat?.images ?? [])];

  return (
    <DashboardContent headline={threat?.commonName} isLoading={isLoading} navigateBackTo={threatOverviewPath}>
      {threat ? (
        <>
          <h2 className={"typo-h2 sr-only"}>{threat.commonName}</h2>
          <div className={"flex flex-col gap-4 lg:grid lg:grid-cols-[320px_1fr]"}>
            {images.length ? (
              <div className={"space-y-4"}>
                {images.map((image) => {
                  if (!image) return null;
                  return (
                    <img
                      key={image.id}
                      src={image.url}
                      alt={image.alt ?? ""}
                      className={"mx-auto h-auto w-full max-w-[180px] object-contain sm:max-h-[20rem] lg:max-w-full"}
                    />
                  );
                })}
              </div>
            ) : null}

            <article className={"flex flex-col gap-12 lg:gap-16"}>
              {/* basic info */}
              <section className={"flex flex-col gap-6"}>
                <h3 className={"typo-h3"}>{threat.commonName}</h3>

                <div className={"flex flex-col gap-4 lg:grid lg:grid-cols-2"}>
                  <div>
                    <div className={"font-bold"}>{t("Scientific Name")}</div>
                    <div>{threat.scientificName}</div>
                  </div>
                  <div>
                    <div className={"font-bold"}>{t("Alternative Names")}</div>
                    <div>{threat.alternativeNames?.length ? threat.alternativeNames.join(", ") : "-"}</div>
                  </div>
                </div>

                <div>
                  <div className={"font-bold"}>{t("Description and ecology")}</div>
                  <div className={"prose"} dangerouslySetInnerHTML={{ __html: threat.description || "-" }} />
                </div>
              </section>

              {threat.threatenedCrops.length ? (
                <section className={"flex flex-col gap-6"}>
                  <h3 id={"threatened-by-title"} className={"typo-h3"}>
                    {t("Threatened Crops")}
                  </h3>

                  <PrimitiveInfoList.Root aria-labelledby={"threatened-by-title"}>
                    {threat.threatenedCrops.map((crop) => {
                      const cropDetailPath = generatePath(routes.CropDetail, { id: crop.id });
                      return (
                        <PrimitiveInfoList.Item
                          key={crop.id}
                          image={crop.mainImage}
                          itemTitle={crop.commonName}
                          to={cropDetailPath}
                          linkText={t("View Crop")}
                        />
                      );
                    })}
                  </PrimitiveInfoList.Root>
                </section>
              ) : null}
            </article>
          </div>
        </>
      ) : null}
    </DashboardContent>
  );
};
