import { useCreateClientAccountMutation } from "@/api/sdk";
import { Card } from "@/components/Card";
import { DashboardContent } from "@/components/DashboardContent";
import { routes } from "@/routes";
import { notify } from "@/utils/Notifications";
import { useAppTitle } from "@/utils/useAppTitle";
import type { Exact } from "@roboton/tools";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClientAccountForm } from "./components/ClientAccountForm";

export const CreateClientAccountPage = () => {
  const { t } = useTranslation();
  useAppTitle(t("Create Client Account"));
  const navigate = useNavigate();

  const { mutate } = useCreateClientAccountMutation({
    onSuccess: ({ createClientAccount: { success } }) => {
      if (!success) return;
      notify.positive(t("New client account successfully created."));
      notify.warning(t("We are in the process of processing your request. This can take up to two days."));
      navigate(routes.ClientAccounts);
    },
  });

  const handleSubmit: ComponentProps<typeof ClientAccountForm>["onSubmitValid"] = (formInputs) => {
    const input: Exact<typeof formInputs, Parameters<typeof mutate>[0]["input"]> = formInputs;
    mutate({ input });
  };

  return (
    <DashboardContent headline={t("Create Client Account")} navigateBackTo={".."}>
      <Card className={"mx-auto max-w-full md:max-w-2xl"}>
        <ClientAccountForm submitButtonText={"Create"} onSubmitValid={handleSubmit} />
      </Card>
    </DashboardContent>
  );
};
