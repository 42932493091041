import { Ordering as SortDirection } from "@/api/sdk";
import { useCallback, useState } from "react";

type SortState<T> = {
  by: T;
  direction: SortDirection;
};

type SortingHandler<T> = {
  sortState: SortState<T> | null;
  setSorting: (option: T) => void;
};

// Switch between ascending and descending order for the same option, reset to null when option was changed or previous order was descending.
const useSorting = <T>(initialValue: SortState<T> | null): SortingHandler<T> => {
  const [sortState, setSortState] = useState(initialValue);

  const setSorting = useCallback((option: T) => {
    setSortState((prevSortState) => {
      const isSameOption = prevSortState?.by === option;
      const shouldReset = isSameOption && prevSortState?.direction === SortDirection.Desc;

      return shouldReset
        ? null
        : {
            by: option,
            direction:
              prevSortState?.by !== option
                ? SortDirection.Asc
                : prevSortState?.direction === SortDirection.Asc
                  ? SortDirection.Desc
                  : SortDirection.Asc,
          };
    });
  }, []);

  return {
    sortState,
    setSorting,
  };
};

export { useSorting, SortDirection };
