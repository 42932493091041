import { routes } from "@/routes";
import { Button } from "@roboton/ui";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const SuccessContent = () => {
  const { t } = useTranslation("public");
  return (
    <>
      <h1 className={"typo-h1 text-center"}>{t("We have sent you an e-mail with a password.")}</h1>

      <p>{t("Click on the link in the email message to verify your email address.")}</p>
      <Button as={Link} to={routes.Home} className={"w-full"}>
        {t("Continue to Log in ➔")}
      </Button>
    </>
  );
};
