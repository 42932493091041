import { formatCoordinates } from "@/utils/format";
import { Button, Icon } from "@roboton/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Marker as MarkerComponent } from "react-map-gl/maplibre";

type EntryPointProps<T extends string> = {
  point: { id: T; name: string; latitude: number; longitude: number };
  isSelected: boolean;
  isCurrent: boolean;
  onClick?: (id: T) => void;
};

/*
---------------------------------------------------------------------
MARKER
---------------------------------------------------------------------
 */
const Marker = <T extends string>(props: EntryPointProps<T>) => {
  const handleClick = () => props.onClick?.(props.point.id);
  return (
    <MarkerComponent
      longitude={props.point.longitude}
      latitude={props.point.latitude}
      onClick={handleClick}
      style={{
        zIndex: props.isSelected ? 1 : "auto",
      }}
    >
      <div
        className={clsx(
          props.isSelected ? "h-[2rem] min-w-[2rem] p-1" : "h-[1.5rem] min-w-[1.5rem] p-1",
          props.isCurrent ? "bg-blue-50" : "bg-brand-50",
          props.onClick ? "hover:cursor-pointer" : "",
          "text-light-0 flex items-center justify-center rounded-full font-bold",
        )}
      >
        {props.point.name}
      </div>
    </MarkerComponent>
  );
};

/*
---------------------------------------------------------------------
List Item
---------------------------------------------------------------------
 */
export const ListItem = <T extends string>(props: EntryPointProps<T>) => {
  const { t } = useTranslation();
  const handleClick = () => props.onClick?.(props.point.id);
  return (
    <li
      key={props.point.id}
      className={clsx(
        "relative flex flex-col items-center justify-between gap-4 p-4 sm:flex-row md:flex-col lg:flex-row",
        props.isSelected &&
          "before:bg-brand-50 before:absolute before:left-0 before:top-0 before:h-full before:w-[0.25rem]",
      )}
    >
      <div className={"flex min-w-0 max-w-full flex-col justify-center"}>
        <strong>{props.point.name}</strong>
        <p className={"typo-sm truncate"}>
          {formatCoordinates({
            lat: props.point.latitude,
            lon: props.point.longitude,
          })}
        </p>
      </div>

      {props.isCurrent ? (
        <div
          className={
            "text-light-0 typo-sm--bold inline-flex shrink-0 items-center gap-1 rounded-lg bg-blue-50 px-2 py-1"
          }
        >
          <Icon type={"check"} className={"h-4 w-4"} />
          {t("Entry Point")}
        </div>
      ) : (
        <Button
          type={"button"}
          variant={"primary-positive"}
          size={"small"}
          className={"shrink-0"}
          onClick={handleClick}
        >
          {t("Mark as Entry Point")}
        </Button>
      )}
    </li>
  );
};

export const EntryPoint = { Marker, ListItem };
