import { type BlockForDetailPageQuery, useBlockForDetailPageQuery } from "@/api/sdk";
import { DashboardContent } from "@/components/DashboardContent";
import {
  type FieldTaskTypesTransformedQuery,
  useFieldTaskTypesTransformedQuery,
} from "@/field-task/useFieldTaskTypesTransformedQuery";
import { FieldBasicModel } from "@/field/field.model";
import { useAppTitle } from "@/utils/useAppTitle";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useOutletContext } from "react-router-dom";
import { BlockDetailTabs } from "./BlockDetailTabs";

type BlockDetailLayoutContext = {
  remoteBlock: BlockForDetailPageQuery["block"];
  remoteTasks: BlockForDetailPageQuery["blockTasks"];
  fieldTaskTypes: FieldTaskTypesTransformedQuery["fieldTaskTypes"];
  parametrization: FieldTaskTypesTransformedQuery["parametrization"];
  onTasksChange: () => void;
};

export const useBlockDetailLayoutContext = () => {
  return useOutletContext<BlockDetailLayoutContext>();
};

export const BlockDetailLayout = ({ id, blockId }: Pick<ComponentProps<typeof BlockDetailTabs>, "id" | "blockId">) => {
  const { t } = useTranslation();

  const {
    data: { block: remoteBlock, blockTasks: remoteTasks } = {},
    refetch,
    isLoading: isLoadingBlock,
  } = useBlockForDetailPageQuery({
    blockId,
  });

  const {
    data: { fieldTaskTypes, parametrization } = {},
    isLoading: isLoadingFieldTaskTypes,
  } = useFieldTaskTypesTransformedQuery({ availableJustForBlock: true });

  const headline = remoteBlock ? t("Block {{ name }}", { name: remoteBlock.name }) : t("Block");
  useAppTitle(headline);

  const isLoading = isLoadingBlock || isLoadingFieldTaskTypes;

  const field = remoteBlock ? new FieldBasicModel(remoteBlock?.field) : null;

  return (
    <DashboardContent headline={headline} isLoading={isLoading}>
      <BlockDetailTabs id={id} blockId={blockId} hideTasks={!field?.isCultivatedByRobot}>
        {remoteBlock && remoteTasks && fieldTaskTypes && parametrization ? (
          <Outlet
            context={
              {
                remoteBlock,
                remoteTasks,
                fieldTaskTypes,
                parametrization,
                onTasksChange: refetch,
              } satisfies BlockDetailLayoutContext
            }
          />
        ) : null}
      </BlockDetailTabs>
    </DashboardContent>
  );
};
