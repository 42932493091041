import { Layer, MapComponent, Source } from "@/components/Map";
import type { LngLatBoundsLike } from "mapbox-gl";
import type { ComponentProps } from "react";
import type { FieldBasicModel } from "../field.model";

export const FieldMap = ({
  field,
  children,
  bounds,
  ...rest
}: { field: FieldBasicModel } & Omit<ComponentProps<typeof MapComponent>, "initialViewState" | "enableControls"> & {
    bounds?: LngLatBoundsLike;
  }) => {
  return (
    <MapComponent
      {...rest}
      initialViewState={{ bounds: bounds || field.bounds, fitBoundsOptions: { padding: 20 } }}
      enableControls
    >
      {field.geofencing ? (
        <Source {...field.geofencing.propsForMap.polygon.sourceProps}>
          <Layer {...field.geofencing.propsForMap.polygon.layerProps} />
        </Source>
      ) : null}
      <Source {...field.propsForMap.polygon.sourceProps}>
        <Layer {...field.propsForMap.polygon.layerProps} />
      </Source>

      {children}
    </MapComponent>
  );
};
