import {
  type FieldTaskBatchInput,
  type FieldTaskInput,
  FieldTaskParametrizationType,
  type PlantSpacingPattern,
} from "@/api/sdk";
import type { CreateNewMultiTaskModal } from "@/field-task/components/CreateNewMultiTaskModal";
import type { ParametrizationFieldsetData } from "@/field-task/components/create-task/ParametrizationFieldset";
import type { StartFieldsetData } from "@/field-task/components/create-task/StartFieldset";
import { scalarUtils } from "@/utils/scalars";
import type { ComponentProps } from "react";
import type { CreateNewTaskForm } from "./components/CreateNewTaskForm";

const _transformStarFieldsetData = (data: StartFieldsetData) => {
  const startAtISOString =
    data.startAtDate && data.startAtTime ? new Date(`${data.startAtDate}T${data.startAtTime}`).toISOString() : null;
  return {
    startOption: data.startOption,
    startAtTime: startAtISOString ? scalarUtils.toTime(startAtISOString) : null,
    startAtDate: startAtISOString ? scalarUtils.toDate(startAtISOString) : null,
  };
};

const _transformParametrization = (
  procedureParametrization: ParametrizationFieldsetData | undefined,
  parametrization: { code: string; type: FieldTaskParametrizationType }[],
) => {
  return procedureParametrization
    ? Object.entries(procedureParametrization).map(([code, value]) => {
        const type = parametrization?.find((param) => param.code === code)?.type;
        return {
          code,
          boolValue: typeof value === "boolean" && type === FieldTaskParametrizationType.Bool ? value : null,
          floatValue: typeof value === "number" && type === FieldTaskParametrizationType.Float ? value : null,
          intValue: typeof value === "number" && type === FieldTaskParametrizationType.Int ? value : null,
          stringValue: typeof value === "string" && type === FieldTaskParametrizationType.String ? value : null,
          intListValue: Array.isArray(value) && type === FieldTaskParametrizationType.IntList ? value : null,
          patternValue:
            typeof value === "string" && type === FieldTaskParametrizationType.PlantSpacingPattern
              ? (value as PlantSpacingPattern)
              : null,
        };
      })
    : null;
};

const transformNewTaskFormDataToFieldTaskInput = (
  data: Parameters<ComponentProps<typeof CreateNewTaskForm>["onSuccessSubmit"]>[0],
  parametrization: { code: string; type: FieldTaskParametrizationType }[],
): FieldTaskBatchInput => {
  return {
    start: _transformStarFieldsetData(data),

    procedures: data.procedures.map((procedure) => ({
      growingPlanId: procedure.entity.entityType === "growingPlan" ? procedure.entity.id : null,
      blockId: procedure.entity.entityType === "block" ? procedure.entity.id : null,
      type: procedure.operation.taskType,
      parametrization: _transformParametrization(procedure.parametrization, parametrization),
    })),
  };
};

const transformMultitaskFormDataToFieldTaskInput = (
  data: Parameters<ComponentProps<typeof CreateNewMultiTaskModal>["onSuccessSubmit"]>[0],
  parametrization: { code: string; type: FieldTaskParametrizationType }[],
): FieldTaskInput => {
  return {
    start: _transformStarFieldsetData(data),
    procedures: data.entities.flatMap((entity) => {
      return entity.procedures.map((procedure) => {
        return {
          growingPlanId: entity.id,
          type: procedure.taskType,
          parametrization: _transformParametrization(procedure.parametrization, parametrization),
        };
      });
    }),
  };
};

export const fieldTaskUtils = {
  transformNewTaskFormDataToFieldTaskInput,
  transformMultitaskFormDataToFieldTaskInput,
};
