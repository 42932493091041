import { RouterTabs } from "@/components/RouterTabs";
import { generatePath, routes } from "@/routes";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type GrowingPlanDetailTabsProps = Omit<ComponentProps<typeof RouterTabs>, "id" | "items" | "size" | "panelProps"> & {
  growingPlanId: string;
  hasRobot: boolean;
};

export const GrowingPlanDetailTabs = ({ growingPlanId, hasRobot, children, ...rest }: GrowingPlanDetailTabsProps) => {
  const { t } = useTranslation();

  const defaultItems = useMemo(
    () => [
      {
        id: "general",
        label: t("General"),
        path: generatePath(routes.GrowingPlanDetailGeneral, { id: growingPlanId }),
      },
      {
        id: "timeline",
        label: t("Timeline"),
        path: generatePath(routes.GrowingPlanDetailTimeline, { id: growingPlanId }),
      },
    ],
    [t, growingPlanId],
  );

  const itemsWithRobot = useMemo(
    () => [
      {
        id: "robotTasks",
        label: t("Robot Tasks"),
        path: generatePath(routes.GrowingPlanDetailRobotTasks, { id: growingPlanId }),
      },
      {
        id: "plant-spacing",
        label: t("Plant Spacing"),
        path: generatePath(routes.GrowingPlanDetailPlants, { id: growingPlanId }),
      },
    ],
    [t, growingPlanId],
  );

  const items = useMemo(
    () => [...defaultItems, ...(hasRobot ? itemsWithRobot : [])],
    [defaultItems, hasRobot, itemsWithRobot],
  );

  return (
    <RouterTabs
      {...rest}
      id={"growing-plan-detail-tabs"}
      items={items}
      size={"large"}
      panelProps={{ className: "mt-4 grow flex flex-col relative" }}
    >
      {children}
    </RouterTabs>
  );
};
