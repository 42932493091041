import { CropDetailLayout } from "@/crop-sheet/crop-detail/CropDetailLayout";
import type { routes } from "@/routes";
import type { ParamParseKey } from "react-router-dom";
import { useParams } from "react-router-dom";

const CropRoute = () => {
  const { id } = useParams<ParamParseKey<typeof routes.CropDetail>>();
  if (!id) throw new Error("Missing ID");

  return <CropDetailLayout cropId={id} />;
};

export default CropRoute;
