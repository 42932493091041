import { initSentry } from "@/utils/monitoring";
import { App } from "App";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

initSentry();

const container = document.getElementById("root") as HTMLElement;
createRoot(container).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
