import { Button, Input } from "@roboton/ui";
import clsx from "clsx";
import { type SubmitErrorHandler, type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
};

type RegisterFormProps = Omit<JSX.IntrinsicElements["form"], "onSubmit"> & {
  onSubmitValid: SubmitHandler<FormInputs>;
  onSubmitInvalid?: SubmitErrorHandler<FormInputs>;
};

const emailRegex =
  /(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const RegisterForm = ({ onSubmitValid, onSubmitInvalid, className, ...rest }: RegisterFormProps) => {
  const { t } = useTranslation("public");
  const { formState, handleSubmit, register } = useForm<FormInputs>();
  const { errors } = formState;

  return (
    <form {...rest} onSubmit={handleSubmit(onSubmitValid, onSubmitInvalid)} className={clsx(className, "space-y-4")}>
      <Input
        {...register("firstName", { minLength: 1, maxLength: 30, required: true, pattern: /^[a-zA-ZÀ-ž]+$/ })}
        id={"register-firstname"}
        label={t("First Name")}
        state={errors.firstName && "negative"}
        required
        autoComplete={"given-name"}
        aria-invalid={!!errors.firstName}
      />
      <Input
        {...register("lastName", { minLength: 1, maxLength: 30, required: true, pattern: /^[a-zA-ZÀ-ž]+$/ })}
        id={"register-lastname"}
        label={t("Last Name")}
        state={errors.lastName && "negative"}
        required
        autoComplete={"family-name"}
        aria-invalid={!!errors.lastName}
      />
      <Input
        {...register("email", { required: true, pattern: emailRegex })}
        id={"register-email"}
        label={t("E-mail")}
        type={"email"}
        state={errors.email && "negative"}
        required
        autoComplete={"email"}
        aria-invalid={!!errors.email}
      />
      <Input
        {...register("phone", {
          required: true,
          // Matches a valid e.164 format telephone number. The same regex is used in the backend.
          // @source https://ihateregex.io/expr/e164-phone/
          pattern: /^\+[1-9]\d{1,14}$/,
        })}
        id={"register-phone"}
        label={t("Phone")}
        state={errors.phone && "negative"}
        required
        autoComplete={"tel"}
        aria-invalid={!!errors.phone}
      />

      <Button type={"submit"} className={"w-full"}>
        Create a New Profile
      </Button>
    </form>
  );
};
