import { ConditionStatus } from "@/api/sdk";
import { Button, Modal } from "@roboton/ui";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import type { GrowingPlanTransformedQuery } from "../../useGrowingPlanTransformedQuery";
import { transformRemoteGrowingPlanConditionTypeToTableCell } from "./../../utils";
import { groupStagesByConditions } from "./utils";

const mappedConditionStatusToClassName = {
  [ConditionStatus.Critical]: "bg-red-0 text-red-50",
  [ConditionStatus.Warning]: "bg-gold-0 text-gold-50",
  [ConditionStatus.Normal]: "",
} satisfies Record<ConditionStatus, string>;

type CropStagesProps = JSX.IntrinsicElements["div"] & {
  cropStages: GrowingPlanTransformedQuery["growingPlan"]["stages"];
};

type ModalState = {
  isOpen: boolean;
  content: {
    conditionName: string;
    cropStageName: string;
    range?: {
      label: string;
      value: string;
    };
    reduceDays?: {
      value: number;
      valuePercentage: string;
      date: string;
      status: ConditionStatus;
    }[];
  };
};

const modalDefaultState = {
  isOpen: false,
  content: {
    conditionName: "",
    cropStageName: "",
  },
} satisfies ModalState;

export const CropStagesTable = ({ cropStages }: CropStagesProps) => {
  const { t } = useTranslation();

  const [modalState, setModalState] = useState<ModalState>(modalDefaultState);
  const handleModalOpen = (content: ModalState["content"]) =>
    setModalState((state) => ({ ...state, content, isOpen: true }));

  const handleCloseModal = () => {
    setModalState(modalDefaultState);
  };

  const mappedCropStagesForTable =
    // Split conditions into two groups: optimals and simulateds
    groupStagesByConditions(cropStages)
      // Resolve values for each condition
      .map((item) => {
        const stages = [...item.stages];
        return {
          ...item,
          stages: stages.map((stage) => {
            return {
              ...stage,
              condition: stage.condition ? transformRemoteGrowingPlanConditionTypeToTableCell(stage.condition) : null,
              className: mappedConditionStatusToClassName[stage.condition?.status || ConditionStatus.Normal],
            };
          }),
        };
      });

  const handleClickInfo = (
    stage: (typeof mappedCropStagesForTable)[number]["stages"][number],
    type: "simulated" | "optimal",
  ) => {
    handleModalOpen({
      conditionName: stage.condition?.name || "",
      cropStageName: stage.name,
      range: {
        label: type === "simulated" ? t("Simulated Range") : t("Optimal Range"),
        value: (type === "simulated" ? stage.condition?.simulated : stage.condition?.optimal) || "-",
      },
      reduceDays: stage.condition?.reduceDays?.map((day) => {
        return {
          valuePercentage: day.yieldDecrementPercentageFormatted,
          value: day.metricFormatted,
          date: day.dateFormatted,
          status: day.status,
        };
      }),
    });
  };

  return (
    <>
      <Modal
        isOpen={modalState.isOpen}
        size={"medium"}
        a11y={{
          dialogId: "cell-detail-dialog",
          closeButtonLabel: t("Close"),
        }}
        heading={modalState.content.conditionName}
        variant={"base"}
        onClose={handleCloseModal}
        footerChildren={
          <Button icon={"check"} onClick={handleCloseModal}>
            {t("OK, close")}
          </Button>
        }
      >
        {t("Crop stage")}: {modalState.content.cropStageName}
        <br />
        {modalState.content.range?.label}: {modalState.content.range?.value}
        <br />
        {modalState.content.reduceDays?.map((day) => {
          return (
            <Fragment key={`${day.date}${day.value}${day.status}`}>
              <span className={mappedConditionStatusToClassName[day.status]}>
                {t("{{ percentage }} for {{ name }} {{ value }} on {{ date }}", {
                  percentage: day.valuePercentage,
                  name: modalState.content.conditionName,
                  value: day.value,
                  date: day.date,
                })}
              </span>
              <br />
            </Fragment>
          );
        })}
      </Modal>
      <table cellPadding={6}>
        <thead>
          <tr>
            <th className={"invisible"} colSpan={2} aria-hidden />
            {cropStages.map((stage) => {
              return <th key={stage.id}>{stage.name}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {mappedCropStagesForTable.map(({ condition, stages }) => {
            return (
              <Fragment key={condition.type}>
                <tr className={"odd:bg-light-25 even:bg-light-50"}>
                  <td rowSpan={2} className={"bg-dark-75 text-light-0 whitespace-nowrap uppercase"}>
                    {condition.name}
                  </td>
                  <td className={"bg-dark-75 text-light-0 whitespace-nowrap"}>{t("Optimal")}</td>
                  {stages.map((stage) => {
                    return (
                      <td key={stage.id} className={clsx("whitespace-nowrap", stage.className)}>
                        {typeof stage.condition?.optimal === "string" ? (
                          <>
                            <span className={"align-middle"}>{stage.condition?.optimal}</span>
                            <Button
                              type={"button"}
                              size={"small"}
                              variant={"text-base"}
                              icon={"information"}
                              className={"ml-1 align-middle"}
                              onClick={() => handleClickInfo(stage, "optimal")}
                            />
                          </>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
                <tr className={"odd:bg-light-25 even:bg-light-50"}>
                  <td className={"bg-dark-75 text-light-0 whitespace-nowrap"}>{t("My Simulated Data")}</td>
                  {stages.map((stage) => {
                    return (
                      <td key={stage.id} className={clsx("whitespace-nowrap", stage.className)}>
                        {typeof stage.condition?.simulated === "string" ? (
                          <>
                            <span className={"align-middle"}>{stage.condition?.simulated}</span>
                            <Button
                              type={"button"}
                              size={"small"}
                              variant={"text-base"}
                              icon={"information"}
                              className={"ml-1 align-middle"}
                              onClick={() => handleClickInfo(stage, "simulated")}
                            />
                          </>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>

                {/* rows separator */}
                <tr aria-hidden className={"bg-none last:hidden"}>
                  <td className={"pt-4"} />
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
