import { BlockDetailLayout } from "@/field/blocks/BlockDetailLayout";
import type { routes } from "@/routes";
import { type ParamParseKey, useParams } from "react-router-dom";

const BlockDetailRoute = () => {
  const { id, blockId } = useParams<ParamParseKey<typeof routes.BlockDetail>>();
  if (!id) throw new Error("Missing Field ID");
  if (!blockId) throw new Error("Missing Block ID");

  return <BlockDetailLayout id={id} blockId={blockId} />;
};

export default BlockDetailRoute;
