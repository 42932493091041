import { Button, FormField, Input } from "@roboton/ui";
import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormData = {
  startAt: string;
};

type FormProps = {
  form: UseFormReturn<FormData>;
  formId: string;
  isSubmitDisabled: boolean;
  onSuccessSubmit: (data: FormData) => void;
};

const Form = ({ form, formId, isSubmitDisabled, onSuccessSubmit }: FormProps) => {
  const { t } = useTranslation();
  const { formState, handleSubmit, register } = form;
  const { errors } = formState;

  return (
    <form id={formId} onSubmit={handleSubmit(onSuccessSubmit)} className={"space-y-2"}>
      <FormField
        messages={
          errors.startAt?.message
            ? [{ key: "invalid", variant: "alert", children: errors.startAt?.message }]
            : undefined
        }
      >
        <Input
          {...register("startAt", { required: true })}
          id={`${formId}-start-at`}
          type={"date"}
          label={t("Select the Start Day (First germination day)")}
          state={errors.startAt ? "negative" : undefined}
        />
      </FormField>

      <Button type={"submit"} disabled={isSubmitDisabled}>
        {t("Confirm selection and show preview")}
      </Button>
    </form>
  );
};

export { Form as CreateGrowingPlanStartAtInfoForm, type FormData as CreateGrowingPlanStartAtFormData };
