import { Select } from "@roboton/ui";
import { useTranslation } from "react-i18next";

type Option = {
  label: string;
  value: string;
};

type Value = { group: string; taskType: string };

const GroupAndTaskFieldset = ({
  groups,
  value,
  error,
  showEmptyValues,
  onChange,
}: {
  groups: (Option & { types: Option[] })[];
  value: Value;
  showEmptyValues: boolean;
  error?: boolean;
  onChange: (value: Value) => void;
}) => {
  const { t } = useTranslation();

  const taskTypesForSelectedGroup = groups.find((group) => group.value === value.group)?.types;

  const selectGroupLabel = t("Select the operation group");
  const selectTaskTypeLabel = t("Select the operation type");

  return (
    <fieldset className={"grid gap-4 sm:grid-cols-2"}>
      <legend>{t("Select the operation")}</legend>

      <Select
        id={"field-task-group"}
        aria-label={selectGroupLabel}
        value={value.group}
        state={error ? "negative" : "base"}
        onChange={(event) => {
          const newGroup = event.target.value;
          onChange({
            group: newGroup,
            taskType: groups.find((group) => group.value === newGroup)?.types[0].value || "",
          });
        }}
      >
        {showEmptyValues ? <option value={""}>{`-- ${selectGroupLabel} --`}</option> : null}
        {groups.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>

      <Select
        id={"field-task-type"}
        aria-label={selectTaskTypeLabel}
        value={value.taskType}
        state={error ? "negative" : "base"}
        onChange={(event) => {
          onChange({ ...value, taskType: event.target.value });
        }}
      >
        {showEmptyValues ? <option value={""}>{`-- ${selectTaskTypeLabel} --`}</option> : null}
        {taskTypesForSelectedGroup?.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </fieldset>
  );
};

export { GroupAndTaskFieldset, type Value as GroupAndTaskFieldsetData };
