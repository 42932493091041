import plugin from "tailwindcss/plugin";

/**
 * @param px {number}
 * @returns {string}
 */
function pxToRem(px: number) {
  return `${px / 16}rem`;
}

/**
 * @param sizeInPx {number}
 * @param lineHeightInPx {number}
 * @returns {{fontSize: string, lineHeight: string}}
 */
function getFontSize(sizeInPx: number, lineHeightInPx: number) {
  return {
    fontSize: pxToRem(sizeInPx),
    lineHeight: `${Math.round((lineHeightInPx / sizeInPx) * 100) / 100}`,
  };
}

/**
 * Reflects typography presets defined in graphics source
 */
export default plugin(({ addUtilities }) => {
  addUtilities({
    /**
     * Headings
     */
    ".typo-h-extra": { ...getFontSize(48, 64), fontWeight: "800" },
    ".typo-h1": { ...getFontSize(32, 40), fontWeight: "800" },
    ".typo-h2": { ...getFontSize(24, 32), fontWeight: "800" },
    ".typo-h3": { ...getFontSize(18, 32), fontWeight: "800" },
    ".typo-h4": { ...getFontSize(16, 24), fontWeight: "700" },
    ".typo-h5": { ...getFontSize(14, 24), fontWeight: "600", textTransform: "uppercase" },
    ".typo-h6": { ...getFontSize(14, 24), fontWeight: "600" },

    /**
     * Paragraphs
     */
    // Large
    ".typo-lg": { ...getFontSize(18, 32), fontWeight: "500" },
    ".typo-lg--link": { ...getFontSize(18, 32), fontWeight: "500", textDecoration: "underline" },
    ".typo-lg--bold": { ...getFontSize(18, 32), fontWeight: "700" },
    ".typo-lg--link--bold": { ...getFontSize(18, 32), fontWeight: "700", textDecoration: "underline" },

    // Medium
    ".typo-md": { ...getFontSize(16, 32), fontWeight: "500" },
    ".typo-md--link": { ...getFontSize(16, 32), fontWeight: "500", textDecoration: "underline" },
    ".typo-md--bold": { ...getFontSize(16, 32), fontWeight: "700" },
    ".typo-md--link--bold": { ...getFontSize(16, 32), fontWeight: "700", textDecoration: "underline" },

    // Small
    ".typo-sm": { ...getFontSize(14, 24), fontWeight: "500" },
    ".typo-sm--link": { ...getFontSize(14, 24), fontWeight: "500", textDecoration: "underline" },
    ".typo-sm--bold": { ...getFontSize(14, 24), fontWeight: "700" },
    ".typo-sm--link--bold": { ...getFontSize(14, 24), fontWeight: "700", textDecoration: "underline" },
  });
});
