import { logger } from "@/logger";
import { notify } from "@/utils/Notifications";
import { translateErrorCode } from "@/utils/useTranslationErrorCode";
import * as Sentry from "@sentry/react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { ReactNode } from "react";
import { GraphQLError } from "./errors";

const handleError = (error: unknown = { code: "global" }) => {
  let msg = translateErrorCode("GENERAL");

  if (error instanceof GraphQLError && error.code) {
    msg = translateErrorCode(error.code);
  }

  // TODO: move it to the logger
  Sentry.captureException(error);
  logger.error(error);

  notify.negative(msg);
};

const client = new QueryClient({
  mutationCache: new MutationCache({
    onError: handleError,
  }),
  queryCache: new QueryCache({
    onError: handleError,
  }),
});

type ApiProviderProps = {
  children: ReactNode;
};

export const ApiProvider = ({ children }: ApiProviderProps) => (
  <QueryClientProvider client={client}>{children}</QueryClientProvider>
);
